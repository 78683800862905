import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { map } from 'rxjs/operators';
import { JsonConvert } from "json2typescript";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { JsonConvertService } from '../../shared/json-convert/json-convert.service';
import { DataModelOverallResult, DataModelResult as DataModelResult } from "../../shared/entity/data-model.model";

@Injectable()
export class DataModelService {
  private jsonConverter: JsonConvert;

  constructor(private httpClient: HttpClient, private jsonConvertService: JsonConvertService) {
    this.jsonConverter =  this.jsonConvertService.getJsonConverter();
  }

  getAllRiskModels(clientId: string, accountId: string, scenarioId: number): Observable<DataModelOverallResult>  {
    let url = `${environment.serverUrl}/restapi/service/portfolio/riskModels/client/${clientId}/account/${encodeURIComponent(accountId)}`;
    
    if (scenarioId) {
      url = url + `/scenario/${scenarioId}`;
    }
    
    return this.httpClient.get(url)
    .pipe(
        map(response => {
            return this.jsonConverter.deserializeObject(response, DataModelOverallResult);
        }));

  }

  getAllAlphaModels(clientId: string, accountId: string, scenarioId: number): Observable<DataModelOverallResult>  {
    let url = `${environment.serverUrl}/restapi/service/portfolio/alphaModels/client/${clientId}/account/${encodeURIComponent(accountId)}`;
    
    if (scenarioId) {
      url = url + `/scenario/${scenarioId}`;
    }
    
    return this.httpClient.get(url)
    .pipe(
        map(response => {
            return this.jsonConverter.deserializeObject(response, DataModelOverallResult);
        }));

  }

  public static sortDataModelsBySelected(dataModels: DataModelResult[], newSelectedId: number) {
    const a = dataModels.map(e => e.dataModelId);
    const index = a.indexOf(Number(newSelectedId));
    const element: DataModelResult = dataModels[index];

    if (index > 0) {
      dataModels.splice(index, 1);
      dataModels.unshift(element);
    }
  }
}