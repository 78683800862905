import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('ClientDetails')
export class ClientDetails {

  @JsonProperty('clientId', String)
  clientId: string = undefined;

  @JsonProperty('name', String)
  name: string = undefined;

  @JsonProperty('clientType', String, true)
  clientType: string = undefined;

  @JsonProperty('owner', String, true)
  owner: string = undefined;

  @JsonProperty('office', String, true)
  office: string = undefined;

  @JsonProperty('status', String, true)
  status: string = undefined;

  @JsonProperty('manager', String, true)
  manager: string = undefined;

  @JsonProperty('assistantManager', String, true)
  assistantManager: string = undefined;

  @JsonProperty('assistant', String, true)
  assistant: string = undefined;

  public getValueByProperty(property): String {
    return this[property];
  }
}

@JsonObject('ClientDetailsMeta')
export class ClientDetailsMeta {

  @JsonProperty('header', String)
  header: string = undefined;

  @JsonProperty('label', String)
  label: string = undefined;

  @JsonProperty('action', String, true)
  action: string = undefined;
}

@JsonObject('ClientDetailsPackage')
export class ClientDetailsPackage {

  @JsonProperty('meta', [[ClientDetailsMeta]])
  meta: [[ClientDetailsMeta]] = undefined;

  @JsonProperty('clientDetails', ClientDetails)
  clientDetails: ClientDetails = undefined;

  @JsonProperty('editable', Boolean)
  editable: boolean = undefined;

  constructor() {}
}
