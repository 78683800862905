import * as BuyListKeFormsActions from './buy-list-ke-forms.actions';
export type BuyListKeFormsAction = BuyListKeFormsActions.All;
import { BuyListKeFormsState } from './buy-list-ke-forms.state';

export const initialState: BuyListKeFormsState  = {
  buyListKeFormConfiguration: undefined,
  buyListKeFormValues: null,
  loading: false
};

export function buyListKeFormsReducer(state: BuyListKeFormsState = initialState, action: BuyListKeFormsAction):
BuyListKeFormsState {
    switch (action.type) {

      case BuyListKeFormsActions.GET_BUY_LIST_KE_FORM_CONFIG: {
        return {...state, ...{loading: true}};
      }

      case BuyListKeFormsActions.GET_BUY_LIST_KE_FORM_CONFIG_SUCCESS: {
        const newState = {...state, ...{loading: false}};

        newState.buyListKeFormConfiguration = action.teamBuyList.config;

        newState.buyListKeFormValues = {formValue : action.teamBuyList.answers, assetClass: action.teamBuyList.assetClass};
        return {...newState};
      }

      case BuyListKeFormsActions.GET_BUY_LIST_KE_FORM_CONFIG_ERROR: {
        console.error('Error caught while loading new configuration for buy list ke form config', action.error);
        return {...initialState};
      }

      case BuyListKeFormsActions.GET_BUY_LIST_KE_FORM_VALUE_WITH_CONFIG_SUCCESS : {
        const newState = {...state, ...{loading: false}};

        newState.buyListKeFormConfiguration = action.teamBuyList.config;
        newState.buyListKeFormValues =  {formValue : action.teamBuyList.answers, assetClass: action.teamBuyList.assetClass};

        return {...newState};
      }

      case BuyListKeFormsActions.GET_BUY_LIST_KE_FORM_VALUE_WITH_CONFIG_ERROR : {
        return {...initialState};
      }

      default:
        return state;
    }
}

