import { HoldingClassNode, AssetClassNode } from './../../shared/entity/current-portfolio-model.model';
import { JsonObject, JsonProperty } from 'json2typescript';
import { MetaData } from '../../shared/entity/bmid-holding-list.model';

@JsonObject('BuyListClient')
export class BuyListClient {

	@JsonProperty('clientId', String)
  clientId: string = undefined;

	@JsonProperty('clientLastName', String)
  clientLastName: string = undefined;

  @JsonProperty('accountId', String)
  accountId: string = undefined;

	@JsonProperty('owner', String)
  owner: string = undefined;

	@JsonProperty('status', String)
  status: string = undefined;

}

@JsonObject('BuyListClients')
export class BuyListClients {

	@JsonProperty('meta', [MetaData])
	meta: MetaData[] = undefined;

	@JsonProperty('clients', [BuyListClient])
	clients: BuyListClient[] = undefined;

}

export interface BuyListKe {
  formValue: Map<string, string>;
  assetClass: string;
}

export interface AddBuyListKeRequest {
  topAssetNode: AssetClassNode;
  holdingLevelNode: HoldingClassNode;
}
