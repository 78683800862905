import * as TradeCashDetailsActions from './trade-cash-details.actions';
import { TradeCashDetailsState } from './trade-cash-details.state';

export type TradeCashDetailsAction = TradeCashDetailsActions.All;
export const initialState: TradeCashDetailsState = { tradeCashDetails: undefined, loading: false};

// tslint:disable-next-line:max-line-length
export function tradeCashDetailsReducer(state: TradeCashDetailsState = initialState, action: TradeCashDetailsAction): TradeCashDetailsState {
  switch (action.type) {

    case TradeCashDetailsActions.GET_TRADE_CASH_DETAILS: {
      return {...state, ...{loading: true}};

    }

    case TradeCashDetailsActions.GET_TRADE_CASH_DETAILS_SUCCESS: {
      return {...state, ...{tradeCashDetails: action.tradeCashDetails}, ...{loading: false}};
    }

    case TradeCashDetailsActions.GET_TRADE_CASH_DETAILS_ERROR: {
      console.error('Error caught while loading Trade Cash Details', action.error);
      return {...initialState};
    }

    default :
      return state;
  }

}
