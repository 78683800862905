import { DataModelService } from '../../../clients/service/data-model.service';
import * as AlphaModelListActions from './alpha-model-selection.actions';

export type DataModelListAction = AlphaModelListActions.All;

import { DataModelResultListState } from './data-model-selection.state';

export const initialAlphaState: DataModelResultListState  = { dataModels: new Array(), loading: false, selected: null, loaded: false, requireReload: false};

export function alphaModelListReducer(state: DataModelResultListState = initialAlphaState, action: DataModelListAction):
DataModelResultListState {
    switch (action.type) {

      case AlphaModelListActions.GET_ALL_ALPHA_MODELS: {
        return {...state, ...{loading: true}};

      }

      case AlphaModelListActions.GET_ALL_ALPHA_MODELS_SUCCESS: {
        return {...state, ...{dataModels: action.result.dataModels}, ...{loading: false}, ...{selected: action.result.selected}, ...{loaded: true}};
      }

      case AlphaModelListActions.GET_ALL_ALPHA_MODELS_ERROR: {
        console.error('Error caught while loading alpha models list', action.error);
        return {...state};
      }

      case AlphaModelListActions.UPDATE_CURRENT_ALPHA_SELECTION: {
        return { ...state, ...{selected: action.newAlphaSelection} }
      }

      case AlphaModelListActions.SORT_ALPHA_SELECTIONS: {
        const dataModels = state.dataModels;
        DataModelService.sortDataModelsBySelected(dataModels, state.selected);
        return { ...state, ...{dataModels: dataModels}}
      }

      case AlphaModelListActions.PRECALL_RESET: {
        return { ...state, ...{requireReload: true}};
      }

      default :
        return state;
    }
}
