import * as ReportsActions from './reports.actions';
import { ReportsState } from './reports.state';

import * as _ from 'lodash';
import { ReportRequest, ReportRequestStatus } from '../../entity/report-request.model';

export type ReportsAction = ReportsActions.All;
export const initialState: ReportsState = { reports: new Array<ReportRequest>(), loading: false};

export function reportsReducer(state: ReportsState = initialState, action: ReportsAction):
ReportsState {
    switch (action.type) {

      case ReportsActions.ADD_REPORT_REQUEST: {
        const reportRequest: ReportRequest = _.find(state.reports, {requestId: action.reportRequest.requestId});
        if (!reportRequest) {
          state.reports.unshift(action.reportRequest);
        }
        return { ...state };
      }

      case ReportsActions.REPORT_REQUEST_COMPLETE: {
        const reportRequest: ReportRequest = _.find(state.reports, {requestId: action.reportRequestId});
        reportRequest.response = action.response;
        reportRequest.status = ReportRequestStatus.Success;
        return { ...state };
      }

      case ReportsActions.REPORT_REQUEST_ERROR: {
        const reportRequest: ReportRequest = _.find(state.reports, {requestId: action.reportRequestId});
        reportRequest.status = ReportRequestStatus.Error;
        return { ...state };
      }

      default:
        return state;
    }
}
