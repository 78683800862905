import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { PasswordComponent } from './password/password.component';
import { AuthenticationService } from './authentication.service';
import { AuthenticatedGuard } from './authenticated.guard';
import { AuthorizationGuard } from './authorization.guard';
import { CopyrightComponent } from './copyright/copyright.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    CopyrightComponent
  ],
  declarations: [AuthComponent, LoginComponent, PasswordComponent, CopyrightComponent],
  providers: [AuthenticationService, AuthenticatedGuard, AuthorizationGuard]
})
export class AuthModule { }
