import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';
import { ClientPmpService } from '../../../shared/pmp/pmp.service';
import * as IssueActions from './issues.actions';
import { map, catchError, switchMap } from 'rxjs/operators';

export type IssueAction = IssueActions.All;

@Injectable()
export class IssuesEffects {

	constructor(private actions$: Actions,
							private clientSlwiService: ClientPmpService) {
	}

	getAllIssues: Observable<IssueAction> = createEffect(() => 
  this.actions$.pipe(
    ofType(IssueActions.GET_WIDGET_ISSUES),
    switchMap((a: IssueActions.GetWidgetIssues) =>  this.clientSlwiService.getAllWidgetIssues(a.clientId, a.accountId).pipe(
      map(post => new IssueActions.GetWidgetIssuesSuccess(post)),
      catchError((error) => of(new IssueActions.GetWidgetIssuesError(error)))
    )
	)));
}
