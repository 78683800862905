export function mockAABoundaryDeviations() {

  return {
    tacLevelCurrentVsTarget: [
      {id: '1', assetName: 'Cash', deviation: -5},
      {id: '2', assetName: 'Alts', deviation: -10},
      {id: '3', assetName: 'Property', deviation: -8},
      {id: '4', assetName: 'Intl EQ', deviation: -15},
      {id: '5', assetName: 'UK Eq', deviation: 30},
      {id: '6', assetName: 'Fixed Inc', deviation: -5}
    ],
    tacLevelProposedVsTarget: [
      {id: '1', assetName: 'Cash', deviation: -1},
      {id: '2', assetName: 'Alts', deviation: -10},
      {id: '3', assetName: 'Property', deviation: -3},
      {id: '4', assetName: 'Intl EQ', deviation: -5},
      {id: '5', assetName: 'UK Eq', deviation: 20},
      {id: '6', assetName: 'Fixed Inc', deviation: 0}
    ]
  };
}
