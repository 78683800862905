import { Action } from '@ngrx/store';

import { WidgetAlert } from '../../entity/widget-alert.model';

export const GET_WIDGET_ALERTS = '[Alert] GET_WIDGET_ALERTS';
export const GET_WIDGET_ALERTS_SUCCESS = '[Alert]  GET_WIDGET_ALERTS_SUCCESS';
export const GET_WIDGET_ALERTS_ERROR = '[Alert] GET_WIDGET_ALERTS_ERROR';

export class GetWidgetAlerts implements Action {
	readonly type = GET_WIDGET_ALERTS;
	constructor(public clientId: string, public accountId: string) {}
}

export class GetWidgetAlertsSuccess implements Action {
	readonly type = GET_WIDGET_ALERTS_SUCCESS;
	constructor(public alerts: WidgetAlert[]) {}
}

export class GetWidgetAlertsError implements Action {
	readonly type = GET_WIDGET_ALERTS_ERROR;
	constructor(public error: any) {}
}

export type All = GetWidgetAlerts | GetWidgetAlertsSuccess | GetWidgetAlertsError;
