import { JsonObject, JsonProperty } from 'json2typescript';
import { AssetColumnMapConverter } from '../json-convert/assetColumnMapConverter';
import { AssetColumn } from './asset-column.model';

@JsonObject('AssetColumnHeader')
export class AssetColumnHeader {
  @JsonProperty('category', String)
  category: string = undefined;
  
  @JsonProperty('title', String)
  title: string = undefined;

  @JsonProperty('hidden', Boolean, true)
  hidden: boolean = undefined;

  @JsonProperty('columns', AssetColumnMapConverter)
  columns: Map<string, AssetColumn> = undefined;
}
