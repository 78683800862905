<div *ngIf="user && isNavigationVisible">
  <div class="btn-group" dropdown>
    <button dropdownToggle type="button" class="btn btn-link user-menu dropdown-toggle">
      {{user.userName}} <span class="caret"></span>
    </button>
    <div *dropdownMenu class="dropdown-menu" role="menu">
      <a class="dropdown-item" (click)="logout()">
        <fa-icon [icon]="faSignOutAlt"></fa-icon> Logout
      </a>
      <div class="dropdown-divider"></div>
      <h6 class="dropdown-header version-details" *ngFor="let versionDetail of versionDetails">{{versionDetail}}</h6>
      <div class="dropdown-divider"></div>
      <ng-container *ngIf="hasUserRole">
        <h6  class="dropdown-header">Previously viewed clients</h6>
        <a class="dropdown-item client-history" *ngFor="let clientId of clientHistory"
          (click)="redirectToClientHomePage(clientId)">{{clientId}}</a>
      </ng-container>
    </div>
  </div>
</div>
