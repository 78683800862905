import {JsonObject, JsonProperty} from 'json2typescript';
import { Moment } from 'moment';
import { DateConverter } from '../json-convert/DateConvertor';

@JsonObject('DataModelResult')
export class DataModelResult {

	@JsonProperty('dataModelId', Number)
	dataModelId: number = undefined;

	@JsonProperty('alias', String)
    alias: String = undefined;

	@JsonProperty('currency', String)
    currency: String = undefined;

	@JsonProperty('createdDate', DateConverter)
    createdDate: Moment = undefined;
}

@JsonObject('DataModelOverallResult')
export class DataModelOverallResult {
	@JsonProperty('dataModels', [DataModelResult])
    dataModels: DataModelResult[] = undefined;

	@JsonProperty('selected', Number)
    selected: number = undefined;
}