import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('FileNoteType')
export class FileNoteType {

  @JsonProperty('label', String)
  label: number = undefined;

  @JsonProperty('value', String)
  value: string = undefined;

  @JsonProperty('level', String)
  level: string = undefined;

  @JsonProperty('selected', Boolean)
  selected: boolean = undefined;
}
