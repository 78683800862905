export function mockAccountCurrentPortfolio() {
  return {
    'clientId': '641580',
    'accountId': 'P6415800000',
    'accountType': 'MandateAccount',
    'accountEditable': false,
    'lookthroughEnabled': false,
    'currency': {
      'currencyCode': 'GBP',
      'currencySymbol': '£'
    },
    'topLevelNode': [
      {
        'asset': {
          'id': 4,
          'name': 'Fixed Interest - United Kingdom',
          'label': 'Fixed Interest - United Kingdom',
          'isComposite': false
        },
        'currentPosition': {
          'shares': 0,
          'value': 0,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 0
        },
        'subAssetNode': [
          {
            'asset': {
              'id': 37,
              'name': 'sac_Other UK Fixed Interest',
              'label': 'Other UK Fixed Interest',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 38,
              'name': 'sac_UK Government Securities',
              'label': 'UK Government Securities',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 39,
              'name': 'sac_UK Index Linked Securities',
              'label': 'UK Index Linked Securities',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          }
        ]
      },
      {
        'asset': {
          'id': 5,
          'name': 'Fixed Interest - Overseas',
          'label': 'Fixed Interest - Overseas',
          'isComposite': false
        },
        'currentPosition': {
          'shares': 0,
          'value': 0,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 0
        },
        'subAssetNode': [
          {
            'asset': {
              'id': 36,
              'name': 'sac_Fixed Interest - Overseas',
              'label': 'Fixed Interest - Overseas',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          }
        ]
      },
      {
        'asset': {
          'id': 6,
          'name': 'Equities - United Kingdom',
          'label': 'Equities - United Kingdom',
          'isComposite': false
        },
        'currentPosition': {
          'shares': 0,
          'value': 0,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 0
        },
        'subAssetNode': [
          {
            'asset': {
              'id': 25,
              'name': 'sac_Basic Materials',
              'label': 'Basic Materials',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 26,
              'name': 'sac_Consumer Goods',
              'label': 'Consumer Goods',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 27,
              'name': 'sac_Consumer Services',
              'label': 'Consumer Services',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 28,
              'name': 'sac_Financials',
              'label': 'Financials',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 29,
              'name': 'sac_Health Care',
              'label': 'Health Care',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 30,
              'name': 'sac_Industrials',
              'label': 'Industrials',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 31,
              'name': 'sac_Oil & Gas',
              'label': 'Oil & Gas',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 32,
              'name': 'sac_Others',
              'label': 'Others',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 33,
              'name': 'sac_Technology',
              'label': 'Technology',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 34,
              'name': 'sac_Telecommunications',
              'label': 'Telecommunications',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 35,
              'name': 'sac_Utilities',
              'label': 'Utilities',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          }
        ]
      },
      {
        'asset': {
          'id': 7,
          'name': 'Equities - Overseas',
          'label': 'Equities - Overseas',
          'isComposite': false
        },
        'currentPosition': {
          'shares': 0,
          'value': 0,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 0
        },
        'subAssetNode': [
          {
            'asset': {
              'id': 19,
              'name': 'sac_Equities - Asia Pacific ex Japan',
              'label': 'Equities - Asia Pacific ex Japan',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 20,
              'name': 'sac_Equities - Emerging Markets',
              'label': 'Equities - Emerging Markets',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 21,
              'name': 'sac_Equities - Europe ex UK',
              'label': 'Equities - Europe ex UK',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 22,
              'name': 'sac_Equities - Global',
              'label': 'Equities - Global',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 23,
              'name': 'sac_Equities - Japan',
              'label': 'Equities - Japan',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 24,
              'name': 'sac_Equities - North America',
              'label': 'Equities - North America',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          }
        ]
      },
      {
        'asset': {
          'id': 8,
          'name': 'Cash',
          'label': 'Cash Products',
          'isComposite': false
        },
        'currentPosition': {
          'shares': 0,
          'value': 0,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 0
        },
        'subAssetNode': [
          {
            'asset': {
              'id': 18,
              'name': 'sac_Cash',
              'label': 'Cash Products',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          }
        ]
      },
      {
        'asset': {
          'id': 9,
          'name': 'Alternative Investments',
          'label': 'Alternative Investments',
          'isComposite': false
        },
        'currentPosition': {
          'shares': 0,
          'value': 534,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 100
        },
        'subAssetNode': [
          {
            'asset': {
              'id': 11,
              'name': 'sac_Absolute Return',
              'label': 'Absolute Return',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 534,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 100
            },
            'holdingNode': [
              {
                'source': 'S',
                'excludedHolding': false,
                'asset': {
                  'id': 4145,
                  'name': 'BYQLMX1',
                  'label': 'Highbridge Multi-Strategy Fund Ltd ord NPV GBP (Assd Cash Exit)',
                  'isComposite': false
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 534,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 100
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '6415804 V641580-P6415800000-6415804',
                    'accountId': 42971,
                    'accountName': '6415804',
                    'accountDescription': 'V641580-P6415800000-6415804',
                    'currentPosition': {
                      'shares': 0,
                      'value': 534,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 100
                    }
                  }
                ]
              }
            ]
          },
          {
            'asset': {
              'id': 12,
              'name': 'sac_Commodities',
              'label': 'Commodities',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 13,
              'name': 'sac_Infrastructure',
              'label': 'Infrastructure',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 14,
              'name': 'sac_Private Equity',
              'label': 'Private Equity',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 15,
              'name': 'sac_Property',
              'label': 'Property',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 16,
              'name': 'sac_Structured Products',
              'label': 'Structured Products',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'asset': {
              'id': 17,
              'name': 'sac_Total Return',
              'label': 'Total Return',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          }
        ]
      },
      {
        'asset': {
          'id': 10,
          'name': 'Unclassified',
          'label': 'Unclassified',
          'isComposite': false
        },
        'currentPosition': {
          'shares': 0,
          'value': 0,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 0
        },
        'subAssetNode': [
          {
            'asset': {
              'id': 40,
              'name': 'sac_Unclassified',
              'label': 'Unclassified',
              'isComposite': false
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          }
        ]
      }
    ],
    'total': {
      'shares': 0,
      'value': 534.00,
      'yield': 0,
      'ctr': 0,
      'mctr': 0,
      'weight': 100.00
    }
  };
}
