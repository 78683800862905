import { Action } from '@ngrx/store';

import { KnownExceptionTypesState } from './knownExceptionTypes.state';
import { KnownExceptionType } from '../../../entity/known-exception-type.model';

export const GET_KNOWN_EXCEPTION_TYPES = '[KnownExceptionType] GET_KNOWN_EXCEPTION_TYPES';
export const GET_KNOWN_EXCEPTION_TYPES_SUCCESS = '[KnownExceptionType]  GET_KNOWN_EXCEPTION_TYPES_SUCCESS';
export const GET_KNOWN_EXCEPTION_TYPES_ERROR = '[KnownExceptionType] GET_KNOWN_EXCEPTION_TYPES_ERROR';

export class GetKnownExceptionTypes implements Action {
	readonly type = GET_KNOWN_EXCEPTION_TYPES;
}

export class GetKnownExceptionTypesSuccess implements Action {
	readonly type = GET_KNOWN_EXCEPTION_TYPES_SUCCESS;
	constructor(public knownExceptionTypes: KnownExceptionType[]) {}
}

export class GetKnownExceptionTypesError implements Action {
	readonly type = GET_KNOWN_EXCEPTION_TYPES_ERROR;
	constructor(public error: any) {}
}

export type All = GetKnownExceptionTypes | GetKnownExceptionTypesSuccess | GetKnownExceptionTypesError;
