import { JsonObject, JsonProperty } from 'json2typescript';
import { Moment } from 'moment';
import { DateConverter } from '../../../shared/json-convert/DateConvertor';
import { TcfdPortfolioInfo } from './tcfd-account-details.model';
import { AssetValueChanges, GhgHistoryMetrics, HighEmissionHistoryMetrics, TcfdHeadlineChangeMetrics, TcfdStyleInfo } from './tcfd-shared.model';

@JsonObject('TcfdHistoryChangeMetrics')
export class TcfdHistoryChangeMetrics {}


@JsonObject('HistoryHeadlineChangeMetrics')
export class HistoryHeadlineChangeMetrics {
  @JsonProperty('assetTypeLabel', String)
  assetTypeLabel: string = undefined;

  @JsonProperty('headline', String)
  headline: string = undefined;

  @JsonProperty('portfolio', TcfdHeadlineChangeMetrics, true)
  portfolio: TcfdHeadlineChangeMetrics = undefined;

  @JsonProperty('benchmark', TcfdHeadlineChangeMetrics, true)
  benchmark: TcfdHeadlineChangeMetrics = undefined;

  @JsonProperty('benchmarkRelative', TcfdHeadlineChangeMetrics, true)
  benchmarkRelative: TcfdHeadlineChangeMetrics = undefined;

  @JsonProperty('portfolioCoverage', TcfdHeadlineChangeMetrics, true)
  portfolioCoverage: TcfdHeadlineChangeMetrics = undefined;

  @JsonProperty('valueWithData', Number)
  valueWithData: number = undefined;

  @JsonProperty('styleInfo', TcfdStyleInfo, true)
  styleInfo: TcfdStyleInfo = undefined;
}

@JsonObject('TcfdHistoryMetrics')
export class TcfdHistoryMetrics {
  @JsonProperty('title', String)
  title: string = undefined;

  @JsonProperty('startDate', DateConverter)
  startDate: Moment = undefined;

  @JsonProperty('endDate', DateConverter)
  endDate: Moment = undefined;

  @JsonProperty('headlineChanges', [HistoryHeadlineChangeMetrics], true)
  headlineChanges: HistoryHeadlineChangeMetrics[] = undefined;

  @JsonProperty('lowEmissionsHistory', [HighEmissionHistoryMetrics], true)
  lowEmissionsHistory: HighEmissionHistoryMetrics[] = undefined;

  @JsonProperty('highEmissionsHistory', [HighEmissionHistoryMetrics], true)
  highEmissionsHistory: HighEmissionHistoryMetrics[] = undefined;

  @JsonProperty('currency', String)
  currency: string = undefined;

  @JsonProperty('currencySymbol', String)
  currencySymbol: string = undefined;
}

@JsonObject('TcfdHistory')
export class TcfdHistory {
  @JsonProperty('portfolioInfo', TcfdPortfolioInfo)
  portfolioInfo: TcfdPortfolioInfo = undefined;

  @JsonProperty('assetValueChanges', AssetValueChanges)
  assetValueChanges: AssetValueChanges = undefined;

  @JsonProperty('ghgEmissions', GhgHistoryMetrics)
  ghgEmissions: GhgHistoryMetrics = undefined;

  @JsonProperty('carbonFootprint', TcfdHistoryMetrics)
  carbonFootprint: TcfdHistoryMetrics = undefined;

  @JsonProperty('weightedAverageCarbonIntensity', TcfdHistoryMetrics)
  weightedAverageCarbonIntensity: TcfdHistoryMetrics = undefined;

  @JsonProperty('impliedTemperatureRise', TcfdHistoryMetrics, true)
  impliedTemperatureRise: TcfdHistoryMetrics = undefined;

  @JsonProperty('climateValueAtRisk', TcfdHistoryMetrics, true)
  climateValueAtRisk: TcfdHistoryMetrics = undefined;

  @JsonProperty('specificExposures', TcfdHistoryMetrics)
  specificExposures: TcfdHistoryMetrics = undefined;
}
