import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('AssetRiskContribution')
export class AssetRiskContribution {

    @JsonProperty('id', Number, true)
    id: number = undefined;

    @JsonProperty('assetName', String)
    assetName: string = undefined;

    @JsonProperty('currentRiskContribution', Number)
    currentRiskContribution: number = undefined;

    @JsonProperty('proposedRiskContribution', Number)
    proposedRiskContribution: number = undefined;

 }
