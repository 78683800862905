import { Action } from '@ngrx/store';

// import { MonitorPortfolio } from '../../../entity/monitor-portfolio.model';
import { AssetResult } from '../../../shared/entity/asset.model';


export const GET_ALL_ASSETS = '[AssetList] GET_ALL_ASSETS';
export const GET_ALL_ASSETS_SUCCESS = '[AssetList]  GET_ALL_ASSETS_SUCCESS';
export const GET_ALL_ASSETS_ERROR = '[AssetList] GET_ALL_ASSETS_ERROR';

export class GetAllAssets implements Action {
  readonly type = GET_ALL_ASSETS;
  constructor(public clientId?: string, public accountId?: string) {}
}

export class GetAllAssetsSuccess implements Action {
	readonly type = GET_ALL_ASSETS_SUCCESS;
	constructor(public assets: AssetResult[]) {}
}

export class GetAllAssetsError implements Action {
	readonly type = GET_ALL_ASSETS_ERROR;
	constructor(public error: any) {}
}

export type All = GetAllAssets | GetAllAssetsSuccess | GetAllAssetsError;
