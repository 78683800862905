import { Action } from '@ngrx/store';

// import { MonitorPortfolio } from '../../../entity/monitor-portfolio.model';
import { DataModelOverallResult as RiskModelOverallResult } from '../../entity/data-model.model';


export const GET_ALL_RISK_MODELS = '[DataModelList] GET_ALL_RISK_MODELS';
export const GET_ALL_RISK_MODELS_SUCCESS = '[DataModelList]  GET_ALL_RISK_MODELS_SUCCESS';
export const GET_ALL_RISK_MODELS_ERROR = '[DataModelList] GET_ALL_RISK_MODELS_ERROR';
export const UPDATE_CURRENT_RISK_SELECTIONS = '[DataModelList] UPDATE_CURRENT_RISK_SELECTION';
export const SORT_RISK_SELECTIONS = '[DataModelList] SORT_RISK_SELECTIONS';
export const PRECALL_RESET = '[DataModelList] PRECALL_RESET';

export class GetAllRiskModels implements Action {
  readonly type = GET_ALL_RISK_MODELS;
  constructor(public clientId: string, public accountId: string, public scenarioId: number) {}
}

export class GetAllRiskModelsSuccess implements Action {
	readonly type = GET_ALL_RISK_MODELS_SUCCESS;
	constructor(public result: RiskModelOverallResult) {}
}

export class UpdateCurrentRiskSelection implements Action {
	readonly type = UPDATE_CURRENT_RISK_SELECTIONS;
	constructor(public newRiskSelection: number) {}
}

export class SortRiskSelections implements Action {
	readonly type = SORT_RISK_SELECTIONS;
	constructor() {}
}

export class GetAllRiskModelsError implements Action {
	readonly type = GET_ALL_RISK_MODELS_ERROR;
	constructor(public error: any) {}
}

export class PrecallReset implements Action {
	readonly type = PRECALL_RESET;
	constructor() {}
}

export type All = GetAllRiskModels | GetAllRiskModelsSuccess | GetAllRiskModelsError 
					| UpdateCurrentRiskSelection | SortRiskSelections
					| PrecallReset;
