export function mockAccountCurrentPortfolioLookThrough() {
  return {
    'clientId': 'SLW1821778',
    'accountId': 'BMID001HH',
    'scenarioId': '',
    'currency': {
      'currencyCode': 'GBP',
      'currencySymbol': '£'
    },
    'lookthroughEnabled': true,

    'topLevelNode': [{
      'asset': {
        'id': 4,
        'name': 'Fixed_Income',
        'label': 'Fixed Income',
        'isComposite': false
      },
      'currentPosition': {
        'weight': 25,
        'shares': 0,
        'value': 25000,
        'yield': 500,
        'ctr': 5.5,
        'mctr': 0
      },
      'subAssetNode': [
        {
          'asset': {
            'id': 1011,
            'name': 'Conventional_Gifts',
            'label': 'Conventional Gifts',
            'isComposite': false
          },
          'currentPosition': {
            'weight': 10,
            'shares': 0,
            'value': 10000,
            'yield': 500,
            'ctr': 5.5,
            'mctr': 0
          },
          'holdingNode': [
            {
              'source': 'S',
              'asset': {
                'id': 1205,
                'name': '9900015',
                'label': 'Product GFI Government Stock',
                'isComposite': false
              },
              'currentPosition': {
                'weight': 10,
                'shares': 540,
                'value': 10000,
                'yield': 500,
                'ctr': 5.5,
                'mctr': 0
              },
              'breakdownElements': [{
                'compositeAssetLabel': 'Direct Holding',
                'currentPosition': {
                  'weight': 10,
                  'shares': 324,
                  'value': 10000,
                  'yield': 300,
                  'ctr': 3.3,
                  'mctr': 0
                },
              }]
            }
          ]
        }
      ]
    },
    {
      'asset': {
        'id': 40,
        'name': 'International_Equity',
        'label': 'International Equity',
        'isComposite': false
      },
      'currentPosition': {
        'weight': 30,
        'shares': 0,
        'value': 50000,
        'yield': 500,
        'ctr': 5.5,
        'mctr': 0
      },
      'subAssetNode': [
        {
          'asset': {
            'id': 100,
            'name': 'North America',
            'label': 'North America',
            'isComposite': false
          },
          'currentPosition': {
            'weight': 10,
            'shares': 0,
            'value': 30000,
            'yield': 500,
            'ctr': 5.5,
            'mctr': 0
          },
          'holdingNode': [
            {
              'source': 'S',
              'asset': {
                'id': 1705,
                'name': '9900035',
                'label': 'Product North America Equities',
                'isComposite': false
              },
              'currentPosition': {
                'weight': 10,
                'shares': 540,
                'value': 10000,
                'yield': 500,
                'ctr': 5.5,
                'mctr': 0
              },
              'breakdownElements': [{
                'compositeAssetLabel': 'Direct Holding',
                'currentPosition': {
                  'weight': 10,
                  'shares': 216,
                  'value': 10000,
                  'yield': 200,
                  'ctr': 2.2,
                  'mctr': 0
                }
              }]
            }
          ]
        },
        {
          'asset': {
            'id': 160,
            'name': 'Japan',
            'label': 'Japan',
            'isComposite': false
          },
          'currentPosition': {
            'weight': 20,
            'shares': 0,
            'value': 20000,
            'yield': 500,
            'ctr': 5.5,
            'mctr': 0
          },
          'holdingNode': [
            {
              'source': 'S',
              'asset': {
                'id': 1206,
                'name': '9900344',
                'label': 'Product Japan Equities',
                'isComposite': false
              },
              'currentPosition': {
                'weight': 10,
                'shares': 540,
                'value': 10000,
                'yield': 500,
                'ctr': 5.5,
                'mctr': 0
              },
              'breakdownElements': [{
                'compositeAssetLabel': 'Direct Holding',
                'currentPosition': {
                  'weight': 10,
                  'shares': 324,
                  'value': 10000,
                  'yield': 300,
                  'ctr': 3.3,
                  'mctr': 0
                },
              }]
            },
            {
              'source': 'M',
              'asset': {
                'id': 1215,
                'name': '99000114',
                'label': 'Yokohama Tyres Ltd.',
                'isComposite': false
              },
              'currentPosition': {
                'weight': 10,
                'shares': 540,
                'value': 10000,
                'yield': 500,
                'ctr': 5.5,
                'mctr': 0
              },
              'breakdownElements': [{
                'compositeAssetLabel': 'Treasury 3.75% T-GILT 9/2/21 FUND',
                'currentPosition': {
                  'weight': 10,
                  'shares': 216,
                  'value': 5000,
                  'yield': 200,
                  'ctr': 2.2,
                  'mctr': 0
                }
              }]
            }
          ]
        },
        {
          'asset': {
            'id': 170,
            'name': 'Asia_Pacific',
            'label': 'Asia Pacific',
            'isComposite': false
          },
          'currentPosition': {
            'weight': 15,
            'shares': 0,
            'value': 10000,
            'yield': 500,
            'ctr': 5.5,
            'mctr': 0
          },
          'holdingNode': [
            {
              'source': 'S',
              'asset': {
                'id': 1201,
                'name': '9900244',
                'label': 'Product Asia Pacific Equities',
                'isComposite': false
              },
              'currentPosition': {
                'weight': 15,
                'shares': 540,
                'value': 10000,
                'yield': 500,
                'ctr': 5.5,
                'mctr': 0
              },
              'breakdownElements': [{
                'compositeAssetLabel': 'Direct Holding',
                'currentPosition': {
                  'weight': 10,
                  'shares': 216,
                  'value': 10000,
                  'yield': 200,
                  'ctr': 2.2,
                  'mctr': 0
                }
              }]
            },
            {
              'source': 'M',
              'asset': {
                'id': 1305,
                'name': '99000114',
                'label': 'Umbrella Corp Ltd.',
                'isComposite': false
              },
              'currentPosition': {
                'weight': 5,
                'shares': 540,
                'value': 10000,
                'yield': 500,
                'ctr': 5.5,
                'mctr': 0
              },
              'breakdownElements': [{
                'compositeAssetLabel': 'Treasury 3.75% T-GILT 9/2/21 FUND',
                'currentPosition': {
                  'weight': 5,
                  'shares': 216,
                  'value': 5000,
                  'yield': 200,
                  'ctr': 2.2,
                  'mctr': 0
                }
              }]
            }
          ]
        }
      ]
    },
    {
      'asset': {
        'id': 5,
        'name': 'Bonds',
        'label': 'Bonds',
        'isComposite': false
      },
      'currentPosition': {
        'weight': 25,
        'shares': 0,
        'value': 25000,
        'yield': 1000,
        'ctr': 7,
        'mctr': 0
      },
      'subAssetNode': [
        {
          'asset': {
            'id': 22,
            'name': 'sac_High Yield Bonds',
            'label': 'High Yield Bond Funds',
            'isComposite': false
          },
          'currentPosition': {
            'weight': 25,
            'shares': 0,
            'value': 25000,
            'yield': 1000,
            'ctr': 7,
            'mctr': 0
          },
          'holdingNode': [
            {
              'source': 'M',
              'asset': {
                'id': 1343,
                'name': 'B0V9T86',
                'label': 'PIMCO GLOBAL ADVIS GBL HIGH YLD BOND HGD INSTL',
                'isComposite': false
              },
              'currentPosition': {
                'weight': 10,
                'shares': 340,
                'value': 10000,
                'yield': 600,
                'ctr': 4.5,
                'mctr': 0
              },
              'breakdownElements': [{
                'compositeAssetLabel': 'Direct Holding',
                'currentPosition': {
                  'weight': 10,
                  'shares': 324,
                  'value': 10000,
                  'yield': 300,
                  'ctr': 3.3,
                  'mctr': 0
                },
              }]
            },
            {
              'source': 'M',
              'asset': {
                'id': 1874,
                'name': 'B7SGDT8',
                'label': 'THREADNEEDLE INV HIGH YIELD BOND ZNI INC NAV',
                'isComposite': false
              },
              'currentPosition': {
                'weight': 15,
                'shares': 430,
                'value': 15000,
                'yield': 400,
                'ctr': 2.5,
                'mctr': 0
              },
              'breakdownElements': [{
                'compositeAssetLabel': 'Direct Holding',
                'currentPosition': {
                  'weight': 15,
                  'shares': 324,
                  'value': 15000,
                  'yield': 300,
                  'ctr': 3.3,
                  'mctr': 0
                },
              }]
            },
          ]
        },
        {
          'asset': {
            'id': 120,
            'name': 'Energy_Company_Bond',
            'label': 'Energy Copmany Bond',
            'isComposite': false
          },
          'currentPosition': {
            'weight': 20,
            'shares': 0,
            'value': 20000,
            'yield': 500,
            'ctr': 5.5,
            'mctr': 0
          },
          'holdingNode': [
            {
              'source': 'S',
              'asset': {
                'id': 3205,
                'name': '9904344',
                'label': 'Preston Green Fuel Ltd USD',
                'isComposite': false
              },
              'currentPosition': {
                'weight': 10,
                'shares': 540,
                'value': 10000,
                'yield': 500,
                'ctr': 5.5,
                'mctr': 0
              },
              'breakdownElements': [{
                'compositeAssetLabel': 'FINDLAY PARK FUNDS PLC AMERICAN USD DIS',
                'currentPosition': {
                  'weight': 10,
                  'shares': 324,
                  'value': 10000,
                  'yield': 300,
                  'ctr': 3.3,
                  'mctr': 0
                },
              }]
            },
            {
              'source': 'M',
              'asset': {
                'id': 1405,
                'name': '99000214',
                'label': 'Donnovan Nuclear Tech Ltd',
                'isComposite': false
              },
              'currentPosition': {
                'weight': 10,
                'shares': 540,
                'value': 10000,
                'yield': 500,
                'ctr': 5.5,
                'mctr': 0
              },
              'breakdownElements': [{
                'compositeAssetLabel': 'FINDLAY PARK FUNDS PLC AMERICAN USD DIS',
                'currentPosition': {
                  'weight': 10,
                  'shares': 216,
                  'value': 5000,
                  'yield': 200,
                  'ctr': 2.2,
                  'mctr': 0
                }
              }]
            }
          ]
        }
      ]
    },
    {
      'asset': {
        'id': 34,
        'name': 'Cash',
        'label': 'Cash',
        'isComposite': false
      },
      'currentPosition': {
        'weight': 25,
        'shares': 0,
        'value': 25000,
        'yield': 500,
        'ctr': 5.5,
        'mctr': 0
      },
      'subAssetNode': [
        {
        'asset': {
          'id': 140,
          'name': 'Cash',
          'label': 'Cash',
          'isComposite': false
        },
        'currentPosition': {
          'weight': 5,
          'shares': 0,
          'value': 5000,
          'yield': 0,
          'ctr': 5.5,
          'mctr': 0
        },
        'holdingNode': [
          {
          'source': 'S',
          'asset': {
            'id': 1505,
            'name': '9900244',
            'label': 'British Sterling Pounds',
            'isComposite': false
          },
          'currentPosition': {
            'weight': 5,
            'shares': 540,
            'value': 5000,
            'yield': 500,
            'ctr': 5.5,
            'mctr': 0
          },
          'breakdownElements': [{
            'compositeAssetLabel': 'Direct Holding',
            'currentPosition': {
              'weight': 5,
              'shares': 216,
              'value': 5000,
              'yield': 200,
              'ctr': 2.2,
              'mctr': 0
            }
          }]
        }]
      }
    ]
  }
  ],
    'currentPortfolioTotal': {
      'weight': 100,
      'shares': 0,
      'value': 100000,
      'yield': 1500,
      'ctr': 12.5,
      'mctr': 0
    }
  };
}
