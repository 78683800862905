import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventNotificationService {

  activity(event) {
    window.top.postMessage({
			name: 'bitarisk.activity',
			lastEventType: event.type,
			timeStamp: Date.now()
		}, '*');
  }

  workflow(name: string, status: string) {
    window.top.postMessage({
			name: 'bitarisk.workflow',
      workflowName: name,
      workflowStatus: status,
			timeStamp: Date.now()
		}, '*');
  }

}
