import * as ClientDetailsActions from './client-details.actions';
import { ClientDetailsPackage } from '../../entity/client-details.model';
import { ClientDetailsState } from './client-details.state';

export type ClientDetailsAction = ClientDetailsActions.All;
export const initialState: ClientDetailsState = { clientDetailsPackage : new ClientDetailsPackage(), loading: false};

export function clientDetailsReducer(state: ClientDetailsState = initialState, action: ClientDetailsAction): ClientDetailsState {
  switch (action.type) {

    case ClientDetailsActions.GET_CLIENT_DETAILS: {
      return {...state, ...{loading: true}};

    }

    case ClientDetailsActions.GET_CLIENT_DETAILS_SUCCESS: {
      return {...state, ...{clientDetailsPackage: action.clientDetails}, ...{loading: false}};
    }

    case ClientDetailsActions.GET_CLIENT_DETAILS_ERROR: {
      console.error('Error caught while loading Client-Home-Details', action.error);
      return {...initialState};
    }

    default :
      return state;
  }
}
