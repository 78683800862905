import { Action } from '@ngrx/store';

import * as ClientAccountDetailsActions from './client-account-details.actions';
import { ClientAccountDetails } from '../../entity/client-account-details.model';
import { ClientAccountDetailsState } from './client-account-details.state';

export type ClientDetailsAction = ClientAccountDetailsActions.All;
export const initialState: ClientAccountDetailsState = { clientAccountDetails: new Array<ClientAccountDetails>(), loading: false};

export function clientAccountDetailsReducer(state: ClientAccountDetailsState = initialState, action: ClientDetailsAction):
  ClientAccountDetailsState {
    switch (action.type) {

      case ClientAccountDetailsActions.GET_CLIENT_ACCOUNT_DETAILS: {
        return {...state, ...{loading: true}};

      }

      case ClientAccountDetailsActions.GET_CLIENT_ACCOUNT_DETAILS_SUCCESS: {
        return {...state, ...{clientAccountDetails: action.clientAccountDetails}, ...{loading: false}};
      }

      case ClientAccountDetailsActions.GET_CLIENT_ACCOUNT_DETAILS_ERROR: {
        console.error('Error caught while loading Client-Home-Account-Details', action.error);
        return {...initialState};
      }

      case ClientAccountDetailsActions.GET_CLIENT_ACCOUNT_DETAILS_SUMMARY: {
        return {...initialState, ...{loading: true}};

      }

      case ClientAccountDetailsActions.GET_CLIENT_ACCOUNT_DETAILS_SUMMARY_SUCCESS: {
        return {...state, ...{clientAccountDetails: action.clientAccountDetails}, ...{loading: false}};
      }

      case ClientAccountDetailsActions.GET_CLIENT_ACCOUNT_DETAILS_SUMMARY_ERROR: {
        console.error('Error caught while loading Client-Home-Account-Details', action.error);
        return {...initialState};
      }

      default :
        return state;
    }
}
