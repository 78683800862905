import { Action } from '@ngrx/store';

import { ClientAap } from '../../entity/client-aap.model';

export const GET_CLIENT_AAP = '[Client] GET_CLIENT_AAP';
export const GET_CLIENT_AAP_SUCCESS = '[Client] GET_CLIENT_AAP_SUCCESS';
export const GET_CLIENT_AAP_ERROR = '[Client] GET_CLIENT_AAP_ERROR';

export class GetClientAap implements Action {
	readonly type = GET_CLIENT_AAP;
	constructor(public clientId: string, public accountId: string, public scenarioId: string = null) {}
}

export class GetClientAapSuccess implements Action {
	readonly type = GET_CLIENT_AAP_SUCCESS;
	constructor(public clientAap: ClientAap) {}
}

export class GetClientAapError implements Action {
	readonly type = GET_CLIENT_AAP_ERROR;
	constructor(public error: any) {}
}

export type All = GetClientAap | GetClientAapSuccess | GetClientAapError;
