import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('TradeRationaleType')
export class TradeRationaleType {

  @JsonProperty('label', String)
  label: string = undefined;

  @JsonProperty('value', String)
  value: string = undefined;

  @JsonProperty('note', String, true)
  note: string = undefined;

}

@JsonObject('TradeRationale')
export class TradeRationale {

  @JsonProperty('rationaleId', String)
  rationaleId: string = undefined;

  @JsonProperty('message', String)
  message: string = undefined;

  @JsonProperty('tradeRationaleTypes', [TradeRationaleType], true)
	tradeRationaleTypes: TradeRationaleType[] = undefined;

}
