import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../json-convert/DateConvertor';
import { Moment } from 'moment';

@JsonObject('MonitorPortfolioDetails')
export class MonitorPortfolioDetails {

    @JsonProperty('type', String)
    type: string = undefined;

    @JsonProperty('title', String)
    title: string = undefined;

    @JsonProperty('status', String)
    status: string = undefined;

    @JsonProperty('statusLabel', String)
    statusLabel: string = undefined;

    @JsonProperty('keStatus', String)
    keStatus: string = undefined;

    @JsonProperty('currentPortfolioValue', String, true)
    currentPortfolioValue: string = undefined;

    @JsonProperty('deviation', String, true)
    deviation: string = undefined;

    @JsonProperty('outlierDaysOut', String, true)
    outlierDaysOut: string = undefined;

    @JsonProperty('outlierDaysSince', String, true)
    outlierDaysSince: string = undefined;

    @JsonProperty('keReason', String, true)
    keReason: string = undefined;

    @JsonProperty('decisionDate', DateConverter, true)
    decisionDate: Moment = undefined;

    @JsonProperty('decisionStatus', String, true)
    decisionStatus: string = undefined;

    @JsonProperty('approver', String, true)
    approver: string = undefined;

    @JsonProperty('keExpiry', DateConverter, true)
    keExpiry: Moment = undefined;
 }

@JsonObject('MonitorPortfolio')
export class MonitorPortfolio {

  @JsonProperty('accountId', String, true)
  accountId: string = undefined;

  @JsonProperty('clientId', String, true)
  clientId: string = undefined;

  @JsonProperty('clientStatus', String, true)
  clientStatus: string = undefined;

  @JsonProperty('name', String, true)
  name: string = undefined;

  @JsonProperty('daysSinceAvailable', Boolean, true)
  daysSinceAvailable: boolean = false;

  @JsonProperty('monitorFlags', [MonitorPortfolioDetails])
  monitorFlags: MonitorPortfolioDetails[] = undefined;

}
