import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of} from 'rxjs';
import { ClientPmpService } from '../../../shared/pmp/pmp.service';
import * as ApiTradeRationaleActions from './api-trade-rationale.actions';
import { map, catchError, switchMap } from 'rxjs/operators';
import { NavActionService } from '../../../core/navigation-floating/nav-action.service';

export type ApiTradeRationaleAction = ApiTradeRationaleActions.All;

@Injectable()
export class ApiTradeRationaleEffects {

	constructor(private actions$: Actions,
              private clientSlwiService: ClientPmpService,
              private navActionService: NavActionService) {
	}

	getApiTradeRationale: Observable<ApiTradeRationaleAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(ApiTradeRationaleActions.GET_API_TRADE_RATIONALE),
      switchMap((a: ApiTradeRationaleActions.GetApiTradeRationale) =>
			this.clientSlwiService.getApiTradeRationales(a.clientId, a.accountId).pipe(
        map(tradeRationale => new ApiTradeRationaleActions.GetApiTradeRationaleSuccess(tradeRationale)),
        catchError((error) => {
          const message = error.error.message;
        this.navActionService.addMessage('Error', message ? message : 'Failed to retrieve trade rationale options', 'error');
          return of(new ApiTradeRationaleActions.GetApiTradeRationaleError(error));
        })
      )
			)
    ));

	}
