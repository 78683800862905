import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../json-convert/DateConvertor';

import {Moment} from 'moment';

@JsonObject('ReportCreation')
export class ReportCreation {

  @JsonProperty('reportId', String)
  reportId: string = undefined;

  @JsonProperty('reportDescription', String, true)
  reportDescription: string = undefined;

  @JsonProperty('reportPath', String)
  reportPath: string = undefined;

  @JsonProperty('status', String)
  status: string = undefined;

  @JsonProperty('message', String, true)
  message: string = undefined;

  @JsonProperty('statusDate', DateConverter)
  statusDate: Moment = undefined;

  @JsonProperty('reportFormat', String)
  reportFormat: string = undefined;

}
