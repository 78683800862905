import { Action } from '@ngrx/store';

import * as PMPAccountSearchResultsActions from './pmp-search-results.actions';
import { PMPAccountSearchResultsState } from './pmp-search-results.state';
import { SearchResult } from '../../entity/search-result.model';


export type PMPAccountSearchResultsAction = PMPAccountSearchResultsActions.All;
export const initialState: PMPAccountSearchResultsState = {
  searchAccountResult: new Array<SearchResult>(),
  searchModelResult: new Array<SearchResult>(),
  searchAccountFormValues: undefined,
  searchModelFormValues: undefined,
  loading: false};

export function pmpAccountSearchResultsReducer(state: PMPAccountSearchResultsState = initialState, action: PMPAccountSearchResultsAction):
PMPAccountSearchResultsState {
    switch (action.type) {

      case PMPAccountSearchResultsActions.GET_PMP_ACCOUNT_SEARCH_RESULTS: {
        return {...state, ...{searchAccountFormValues: action.searchCriteria}, ...{loading: true}};
      }

      case PMPAccountSearchResultsActions.GET_PMP_ACCOUNT_SEARCH_RESULTS_SUCCESS: {
        return {...state, ...{searchAccountResult: action.searchAccountResult}, ...{loading: false}};
      }

      case PMPAccountSearchResultsActions.GET_PMP_ACCOUNT_SEARCH_RESULTS_ERROR: {
        console.error('Error caught while loading Search-Results', action.error);
        return {...state, ...{searchAccountResult: new Array<SearchResult>()}, ...{loading: false}};
      }

      case PMPAccountSearchResultsActions.GET_PMP_MODEL_SEARCH_RESULTS: {
        return {...state, ...{searchModelFormValues: action.searchCriteria},  ...{loading: true}};
      }

      case PMPAccountSearchResultsActions.GET_PMP_MODEL_SEARCH_RESULTS_SUCCESS: {
        return {...state, ...{searchModelResult: action.searchModelResult}, ...{loading: false}};
      }

      case PMPAccountSearchResultsActions.GET_PMP_MODEL_SEARCH_RESULTS_ERROR: {
        console.error('Error caught while loading Search-Results', action.error);
        return {...state, ...{searchModelResult: new Array<SearchResult>()}, ...{loading: false}};
      }

      default :
        return state;
    }
}
