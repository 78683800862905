import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('WidgetMonitorFlag')
export class WidgetMonitorFlag {

    @JsonProperty('type', String)
    type: string = undefined;

    @JsonProperty('title', String)
    title: string = undefined;

    @JsonProperty('label', String, true)
    label: string = undefined;

    @JsonProperty('status', String)
    status: string = undefined;

    @JsonProperty('keStatus', String)
    keStatus: string = undefined;

    @JsonProperty('scaleLowerValue', Number, true)
    scaleLowerValue: number = undefined;

    @JsonProperty('scaleUpperValue', Number, true)
    scaleUpperValue: number = undefined;

    @JsonProperty('compliantLowerValue', Number, true)
    compliantLowerValue: number = undefined;

    @JsonProperty('compliantUpperValue', Number, true)
    compliantUpperValue: number = undefined;

    @JsonProperty('almostOutlierLowerValue', Number, true)
    almostOutlierLowerValue: number = undefined;

    @JsonProperty('almostOutlierUpperValue', Number, true)
    almostOutlierUpperValue: number = undefined;

    @JsonProperty('currentValue', Number, true)
    currentPortfolioValue: number = undefined;

    @JsonProperty('proposedValue', Number, true)
    proposedPortfolioValue: number = undefined;

    @JsonProperty('deviation', Number, true)
    deviation: number = undefined;

    @JsonProperty('outlierDaysOut', Number, true)
    outlierDaysOut: number = undefined;

    @JsonProperty('proposedOutlier', Boolean, true)
    proposedOutlier: boolean = undefined;

    @JsonProperty('currentOutlier', Boolean, true)
    currentOutlier: boolean = undefined;
    @JsonProperty('unit', String, true)
    unit: String = undefined;
 }
