export enum Authority {
 AUTHORITY_MONITOR = 'BITAStarMonitor_w',
 AUTHORITY_APPROVAL = 'BITAStarKEApproval_w',
 AUTHORITY_CLIENT_CREATE = 'BITAStarClientCreate_w',
 AUTHORITY_ADMIN_CLIENT_LIST_R = 'BITAStarAdminClientList_r',
 AUTHORITY_ADMIN_CLIENT_R = 'BITAStarAdminClient_r',
 AUTHORITY_ADMIN_ENTITIES_R = 'BITAStarAdminEntities_r',
 AUTHORITY_ADMIN_GROUPS_R = 'BITAStarAdminGroups_r',
 AUTHORITY_ADMIN_USERS_R = 'BITAStarAdminUsers_r',
 AUTHORITY_ADMIN_CLIENT_LIST_W = 'BITAStarAdminClientList_w',
 AUTHORITY_ADMIN_CLIENT_W = 'BITAStarAdminClient_w',
 AUTHORITY_ADMIN_ENTITIES_W = 'BITAStarAdminEntities_w',
 AUTHORITY_ADMIN_GROUPS_W = 'BITAStarAdminGroups_w',
 AUTHORITY_ADMIN_USERS_W = 'BITAStarAdminUsers_w',
 AUTHORITY_BULK_TRADE_R = 'BITAStarBulkTrade_r',
 AUTHORITY_BULK_TRADE_W = 'BITAStarBulkTrade_w',
 AUTHORITY_MODEL_MANAGEMENT_R = 'BITAStarModelManagement_r',
 AUTHORITY_MODEL_MANAGEMENT_W = 'BITAStarModelManagement_w',
 AUTHORITY_TEAM_BUY_LIST_R = 'BITAStarTeamBuyList_r',
 AUTHORITY_TEAM_BUY_LIST_W = 'BITAStarTeamBuyList_w',
 AUTHORITY_PMI_R = 'BITAStarPmi_r',
 AUTHORITY_PMI_W = 'BITAStarPmi_w',
 AUTHORITY_ESG_QUESTIONNAIRE_R = 'BITAStarEsgQuestionnaire_r',
 AUTHORITY_ESG_QUESTIONNAIRE_W = 'BITAStarEsgQuestionnaire_w',
 AUTHORITY_MODEL_MANAGEMENT_APPROVAL_R = 'BITAStarModelManagementApproval_r',
 AUTHORITY_MODEL_MANAGEMENT_APPROVAL_W = 'BITAStarModelManagementApproval_w',
 ROLE_USER = 'ROLE_USER',
 ROLE_PROXY_USER = 'ROLE_PROXY_USER'
}
