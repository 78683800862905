import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('AABoundaryDeviation')
export class AABoundaryDeviation {

	@JsonProperty('id', Number)
	id: number = undefined;

	@JsonProperty('assetName', String)
	assetName: string = undefined;

	@JsonProperty('deviation', Number)
	deviation: number = undefined;

	@JsonProperty('scaleLowerValue', Number)
	scaleLowerValue: number = undefined;

	@JsonProperty('scaleUpperValue', Number)
	scaleUpperValue: number = undefined;
}
