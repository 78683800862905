import {JsonObject, JsonProperty} from 'json2typescript';
import { DateConverter } from '../json-convert/DateConvertor';
import { Moment } from 'moment';

@JsonObject('Asset')
export class Asset {
	constructor(
    id: number,
    name: string,
    label: string,
    isComposite: boolean,
    inBuyList: boolean,
    isPriips?: boolean,
    isUcits?: boolean,
    isFund?: boolean
  ) {
		this.id = id;
		this.name = name;
		this.label = label;
    this.isComposite = isComposite;
    this.inBuyList = inBuyList;
    this.isPriips = isPriips;
    this.isUcits = isUcits;
    this.isFund = isFund;
	}

	@JsonProperty('id', Number)
	id: number = undefined;

	@JsonProperty('name', String)
	name: string = undefined;

	@JsonProperty('label', String)
	label: string = undefined;

	@JsonProperty('isComposite', Boolean)
	isComposite: boolean = undefined;

	@JsonProperty('isExcludeHolding', Boolean, true)
	isExcludeHolding: boolean = undefined;

	@JsonProperty('ocfValue', String, true)
	ocfValue: string = undefined;

	@JsonProperty('rating', String, true)
	bondRating = undefined;

	@JsonProperty('maturityDate', DateConverter, true)
	bondMaturityDate: Moment = undefined;

  @JsonProperty('inBuyList', Boolean)
  inBuyList: boolean = undefined;

  @JsonProperty('price', Number, true)
  price: number = undefined;

  @JsonProperty('fxRate', Number, true)
  fxRate: number = undefined;

  @JsonProperty('currency', String, true)
  currency: string = undefined;

  @JsonProperty('isPriips', Boolean, true)
  isPriips: boolean;

  @JsonProperty('isUcits', Boolean, true)
  isUcits: boolean;

  @JsonProperty('isFund', Boolean, true)
  isFund = false;

  @JsonProperty('assetType', String, true)
  assetType = undefined;
}


@JsonObject('AssetResult')
export class AssetResult {

	@JsonProperty('sedol', String)
	sedol: string = undefined;

	@JsonProperty('epic', String, true)
	epic: string = undefined;

	@JsonProperty('subAssetClass', String)
	subAssetClass: string = undefined;

	@JsonProperty('currency', String)
	currency: string = undefined;

	@JsonProperty('description', String)
	description: string = undefined;

	@JsonProperty('composite', Boolean)
	composite: boolean = undefined;

	@JsonProperty('inBuyList', Boolean)
  inBuyList: boolean = undefined;

  @JsonProperty('price', Number, true)
	price: number = undefined;

}

export interface AddAssetRequest {
  asset: AssetResult;
  assetValue: number;
}
