import { Action } from '@ngrx/store';
import * as MonitorFlag from '../../entity/monitor-flag-const.model';
import { MonitorFlagTypeState } from './monitor-flag-type.state';
import { MonitorFlagType } from '../../entity/monitor-flag-type.model';

export const GET_ALL_MONITOR_FLAG_TYPES = '[MonitorFlag] GET_ALL_MONITOR_FLAG_TYPES';
export const GET_ALL_MONITOR_FLAG_TYPES_SUCCESS = '[MonitorFlag] GET_ALL_MONITOR_FLAG_TYPES_SUCCESS';
export const GET_ALL_MONITOR_FLAG_TYPES_ERROR = '[MonitorFlag] GET_ALL_MONITOR_FLAG_TYPES_ERROR';


export class GetAllMonitorFlagTypes implements Action {
	readonly type = GET_ALL_MONITOR_FLAG_TYPES;
	constructor(public clientId?: string, public accountId?: string) {}
}

export class GetAllMonitorFlagsTypesSuccess implements Action {
	readonly type = GET_ALL_MONITOR_FLAG_TYPES_SUCCESS;
	constructor(public allMonitorFlagTypes: MonitorFlagType[]) {}
}

export class GetAllMonitorFlagTypesError implements Action {
	readonly type = GET_ALL_MONITOR_FLAG_TYPES_ERROR;
	constructor(public error: any) {}
}

export type All = GetAllMonitorFlagTypes | GetAllMonitorFlagsTypesSuccess | GetAllMonitorFlagTypesError;
