/* tslint:disable:max-line-length */
export function mockMonitorFlagValues() {
  return {
    'monitorFlagMatrix': [
      {
        'monitorFlagRow': [
          {
            'id': 1,
            'type': 'stockLevelTotalRisk',
            'label': 'RISK',
            'outlierStatus': 'OUTLIERS',
            'chartType': 'DOUBLE',
            'tooltip': '<b>Risk Outliers:</b></br>The number of BMIDs above and below the risk threshold for their risk category.</br></br>Upper ring shows outliers broken down into critical, need attention and marginal.</br></br>The lower pie chart illustrates the outliers, almost outliers and ok BMIDS, together with known exceptions for each category',
            'monitorFlagCategory': [
              {
                'id': 1,
                'type': '0',
                'name': 'Outlier - Critical',
                'value': 319
              },
              {
                'id': 2,
                'type': '0',
                'name': 'Outlier - Important',
                'value': 373
              },
              {
                'id': 3,
                'type': '0',
                'name': 'Outlier - Marginal',
                'value': 801
              },
              {
                'id': 4,
                'type': '1',
                'name': 'Outlier - KE approved',
                'value': 100
              },
              {
                'id': 5,
                'type': '1',
                'name': 'Almost outlier',
                'value': 897
              },
              {
                'id': 6,
                'type': '1',
                'name': 'Almost outlier - KE approved',
                'value': 200
              },
              {
                'id': 7,
                'type': '1',
                'name': 'Non-outlier',
                'value': 824
              },
              {
                'id': 8,
                'type': '1',
                'name': 'Non-outlier - KE approved',
                'value': 200
              }
            ]
          },
          {
            'id': 2,
            'type': 'stockLevelConcentration',
            'label': 'CONC',
            'outlierStatus': 'OUTLIERS',
            'chartType': 'DOUBLE',
            'tooltip': '<b>Conc Outliers:</b></br>',
            'monitorFlagCategory': [
              {
                'id': 1,
                'type': '0',
                'name': 'Outlier - Critical',
                'value': 185,
                'colourRef': 'defaultCriticalOutlier'
              },
              {
                'id': 2,
                'type': '0',
                'name': 'Outlier - Important',
                'value': 161,
                'colourRef': 'defaultImportantOutlier'
              },
              {
                'id': 3,
                'type': '0',
                'name': 'Outlier - Marginal',
                'value': 117,
                'colourRef': 'defaultMarginalOutlier'
              },
              {
                'id': 4,
                'type': '1',
                'name': 'Outlier - KE approved',
                'value': 111,
                'colourRef': 'defaultOutlierKE'
              },
              {
                'id': 5,
                'type': '1',
                'name': 'Almost outlier',
                'value': 112,
                'colourRef': 'defaultAlmostOutlier'
              },
              {
                'id': 6,
                'type': '1',
                'name': 'Almost outlier - KE approved',
                'value': 111,
                'colourRef': 'defaultAlmostOutlierKE'
              },
              {
                'id': 7,
                'type': '1',
                'name': 'Non-outlier',
                'value': 907,
                'colourRef': 'defaultNonOutlier'
              },
              {
                'id': 8,
                'type': '1',
                'name': 'Non-outlier - KE approved',
                'value': 111,
                'colourRef': 'defaultNonOutlierKE'
              }
            ]
          },
          {
            'id': 12,
            'type': 'issuerConcentration',
            'label': 'ISSUE',
            'outlierStatus': 'OUTLIERS',
            'chartType': 'DOUBLE',
            'tooltip': '<b>ISSUE Outliers:</b></br>',
            'monitorFlagCategory': [
              {
                'id': 1,
                'type': '0',
                'name': 'Outlier - Critical',
                'value': 30,
                'colourRef': 'defaultCriticalOutlier'
              },
              {
                'id': 2,
                'type': '0',
                'name': 'Outlier - Important',
                'value': 40,
                'colourRef': 'defaultImportantOutlier'
              },
              {
                'id': 3,
                'type': '0',
                'name': 'Outlier - Marginal',
                'value': 35,
                'colourRef': 'defaultMarginalOutlier'
              },
              {
                'id': 4,
                'type': '1',
                'name': 'Outlier - KE approved',
                'value': 23,
                'colourRef': 'defaultOutlierKE'
              },
              {
                'id': 5,
                'type': '1',
                'name': 'Almost outlier',
                'value': 45,
                'colourRef': 'defaultAlmostOutlier'
              },
              {
                'id': 6,
                'type': '1',
                'name': 'Almost outlier - KE approved',
                'value': 50,
                'colourRef': 'defaultAlmostOutlierKE'
              },
              {
                'id': 7,
                'type': '1',
                'name': 'Non-outlier',
                'value': 469,
                'colourRef': 'defaultNonOutlier'
              },
              {
                'id': 8,
                'type': '1',
                'name': 'Non-outlier - KE approved',
                'value': 25,
                'colourRef': 'defaultNonOutlierKE'
              }
            ]
          },
          {
            'id': 14,
            'type': 'stockLevelActiveRisk',
            'label': 'TRACK',
            'outlierStatus': 'OUTLIERS',
            'chartType': 'DOUBLE',
            'tooltip': '<b>Track Outliers:</b></br>',
            'monitorFlagCategory': [
              {
                'id': 1,
                'type': '0',
                'name': 'Outlier - Critical',
                'value': 149,
                'colourRef': 'defaultCriticalOutlier'
              },
              {
                'id': 2,
                'type': '0',
                'name': 'Outlier - Important',
                'value': 487,
                'colourRef': 'defaultImportantOutlier'
              },
              {
                'id': 3,
                'type': '0',
                'name': 'Outlier - Marginal',
                'value': 132,
                'colourRef': 'defaultMarginalOutlier'
              },
              {
                'id': 4,
                'type': '1',
                'name': 'Outlier - KE approved',
                'value': 123,
                'colourRef': 'defaultOutlierKE'
              },
              {
                'id': 5,
                'type': '1',
                'name': 'Almost outlier',
                'value': 100,
                'colourRef': 'defaultAlmostOutlier'
              },
              {
                'id': 6,
                'type': '1',
                'name': 'Almost outlier - KE approved',
                'value': 345,
                'colourRef': 'defaultAlmostOutlierKE'
              },
              {
                'id': 7,
                'type': '1',
                'name': 'Non-outlier',
                'value': 412,
                'colourRef': 'defaultNonOutlier'
              },
              {
                'id': 8,
                'type': '1',
                'name': 'Non-outlier - KE approved',
                'value': 220,
                'colourRef': 'defaultNonOutlierKE'
              }
            ]
          },
          {
            'id': 5,
            'type': 'buyListOutlier',
            'label': 'BUYLIST',
            'outlierStatus': 'OUTLIERS',
            'chartType': 'DOUBLE',
            'tooltip': '<b>BuyList Outliers:</b></br>',
            'monitorFlagCategory': [
              {
                'id': 1,
                'type': '0',
                'name': 'Outlier - Critical',
                'value': 182,
                'colourRef': 'defaultCriticalOutlier'
              },
              {
                'id': 2,
                'type': '0',
                'name': 'Outlier - Important',
                'value': 0,
                'colourRef': 'defaultImportantOutlier'
              },
              {
                'id': 3,
                'type': '0',
                'name': 'Outlier - Marginal',
                'value': 0,
                'colourRef': 'defaultMarginalOutlier'
              },
              {
                'id': 4,
                'type': '1',
                'name': 'Outlier - KE approved',
                'value': 1,
                'colourRef': 'defaultOutlierKE'
              },
              {
                'id': 5,
                'type': '1',
                'name': 'Almost outlier',
                'value': 30,
                'colourRef': 'defaultAlmostOutlier'
              },
              {
                'id': 6,
                'type': '1',
                'name': 'Almost outlier - KE approved',
                'value': 0,
                'colourRef': 'defaultAlmostOutlierKE'
              },
              {
                'id': 7,
                'type': '1',
                'name': 'Non-outlier',
                'value': 6801,
                'colourRef': 'defaultNonOutlier'
              },
              {
                'id': 8,
                'type': '1',
                'name': 'Non-outlier - KE approved',
                'value': 0,
                'colourRef': 'defaultNonOutlierKE'
              }
            ]
          },
          {
            'id': 4,
            'type': 'holdingExposureOutlier',
            'label': 'MHW',
            'outlierStatus': 'OUTLIERS',
            'chartType': 'DOUBLE',
            'tooltip': '<b>AA Outliers:</b></br>The number os the total number of outliers excluding approved KE.</br></br>Blue indicates under-weight.</br></br>Red indicates over-weight',
            'monitorFlagCategory': [
              {
                'id': 1,
                'type': '0',
                'name': 'Outlier - Critical',
                'value': 301,
                'colourRef': 'defaultCriticalOutlier'
              },
              {
                'id': 2,
                'type': '0',
                'name': 'Outlier - Important',
                'value': 54,
                'colourRef': 'defaultImportantOutlier'
              },
              {
                'id': 3,
                'type': '0',
                'name': 'Outlier - Marginal',
                'value': 55,
                'colourRef': 'defaultMarginalOutlier'
              },
              {
                'id': 4,
                'type': '1',
                'name': 'Outlier - KE approved',
                'value': 0,
                'colourRef': 'defaultOutlierKE'
              },
              {
                'id': 5,
                'type': '1',
                'name': 'Almost outlier',
                'value': 500,
                'colourRef': 'defaultAlmostOutlier'
              },
              {
                'id': 6,
                'type': '1',
                'name': 'Almost outlier - KE approved',
                'value': 0,
                'colourRef': 'defaultAlmostOutlierKE'
              },
              {
                'id': 7,
                'type': '1',
                'name': 'Non-outlier',
                'value': 6604,
                'colourRef': 'defaultNonOutlier'
              },
              {
                'id': 8,
                'type': '1',
                'name': 'Non-outlier - KE approved',
                'value': 0,
                'colourRef': 'defaultNonOutlierKE'
              }
            ]
          }
        ]
      },
      {
        'monitorFlagRow': [
          {
            'id': 7,
            'type': 'assetClassCash',
            'label': 'CASH',
            'outlierStatus': 'OUTLIERS',
            'chartType': 'SINGLE',
            'tooltip': '',
            'monitorFlagCategory': [
              {
                'id': 1,
                'type': '0',
                'name': 'Outlier - Critical under',
                'value': 140
              },
              {
                'id': 2,
                'type': '0',
                'name': 'Outlier - Important under',
                'value': 370
              },
              {
                'id': 3,
                'type': '0',
                'name': 'Outlier - Marginal under',
                'value': 120
              },
              {
                'id': 4,
                'type': '1',
                'name': 'Non-outlier',
                'value': 661
              },
              {
                'id': 5,
                'type': '0',
                'name': 'Outlier - Marginal over',
                'value': 161
              },
              {
                'id': 6,
                'type': '0',
                'name': 'Outlier - Important over',
                'value': 191
              },
              {
                'id': 7,
                'type': '0',
                'name': 'Outlier - Critical over',
                'value': 331
              }
            ]
          },
          {
            'id': 9,
            'type': 'assetClassEquity',
            'label': 'EQ UK',
            'outlierStatus': 'OUTLIERS',
            'chartType': 'SINGLE',
            'tooltip': '',
            'monitorFlagCategory': [
              {
                'id': 1,
                'type': '0',
                'name': 'Outlier - Critical under',
                'value': 231,
                'colourRef': 'defaultCriticalUnderOutlier'
              },
              {
                'id': 2,
                'type': '0',
                'name': 'Outlier - Important under',
                'value': 131,
                'colourRef': 'defaultImportantUnderOutlier'
              },
              {
                'id': 3,
                'type': '0',
                'name': 'Outlier - Marginal under',
                'value': 112,
                'colourRef': 'defaultMarginalUnderOutlier'
              },
              {
                'id': 4,
                'type': '1',
                'name': 'Non-outlier',
                'value': 6263,
                'colourRef': 'defaultNonOutliers'
              },
              {
                'id': 5,
                'type': '0',
                'name': 'Outlier - Marginal over',
                'value': 111,
                'colourRef': 'defaultMarginalOverOutlier'
              },
              {
                'id': 6,
                'type': '0',
                'name': 'Outlier - Important over',
                'value': 415,
                'colourRef': 'defaultImportantOverOutlier'
              },
              {
                'id': 7,
                'type': '0',
                'name': 'Outlier - Critical over',
                'value': 453,
                'colourRef': 'defaultCriticalOverOutlier'
              }
            ]
          },
          {
            'id': 10,
            'type': 'assetClassVol',
            'label': 'EQ OS',
            'outlierStatus': 'OUTLIERS',
            'chartType': 'SINGLE',
            'tooltip': '',
            'monitorFlagCategory': [
              {
                'id': 1,
                'type': '0',
                'name': 'Outlier - Critical under',
                'value': 171,
                'colourRef': 'defaultCriticalUnderOutlier'
              },
              {
                'id': 2,
                'type': '0',
                'name': 'Outlier - Important under',
                'value': 115,
                'colourRef': 'defaultImportantUnderOutlier'
              },
              {
                'id': 3,
                'type': '0',
                'name': 'Outlier - Marginal under',
                'value': 112,
                'colourRef': 'defaultMarginalUnderOutlier'
              },
              {
                'id': 4,
                'type': '1',
                'name': 'Non-outlier',
                'value': 568,
                'colourRef': 'defaultNonOutliers'
              },
              {
                'id': 5,
                'type': '0',
                'name': 'Outlier - Marginal over',
                'value': 123,
                'colourRef': 'defaultMarginalOverOutlier'
              },
              {
                'id': 6,
                'type': '0',
                'name': 'Outlier - Important over',
                'value': 140,
                'colourRef': 'defaultImportantOverOutlier'
              },
              {
                'id': 7,
                'type': '0',
                'name': 'Outlier - Critical over',
                'value': 125,
                'colourRef': 'defaultCriticalOverOutlier'
              }
            ]
          },
          {
            'id': 8,
            'type': 'assetClassBond',
            'label': 'FI UK',
            'outlierStatus': 'OUTLIERS',
            'chartType': 'SINGLE',
            'tooltip': '',
            'monitorFlagCategory': [
              {
                'id': 1,
                'type': '0',
                'name': 'Outlier - Critical under',
                'value': 8,
                'colourRef': 'defaultCriticalUnderOutlier'
              },
              {
                'id': 2,
                'type': '0',
                'name': 'Outlier - Important under',
                'value': 7,
                'colourRef': 'defaultImportantUnderOutlier'
              },
              {
                'id': 3,
                'type': '0',
                'name': 'Outlier - Marginal under',
                'value': 1,
                'colourRef': 'defaultMarginalUnderOutlier'
              },
              {
                'id': 4,
                'type': '1',
                'name': 'Non-outlier',
                'value': 6911,
                'colourRef': 'defaultNonOutliers'
              },
              {
                'id': 5,
                'type': '0',
                'name': 'Outlier - Marginal over',
                'value': 6,
                'colourRef': 'defaultMarginalOverOutlier'
              },
              {
                'id': 6,
                'type': '0',
                'name': 'Outlier - Important over',
                'value': 37,
                'colourRef': 'defaultImportantOverOutlier'
              },
              {
                'id': 7,
                'type': '0',
                'name': 'Outlier - Critical over',
                'value': 48,
                'colourRef': 'defaultCriticalOverOutlier'
              }
            ]
          },
          {
            'id': 11,
            'type': 'assetClassOther',
            'label': 'FI OS',
            'outlierStatus': 'OUTLIERS',
            'chartType': 'SINGLE',
            'tooltip': '',
            'monitorFlagCategory': [
              {
                'id': 1,
                'type': '0',
                'name': 'Outlier - Critical under',
                'value': 335,
                'colourRef': 'defaultCriticalUnderOutlier'
              },
              {
                'id': 2,
                'type': '0',
                'name': 'Outlier - Important under',
                'value': 67,
                'colourRef': 'defaultImportantUnderOutlier'
              },
              {
                'id': 3,
                'type': '0',
                'name': 'Outlier - Marginal under',
                'value': 18,
                'colourRef': 'defaultMarginalUnderOutlier'
              },
              {
                'id': 4,
                'type': '1',
                'name': 'Non-outlier',
                'value': 6425,
                'colourRef': 'defaultNonOutliers'
              },
              {
                'id': 5,
                'type': '0',
                'name': 'Outlier - Marginal over',
                'value': 1,
                'colourRef': 'defaultMarginalOverOutlier'
              },
              {
                'id': 6,
                'type': '0',
                'name': 'Outlier - Important over',
                'value': 20,
                'colourRef': 'defaultImportantOverOutlier'
              },
              {
                'id': 7,
                'type': '0',
                'name': 'Outlier - Critical over',
                'value': 152,
                'colourRef': 'defaultCriticalOverOutlier'
              }
            ]
          }
        ]
      },
      {
        'monitorFlagRow': [
          {
            'id': 3,
            'type': 'sectorOutlier',
            'label': 'SECTOR',
            'outlierStatus': 'OUTLIERS',
            'chartType': 'TEST',
            'tooltip': '',
            'monitorFlagCategory': [
              {
                'id': 1,
                'type': '0',
                'name': 'Fail',
                'value': 661
              },
              {
                'id': 2,
                'type': '1',
                'name': 'Pass',
                'value': 6353
              }
            ]
          },
          {
            'id': 6,
            'type': 'commonality',
            'label': 'COMMON',
            'outlierStatus': 'OUTLIERS',
            'chartType': 'TEST',
            'tooltip': '',
            'monitorFlagCategory': [
              {
                'id': 1,
                'type': '0',
                'name': 'Fail',
                'value': 1068,
                'colourRef': 'defaultTestFail'
              },
              {
                'id': 2,
                'type': '1',
                'name': 'Pass',
                'value': 5946,
                'colourRef': 'defaultTestPass'
              }
            ]
          },
          {
            'id': 13,
            'type': 'anomalousHoldings',
            'label': 'HVH',
            'outlierStatus': 'HOLDINGS',
            'chartType': 'TEST',
            'tooltip': '',
            'monitorFlagCategory': [
              {
                'id': 1,
                'type': '0',
                'name': 'Fail',
                'value': 4,
                'colourRef': 'defaultTestFail'
              },
              {
                'id': 2,
                'type': '1',
                'name': 'Pass',
                'value': 7465,
                'colourRef': 'defaultTestPass'
              }
            ]
          },
          {
            'id': 100,
            'type': 'keExpiry',
            'label': 'KE EXPIRE',
            'outlierStatus': 'SOON',
            'chartType': 'TEST',
            'tooltip': '',
            'monitorFlagCategory': [
              {
                'id': 1,
                'type': '0',
                'name': 'KE - Expiring',
                'value': 1,
                'colourRef': 'defaultTestFail'
              },
              {
                'id': 2,
                'type': '1',
                'name': 'KE - Long term',
                'value': 2,
                'colourRef': 'defaultTestPass'
              }
            ]
          },
          {
            'id': 101,
            'type': 'keStatus',
            'label': 'KE STATUS',
            'outlierStatus': 'PENDINGS',
            'chartType': 'TEST',
            'tooltip': '',
            'monitorFlagCategory': [
              {
                'id': 1,
                'type': '0',
                'name': 'KE - Pending',
                'value': 6,
                'colourRef': 'defaultTestFail'
              },
              {
                'id': 2,
                'type': '1',
                'name': 'KE - Other',
                'value': 3,
                'colourRef': 'defaultTestPass'
              }
            ]
          }
        ]
      }
    ]
  };
}
