export function mockClientDetails() {
    return [
        {
            clientId: 'CID1234',
            name: 'Mrs Miggins',
            clientType: 'Charity',
            owner: 'Julie Primrose',
            office: 'Edinburgh',
            status: 'Active',
            manager: 'Julie Primrose',
            assistantManager: 'Tessa Yates',
            assistant: 'Geroge Formby'
        },
        {
            clientId: 'CID123xxx987',
            name: 'Mr Terry',
            clientType: 'Trust',
            owner: 'Julie Primrose',
            office: 'Edinburgh',
            status: 'Active',
            manager: 'Julie Primrose',
            assistantManager: 'Tessa Yates',
            assistant: 'Geroge Formby'
        }
    ];
}
