import * as AnalyticsActions from './analytics.actions';
import { AnalyticsState } from './analytics.state';

export type AnalyticsAction = AnalyticsActions.All;
export const initialState: AnalyticsState = { fact: null, loading: false};

export function analyticsReducer(state: AnalyticsState = initialState, action: AnalyticsAction): AnalyticsState {
  switch (action.type) {
    case AnalyticsActions.GET_ANALYTICS_FACT: {
      return {...state, ...{loading: true}};
    }

    case AnalyticsActions.GET_ANALYTICS_FACT_SUCCESS: {
      return {...state, ...{fact: action.fact}, ...{loading: false}};
    }

    case AnalyticsActions.GET_ANALYTICS_FACT_ERROR: {
      console.error('Error caught while loading FACT analytics', action.error);
      return {...initialState};
    }

    default:
      return state;

  }

}
