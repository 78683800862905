import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JsonConvert } from 'json2typescript';
import { JsonConvertService } from '../shared/json-convert/json-convert.service';
import { SearchResult } from '../shared/entity/search-result.model';
import { SearchMetaData } from '../shared/entity/search-metadata.model';
import { SearchCriteriaRequest } from './entity/search-criteria-request.model';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { GenericFormConfiguration } from '../shared/entity/generic-form/generic-form.model';
import { TableHeaderForm } from '../shared/entity/generic-form/generic-table-header-form';

@Injectable()
export class SearchService {
  private jsonConverter: JsonConvert;
  private readonly LAST_SEARCH_CRITERIA = 'LastSearchCriteria';

  constructor(
    private httpClient: HttpClient,
    private jsonConvertService: JsonConvertService) {
    this.jsonConverter =  this.jsonConvertService.getJsonConverter();
  }

  getSearchResults(searchCriteria: SearchCriteriaRequest): Observable<SearchResult[]> {
    return this.httpClient.post(`${environment.serverUrl}/restapi/service/search/accounts`, searchCriteria).pipe(
      map(response => {
        const searchResults: SearchResult[] = response as SearchResult[];
        return this.jsonConverter.deserializeArray(searchResults, SearchResult);
      }));
  }

  getPMPSearchResults(searchCriteria: SearchCriteriaRequest, clientId: String, accountId: String): Observable<SearchResult[]> {
    return this.httpClient.post(`${environment.serverUrl}/restapi/service/search/accounts/${clientId}/${encodeURIComponent(String(accountId))}`, searchCriteria).pipe(
      map(response => {
        const searchResults: SearchResult[] = response as SearchResult[];
        return this.jsonConverter.deserializeArray(searchResults, SearchResult);
      }));
  }

  getSearchTermResults(searchTerm: string): Observable<SearchResult[]> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/search/accounts/multiple/${searchTerm}`).pipe(
      map(response => {
        const searchResults: SearchResult[] = response as SearchResult[];
        return this.jsonConverter.deserializeArray(searchResults, SearchResult);
      }));
  }


  getSearchResultFormConfig(): Observable<TableHeaderForm[]> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/search/accounts/searchResultsConfig`).pipe(
      map(response => {
        const searchResultForm: TableHeaderForm[] = response as TableHeaderForm[];
        return this.jsonConverter.deserializeArray(searchResultForm, TableHeaderForm);
      }));
  }

  getAssetsSearchResultFormConfig(): Observable<TableHeaderForm[]> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/search/assets/searchResultsConfig`).pipe(
      map(response => {
        const searchResultForm: TableHeaderForm[] = response as TableHeaderForm[];
        return this.jsonConverter.deserializeArray(searchResultForm, TableHeaderForm);
      }));
  }

  getPMPSearchResultFormConfig(): Observable<TableHeaderForm[]> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/search/pmp/accounts/searchResultsConfig`).pipe(
      map(response => {
        const searchResultForm: TableHeaderForm[] = response as TableHeaderForm[];
        return this.jsonConverter.deserializeArray(searchResultForm, TableHeaderForm);
      }));
  }

  getSearchMetaData(): Observable<SearchMetaData> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/search/accounts/configuration`).pipe(
      map(response => this.jsonConverter.deserializeObject(response, SearchMetaData)));
  }

  getSearchFormConfig(): Observable<GenericFormConfiguration> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/search/accounts/searchCriteriaConfig`).pipe(
      map(response => this.jsonConverter.deserializeObject(response['filters'], GenericFormConfiguration)));
  }

  getPMPAccountSearchFormConfig(): Observable<GenericFormConfiguration> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/search/pmp/accounts/searchCriteriaConfig`).pipe(
      map(response => this.jsonConverter.deserializeObject(response['filters'], GenericFormConfiguration)));
  }

  getAssetSearchFormConfig(): Observable<GenericFormConfiguration> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/search/assets/searchCriteriaConfig`).pipe(
      map(response => this.jsonConverter.deserializeObject(response['filters'], GenericFormConfiguration)));
  }

  getSearchCriteria(): SearchCriteriaRequest {
    return JSON.parse(sessionStorage.getItem(this.LAST_SEARCH_CRITERIA));
  }

  setSearchCriteria(searchCriteria: SearchCriteriaRequest) {
    this.clearSearchCriteria();
    sessionStorage.setItem(this.LAST_SEARCH_CRITERIA, JSON.stringify(searchCriteria));
  }

  clearSearchCriteria() {
    sessionStorage.removeItem(this.LAST_SEARCH_CRITERIA);
  }
}
