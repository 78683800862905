export function mockMonitorPortfolioTable() {
    return [
        {
            accountId: 'BMID001HH',
            clientId: 'CID1234',
            calculationDate: '2017-11-19 10:00:00',
            calculationDateStatus: 'COMPLIANT',
            bitaRiskCategory: 'RG2',
            bitaRiskCategoryStatus: 'OUTLIER',
            bitaModel: 'Growth',
            bitaModelStatus: 'COMPLIANT',
            bitaModelId: 'M00151',
            bitaModelIdStatus: 'COMPLIANT',
            bitaObjective: 'Balanced',
            bitaObjectiveStatus: 'COMPLIANT',
            monitorFlags: [
                { type: 'Risk', title: 'risk', status: 'OUTLIER', keStatus: 'Approved',
                 currentPortfolioValue: 13, deviation: 4.69, outlierDaysOut: 3 },
                { type: 'Concentration', title: 'conc', status: 'OUTLIER', keStatus: 'Rejected',
                 currentPortfolioValue: 23.8, deviation: 5.69, outlierDaysOut: 12 },
                { type: 'Yield Concentration', title: 'Yconc', status: 'OUTLIER', keStatus: 'Approved',
                 currentPortfolioValue: 17, deviation: 1.69, outlierDaysOut: 32 },
                { type: 'Issue Concentration', title: 'Iconc', status: 'COMPLIANT', keStatus: 'Approved',
                 currentPortfolioValue: 20, deviation: 0 },
                { type: 'Tracking', title: 'track', status: 'COMPLIANT', keStatus: 'Rejected', currentPortfolioValue: 20.6, deviation: 0 },
                { type: 'Buylist', title: 'buy', status: 'OUTLIER', keStatus: 'Approved',
                 currentPortfolioValue: 66.7, deviation: 34.69, outlierDaysOut: 4 },
                { type: 'Max Holding Weight', title: 'mhw', status: 'OUTLIER', keStatus: 'Rejected',
                 currentPortfolioValue: 34.4, deviation: 11, outlierDaysOut: 14 },
                { type: 'Asset C. EQ', title: 'asseteq', status: 'OUTLIER', keStatus: 'Approved',
                 currentPortfolioValue: 8, deviation: 2.6, outlierDaysOut: 36 },
                { type: 'Asset C. BD', title: 'assetbd', status: 'OUTLIER', keStatus: 'Rejected',
                 currentPortfolioValue: 6, deviation: 5.8, outlierDaysOut: 18 },
                { type: 'Asset C. IL', title: 'assetil', status: 'COMPLIANT', keStatus: 'Rejected',
                 currentPortfolioValue: 5, deviation: 0 },
                { type: 'Asset C. CB', title: 'assetcb', status: 'COMPLIANT', keStatus: 'Rejected',
                 currentPortfolioValue: 2, deviation: 0 },
                { type: 'Asset C. Alt', title: 'assetalt', status: 'COMPLIANT', keStatus: 'Rejected',
                 currentPortfolioValue: 9, deviation: 0 },
                { type: 'Asset C. OS EQ', title: 'assetoseq', status: 'OUTLIER', keStatus: 'Approved',
                 currentPortfolioValue: 7.6, deviation: 12.7, outlierDaysOut: 38 },
                { type: 'Asset C. EM EQ', title: 'assetemeq', status: 'COMPLIANT', keStatus: 'Rejected',
                 currentPortfolioValue: 6, deviation: 0 },
                { type: 'Asset C. SV BD', title: 'assetsvbd', status: 'OUTLIER', keStatus: 'Rejected',
                 currentPortfolioValue: 7, deviation: 4, outlierDaysOut: 22 },
                { type: 'Asset C. OS BD ', title: 'assetosbd', status: 'COMPLIANT', keStatus: 'Rejected',
                 currentPortfolioValue: 8, deviation: 0 },
                { type: 'Asset C. CA ', title: 'assetca', status: 'COMPLIANT', keStatus: 'Rejected',
                 currentPortfolioValue: 4, deviation: 0 },
                { type: 'Sector', title: 'sector', status: 'COMPLIANT', keStatus: 'Rejected', currentPortfolioValue: 13, deviation: 0 },
                { type: 'Commonality', title: 'com', status: 'COMPLIANT', keStatus: 'Approved', currentPortfolioValue: 13, deviation: 0 },
                { type: 'High Value Holding', title: 'hvh', status: 'OUTLIER', keStatus: 'Rejected',
                 currentPortfolioValue: 23, deviation: 2.45, outlierDaysOut: 21 }
            ],
            knownExceptions: [
                {
                    id: 1,
                    keId: 'KEID0001',
                    accountId: 'BMID001HH',
                    monitorFlagName: 'Risk',
                    decisionDate: '2017-11-19 10:00:00',
                    status: 'Approved',
                    reason: 'CGT',
                    approver: 'Abal'
                },
                {
                    id: 2,
                    keId: 'KEID0002',
                    accountId: 'BMID001HH',
                    monitorFlagName: 'Yield Concentration',
                    decisionDate: '2017-10-21 10:00:00',
                    status: 'Approved',
                    reason: 'Cherished',
                    approver: 'Dmart'
                },
                {
                    id: 3,
                    keId: 'KEID0005',
                    accountId: 'BMID001HH',
                    monitorFlagName: 'Issue Concentration',
                    decisionDate: '2016-12-19 10:00:00',
                    status: 'Pending',
                    reason: 'New Client'
                },
                {
                    id: 4,
                    keId: 'KEID0006',
                    accountId: 'BMID001HH',
                    monitorFlagName: 'Buylist',
                    decisionDate: '2017-10-01 10:00:00',
                    status: 'Approved',
                    reason: 'Cherished',
                    approver: 'Abal'
                },
                {
                    id: 5,
                    keId: 'KEID0007',
                    accountId: 'BMID001HH',
                    monitorFlagName: 'Asset C. EQ',
                    decisionDate: '2017-06-19 10:00:00',
                    status: 'Pending',
                    reason: 'CGT'
                },
                {
                    id: 6,
                    keId: 'KEID0056',
                    accountId: 'BMID001HH',
                    monitorFlagName: 'Asset C. OS EQ',
                    decisionDate: '2017-03-03 10:00:00',
                    status: 'Pending',
                    reason: 'New Client'
                },
                {
                    id: 7,
                    keId: 'KEID0575',
                    accountId: 'BMID001HH',
                    monitorFlagName: 'Commonality',
                    decisionDate: '2017-11-19 10:00:00',
                    approver: 'Dmart',
                    reason: 'Cherished',
                    status: 'Approved'
                }
            ]

        },
        {
            accountId: 'BMID001GG',
            clientId: 'CID1234',
            calculationDate: '2017-10-22 10:00:00',
            bitaRiskCategory: 'RG3',
            bitaModel: 'Yield',
            bitaModelId: 'M00122',
            bitaObjective: 'Balanced',
            monitorFlags: [
                { type: 'Risk', title: 'risk', status: 'OUTLIER', keStatus: 'Approved', currentPortfolioValue: 13, deviation: 4.69 },
                { type: 'Concentration', title: 'conc', status: 'OUTLIER', keStatus: 'Rejected',
                 currentPortfolioValue: 23.8, deviation: 5.69 },
                { type: 'Yield Concentration', title: 'Yconc', status: 'OUTLIER', keStatus: 'Approved',
                 currentPortfolioValue: 17, deviation: 1.69 },
                { type: 'Issue Concentration', title: 'Iconc', status: 'COMPLIANT', keStatus: 'Approved',
                 currentPortfolioValue: 20, deviation: 0 },
                { type: 'Tracking', title: 'track', status: 'COMPLIANT', keStatus: 'Rejected', currentPortfolioValue: 20.6, deviation: 0 },
                { type: 'Buylist', title: 'buy', status: 'OUTLIER', keStatus: 'Approved', currentPortfolioValue: 66.7, deviation: 34.69 },
                { type: 'Max Holding Weight', title: 'mhw', status: 'OUTLIER', keStatus: 'Rejected',
                 currentPortfolioValue: 34.4, deviation: 11 },
                { type: 'Asset C. EQ', title: 'asseteq', status: 'OUTLIER', keStatus: 'Approved',
                 currentPortfolioValue: 8, deviation: 2.6 },
                { type: 'Asset C. BD', title: 'assetbd', status: 'OUTLIER', keStatus: 'Rejected',
                 currentPortfolioValue: 6, deviation: 5.8 },
                { type: 'Asset C. IL', title: 'assetil', status: 'COMPLIANT', keStatus: 'Rejected',
                 currentPortfolioValue: 5, deviation: 0 },
                { type: 'Asset C. CB', title: 'assetcb', status: 'COMPLIANT', keStatus: 'Rejected',
                 currentPortfolioValue: 2, deviation: 0 },
                { type: 'Asset C. Alt', title: 'assetalt', status: 'COMPLIANT', keStatus: 'Rejected',
                 currentPortfolioValue: 9, deviation: 0 },
                { type: 'Asset C. OS EQ', title: 'assetoseq', status: 'OUTLIER', keStatus: 'Approved',
                 currentPortfolioValue: 7.6, deviation: 12.7 },
                { type: 'Asset C. EM EQ', title: 'assetemeq', status: 'COMPLIANT', keStatus: 'Rejected',
                 currentPortfolioValue: 6, deviation: 0 },
                { type: 'Asset C. SV BD', title: 'assetsvbd', status: 'OUTLIER', keStatus: 'Rejected',
                 currentPortfolioValue: 7, deviation: 0 },
                { type: 'Asset C. OS BD ', title: 'assetosbd', status: 'COMPLIANT', keStatus: 'Rejected',
                 currentPortfolioValue: 8, deviation: 0 },
                { type: 'Asset C. CA ', title: 'assetca', status: 'COMPLIANT', keStatus: 'Rejected',
                 currentPortfolioValue: 4, deviation: 0 },
                { type: 'Sector', title: 'sector', status: 'COMPLIANT', keStatus: 'Rejected', currentPortfolioValue: 13, deviation: 0 },
                { type: 'Commonality', title: 'com', status: 'COMPLIANT', keStatus: 'Approved', currentPortfolioValue: 13, deviation: 0 },
                { type: 'High Value Holding', title: 'hvh', status: 'OUTLIER', keStatus: 'Rejected',
                 currentPortfolioValue: 23, deviation: 2.45 }
            ],
            knownExceptions: [
                {
                    id: 8,
                    keId: 'KEID0003',
                    accountId: 'BMID001GG',
                    keType: 'Risk',
                    decisionDate: '2017-11-19 10:00:00',
                    status: 'Approved',
                    reason: 'CGT',
                    approver: 'Abal'
                },
                {
                    id: 9,
                    keId: 'KEID0004',
                    accountId: 'BMID001GG',
                    keType: 'Yield Concentration',
                    decisionDate: '2017-11-19 10:00:00',
                    status: 'Approved',
                    reason: 'CGT',
                    approver: 'Abal'
                }
            ]

        },
    ];
}
