import { HttpResponse } from '@angular/common/http';
import { Moment } from 'moment';
import { ReportRequestParams } from './monitor-criterions.model';
import { Subscription } from 'rxjs';

export enum ReportRequestStatus {
  Pending,
  Success,
  Error,
  Progress
}

export class ReportRequest {
  public description: string;
  public lastRunDate: Moment;
  public requestId: string;
  public reportRequest: ReportRequestParams;
  public status: ReportRequestStatus = ReportRequestStatus.Pending;
  public progress = 0;
  public request: Subscription = null;
  public response: HttpResponse<Object>;

  constructor(reportRequest: any) {
    this.reportRequest = reportRequest;
  }

  // actions
  public requestReport = () => { /* set in service */ };
  public cancel = () => { /* set in service */ };

  // statuses
  public isPending = () => this.status === ReportRequestStatus.Pending;
  public isSuccess = () => this.status === ReportRequestStatus.Success;
  public isError = () => this.status === ReportRequestStatus.Error;
  public inProgress = () => this.status === ReportRequestStatus.Progress;
  public isUploadable = () => this.status === ReportRequestStatus.Pending || this.status === ReportRequestStatus.Error;
}
