import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';
import { ClientPmpService } from '../../../shared/pmp/pmp.service';
import * as AABoundaryDeviationActions from './aaBoundaryDeviation.actions';

import { map, catchError, switchMap } from 'rxjs/operators';

export type AABoundaryDeviationAction = AABoundaryDeviationActions.All;

@Injectable()
export class AABoundaryDeviationEffects {

	constructor(private actions$: Actions,
							private clientSlwiService: ClientPmpService) {
	}

	getAABoundaryDeviations: Observable<AABoundaryDeviationAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(AABoundaryDeviationActions.GET_TAC_LEVEL_AA_BOUNDARY_DEVIATION),
      switchMap((a: AABoundaryDeviationActions.GetTacLevelAABoundaryDeviation) =>
			this.clientSlwiService.getAABoundaryDeviations(a.clientId, a.accountId).pipe(
        map(post => new AABoundaryDeviationActions.GetTacLevelAABoundaryDeviationSuccess(post)),
        catchError((error) => of(new AABoundaryDeviationActions.GetTacLevelAABoundaryDeviationError(error)))
      )
	)));
}
