import { FileNoteType } from '../entity/file-note-type.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { JsonConvert } from 'json2typescript';

import { JsonConvertService } from '../../shared/json-convert/json-convert.service';
import { ClientDetailsPackage } from '../entity/client-details.model';
import { ClientAccountDetails } from '../entity/client-account-details.model';
import { ClientNote } from '../entity/client-note.model';
import { ClientScenario } from '../entity/client-scenario.model';
import { ClientScenarioState } from '../store/client-scenario/client-scenario.state';
import { ScenarioStatusUpdate } from '../entity/scenario-status-update.model';

import { environment } from '../../../environments/environment';
import { GenericFormConfiguration } from '../../shared/entity/generic-form/generic-form.model';
import { NavActionService } from '../../core/navigation-floating/nav-action.service';

@Injectable()
export class ClientHomeService {

  private jsonConverter: JsonConvert;

  constructor(private httpClient: HttpClient, private jsonConvertService: JsonConvertService,
    private navActionService: NavActionService) {
    this.jsonConverter =  this.jsonConvertService.getJsonConverter();
  }

  getClientDetails(clientId: string): Observable<ClientDetailsPackage> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/client/${clientId}/home/details`).pipe(
      map(response => {
        return this.jsonConverter.deserializeObject(response, ClientDetailsPackage);
      }));
  }

  getEditClientConfiguration(clientId: string): Observable<GenericFormConfiguration> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/client/edit/config/${clientId}`).pipe(
    map(response => {
      return this.jsonConverter.deserializeObject(response['clientFormConfigDto'], GenericFormConfiguration);
    }));

  }

  updateClientDetails(newValues: any) {
    return this.httpClient.post(`${environment.serverUrl}/restapi/service/client/edit/save`, newValues).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.navActionService.addMessage('Error', 'An error occurred while saving', 'error');
          console.error('An error occurred: ', err.error.message);
        } else {
          // TODO: Send error message to polo mint or notification service when it's implemented
          this.navActionService.addMessage('Error', err.error.message, 'error');
          console.error(`Backend returned code ${err.status}, error was: ${err.error.message}`);
        }
        // empty observable
        return empty();
      }));
  }

  getClientAccountDetails(clientId: string): Observable<ClientAccountDetails[]> {

    return this.httpClient.get(`${environment.serverUrl}/restapi/service/client/${clientId}/home/accounts`).pipe(
      map(response => {
        const clientAccountDetails: ClientAccountDetails[] = response as ClientAccountDetails[];
        return this.jsonConverter.deserializeArray(clientAccountDetails['accountDetails'], ClientAccountDetails);
      }
    ));
  }

  getClientAccountDetailsSummary(clientId: string): Observable<ClientAccountDetails[]> {

    return this.httpClient.get(`${environment.serverUrl}/restapi/service/client/${clientId}/accounts/summary`).pipe(
      map(response => {
        const clientAccountDetails: ClientAccountDetails[] = response as ClientAccountDetails[];
        return this.jsonConverter.deserializeArray(clientAccountDetails['accountDetails'], ClientAccountDetails);
      }
    ));
  }

  getClientFileNotes(clientId: string): Observable<ClientNote[]> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/client/${clientId}/home/fileNotes`).pipe(
      map(response => {
        const clientFileNotes: ClientNote[] = response as ClientNote[];
        return this.jsonConverter.deserializeArray(clientFileNotes['fileNotes'], ClientNote);
      }
    ));
  }

  addClientFileNote(clientId: string, fileNote: ClientNote): Observable<ClientNote[]> {
    return this.httpClient.post(`${environment.serverUrl}/restapi/service/filenote/client/${clientId}/save`, {
      type: fileNote.fileNoteType,
      description: fileNote.fileNoteDesc
    }).pipe(
      map(response => {
      const clientFileNotes: ClientNote[] = response as ClientNote[];
      return this.jsonConverter.deserializeArray(clientFileNotes['fileNotes'], ClientNote);
    }));
  }

  getAllClientScenarios(clientId: string): Observable<ClientScenarioState> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/client/${clientId}/home/scenarios`).pipe(
			map(response => this.parseClientScenarioState(response)));
    }

  private parseClientScenarioState(response) {
    const clientScenario: ClientScenarioState = response as ClientScenarioState;
    const clientScenarioState: ClientScenarioState = {
      clientScenarios: this.jsonConverter.deserializeArray(clientScenario.clientScenarios, ClientScenario),
      scenarioStatuses: clientScenario.scenarioStatuses as String[]
    };
    return clientScenarioState;
  }

  updateClientScenarioStatus(scenarioUpdate: ScenarioStatusUpdate): Observable<any> {
    const url = `${environment.serverUrl}/restapi/service/client/${scenarioUpdate.clientId}/scenario/${scenarioUpdate.scenarioId}/status`;

    return this.httpClient.put(url, {
      newScenarioStateName: scenarioUpdate.newStatus,
      origScenarioStateName: scenarioUpdate.oldStatus
    }).pipe(map(response => this.parseClientScenarioState(response)));
  }
  deleteClientScenarioStatus(clientId: string, scenarioId: number): Observable<ClientScenarioState> {
    const url = `${environment.serverUrl}/restapi/service/client/${clientId}/scenario/${scenarioId}/delete`;

    return this.httpClient.delete(url).pipe(map(response => this.parseClientScenarioState(response)));
  }

  getClientFileNoteTypes(): Observable<FileNoteType[]> {
    const url = `${environment.serverUrl}/restapi/service/filenote/client/list`;
    return this.httpClient.get(url).pipe(
      map(response => {
        return this.jsonConverter.deserializeArray(response as FileNoteType[], FileNoteType);
      }));
  }

}
