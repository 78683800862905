import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable ,  of } from 'rxjs';
import { AppData } from '../core/store/appState.model';
import { AuthenticationState } from '../core/store/auth/authentication.state';
import { AppRouterService } from '../shared/router/app-router.service';
import { map } from 'rxjs/operators';
/**
 * Prevent unauthorized activating and loading of routes
 * @class AuthenticatedGuard
 */
@Injectable()
export class AuthenticatedGuard implements CanActivate {

  private authenticationState: Observable<AuthenticationState>;
  constructor(private store: Store<AppData>, private appRouterService: AppRouterService) {
    this.authenticationState = this.store.select('authentication');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    this.authenticationState.subscribe(authState => {
      if (!authState.session || !authState.session.user) {
        this.appRouterService.loginPage({redirect: state.url});
      }
    });

    return this.authenticationState.pipe(map(authState => authState.session !== undefined && authState.session.user !== null));
  }
}
