import { JsonObject, JsonProperty } from 'json2typescript';
import { FormComponentGroup } from './form-component-group.model';
import { MapConverter } from '../../json-convert/MapConverter';
import { FormComponentBase } from './form-component-element';


@JsonObject('GenericFormConfiguration')
export class GenericFormConfiguration {

  @JsonProperty('rowComponents', [FormComponentGroup])
  rowComponents: FormComponentGroup[] = undefined;

  @JsonProperty('formName', String, true)
  formName: string = undefined;

  @JsonProperty('formLabel', String, true)
  formLabel: string = undefined;

  @JsonProperty('formValues', MapConverter)
  formValues: Map<string, string> = undefined;

  @JsonProperty('exclusive', Boolean, true)
  exclusive: boolean = undefined;

  sort() {
    this.rowComponents.sort((a, b) => a.order - b.order);
    this.rowComponents.forEach(component => component.sort());
  }

  getComponent(rowNameKey, columnNameKey): FormComponentBase<any> {
    const rowComponent = this.rowComponents.find(component => component.key === rowNameKey);
    if (rowComponent) {
      return rowComponent.components.find(component => component.key === columnNameKey);
    }
    return;
  }

}

