import { GenericFormConfiguration } from './generic-form/generic-form.model';
import { JsonObject, JsonProperty } from 'json2typescript';
import { MapConverter } from '../json-convert/MapConverter';

@JsonObject('TeamBuyList')
export class TeamBuyList {

  @JsonProperty('config', GenericFormConfiguration)
  config: GenericFormConfiguration = undefined;

  @JsonProperty('answers', MapConverter)
  answers: Map<string, string> = undefined;

  @JsonProperty('assetClass', String)
  assetClass: string = undefined;

}
