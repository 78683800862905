import {JsonObject, JsonProperty} from 'json2typescript';
import { MapConverter } from '../../shared/json-convert/MapConverter';

@JsonObject('Configuration')
export class Configuration {

  @JsonProperty('systemConfig', MapConverter)
  systemConfig: Map<string, string> = undefined;

  @JsonProperty('userConfig', MapConverter)
  userConfig: Map<string, string> = undefined;

  @JsonProperty('versionDetails', [String])
  versionDetails: string[] = undefined;

  constructor(systemConfig?: Map<string, string>, userConfig?: Map<string, string>,
    versionDetails?: string[]) {
    this.systemConfig = systemConfig;
    this.userConfig = userConfig;
    this.versionDetails = versionDetails;
  }

  getConfig(key: string) {
    let value = null;
    if (this.userConfig && this.userConfig.size > 0) {
      value = this.userConfig.get(key);
    }

    if (value) {
      return value;
    } else {
      return this.systemConfig.get(key);
    }
  }
 }
