import {JsonObject, JsonProperty} from 'json2typescript';
import { DateConverter } from '../json-convert/DateConvertor';
import { Moment } from 'moment';
import { MapConverter } from '../json-convert/MapConverter';

@JsonObject('WidgetProfile')
export class WidgetProfile {

    @JsonProperty('accountId', String)
    accountName: string = undefined;

    @JsonProperty('bitaRiskCategory', String)
    bitaRiskCategory: string = undefined;

    @JsonProperty('backOfficeRiskCategory', String)
    backOfficeRiskCategory: string = undefined;

    @JsonProperty('bitaModel', String)
    bitaModel: string = undefined;

    @JsonProperty('backOfficeModel', String)
    backOfficeModel: string = undefined;

    @JsonProperty('bitaObjective', String)
    bitaObjective: string = undefined;

    @JsonProperty('backOfficeObjective', String)
    backOfficeObjective: string = undefined;

    @JsonProperty('bitaModelId', String)
    bitaModelId: string = undefined;

    @JsonProperty('backOfficeModelId', String)
    backOfficeModelId: string = undefined;

    @JsonProperty('bitaServiceCategory', String)
    bitaServiceCategory: string = undefined;

    @JsonProperty('backOfficeServiceCategory', String)
    backOfficeServiceCategory: string = undefined;

    @JsonProperty('clientStatus', String)
    clientStatus: string = undefined;

    @JsonProperty('modelCurrency', String)
    modelCurrency: string = undefined;

    @JsonProperty('modelDate', DateConverter)
    modelDate: Moment = undefined;

    @JsonProperty('office', String)
    office: string = undefined;

    @JsonProperty('owner', String)
    clientOwner: string = undefined;

 }

 @JsonObject('WidgetModelDetail')
export class WidgetModelDetail {

    @JsonProperty('key', String)
    key: string = undefined;

    @JsonProperty('label', String)
    label: string = undefined;

    @JsonProperty('value', String)
    value: string = undefined;

    @JsonProperty('metaData', MapConverter)
    metaData: Map<string, string> = undefined;
}
