import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';
import { SearchService } from '../../../../search/search.service';
import * as SearchMetaDataActions from './search-criteria.actions';
import { map, catchError, switchMap } from 'rxjs/operators';

export type SearchMetaDataAction = SearchMetaDataActions.All;

@Injectable()
export class SearchMetaDataEffects {

	constructor(private actions$: Actions,
							private searchService: SearchService) {
	}

	getSearchMetaData: Observable<SearchMetaDataAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(SearchMetaDataActions.GET_SEARCH_META_DATA),
      switchMap(() =>  this.searchService.getSearchMetaData().pipe(
        map(post => new SearchMetaDataActions.GetSearchMetaDataSuccess(post)),
        catchError((error) => of(new SearchMetaDataActions.GetSearchMetaDataError(error)))
      )))
    );

}
