import { JsonObject, JsonProperty } from 'json2typescript';
import { MapOfMapConverter } from '../../json-convert/map-of-map-converter';
import { MapConverter } from '../../json-convert/MapConverter';


@JsonObject('FormOption')
export class FormOption {

  @JsonProperty('key', String)
  value: string = undefined;

  @JsonProperty('value', String)
  label: string = undefined;
}

@JsonObject('FormComponentBase')
export class FormComponentBase<T> {
  @JsonProperty('key', String)
  key: string = undefined;

  @JsonProperty('label', String)
  label: string = undefined;

  @JsonProperty('required', Boolean)
  required: boolean = undefined;

  @JsonProperty('order', Number, true)
  order: number = undefined;

  @JsonProperty('controlType', String)
  controlType: string = undefined;

  @JsonProperty('disabled', Boolean, true)
  disabled: boolean = undefined;

  @JsonProperty('cssStyle', String, true)
  cssStyle: string = undefined;

  @JsonProperty('validators', MapOfMapConverter, true)
  validators: Map<string, Map<string, string>> = undefined;

  @JsonProperty('metaData', MapConverter, true)
  metaData: Map<string, string> = undefined;

  @JsonProperty('unit', String, true)
  unit: string = undefined;

  constructor(key?: string, label?: string, required?: boolean) {
    this.key = key;
    this.label = label;
    this.required = required;
  }
}

@JsonObject('DropdownFormComponent')
export class DropdownFormComponent extends FormComponentBase<string> {
  controlType = 'dropdown';

  @JsonProperty('options', [FormOption])
  options: FormOption[] = undefined;

  constructor(key?: string, label?: string, required?: boolean) {
    super(key, label, required);
  }

  getOptionValue(key: string) {
    return this.options.find(element => element.value === key);
  }
}

@JsonObject('MultiSelectFormComponent')
export class MultiSelectFormComponent extends FormComponentBase<string> {
  controlType = 'multiSelect';

  @JsonProperty('options', [FormOption])
  options: FormOption[] = undefined;

  constructor(key?: string, label?: string, required?: boolean) {
    super(key, label, required);
  }

  getOptionValue(key: string) {
    return this.options.find(element => element.value === key);
  }
}

@JsonObject('SwitchFormComponent')
export class SwitchFormComponent extends FormComponentBase<string> {
  controlType = 'switch';

  constructor(key?: string, label?: string, required?: boolean) {
    super(key, label, required);
  }
}

@JsonObject('SwitchTextFormComponent')
export class SwitchTextFormComponent extends FormComponentBase<string> {
  controlType = 'switchText';

  constructor(key?: string, label?: string, required?: boolean) {
    super(key, label, required);
  }
}

@JsonObject('TextboxFormComponent')
export class TextboxFormComponent extends FormComponentBase<string> {
  controlType = 'text';

  constructor(key?: string, label?: string, required?: boolean) {
    super(key, label, required);
  }
}

@JsonObject('TextAreaFormComponent')
export class TextAreaFormComponent extends FormComponentBase<string> {
  controlType = 'text';

  constructor(key?: string, label?: string, required?: boolean) {
    super(key, label, required);
  }
}

@JsonObject('DateFormComponent')
export class DateFormComponent extends FormComponentBase<string> {
  controlType = 'date';

  constructor(key?: string, label?: string, required?: boolean) {
    super(key, label, required);
  }
}

@JsonObject('NumberFormComponent')
export class NumberFormComponent extends FormComponentBase<string> {
  controlType = 'number';

  constructor(key?: string, label?: string, required?: boolean) {
    super(key, label, required);
  }
}

@JsonObject('OptionFormComponent')
export class OptionFormComponent extends FormComponentBase<string> {
  controlType = 'option';

  constructor(key?: string, label?: string, required?: boolean) {
    super(key, label, required);
  }
}

@JsonObject('HiddenFormComponent')
export class HiddenFormComponent extends FormComponentBase<string> {
  controlType = 'hidden';

  constructor(key?: string, label?: string, required?: boolean) {
    super(key, label, required);
  }
}

