import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('MonitorFlagType')
export class MonitorFlagType {

  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('type', String)
  type: string = undefined;

  @JsonProperty('label', String)
  label: string = undefined;

  isOutlier: boolean = undefined;

}
