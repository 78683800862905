import { Action } from '@ngrx/store';

import * as KnownExceptionTypesActions from './knownExceptionTypes.actions';
import { KnownExceptionTypesState } from './knownExceptionTypes.state';
import { KnownExceptionType } from '../../../entity/known-exception-type.model';

export type KnownExceptionTypesAction = KnownExceptionTypesActions.All;
export const initialState: KnownExceptionTypesState = {  KnownExceptionTypes : new Array<KnownExceptionType>()};

export function knownExceptionTypesReducer(state: KnownExceptionTypesState = initialState,
   action: KnownExceptionTypesAction): KnownExceptionTypesState {
  switch (action.type) {

    case KnownExceptionTypesActions.GET_KNOWN_EXCEPTION_TYPES_SUCCESS: {
      return {...state, ...{KnownExceptionTypes: action.knownExceptionTypes}
    };
    }

    case KnownExceptionTypesActions.GET_KNOWN_EXCEPTION_TYPES_ERROR: {
      console.error('Error caught while loading knownExceptionTypes', action.error);
      return {...initialState};
    }

    default :
      return state;
  }

}
