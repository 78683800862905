import { JsonObject, JsonProperty } from 'json2typescript';
import { Moment } from 'moment';
import { DateConverter } from '../../../shared/json-convert/DateConvertor';

@JsonObject('TcfdPortfolioInfo')
export class TcfdPortfolioInfo {

    @JsonProperty('portfolioName', String)
    portfolioName: string = undefined;

    @JsonProperty('portfolioId', String)
    portfolioId: string = undefined;

    @JsonProperty('calculationBasic', String)
    calculationBasic: string = undefined;

    @JsonProperty('reportDate', DateConverter)
    reportDate: Moment = undefined;

    @JsonProperty('dataDate', DateConverter)
    dataDate: Moment = undefined;

    @JsonProperty('benchmarkName', String)
    benchmarkName: string = undefined;

    @JsonProperty('additionalInfo', String)
    additionalInfo: string = undefined;

 }
