import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JsonConvert } from 'json2typescript';
import { BmidHoldingList } from '../entity/bmid-holding-list.model';
import { JsonConvertService } from '../json-convert/json-convert.service';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class BmidHoldingService {

  constructor(private httpClient: HttpClient, private jsonConvertService: JsonConvertService) { }

  getAllBmidHoldingList(monitorFlagName: string, chartType: string,
    outlierStatusType: string, withKE: boolean): Observable<BmidHoldingList> {
      const baseUrl = `${environment.serverUrl}/restapi/service/dashboard/outlier/monitor`;
      if (outlierStatusType) {
        return this.httpClient.get(
          `${baseUrl}/${monitorFlagName}/chart/${chartType}/${outlierStatusType}/${withKE}`)
          .pipe(
            map(response => {
              return this.processBmidHoldingList(response, monitorFlagName);
          }));
      } else {
        return this.httpClient.get(
          `${baseUrl}/${monitorFlagName}/chart/${chartType}`)
          .pipe(
            map(response => {
              return this.processBmidHoldingList(response, monitorFlagName);
          }));
      }
  }

  processBmidHoldingList(response, monitorFlagName: string): BmidHoldingList {
    const jsonStr: string = JSON.stringify(response);
    const jsonObj: BmidHoldingList = JSON.parse(jsonStr);
    const jsonConvert: JsonConvert = this.jsonConvertService.getJsonConverter();
    const bmidList: BmidHoldingList = jsonConvert.deserializeObject(jsonObj, BmidHoldingList);

    bmidList.monitorFlagType = monitorFlagName;
    return bmidList;
  }
}
