import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable ,  of } from 'rxjs';
import { AppData } from '../core/store/appState.model';
import { AuthenticationState } from '../core/store/auth/authentication.state';
import { AppRouterService } from '../shared/router/app-router.service';
import { map, filter } from 'rxjs/operators';
import { User } from '../shared/entity/user.model';
/**
 * Prevent unauthorized activating and loading of routes
 * @class AuthorizationGuard
 */
@Injectable()
export class AuthorizationGuard implements CanActivate {

  private authenticationState: Observable<AuthenticationState>;
  user: User;

  constructor(private route: ActivatedRoute, private store: Store<AppData>, private appRouterService: AppRouterService) {
    this.authenticationState = this.store.select('authentication');
    this.authenticationState.pipe(filter(state => !!state && !!state.session && !!state.session.user),
    map(state => state.session.user)).subscribe(user => this.user = user);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const authorities: string[] = route.data['authorities'];

    if (!authorities || !this.user) {
      return false;
    }

    const cannotActivate = authorities.some(role => {
      const index = this.user.authorities.findIndex(authority => authority === role);
      return index === -1;
    });

    if (cannotActivate) {
      this.appRouterService.accessDeniedPage();
    }
    return !cannotActivate;
  }
}
