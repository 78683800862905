import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { JsonConvert } from 'json2typescript';
import { JsonConvertService } from '../../json-convert/json-convert.service';
import { KnownExceptionState } from '../../store/client-account/knownExceptions/knownExceptions.state';
import { KnownException } from '../../entity/known-exception.model';
import { KnownExceptionRequestDecision, AddKnownExceptionRequestDecision } from '../../entity/known-exception-request-decision.model';
import { KnownExceptionType } from '../../entity/known-exception-type.model';
import { MonitorFlagType } from '../../entity/monitor-flag-type.model';
import { AddKnownExceptionRequest } from '../../entity/add-known-exception-request.model';
import { environment } from '../../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable()
export class KnownExceptionsService {

  private jsonConverter: JsonConvert;

  constructor(private httpClient: HttpClient, private jsonConvertService: JsonConvertService) {
    this.jsonConverter =  this.jsonConvertService.getJsonConverter();
	}

	getKnownException(keId: number): Observable<KnownException> {
		return this.httpClient.get(`${environment.serverUrl}/restapi/service/knownexception/${keId}`).pipe(
		map(response => {
			return this.jsonConverter.deserializeObject(response, KnownException);
		}));
	}

  getAllKnownException(clientId: string, accountId: string): Observable<KnownExceptionState> {
		return this.httpClient.get(`${environment.serverUrl}/restapi/service/knownexception/client/${clientId}/account/${encodeURIComponent(accountId)}`).pipe(
		map(response => {
			return this.mapKnownException(response);
		}));
	}

	getAllKnownExceptionTypes(): Observable<KnownExceptionType[]> {
		return this.httpClient.get(`${environment.serverUrl}/restapi/service/knownexception/type`).pipe(
		map(response => {
			const knownExceptionTypes = response as KnownExceptionType[];

			return this.jsonConverter.deserializeArray(knownExceptionTypes, KnownExceptionType);
		}));
	}

	getAllMonitorFlagTypes(clientId: string, accountId: string): Observable<MonitorFlagType[]> {

		let url = `${environment.serverUrl}/restapi/service/knownexception/monitorflag/type`;
		if(clientId && accountId)
		{ 
			url = `${environment.serverUrl}/restapi/service/knownexception/monitorflag/client/${clientId}/account/${encodeURIComponent(accountId)}/type2`;
		}
		return this.httpClient.get(url).pipe(
		map(response => {
			const monitorFlagTypes = response as MonitorFlagType[];

			return this.jsonConverter.deserializeArray(monitorFlagTypes, MonitorFlagType);
		}));
	}

	addKnownException(knownExceptionRequest: AddKnownExceptionRequest): Observable<KnownExceptionState> {
		return this.httpClient.post(`${environment.serverUrl}/restapi/service/knownexception/add`, knownExceptionRequest).pipe(
		map(response => {
			return this.mapKnownException(response);
		}));
	}

	addExcludeHoldingKnownException(knownExceptionRequest: AddKnownExceptionRequest): Observable<KnownExceptionState> {
		return this.httpClient.post(`${environment.serverUrl}/restapi/service/knownexception/excludeHolding`, {
			clientId: knownExceptionRequest.clientId,
			accountId: knownExceptionRequest.accountId,
			knownExceptionTypeId: knownExceptionRequest.knownExceptionTypeId,
			description: knownExceptionRequest.description,
			expiryDate: knownExceptionRequest.expiryDate,
			sedol: knownExceptionRequest.sedol,
		}).pipe(
		map(response => {
			return this.mapKnownException(response);
		}));
	}

	deleteKnownException(id: number): Observable<KnownExceptionState> {
		return this.httpClient.delete(`${environment.serverUrl}/restapi/service/knownexception/${id}/delete`).pipe(
		map(response => {
			return this.mapKnownException(response);
		}));
	}

	private mapKnownException(response): KnownExceptionState {
		const kes: KnownExceptionState = response as KnownExceptionState;
		const keState: KnownExceptionState = {
			pendingKnownExceptions: this.jsonConverter.deserializeArray(kes.pendingKnownExceptions, KnownException),
			pendingReviewKnownExceptions: this.jsonConverter.deserializeArray(kes.pendingReviewKnownExceptions, KnownException),
			pausedKnownExceptions: this.jsonConverter.deserializeArray(kes.pausedKnownExceptions, KnownException),
			currentKnownExceptions: this.jsonConverter.deserializeArray(kes.currentKnownExceptions, KnownException),
			rejectedKnownExceptions: this.jsonConverter.deserializeArray(kes.rejectedKnownExceptions, KnownException),
			reversedKnownExceptions: this.jsonConverter.deserializeArray(kes.reversedKnownExceptions, KnownException),
			historyKnownExceptions: this.jsonConverter.deserializeArray(kes.historyKnownExceptions, KnownException),
			addKnownExceptionSuccess: false,
			errorMessage: kes.errorMessage
		};
		return keState;

	}

	getKnownExceptionRequestDecisions(keId: number): Observable<KnownExceptionRequestDecision[]> {
		return this.httpClient.get(`${environment.serverUrl}/restapi/service/knownexception/${keId}/decision`).pipe(
		map(response => {
			const keDecisions: KnownExceptionRequestDecision[] = response as KnownExceptionRequestDecision[];
			return this.jsonConverter.deserializeArray(keDecisions, KnownExceptionRequestDecision);
		}));
	}

	getKnownExceptionForApproval(keId: number): Observable<KnownException> {
		return this.httpClient.get(`${environment.serverUrl}/restapi/service/knownexception/${keId}`).pipe(
		map(response => {
			const ke: KnownException = response as KnownException;
			return this.jsonConverter.deserializeObject(ke, KnownException);
		}));
	}

	addKnownExceptionDecision(keDecision: AddKnownExceptionRequestDecision): Observable<KnownExceptionState> {
		return this.httpClient.post(`${environment.serverUrl}/restapi/service/knownexception/add/decision`, keDecision).pipe(
		map(response => {
			return this.mapKnownException(response);
		}));
	}

  submitToMinotaur(clientId: string, accountId: string, keDecision: AddKnownExceptionRequestDecision) {
    const url = `/restapi/service/knownexception/minotaur/client/${clientId}/account/${encodeURIComponent(accountId)}`;
    return this.httpClient.post(`${environment.serverUrl}${url}`, keDecision).pipe(
      map(response => this.mapKnownException(response))
    );
  }

  getPendingProfileRequestDate(clientId: string, accountId: string) {
    const url = `/restapi/service/profile/client/${clientId}/account/${encodeURIComponent(accountId)}/pendingProfileRequestDate`;
    return this.httpClient.get(`${environment.serverUrl}${url}`).pipe(
      map(response => moment(response))
    );
  }

}
