import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';

import { ClientPmpService } from '../../../shared/pmp/pmp.service';
import * as ProfileActions from './profile.actions';
import { map, catchError, switchMap } from 'rxjs/operators';

export type ProfileAction = ProfileActions.All;

@Injectable()
export class ProfileEffects {

	constructor(private actions$: Actions,
							private clientSlwiService: ClientPmpService) {
	}

	getProfile: Observable<ProfileAction> = createEffect(() =>
   this.actions$.pipe(
    ofType(ProfileActions.GET_WIDGET_PROFILE),
    switchMap((a: ProfileActions.GetWidgetProfile) =>  this.clientSlwiService.getWidgetProfile(a.clientId, a.accountId).pipe(
      /* the end point to this service is unavailable (it is commented out). So just for the code to compile, an
       empty array is added as input of the action instead of DynamicWidgetProfile  */
      map(post => new ProfileActions.GetWidgetProfileSuccess(post, [])),
      catchError((error) => of(new ProfileActions.GetWidgetProfileError(error)))
    ))
  ));

}
