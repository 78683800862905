import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { localStorageSync } from 'ngrx-store-localstorage';

import { authenticationReducer } from './auth/authentication.reducers';
import { AuthenticationEffects } from './auth/authentication.effects';
import { RouterEffects } from './router/router.effects';
import { logout } from './meta-reducer/logout-meta-reducer';
import { Session } from '../entity/session.model';
import { User } from '../../shared/entity/user.model';
import { Configuration } from '../entity/configuration.model';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: [{'authentication': {deserialize: authenticationDeserializer } } ],
   rehydrate: true, removeOnUndefined : true })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, logout];

@NgModule({
  imports: [
    EffectsModule.forRoot(
      [
        AuthenticationEffects,
        RouterEffects
      ]
    ),
    StoreModule.forRoot(
      {
        authentication: authenticationReducer,
      },
      { metaReducers,
        runtimeChecks: {
            strictStateImmutability: false,
            strictActionImmutability: false
        }
      }
    ),
    StoreDevtoolsModule.instrument(
      {
        maxAge: 30
      }
    )
  ]
})
export class RootStoreModule { }


function authenticationDeserializer(obj: any) {

if (obj && obj.session) {

  let session: Session;
  let user: User;
  let configuration: Configuration;

  if (obj.session.user) {
    const tempUser = obj.session.user;
    const authorities = tempUser.authorities ? tempUser.authorities : [];
    user = new User(tempUser.userName, authorities);
  }

  if (obj.session.configuration) {
    const tempConfiguration = obj.session.configuration;

    configuration = new Configuration(arrayToMap(tempConfiguration.systemConfig),
    arrayToMap(tempConfiguration.userConfig), tempConfiguration.versionDetails);
  }

  session = new Session(user, obj.session.lastAccessedClientId, obj.session.lastAccessedClientList, configuration);

  return { session: session, loading: obj.loading };
  }
  return obj;
}

function arrayToMap(jsonObject: string[]) {
  const mapData = new Map<string, string>();
  if (!jsonObject) {
    return mapData;
  }
  
  for (const k of jsonObject) {
    mapData.set(k[0], k[1]);
  }
  return mapData;
}
