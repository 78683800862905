import { JsonObject, JsonProperty } from 'json2typescript';
import { Moment } from 'moment';
import { DateConverter } from '../../../shared/json-convert/DateConvertor';
import { TcfdPortfolioInfo } from './tcfd-account-details.model';
import { TcfdHeadlineItem } from './tcfd-shared.model';

@JsonObject('TcfdCoverageDataItem')
export class TcfdCoverageDataItem {
  @JsonProperty('esgAssetType', String, true)
  esgAssetType: string = undefined;

  @JsonProperty('esgAssetTypeName', String, true)
  esgAssetTypeName: string = undefined;

  @JsonProperty('currency', String, true)
  currency: string = undefined;

  @JsonProperty('currencySymbol', String, true)
  currencySymbol: string = undefined;

  @JsonProperty('value', Number)
  value: number = undefined;

  @JsonProperty('weight', Number)
  weight: number = undefined;

  @JsonProperty('coverageScope123', Number)
  coverageScope123WithData: number = undefined;

  @JsonProperty('coverageScope12', Number)
  coverageScope12WithData: number = undefined;

  @JsonProperty('coverageNoData', Number)
  coverageNoData: number = undefined;
}


@JsonObject('TcfdDataCoverage')
export class TcfdDataCoverage {

  @JsonProperty('note', String)
  note: string = undefined;

  @JsonProperty('assetTypeDataCoverage', [TcfdCoverageDataItem])
  assetTypeDataCoverage: TcfdCoverageDataItem[] = undefined;

  @JsonProperty('summary', TcfdCoverageDataItem, true)
  summary: TcfdCoverageDataItem = undefined;

}


@JsonObject('TcfdDataComparisonItem')
export class TcfdDataComparisonItem {
  @JsonProperty('header', String)
  header: string = undefined;

  @JsonProperty('headlineItems', [TcfdHeadlineItem], true)
  headlineItems: TcfdHeadlineItem[] = undefined;

  @JsonProperty('summary', TcfdHeadlineItem, true)
  summary: TcfdHeadlineItem = undefined;

}

@JsonObject('TcfdDataComparison')
export class TcfdDataComparison {
  @JsonProperty('title', String)
  title: string = undefined;

  @JsonProperty('note', String)
  note: string = undefined;

  @JsonProperty('portfolioName', String)
  portfolioName: string = undefined;

  @JsonProperty('portfolioId', String)
  portfolioId: string = undefined;

  @JsonProperty('reportDate', DateConverter)
  reportDate: Moment = undefined;

  @JsonProperty('ghgEmissions', TcfdDataComparisonItem)
  ghgEmissions: TcfdDataComparisonItem = undefined;

  @JsonProperty('carbonFootprint', TcfdDataComparisonItem)
  carbonFootPrint: TcfdDataComparisonItem = undefined;

  @JsonProperty('weightedAverageCarbonIntensity', TcfdDataComparisonItem)
  carbonIntensity: TcfdDataComparisonItem = undefined;

  @JsonProperty('climateValueAtRisk', TcfdDataComparisonItem)
  climateValueAtRisk: TcfdDataComparisonItem = undefined;

  @JsonProperty('impliedTemperatureRise', TcfdDataComparisonItem)
  impliedTemperatureRise: TcfdDataComparisonItem = undefined;

  @JsonProperty('specificExposuresPositive', TcfdDataComparisonItem)
  specificExposuresPositive: TcfdDataComparisonItem = undefined;

  @JsonProperty('specificExposuresRisk', TcfdDataComparisonItem)
  specificExposuresRisk: TcfdDataComparisonItem = undefined;

}

@JsonObject('TcfdDashboard')
export class TcfdDashboard {

  @JsonProperty('portfolioInfo', TcfdPortfolioInfo)
  portfolioInfo: TcfdPortfolioInfo = undefined;

  @JsonProperty('dataCoverage', TcfdDataCoverage)
  dataCoverage: TcfdDataCoverage = undefined;

  @JsonProperty('comparisons', TcfdDataComparison)
  comparisons: TcfdDataComparison = undefined;

  @JsonProperty('commentary', [String])
  commentary: string[] = undefined;

}

export class TcfdCarbonEmissionData {
  name: string;
  scope123: number;
  scope12: number;
  noData: number;

  constructor(name: string, scope123: number, scope12: number, noData: number) {
    this.name = name;
    this.scope123 = scope123;
    this.scope12 = scope12;
    this.noData = noData;
  }
}
