import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ClientPppBenchmarkGroup')
export class ClientPppBenchmarkGroup {

  @JsonProperty('BENCHMARK_GROUP_ID', Number)
  benchmarkGroupId: number = undefined;

  @JsonProperty('BENCHMARK_GROUP_NAME', String)
  benchmarkGroupName: string = undefined;

}

@JsonObject('ClientPppReportGroup')
export class ClientPppReportGroup {

  @JsonProperty('REPORT_GROUP_ID', Number)
  reportGroupId: number = undefined;

  @JsonProperty('REPORT_GROUP_NAME', String)
  reportGroupName: string = undefined;

}

@JsonObject('ClientPppReport')
export class ClientPppReport {

  @JsonProperty('REPORT_GROUP', [ClientPppReportGroup])
  reportGroup: ClientPppReportGroup[] = undefined;

  @JsonProperty('BENCHMARK_GROUP', [ClientPppBenchmarkGroup])
  benchmarkGroup: ClientPppBenchmarkGroup[] = undefined;

}
