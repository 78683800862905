import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { PortfolioConstructionModel } from '../entity/portfolio-construction-model.model';
import { JsonConvert } from 'json2typescript';
import { JsonConvertService } from '../json-convert/json-convert.service';
import { ReportCreation } from '../entity/report-creation.model';

interface Analytic {
  key: string;
  value: any;
}

export interface AnalyticObj {
  [key: string]: Analytic;
}

export enum AnalyticsType {
  FACT = 'FACT'
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private jsonConverter: JsonConvert;

  constructor(private httpClient: HttpClient, private jsonConvertService: JsonConvertService) {
    this.jsonConverter = this.jsonConvertService.getJsonConverter();
  }

  getAnalyticsOld(currentPcm: PortfolioConstructionModel, type: AnalyticsType): Observable<AnalyticObj[]> {
    const pcm = this.jsonConverter.serializeObject(currentPcm);
    const url = `${environment.serverUrl}/restapi/service/portfolio/analytics/${type}/data`;
    return this.httpClient.post(url, pcm).pipe(map((response: AnalyticObj[]) => response));
  }
  getAnalytics(currentPcm: PortfolioConstructionModel, type: AnalyticsType): Observable<AnalyticObj> {
    const pcm = this.jsonConverter.serializeObject(currentPcm);
    const url = `${environment.serverUrl}/restapi/service/portfolio/analytics/factorData`;
    return this.httpClient.post(url, pcm)
      .pipe(map((response: AnalyticObj) => {
        return response;
      }));
  }
  getExtra(port3: {}[]): any { // Colin Smith... for a bit of testing
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.httpClient.post('http://localhost:4024/factoranalysis', port3, options)
      .pipe(map(rr => rr));
  }
  getAnalyticsReport(currentPcm: PortfolioConstructionModel, type: AnalyticsType): any {
    const pcm = this.jsonConverter.serializeObject(currentPcm);
    const url = `${environment.serverUrl}/restapi/service/portfolio/analytics/${type}/report`;
    return this.httpClient
      .post(url, pcm).pipe(
        map(response => this.jsonConverter.deserializeObject(response, ReportCreation))
      );
  }
}
