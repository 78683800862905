export function mockMonitorFlags() {
	return {
		monitorFlags :
			[
        {
					title: 'Risk',
					type: 'risk',
					status: 'OUTLIER',
					keStatus: '',
					compliantLowerValue: 20,
					compliantUpperValue: 60,
					almostOutlierLowerValue: 40,
					almostOutlierUpperValue: 55,
					currentPortfolioValue: 21,
					proposedPortfolioValue: 50,
					deviation: 14.69
        },
        {
					title: 'Concentration',
					type: 'concentration',
					status: 'NOT OUTLIER',
					keStatus: '',
					compliantLowerValue: 21,
					compliantUpperValue: 58,
					almostOutlierLowerValue: 30,
					almostOutlierUpperValue: 50,
					currentPortfolioValue: 35,
					proposedPortfolioValue: 23,
					deviation: 35
        },
        {
					title: 'Sector Conc',
					type: 'sectorConc',
					status: 'NOT OUTLIER',
					keStatus: '',
					compliantLowerValue: 40,
					compliantUpperValue: 60,
					almostOutlierLowerValue: 35,
					almostOutlierUpperValue: 40,
					currentPortfolioValue: 32,
					proposedPortfolioValue: 36,
					deviation: -45
        },
        {
					title: 'Max Holding',
					type: 'maxHolding',
					status: 'NOT OUTLIER',
					keStatus: '',
					compliantLowerValue: 40,
					compliantUpperValue: 60,
					almostOutlierLowerValue: 30,
					almostOutlierUpperValue: 80,
					currentPortfolioValue: 15,
					proposedPortfolioValue: 23,
					deviation: -36
        },
        {
					title: 'Tracking error',
					type: 'trackingError',
					status: 'OUTLIER KE',
					keStatus: 'Approved',
					compliantLowerValue: 20,
					compliantUpperValue: 50,
					almostOutlierLowerValue: 24,
					almostOutlierUpperValue: 40,
					currentPortfolioValue: 26,
					proposedPortfolioValue: 38,
					deviation: 70
        },
        {
					title: 'Conc LT',
					type: 'concLt',
					status: 'OUTLIER',
					keStatus: 'Approved',
					compliantLowerValue: 40,
					compliantUpperValue: 60,
					almostOutlierLowerValue: 30,
					almostOutlierUpperValue: 70,
					currentPortfolioValue: 13,
					proposedPortfolioValue: 20,
					deviation: 14.69
        },
        {
					title: 'Max HW LT',
					type: 'maxHwLt',
					status: 'NOT OUTLIER',
					keStatus: '',
					compliantLowerValue: 40,
					compliantUpperValue: 60,
					almostOutlierLowerValue: 30,
					almostOutlierUpperValue: 70,
					currentPortfolioValue: 15,
					proposedPortfolioValue: 23,
					deviation: 35
        },
        {
					title: 'AA',
					type: 'aa',
					status: 'NOT OUTLIER',
					keStatus: '',
					compliantLowerValue: 40,
					compliantUpperValue: 60,
					almostOutlierLowerValue: 30,
					almostOutlierUpperValue: 70,
					currentPortfolioValue: 15,
					proposedPortfolioValue: 23,
					deviation: -45
        },
        {
					title: 'Diversity',
					type: 'diversity',
					status: 'NOT OUTLIER',
					keStatus: '',
					compliantLowerValue: 40,
					compliantUpperValue: 60,
					almostOutlierLowerValue: 30,
					almostOutlierUpperValue: 70,
					currentPortfolioValue: 15,
					proposedPortfolioValue: 23,
					deviation: -36
        },
        {
					title: 'Liquidity',
					type: 'liquidity',
					status: 'OUTLIER',
					keStatus: 'Pending',
					compliantLowerValue: 40,
					compliantUpperValue: 60,
					almostOutlierLowerValue: 30,
					almostOutlierUpperValue: 70,
					currentPortfolioValue: 15,
					proposedPortfolioValue: 23,
					deviation: 70
        },
        {
					title: 'Buy List',
					type: 'buyList',
					status: 'OUTLIER',
					keStatus: 'Rejected',
					compliantLowerValue: 50,
					compliantUpperValue: 80,
					almostOutlierLowerValue: 55,
					almostOutlierUpperValue: 75,
					currentPortfolioValue: 48,
					proposedPortfolioValue: 68,
					deviation: 2
        },
   ],
   positions: [
		{
			type: 'risk',
			widgets: ['keyFlagMonitorWidget', 'dialWidget']
		},
		{
			type: 'concentration',
			widgets: ['keyFlagMonitorWidget', 'dialWidget']
		},
   	{
			type: 'sectorConc',
			widgets: ['keyFlagMonitorWidget']
		},
   	{
			type: 'maxHolding',
			widgets: ['keyFlagMonitorWidget']
		},
   	{
			type: 'trackingError',
			widgets: ['keyFlagMonitorWidget', 'dialWidget']
		},
   	{
			type: 'concLt',
			widgets: ['nonkeyFlagMonitorWidget']
		},
		{
			type: 'maxHwLt',
			widgets: ['nonkeyFlagMonitorWidget']
		},
   	{
			type: 'aa',
			widgets: ['nonkeyFlagMonitorWidget']
		},
  	{
			type: 'diversity',
			widgets: ['nonkeyFlagMonitorWidget']
		},
  	{
			type: 'liquidity',
			widgets: ['nonkeyFlagMonitorWidget']
		},
  	{
			type: 'buyList',
			widgets: ['dialWidget']
		},
	]
	};
}
