import { Action } from '@ngrx/store';

import { SearchMetaData } from '../../../entity/search-metadata.model';

export const GET_SEARCH_META_DATA = '[SearchMetaData] GET_SEARCH_META_DATA';
export const GET_SEARCH_META_DATA_SUCCESS = '[SearchMetaData]  GET_SEARCH_META_DATA_SUCCESS';
export const GET_SEARCH_META_DATA_ERROR = '[SearchMetaData] GET_SEARCH_META_DATA_ERROR';

export class GetSearchMetaData implements Action {
	readonly type = GET_SEARCH_META_DATA;
	constructor() {}
}

export class GetSearchMetaDataSuccess implements Action {
	readonly type = GET_SEARCH_META_DATA_SUCCESS;
	constructor(public searchMetaData: SearchMetaData) {}
}

export class GetSearchMetaDataError implements Action {
	readonly type = GET_SEARCH_META_DATA_ERROR;
	constructor(public error: any) {}
}

export type All = GetSearchMetaData | GetSearchMetaDataSuccess | GetSearchMetaDataError;
