import { Action } from '@ngrx/store';
import { TableHeaderForm } from '../../../shared/entity/generic-form/generic-table-header-form';

export const GET_SEARCH_RESULT_FORM_CONFIG = '[DashMetric] GET_SEARCH_RESULT_FORM_CONFIG';
export const GET_SEARCH_RESULT_FORM_CONFIG_SUCCESS = '[DashMetric]  GET_SEARCH_RESULT_FORM_CONFIG_SUCCESS';
export const GET_SEARCH_RESULT_FORM_CONFIG_ERROR = '[DashMetric] GET_SEARCH_RESULT_FORM_CONFIG_ERROR';

export const GET_ASSETS_SEARCH_RESULT_FORM_CONFIG = '[DashMetric] GET_ASSETS_SEARCH_RESULT_FORM_CONFIG';
export const GET_ASSETS_SEARCH_RESULT_FORM_CONFIG_SUCCESS = '[DashMetric]  GET_ASSETS_SEARCH_RESULT_FORM_CONFIG_SUCCESS';
export const GET_ASSETS_SEARCH_RESULT_FORM_CONFIG_ERROR = '[DashMetric] GET_ASSETS_SEARCH_RESULT_FORM_CONFIG_ERROR';


export const GET_PMP_ACCOUNT_SEARCH_RESULT_FORM_CONFIG = '[DashMetric] GET_PMP_ACCOUNT_SEARCH_RESULT_FORM_CONFIG';
export const GET_PMP_ACCOUNT_SEARCH_RESULT_FORM_CONFIG_SUCCESS = '[DashMetric]  GET_PMP_ACCOUNT_SEARCH_RESULT_FORM_CONFIG_SUCCESS';
export const GET_PMP_ACCOUNT_SEARCH_RESULT_FORM_CONFIG_ERROR = '[DashMetric] GET_PMP_ACCOUNT_SEARCH_RESULT_FORM_CONFIG_ERROR';

export class GetSearchResultFormConfig implements Action {
  readonly type = GET_SEARCH_RESULT_FORM_CONFIG;
  constructor() {}
}

export class GetSearchResultFormConfigSuccess implements Action {
  readonly type = GET_SEARCH_RESULT_FORM_CONFIG_SUCCESS;
  constructor(public searchResultForm: TableHeaderForm[]) {}
}

export class GetSearchResultFormConfigError implements Action {
  readonly type = GET_SEARCH_RESULT_FORM_CONFIG_ERROR;
  constructor(public error: any) {}
}

export class GetAssetsSearchResultFormConfig implements Action {
  readonly type = GET_ASSETS_SEARCH_RESULT_FORM_CONFIG;
  constructor() {}
}

export class GetAssetsSearchResultFormConfigSuccess implements Action {
	readonly type = GET_ASSETS_SEARCH_RESULT_FORM_CONFIG_SUCCESS;
	constructor(public searchResultForm: TableHeaderForm[]) {}
}

export class GetAssetsSearchResultFormConfigError implements Action {
	readonly type = GET_ASSETS_SEARCH_RESULT_FORM_CONFIG_ERROR;
	constructor(public error: any) {}
}

export class GetPMPSearchResultFormConfig implements Action {
  readonly type = GET_PMP_ACCOUNT_SEARCH_RESULT_FORM_CONFIG;
  constructor() {}
}

export class GetPMPSearchResultFormConfigSuccess implements Action {
  readonly type = GET_PMP_ACCOUNT_SEARCH_RESULT_FORM_CONFIG_SUCCESS;
  constructor(public searchResultForm: TableHeaderForm[]) {}
}

export class GetPMPSearchResultFormConfigError implements Action {
  readonly type = GET_PMP_ACCOUNT_SEARCH_RESULT_FORM_CONFIG_ERROR;
  constructor(public error: any) {}
}

export type All = GetSearchResultFormConfig | GetSearchResultFormConfigSuccess | GetSearchResultFormConfigError
                | GetAssetsSearchResultFormConfig | GetAssetsSearchResultFormConfigSuccess | GetAssetsSearchResultFormConfigError
                | GetPMPSearchResultFormConfig | GetPMPSearchResultFormConfigSuccess | GetPMPSearchResultFormConfigError;
