import * as AccountCurrentPortfolioActions from './account-current-portfolio.actions';
import { CurrentPortfolioModel } from '../../../entity/current-portfolio-model.model';
import { AccountCurrentPortfolioState } from './account-current-portfolio.state';
import AccountCurrentPortfolioUtil from './account-current-portfolio.util';

export type AccountCurrentPortfolioAction = AccountCurrentPortfolioActions.All;
export const initialState: AccountCurrentPortfolioState = { accountCurrentPortfolio: new CurrentPortfolioModel(),
  accountOverviewConfig: null, loading: false, error: null};

export function accountCurrentPortfolioReducer(state: AccountCurrentPortfolioState = initialState, action: AccountCurrentPortfolioAction):
  AccountCurrentPortfolioState {
    switch (action.type) {

      case AccountCurrentPortfolioActions.GET_ACCOUNT_CURRENT_PORTFOLIO: {
        return {...initialState, ...{loading: true}};
      }

      case AccountCurrentPortfolioActions.GET_ACCOUNT_CURRENT_PORTFOLIO_SUCCESS: {
        return {...state, ...{accountCurrentPortfolio: action.accountCurrentPortfolio}, ...{loading: false}};
      }

      case AccountCurrentPortfolioActions.GET_ACCOUNT_CURRENT_PORTFOLIO_ERROR: {
        console.error('Error caught while loading Client-Account-Current-Portfolio', action.error);
        return {...initialState};
      }

      case AccountCurrentPortfolioActions.GET_ACCOUNT_OVERVIEW_CONFIG: {
        return {...state, ...{accountOverviewConfig: null, ...{loading: true}}};
      }

      case AccountCurrentPortfolioActions.GET_ACCOUNT_OVERVIEW_CONFIG_SUCCESS: {
        return {...state, ...{accountOverviewConfig: action.accountOverviewConfig}};
      }

      case AccountCurrentPortfolioActions.GET_ACCOUNT_OVERVIEW_CONFIG_ERROR: {
        console.error('Error caught while loading Client-Account-Current-Portfolio', action.error);
        return {...initialState};
      }

      case AccountCurrentPortfolioActions.CREATE_MANDATE_PORTFOLIO_SUCCESS: {
        return {...state};
      }

      case AccountCurrentPortfolioActions.CREATE_MANDATE_PORTFOLIO_ERROR: {
        console.error('Error caught while loading Client-Account-Current-Portfolio', action.error);
        return {...initialState};
      }

      case AccountCurrentPortfolioActions.ADD_HOLDING: {
        // according to BITA20-579, only front end BMID are editable and will always have one account
        /*if (state.accountCurrentPortfolio.accountNames && state.accountCurrentPortfolio.accountNames.length !== 1) {
          throw Error('can only edit front end BMID with one account');
        }*/
        // extract the holdingNode from the model with the name of Holding;
        let returnedHoldingNode = AccountCurrentPortfolioUtil.
          extractHoldingNodeFromModel(state.accountCurrentPortfolio, action.asset.sedol);

        // if there is no such holding, add it to appropriate SAC
        if (!returnedHoldingNode) {
          returnedHoldingNode = AccountCurrentPortfolioUtil.createHoldingNode(state.accountCurrentPortfolio, action.asset);
          if (!returnedHoldingNode) {
            return {...state, ...{error: `cannot find SAC for ${action.asset.sedol}`}};
          }
        }

        if (action.accountName) {
          returnedHoldingNode.breakdownNode[returnedHoldingNode.breakdownNode.findIndex(
            b => b.compositeLabel === action.accountName)].currentPosition.value = action.value;
        } else if (returnedHoldingNode.breakdownNode && returnedHoldingNode.breakdownNode[0]) {
          // add to the first child
          returnedHoldingNode.breakdownNode[0].currentPosition.value = action.value;
        }

        state.accountCurrentPortfolio.recalculateValue();
        return {...state, ...{error: null}};
      }

      case AccountCurrentPortfolioActions.REMOVE_HOLDING: {
        // according to BITA20-579, only front end BMID are editable and will always have one account
        if (state.accountCurrentPortfolio.accountNames && state.accountCurrentPortfolio.accountNames.length !== 1) {
          throw Error('can only edit front end BMID with one account');
        }
        // extract the holdingNode from the model with the name of Holding;
        const success = AccountCurrentPortfolioUtil.deleteHoldingNodeFromModel
          (state.accountCurrentPortfolio, action.asset.name);

        if (!success) {
          console.error(`cannot remove ${action.asset.label} from the portfolio`);
          }

        state.accountCurrentPortfolio.recalculateValue();
        return {...state};
      }

      case AccountCurrentPortfolioActions.ADD_HOLDING_ERROR: {
        console.error('Error caught while adding a asset', action.error);
        return {...initialState};
      }

      case AccountCurrentPortfolioActions.CLEAR_HOLDINGS: {
        AccountCurrentPortfolioUtil.clearHoldingsFromCurrentPortfolio(state.accountCurrentPortfolio);
        return {...state};
      }

      case AccountCurrentPortfolioActions.CLEAR_HOLDINGS_ERROR: {
        console.error('Error caught while clearing holdings from current portfolio', action.error);
        return {...initialState};
      }

      case AccountCurrentPortfolioActions.SAVE_ACCOUNT_CURRENT_PORTFOLIO_SUCCESS: {
        return {...state, ...{accountCurrentPortfolio: action.accountCurrentPortfolio}, ...{loading: false}};
      }

      case AccountCurrentPortfolioActions.SAVE_ACCOUNT_CURRENT_PORTFOLIO_ERROR: {
        console.error('Error caught while saving Client-Account-Current-Portfolio', action.error);
        return {...initialState};
      }

      case AccountCurrentPortfolioActions.UPDATE_FLAT_ACCOUNT_SUCCESS: {
        return {...state, ...{accountCurrentPortfolio: action.accountCurrentPortfolio}, ...{loading: false}};
      }

      case AccountCurrentPortfolioActions.UPDATE_FLAT_ACCOUNT_ERROR: {
        console.error('Error caught while updating account', action.error);
        return {...initialState};
      }

      default :
        return state;
    }
}
