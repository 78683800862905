import { Action } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

import { Session } from '../../entity/session.model';

export const REQUEST_LOGIN = '[Authentication] REQUEST_LOGIN';
export const REQUEST_LOGIN_SUCCESS = '[Authentication]  REQUEST_LOGIN_SUCCESS';
export const REQUEST_LOGIN_ERROR = '[Authentication] REQUEST_LOGIN_ERROR';

export const REQUEST_LOGOUT = '[Authentication] REQUEST_LOGOUT';
export const REQUEST_LOGOUT_SUCCESS = '[Authentication]  REQUEST_LOGOUT_SUCCESS';
export const REQUEST_LOGOUT_ERROR = '[Authentication] REQUEST_LOGOUT_ERROR';

export const CHECK_SINGLE_SIGN_ON_SUCCESS = '[Authentication]  CHECK_SINGLE_SIGN_ON_SUCCESS';
export const CHECK_SINGLE_SIGN_ON_ERROR = '[Authentication] CHECK_SINGLE_SIGN_ON_ERROR';

export const CLEAR_ERROR = '[Authentication] CLEAR_ERROR';

export const GO = '[Router] Go';

export const UPDATE_LAST_ACCESSED_CLIENT = '[LastAccessedClient] UPDATE_LAST_ACCESSED_CLIENT';

export class RequestLogin implements Action {
	readonly type = REQUEST_LOGIN;

	constructor(public userName: string, public password: string) {}
}

export class RequestLoginSuccess implements Action {
	readonly type = REQUEST_LOGIN_SUCCESS;
	constructor(public session: Session) {}
}

export class RequestLoginError implements Action {
	readonly type = REQUEST_LOGIN_ERROR;
	constructor(public error: any) {}
}

export class RequestLogout implements Action {
	readonly type = REQUEST_LOGOUT;
}

export class RequestLogoutSuccess implements Action {
	readonly type = REQUEST_LOGOUT_SUCCESS;
}

export class RequestLogoutError implements Action {
	readonly type = REQUEST_LOGOUT_ERROR;
	constructor(public error: any) {}
}

export class CheckSingleSignOnSuccess implements Action {
	readonly type = CHECK_SINGLE_SIGN_ON_SUCCESS;
	constructor(public session: Session) {}
}

export class CheckSingleSignOnError implements Action {
	readonly type = CHECK_SINGLE_SIGN_ON_ERROR;
	constructor(public session: Session) {}
}

export class ClearError implements Action {
	readonly type = CLEAR_ERROR;
	constructor() {}
}

export class Go implements Action {
  readonly type = GO;

  constructor(public payload: {
    path: any[];
    query?: object;
    extras?: NavigationExtras;
  }) {}
}

export class UpdateLastAccessedClient implements Action {
	readonly type = UPDATE_LAST_ACCESSED_CLIENT;
	constructor(public newClientId: string) {}
}

export type All = RequestLogin | RequestLoginSuccess | RequestLoginError
								| RequestLogout | RequestLogoutSuccess | RequestLogoutError
                | CheckSingleSignOnSuccess | CheckSingleSignOnError
                | ClearError | Go | UpdateLastAccessedClient;
