export function mockReports() {
  return [
    {
      id: 'report-12345',
      name: 'MIS-Traffic-Lights',
      description: 'Traffic light report',
      lastRunDate: '2017-07-19 09:15:23',
      filePath: '/generated_reports/trafficlight12112016.pdf'
    },
    {
      id: 'report-12346',
      name: 'teamReport',
      description: 'Team report',
      lastRunDate: '2017-07-19 09:17:40',
      filePath: '/generated_reports/trafficlight12112016.pdf'
    },
    {
      id: 'report-12347',
      name: 'MIS-Risk',
      description: 'Risk report',
      lastRunDate: '2017-07-19 10:14:56',
      filePath: '/generated_reports/risk19072016.pdf'
    },
    {
      id: 'report-12348',
      name: 'MIS-Tracking',
      description: 'Tracking error report',
      lastRunDate: '2017-07-19 11:30:07',
      filePath: '/generated_reports/trackingerror12112016.pdf'
    },
    {
      id: 'report-12349',
      name: 'MIS-Concentration',
      description: 'Concentration report',
      lastRunDate: '2017-07-19 11:16:32',
      filePath: '/generated_reports/conc12112016.pdf'
    },
    {
      id: 'report-12350',
      name: 'MIS-Risk',
      description: 'Risk report',
      lastRunDate: '2017-07-19 11:17:51',
      filePath: '/generated_reports/risk12112016.pdf'
    }
  ];
}
