import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule, MetaReducer } from '@ngrx/store';

import { alertReducer } from './alerts/alerts.reducers';
import { AlertEffects } from './alerts/alerts.effects';
import { issueReducer } from './issues/issues.reducers';
import { IssuesEffects } from './issues/issues.effects';
import { profileReducer } from './profile/profile.reducers';
import { ProfileEffects } from './profile/profile.effects';
import { monitorFlagReducer } from './monitor-flags/monitor-flag.reducers';
import { MonitorFlagEffects } from './monitor-flags/monitor-flag.effects';
import { modelReducer } from './models/models.reducers';
import { ModelEffects } from './models/models.effects';
import { aaBoundaryDeviationReducer } from './aaBoundaryDeviation/aaBoundaryDeviation.reducers';
import { AABoundaryDeviationEffects } from './aaBoundaryDeviation/aaBoundaryDeviation.effects';
import { assetRiskContributionReducer } from './assetRiskContribution/assetRiskContribution.reducers';
import { AssetRiskContributionEffects } from './assetRiskContribution/assetRiskContribution.effects';
import { bmidHoldingListReducer } from './bmid-holding-list/bmid-holding-list.reducers';
import { BmidHoldingListEffects } from './bmid-holding-list/bmid-holding-list.effects';
import { searchMetaDataReducer } from './search/search-criteria/search-criteria.reducers';
import { SearchMetaDataEffects } from './search/search-criteria/search-criteria.effects';
import { reportsReducer } from './reports/reports.reducers';
import { ReportsEffects } from './reports/reports.effects';
import { searchResultsReducer } from './search/search-results/search-results.reducers';
import { SearchResultsEffects } from './search/search-results/search-results.effects';
import { monitorFlagTypeReducer } from './monitor-flag-type/monitor-flag-type.reducers';
import { MonitorFlagTypeEffects } from './monitor-flag-type/monitor-flag-type.effects';
import { monitorPortfolioReducer } from './client-account/monitor-portfolio/monitor-portfolio.reducers';
import { MonitorPortfolioEffects } from './client-account/monitor-portfolio/monitor-portfolio.effects';
import { knownExceptionReducer } from './client-account/knownExceptions/knownExceptions.reducers';
import { KnownExceptionEffects } from './client-account/knownExceptions/knownExceptions.effects';
import { knownExceptionTypesReducer } from './client-account/knownExceptionTypes/knownExceptionTypes.reducers';
import { KnownExceptionTypesEffects } from './client-account/knownExceptionTypes/knownExceptionTypes.effects';
// tslint:disable-next-line:max-line-length
import { accountCurrentPortfolioReducer } from './client-account/account-current-portfolio/account-current-portfolio.reducers';
import { AccountCurrentPortfolioEffects } from './client-account/account-current-portfolio/account-current-portfolio.effects';
import { interfaceReducer } from './interface-options/interface-options.reducers';
import { tradeCashDetailsReducer } from './trade-cash-details/trade-cash-details.reducers';
import { tradeRationaleReducer } from './trade-rationale/trade-rationale.reducers';
import { apiTradeRationaleReducer } from './api-trade-rationale/api-trade-rationale.reducers';
import { ApiTradeRationaleEffects } from './api-trade-rationale/api-trade-rationale.effects';

import { clientDetailsReducer } from './client-details/client-details.reducers';
import { ClientDetailsEffects } from './client-details/client-details.effects';
import { clientAccountDetailsReducer } from './client-account-details/client-account-details.reducers';
import { ClientAccountDetailsEffects } from './client-account-details/client-account-details.effects';
import { assetListReducer } from './asset-list/asset-list.reducers';
import { AssetListEffects } from './asset-list/asset-list.effects';

import { portfolioConstructionModelReducer } from './portfolioConstructionModel/portfolioConstructionModel.reducers';
import { PortfolioConstructionModelEffects } from './portfolioConstructionModel/portfolioConstructionModel.effects';

import { logout } from '../../core/store/meta-reducer/logout-meta-reducer';
import { ClearAllStoreData } from './meta-reducers/shared-store-meta-reducer';
import { InterfaceOptionsEffects } from './interface-options/interface-options.effects';
import { AnalyticsEffects } from './analytics/analytics.effects';
import { analyticsReducer } from './analytics/analytics.reducers';
import { IprFileNotesEffects } from './ipr-file-notes/ipr-file-notes.effects';
import { iprFileNotesReducer } from './ipr-file-notes/ipr-file-notes.reducers';
import { SearchFormEffects } from '../../search/store/search-form/search-form.effects';
import { searchFormReducer } from '../../search/store/search-form/search-form.reducers';
import { PMPAccountSearchResultsEffects } from './pmp-search-results/pmp-search-results.effects';
import { pmpAccountSearchResultsReducer } from './pmp-search-results/pmp-search-results.reducers';

import { BuyListKeFormsEffects } from './buy-list-ke-forms/buy-list-ke-forms.effects';
import { buyListKeFormsReducer } from './buy-list-ke-forms/buy-list-ke-forms.reducers';
import { searchResultFormReducer } from '../../search/store/search-result-form/search-result-form.reducers';
import { SearchResultFormEffects } from '../../search/store/search-result-form/search-result-form.effects';
import { clientAapReducer } from '../../clients/store/client-aap/client-aap.reducers';
import { ClientAapEffects } from '../../clients/store/client-aap/client-aap.effects';
import { ReadOnlyPortfolioEffects } from './read-only-portfolio/readOnlyPortfolio.effects';
import { readOnlyPortfolioReducer } from './read-only-portfolio/readOnlyPortfolio.reducers';
import { RiskModelListEffects } from './data-model-selection/risk-model-selection.effects';
import { riskModelListReducer } from './data-model-selection/risk-model-selection.reducers';
import { AlphaModelListEffects } from './data-model-selection/alpha-model-selection.effects';
import { alphaModelListReducer } from './data-model-selection/alpha-model-selection.reducers';

const metaReducers: Array<MetaReducer<any, any>> = [logout, ClearAllStoreData];

@NgModule({
  imports: [
    EffectsModule.forFeature(
      [
        AlertEffects,
        IssuesEffects,
        ProfileEffects,
        MonitorFlagEffects,
        ModelEffects,
        AABoundaryDeviationEffects,
        AssetRiskContributionEffects,
        BmidHoldingListEffects,
        ReportsEffects,
        SearchMetaDataEffects,
        SearchResultsEffects,
        MonitorFlagTypeEffects,
        MonitorPortfolioEffects,
        KnownExceptionEffects,
        AccountCurrentPortfolioEffects,
        KnownExceptionTypesEffects,
        InterfaceOptionsEffects,
        AnalyticsEffects,
        ClientDetailsEffects,
        ClientAccountDetailsEffects,
        AssetListEffects,
        RiskModelListEffects,
        AlphaModelListEffects,
        PortfolioConstructionModelEffects,
        ApiTradeRationaleEffects,
        IprFileNotesEffects,
        SearchFormEffects,
        PMPAccountSearchResultsEffects,
        IprFileNotesEffects,
        BuyListKeFormsEffects,
        SearchResultFormEffects,
        ClientAapEffects,
        ReadOnlyPortfolioEffects
      ]
    ),
    StoreModule.forFeature('shared',
      {
        alerts: alertReducer,
        issues: issueReducer,
        profile: profileReducer,
        monitorFlags: monitorFlagReducer,
        models: modelReducer,
        aaBoundaryDeviations: aaBoundaryDeviationReducer,
        assetRiskContributions: assetRiskContributionReducer,
        bmidHoldingList: bmidHoldingListReducer,
        reports: reportsReducer,
        searchResults: searchResultsReducer,
        searchMetaData: searchMetaDataReducer,
        monitorFlagTypes: monitorFlagTypeReducer,
        monitorPortfolio: monitorPortfolioReducer,
        knownExceptions: knownExceptionReducer,
        accountCurrentPortfolio: accountCurrentPortfolioReducer,
        knownExceptionTypes: knownExceptionTypesReducer,
        interfaceOptions: interfaceReducer,
        tradeCashDetails: tradeCashDetailsReducer,
        tradeRationale: tradeRationaleReducer,
        apiTradeRationale: apiTradeRationaleReducer,
        analytics: analyticsReducer,
        clientDetails: clientDetailsReducer,
        clientAccountDetails: clientAccountDetailsReducer,
        assetList: assetListReducer,
        riskModelList: riskModelListReducer,
        alphaModelList: alphaModelListReducer,
        portfolioConstructionModel: portfolioConstructionModelReducer,
        iprFileNotes: iprFileNotesReducer,
        pmpAccountSearchForm: searchFormReducer,
        pmpAccountSearchResults: pmpAccountSearchResultsReducer,
        buyListKeForms: buyListKeFormsReducer,
        assetsSearchResultForm: searchResultFormReducer,
        assetSeachCriteriaForm: searchFormReducer,
        pmpAccountSearchResultForm: searchResultFormReducer,
        clientAap: clientAapReducer,
        readOnlyPortfolio: readOnlyPortfolioReducer
      },
      { metaReducers }),
  ]
})
export class SharedStoreModule { }
