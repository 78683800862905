import { Action } from '@ngrx/store';

import { ClientAccountDetails } from '../../entity/client-account-details.model';

export const GET_CLIENT_ACCOUNT_DETAILS = '[ClientAccountDetails] GET_CLIENT_ACCOUNT_DETAILS';
export const GET_CLIENT_ACCOUNT_DETAILS_SUCCESS = '[ClientAccountDetails]  GET_CLIENT_ACCOUNT_DETAILS_SUCCESS';
export const GET_CLIENT_ACCOUNT_DETAILS_ERROR = '[ClientAccountDetails] GET_CLIENT_ACCOUNT_DETAILS_ERROR';

export const GET_CLIENT_ACCOUNT_DETAILS_SUMMARY = '[ClientAccountDetails] GET_CLIENT_ACCOUNT_DETAILS_SUMMARY';
export const GET_CLIENT_ACCOUNT_DETAILS_SUMMARY_SUCCESS = '[ClientAccountDetails] GET_CLIENT_ACCOUNT_DETAILS_SUMMARY_SUCCESS';
export const GET_CLIENT_ACCOUNT_DETAILS_SUMMARY_ERROR = '[ClientAccountDetails] GET_CLIENT_ACCOUNT_DETAILS_SUMMARY_ERROR';

export class GetClientAccountDetails implements Action {
	readonly type = GET_CLIENT_ACCOUNT_DETAILS;
	constructor(public clientId: string) {}
}

export class GetClientAccountDetailsSuccess implements Action {
	readonly type = GET_CLIENT_ACCOUNT_DETAILS_SUCCESS;
	constructor(public clientAccountDetails: ClientAccountDetails[]) {}
}

export class GetClientAccountDetailsError implements Action {
	readonly type = GET_CLIENT_ACCOUNT_DETAILS_ERROR;
	constructor(public error: any) {}
}

export class GetClientAccountDetailsSummary implements Action {
	readonly type = GET_CLIENT_ACCOUNT_DETAILS_SUMMARY;
	constructor(public clientId: string) {}
}

export class GetClientAccountDetailsSummarySuccess implements Action {
	readonly type = GET_CLIENT_ACCOUNT_DETAILS_SUMMARY_SUCCESS;
	constructor(public clientAccountDetails: ClientAccountDetails[]) {}
}

export class GetClientAccountDetailsSummaryError implements Action {
	readonly type = GET_CLIENT_ACCOUNT_DETAILS_SUMMARY_ERROR;
	constructor(public error: any) {}
}

export type All = GetClientAccountDetails | GetClientAccountDetailsSuccess | GetClientAccountDetailsError |
GetClientAccountDetailsSummary | GetClientAccountDetailsSummarySuccess | GetClientAccountDetailsSummaryError ;
