import { JsonObject, JsonProperty } from 'json2typescript';
import { Report } from './report.model';
import { PresetMonitorParameters } from './preset.model';

@JsonObject('LabelValuePair')
export class LabelValuePair {

    @JsonProperty('label', String, true)
    label: string  = undefined;

    @JsonProperty('value', String, true)
    value: string  = undefined;

}

@JsonObject('MonitorParameter')
export class MonitorParameter {

    @JsonProperty('type', String)
    type: string  = undefined;

    @JsonProperty('title', String)
    title: string  = undefined;

    @JsonProperty('options', [LabelValuePair], true)
    options: LabelValuePair[] = [];

}

@JsonObject('StatusGroup')
export class StatusGroup {

    @JsonProperty('type', String)
    type: string  = undefined;

    @JsonProperty('title', String)
    title: string  = undefined;

    @JsonProperty('options', [LabelValuePair], true)
    options: LabelValuePair[] = [];
}

@JsonObject('PresetStatusGroup')
export class PresetStatusGroup {

    @JsonProperty('type', String)
    type: string = undefined;

    @JsonProperty('selected', String, true)
    selected: string = undefined;

    constructor(type: string, selected: string) {
        this.type = type;
        this.selected = selected;
    }

}
@JsonObject('MonitorCriterions')
export class MonitorCriterions {

    @JsonProperty('monitorParameters', [MonitorParameter], true)
    monitorParameters: [MonitorParameter] = undefined;

    @JsonProperty('statusGroup', [StatusGroup], true)
    statusGroup: [StatusGroup] = undefined;

    @JsonProperty('reportSelection', [Report], true)
    reportSelection: Report[] = undefined;

}

export class ReportRequestParams {
    reportName: string;
    reportDescription: string;
    monitorParameters: PresetMonitorParameters[];
    statusGroup: PresetStatusGroup[];
    reportFormat: string = null;

    constructor() {
        this.monitorParameters = new Array<PresetMonitorParameters>();
        this.statusGroup = new Array<PresetStatusGroup>();
    }
}

