import { Action } from '@ngrx/store';

import { TradeRationale } from '../../entity/trade-rationale.model';

export const GET_TRADE_RATIONALE = '[TradeRationale] GET_TRADE_RATIONALE';
export const GET_TRADE_RATIONALE_SUCCESS = '[TradeRationale] GET_TRADE_RATIONALE_SUCCESS';
export const GET_TRADE_RATIONALE_ERROR = '[TradeRationale] GET_TRADE_RATIONALE_ERROR';

export class GetTradeRationale implements Action {
	readonly type = GET_TRADE_RATIONALE;
	constructor() {}
}

export class GetTradeRationaleSuccess implements Action {
	readonly type = GET_TRADE_RATIONALE_SUCCESS;
	constructor(public tradeRationale: TradeRationale) {}
}

export class GetTradeRationaleError implements Action {
	readonly type = GET_TRADE_RATIONALE_ERROR;
	constructor(public error: any) {}
}

export type All = GetTradeRationale
	| GetTradeRationaleSuccess
  | GetTradeRationaleError;
