import * as ClientAapActions from './client-aap.actions';
import { ClientAap } from '../../entity/client-aap.model';
import { ClientAapState } from './client-aap.state';

export type ClientAapAction = ClientAapActions.All;
export const initialState: ClientAapState = { clientAap: new ClientAap(), loading: false };

export function clientAapReducer(state: ClientAapState = initialState, action: ClientAapAction):
ClientAapState {
    switch (action.type) {

      case ClientAapActions.GET_CLIENT_AAP: {
        return {...initialState, ...{loading: true}};
      }

      case ClientAapActions.GET_CLIENT_AAP_SUCCESS: {
        return {...state, ...{clientAap: action.clientAap}, ...{loading: false}};
      }

      case ClientAapActions.GET_CLIENT_AAP_ERROR: {
        console.error('Error caught while loading Client AAP', action.error);
        return {...initialState};
      }

      default:
        return state;
    }
}
