export function mockPortfolioPerformance() {
  return [
    {
  'id': 1,
  'portfolioDetails': [{
      'key': 'portfolioName',
      'label': 'port Name',
      'value': 'School fee',
      'metaData': null
    },
    {
      'key': 'team',
      'label': 'Office/Team',
      'value': 'MANCHESTER',
      'metaData': {
        'account_id': 21487,
        'account_name': 'P0000709405',
        'team_id': 39,
        'team_name': 'MANCHESTER',
        'team_client_id': 99181,
        'team_client_displayId': 'BLCID000027',
        'team_portfolioId': 'BLMID000027'
      }
    }, {
      'key': 'portfolioManager',
      'label': 'Portfolio Manager',
      'value': 'C0000307790',
      'metaData': null
    }, {
      'key': 'accountType',
      'label': 'Portfolio Type',
      'value': 'General Investment',
      'metaData': null
    }, {
      'key': 'serviceCategory',
      'label': 'Service Category',
      'value': 'Discretionary',
      'metaData': null
    }, {
      'key': 'riskCategory',
      'label': 'Risk Category',
      'value': 'CR',
      'metaData': null
    }
  ],

  // 3 Pairs of dials
  'returnDials' : [
    {'label': '1YR',
      'absolute': {
        'return': 6,
        'almostOutlierLowerValue' : 12,
        'almostOutlierUpperValue' : 16,
        'compliantLowerValue' : 5,
        'compliantUpperValue' : 12,
        'outlierLowerValue': 0,
        'outlierUpperValue': 5,
        'scaleLowerValue' : 0,
        'scaleUpperValue' : 16,
        'title' : 'ABSOLUTE',
        'unit' : '%'

        },
        'relative': {
          'return': -2,
          'almostOutlierLowerValue' : 12,
          'almostOutlierUpperValue' : 16,
          'compliantLowerValue' : 5,
          'compliantUpperValue' : 12,
          'outlierLowerValue': 0,
          'outlierUpperValue': 5,
          'scaleLowerValue' : 0,
          'scaleUpperValue' : 16,
          'title' : 'Relative',
          'unit' : '%'
        }},

    {'label': '3YR',
      'absolute': {
        'return': 6,
        'almostOutlierLowerValue' : 12,
        'almostOutlierUpperValue' : 16,
        'compliantLowerValue' : 5,
        'compliantUpperValue' : 12,
        'outlierLowerValue': 0,
        'outlierUpperValue': 5,
        'scaleLowerValue' : 0,
        'scaleUpperValue' : 16,
        'title' : 'ABSOLUTE',
        'unit' : '%'

        },
        'relative': {
          'return': -2,
          'almostOutlierLowerValue' : 12,
          'almostOutlierUpperValue' : 16,
          'compliantLowerValue' : 5,
          'compliantUpperValue' : 12,
          'outlierLowerValue': 0,
          'outlierUpperValue': 5,
          'scaleLowerValue' : 0,
          'scaleUpperValue' : 16,
          'title' : 'Relative',
          'unit' : '%'
        }},
    {'label': '5YR',
      'absolute': {
        'return': 6,
        'almostOutlierLowerValue' : 12,
        'almostOutlierUpperValue' : 16,
        'compliantLowerValue' : 5,
        'compliantUpperValue' : 12,
        'outlierLowerValue': 0,
        'outlierUpperValue': 5,
        'scaleLowerValue' : 0,
        'scaleUpperValue' : 16,
        'title' : 'ABSOLUTE',
        'unit' : '%'

        },
        'relative': {
          'return': -2,
          'almostOutlierLowerValue' : 12,
          'almostOutlierUpperValue' : 16,
          'compliantLowerValue' : 5,
          'compliantUpperValue' : 12,
          'outlierLowerValue': 0,
          'outlierUpperValue': 5,
          'scaleLowerValue' : 0,
          'scaleUpperValue' : 16,
          'title' : 'Relative',
          'unit' : '%'
        }}
  ],
  'timePeriod' : {
    'columnHeader': [
                      [{'header': 'timePeriod', 'label': 'TIME'}, {'header': 'microChart', 'label': ''},
                       {'header': 'absoluteReturn', 'label': 'ABS'}, {'header': 'relativeReturn', 'label': 'REL'}]
                     ],
    'body': [
              {'timePeriod': 'ytd', 'microChart': [4.1, 5.45, 6.44, 4.34, 4.5, 6.77, 9.55], 'absoluteReturn': '22.2%', 'relativeReturn': '0.0%'},
              {'timePeriod': '1m', 'microChart': [ 6.77], 'absoluteReturn': '0.0%', 'relativeReturn': '0.0%'},
              {'timePeriod': '3m', 'microChart': [4.5, 6.77, 9.55], 'absoluteReturn': '0.0%', 'relativeReturn': '0.0%'},
              {'timePeriod': '6m', 'microChart': [4.5, 6.77, 9.55, 4.5, 6.77, 9.55], 'absoluteReturn': '0.0%', 'relativeReturn': '0.0%'},
              {'timePeriod': '1y', 'microChart': [4.5, 6.77, 9.55, 4.5, 6.77, 9.55, 4.5, 6.77, 9.55, 4.5, 6.77, 9.55], 'absoluteReturn': '0.0%', 'relativeReturn': '0.0%'}
            ],
    'columnFooter': [[]]

  },
  'performanceDetails':   {
    'columnHeader': [[{'header': 'header', 'label': 'PERIOD'}, {'header': 'initialData', 'label': 'FROM'}, {'header': 'finalData', 'label': 'TO'}],
                    [{'header': '', 'label': '1Y'}, {'header': '', 'label': '01.05.19'}, {'header': '', 'label': '01.05.20'}]
                     ],
    'body': [{'label': 'start', 'initialData': '2,100,155', 'finalData': ''},
             {'label': 'with accruals', 'initialData': '6,052', 'finalData': ''},
             {'label': 'start value', 'initialData': '2,106,207', 'finalData': ''},
             {'label': 'contributions', 'initialData': '-', 'finalData': '0%'},
             {'label': 'withdrawals', 'initialData': '127,588', 'finalData': '-6%'},
             {'label': 'income', 'initialData': '151,444', 'finalData': '7%'},
             {'label': 'fees', 'initialData': '-', 'finalData': '0%'},
             {'label': 'market', 'initialData': '92,972', 'finalData': '4%'},
             {'label': 'end value', 'initialData': '2,216,983', 'finalData': ''},
             {'label': 'plus accruals', 'initialData': '2,862', 'finalData': ''},
             {'label': 'end', 'initialData': '2,219,845', 'finalData': ''},

              ],
    'columnFooter': [
      [{'header': 'header', 'label': 'period return'}, {'header': 'initialData', 'label': ''}, {'header': 'finalData', 'label': '11.5%'}]]

  },

  'worstAndBestPerformers': {
    'columnHeader': [
                    [
                      {'header': 'bestPerformer', 'label': 'CONTRIBUTIONS'},
                      {'header': 'bestPerformerReturn', 'label': ''},
                      {'header': 'worstPerformer', 'label': ''},
                      {'header': 'worstPerformerReturn', 'label': ''}],
                    [
                      {'header': '', 'label': 'BEST'},
                      {'header': '', 'label': ''},
                      {'header': '', 'label': 'WORST'},
                      {'header': '', 'label': ''}]
                     ],
    'body': [
      {'bestPerformer': 'ROYAL DUTCH SHELL', 'bestPerformerReturn': '24%', 'worstPerformer': 'SHIRE ORD GBP0.05', 'worstPerformerReturn': '-24%'},
      {'bestPerformer': 'ASHTEAD GROUP ORD', 'bestPerformerReturn': '21%', 'worstPerformer': 'AVIVA ORD GBP0.25', 'worstPerformerReturn': '-21%'}
              ],
    'columnFooter': [
      []]

  },

  // both bubble chart and spiral chart should use same data.
  'assetAnalysis' : {
    'currency': {
      'currencyCode': 'GBP',
      'currencySymbol': '£'
    },
    'topLevelNodes': [{ // data from API uses topLevelNodes nottopLevelNode
      'assetName': 'EQUITY UK',
      'subAssetNode': [{
        'assetName': 'TELCO',
        'holdingNode': [{
          'assetName': 'BT',
          'weight': 12,
          'contribution': 3,
          'gainOrLoss': 12000
        }]
      }]
    },
    {
      'assetName': 'International Equities',
      'subAssetNode': [{
        'assetName': 'Japan',
        'holdingNode': [{
          'assetName': 'Sumitomo Corp NPV',
          'weight': 10,
          'contribution': 13,
          'gainOrLoss': 3000
        }]
      }]
    }
    ]
  }
}
];
}
