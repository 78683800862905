import { Action } from '@ngrx/store';

import { AssetRiskContribution } from '../../entity/asset-risk-contribution.model';

export const GET_TAC_LEVEL_ASSET_RISK_CONTRIBUTION = '[AssetRiskContribution] GET_TAC_LEVEL_ASSET_RISK_CONTRIBUTION';
export const GET_TAC_LEVEL_ASSET_RISK_CONTRIBUTION_SUCCESS = '[AssetRiskContribution]  GET_TAC_LEVEL_ASSET_RISK_CONTRIBUTION_SUCCESS';
export const GET_TAC_LEVEL_ASSET_RISK_CONTRIBUTION_ERROR = '[AssetRiskContribution] GET_TAC_LEVEL_ASSET_RISK_CONTRIBUTION_ERROR';

export class GetAssetLevelAssetRiskContribution implements Action {
	readonly type = GET_TAC_LEVEL_ASSET_RISK_CONTRIBUTION;
	constructor(public clientId: string, public accountId: string) {}
}

export class GetAssetLevelAssetRiskContributionSuccess implements Action {
	readonly type = GET_TAC_LEVEL_ASSET_RISK_CONTRIBUTION_SUCCESS;
	constructor(public assetRiskContributions: AssetRiskContribution[]) {}
}

export class GetAssetLevelAssetRiskContributionError implements Action {
	readonly type = GET_TAC_LEVEL_ASSET_RISK_CONTRIBUTION_ERROR;
	constructor(public error: any) {}
}

export type All = GetAssetLevelAssetRiskContribution
	| GetAssetLevelAssetRiskContributionSuccess
	| GetAssetLevelAssetRiskContributionError;
