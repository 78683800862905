import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable ,  of } from 'rxjs';



import * as ReportsActions from './reports.actions';
import { MonitorService } from '../../../monitor/monitor.service';


export type ReportsAction = ReportsActions.All;

@Injectable()
export class ReportsEffects {

	constructor(private actions$: Actions,
    private monitorService: MonitorService) {
  }

}
