import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class MapConverter implements JsonCustomConvert<Map<string, string>> {
  serialize(data: Map<string, string>): object {
    const obj = Array.from(data.entries()).reduce((main, [key, value]) => ({...main, [key]: value}), {});
    return obj;
  }

  deserialize(jsonObject: any): Map<string, string> {
    if (jsonObject) {
      const mapData = new Map<string, string>();
      for (const k of Object.keys(jsonObject)) {
        mapData.set(k, jsonObject[k]);
      }
      return mapData;
    } else {
      return null;
    }
  }
}
