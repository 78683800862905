
import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Subject, Observable, empty, timer } from 'rxjs';
import { JsonConvert } from 'json2typescript';
import { JsonConvertService } from '../shared/json-convert/json-convert.service';
import { MonitorCriterions, ReportRequestParams } from '../shared/entity/monitor-criterions.model';
import { PresetStatusGroup } from '../shared/entity/monitor-criterions.model';
import { Preset, PresetMonitorParameters } from '../shared/entity/preset.model';

import { Store } from '@ngrx/store';
import * as ReportsActions from '../shared/store/reports/reports.actions';

import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { map, catchError, takeUntil, switchMap, filter, take } from 'rxjs/operators';
import { ReportRequest, ReportRequestStatus } from '../shared/entity/report-request.model';


@Injectable()
export class MonitorService {


  private static readonly REPORT_STATUS_COMPLETED = 'COMPLETED';
  private static readonly REPORT_STATUS_FAILED = 'FAILED';

  private jsonConverter: JsonConvert;
  public presetSelected = new Subject<Preset>();
  public criteriaChanged = new Subject<PresetMonitorParameters[]>();
  public statusGroupChanged = new Subject<PresetStatusGroup[]>();

  constructor(private httpClient: HttpClient, private jsonConvertService: JsonConvertService, private store: Store<any>) {
    this.jsonConverter = this.jsonConvertService.getJsonConverter();
  }

  public runReportRequest(reportRequest: ReportRequestParams,
     pollingPeriod: number, pollingRetryCount: number): ReportRequest {
    const queueObj = new ReportRequest(reportRequest);

    // set the individual object events
    queueObj.requestReport = () => this._requestReport(queueObj, pollingPeriod, pollingRetryCount);
    queueObj.cancel = () => this._cancel(queueObj);

    // Send request
    queueObj.requestReport();

    return queueObj;
  }

  private _requestReport(queueObj: ReportRequest, pollingPeriod: number, pollingRetryCount: number): ReportRequest {
    // request report and report progress
    const request = _.clone(queueObj.reportRequest);
    delete(request.reportDescription);
    const req = new HttpRequest('POST', `${environment.serverUrl}/restapi/service/monitor/report/creation`, request);

    queueObj.requestId = this.newGuid();
    queueObj.description = queueObj.reportRequest.reportDescription;
    queueObj.lastRunDate = moment();
    // upload file and report progress
    queueObj.request = this.httpClient.request(req).subscribe(event => {
        if (event instanceof HttpResponse) {
          const stopPolling = new Subject<void>();
          const reportId = event.body['reportId'];

          const pollingRequest = new HttpRequest('GET',
          `${environment.serverUrl}/restapi/service/monitor/report/status/${reportId}`, request);

          timer(1000, pollingPeriod).pipe(
          switchMap(() => this.httpClient.request(pollingRequest)),
          takeUntil(stopPolling),
          filter(response => !!response['body'] ),
          take(pollingRetryCount)

    ).subscribe((response: HttpResponse<Object>) => {

      console.log('polling for monitor report');
      console.log(response);

      if (response.body['complete'] && response.body['reportStatus'] === MonitorService.REPORT_STATUS_COMPLETED) {
        this._requestComplete(queueObj, response);
        stopPolling.next();
        stopPolling.complete();
      } else if (response.body['complete'] && response.body['reportStatus'] === MonitorService.REPORT_STATUS_FAILED) {
        this._requestFailed(queueObj);
        stopPolling.next();
        stopPolling.complete();
      }
    },  error => {
      stopPolling.next();
      stopPolling.complete();
      this._requestFailed(queueObj);
    });
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          this._requestFailed(queueObj);
        } else {
          // The backend returned an unsuccessful response code.
          this._requestFailed(queueObj);
        }
      }
    );

    return queueObj;
  }

  private _cancel(reportRequest: ReportRequest) {
    // update the ReportQueueObject as cancelled
    reportRequest.request.unsubscribe();
    reportRequest.progress = 0;
    reportRequest.status = ReportRequestStatus.Pending;
  }

  private _requestComplete(reportRequest: ReportRequest, response: HttpResponse<Object>) {
    this.store.dispatch(new ReportsActions.ReportRequestComplete(reportRequest.requestId, response));
  }

  private _requestFailed(reportRequest: ReportRequest) {
    this.store.dispatch(new ReportsActions.ReportRequestError(reportRequest.requestId));
  }

  // TODO: Shall we move this to a general utility?
  private newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      // tslint:disable-next-line no-bitwise
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  getMonitorCriterions(id: string): Observable<MonitorCriterions> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/monitor/configuration`).pipe(
      map(response => {
        return this.jsonConverter.deserializeObject(response, MonitorCriterions);
      }));
  }

  getPresets(): Observable<Preset[]> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/monitor/preSet`).pipe(
      map(response => {
        const presets: Preset[] = response as Preset[];
        return this.jsonConverter.deserializeArray(presets, Preset);
      }));
  }

  runReport(reportRequest: ReportRequestParams): Observable<HttpResponse<Object>> {
    return this.httpClient.post(`${environment.serverUrl}/restapi/service/monitor/report/creation`, reportRequest, {observe: 'response'})
    .pipe(
      map((response: HttpResponse<Object>) => {
          return response;
        }),
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.error('An error occurred: ', err.error.message);
        } else {
          // TODO: Send error message to polo mint or notification service when it's implemented
          console.error(`Backend returned code ${err.status}, error was: ${err.error.message}`);
        }
        // empty observable
        return empty();
      }));
  }

  openReport(reportPath: String) {
    window.open(`${environment.serverUrl}/restapi/service/monitor/v1/report/retrieval/${reportPath}`, '_blank');
  }

  fetchReport(reportPath: String, fileFormat: string): Observable<any> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/monitor/v1/report/retrieval/${reportPath}`,
      {responseType: 'blob'}).pipe(
      map(response => {
        const blob = new Blob([response], { type: fileFormat });
        const file = new File([blob], '', { type: fileFormat });
        return file;
      }));
  }

  savePreset(preset: Preset): Observable<Preset> {
		return this.httpClient.post(`${environment.serverUrl}/restapi/service/monitor/preSet/create`, preset).pipe(
    map(response => {
      return this.jsonConverter.deserializeObject(response, Preset);
    }));
  }

	deletePreset(preset: Preset): Observable<Preset> {
    return this.httpClient.delete(`${environment.serverUrl}/restapi/service/monitor/preSet/${preset.id}/delete`).pipe(
    map(response => {
      return preset;
    }));
	}
}
