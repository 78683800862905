import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { TeamBuyList } from '../shared/entity/teamBuyList.model';
import { JsonConvertService } from '../shared/json-convert/json-convert.service';
import { GenericFormConfiguration } from './../shared/entity/generic-form/generic-form.model';
import { BuyListClients, BuyListKe } from './entity/buy-list-client.model';

@Injectable()
export class BuyListService {

  private jsonConverter: JsonConvert;

  constructor(private httpClient: HttpClient, private jsonConvertService: JsonConvertService) {
    this.jsonConverter =  this.jsonConvertService.getJsonConverter();
  }

  getAllBuyListClients(): Observable<BuyListClients> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/teamBuyList/client/list`)
      .pipe(map(response => this.jsonConverter.deserializeObject(response, BuyListClients)));
  }

  getBuyListKeFormConfiguration(assetClass: string, sedol: string): Observable<TeamBuyList> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/teamBuyList/ke/configuration/${assetClass}/asset/${sedol}`)
      .pipe(map(response => this.jsonConverter.deserializeObject(response, TeamBuyList)));
  }

  submitKe(clientId: string, accountId: string, sedol: string, buyList: BuyListKe) {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.post(`${environment.serverUrl}/restapi/service/teamBuyList/client/${clientId}/account/${encodeURIComponent(accountId)}/asset/${sedol}/knownException`,
      buyList);
  }

  getTeamListKeFormValue(clientId: string, accountId: string, sedol: string) {
    return this.httpClient.get(`${environment.serverUrl}/teamBuyList/client/${clientId}/account/${encodeURIComponent(accountId)}/asset/${sedol}formValue`)
    .pipe(map(response => this.jsonConverter.deserializeObject(response, Map)));
  }

  getTeamListKeFormValueWithConfig(keId: number) {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/teamBuyList/knownException/${keId}/formValueWithConfig`)
    .pipe(map(response => {
      return this.jsonConverter.deserializeObject(response, TeamBuyList);
    }));
  }
}
