import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('MoveAccountClientSummary')
export class ClientSummary {

    @JsonProperty('clientId', String)
    clientId: string = undefined;

    @JsonProperty('clientName', String)
    clientName: string = undefined;
}

@JsonObject('ClientDestinationSearch')
export class ClientDestinationSearch {

    @JsonProperty('clientDestinationList', [ClientSummary])
    clientDestinationList: ClientSummary[] = undefined;

    @JsonProperty('searchResultMessage', String)
    searchResultMessage: string = undefined;
}

export class ClientSearchTerms {

    clientId: string = undefined;
    clientName: string = undefined;

    getClientId(): string {
        return this.clientId;
    }

    getClientName(): string {
        return this.clientName;
    }

    constructor(clientId: string, clientName: string) {
        this.clientId = clientId;
        this.clientName = clientName;
    }
}

export class MandateMoveData {

    sourceClientId: string;
    sourceMandateId: string;
    destinationClientId: string;

    constructor(sourceClientId: string, sourceMandateId: string, destinationClientId: string) {
        this.sourceClientId = sourceClientId;
        this.sourceMandateId = sourceMandateId;
        this.destinationClientId = destinationClientId;
    }

    getSourceClientId() {
        return this.sourceClientId;
    }

    getSourceMandateId() {
        return this.sourceMandateId;
    }

    getDestinationClientId() {
        return this.destinationClientId;
    }
}
