export function mockMonitorFlagTypes() {
  return [
    {
      id: 1,
      name: 'stockLevelTotalRisk',
      label: 'Risk'
    },

    {
      id: 2,
      name: 'stockLevelConcentration',
      label: 'Concentration'
    },

    {
      id: 3,
      name: 'sectorOutlier',
      label: 'Effective Sector'
    },

    {
      id: 4,
      name: 'holdingExposureOutlier',
      label: 'Max Holding Weight'
    },

    {
      id: 5,
      name: 'buyListOutlier',
      label: 'Buy-list'
    },

    {
      id: 6,
      name: 'commonality',
      label: 'Effective Holding Commonality'
    },

    {
      id: 7,
      name: 'assetClassCash',
      label: 'Effective AA - Cash'
    },

    {
      id: 8,
      name: 'assetClassBond',
      label: 'Effective AA - Bonds'
    },

    {
      id: 9,
      name: 'assetClassEquity',
      label: 'Effective AA - Equity'
    },

    {
      id: 10,
      name: 'assetClassVol',
      label: 'Effective AA - Volatility'
    },
  ];

}
