export function mockPortfolioConstructionModel() {

  return {
    'clientId': '16147',
    'accountId': 'P2864701',
    'scenarioId': null,
    'scenarioName': null,
    'lookthroughEnabled': false,
    'targetModelName': 'BMK',
    'targetModelId': '-2',
    'currency': {
      'currencyCode': 'GBP',
      'currencySymbol': '£'
    },
    'topLevelNode': [
      {
        'proposedVsTarget': 0,
        'proposedVsCurrent': 0,
        'proposedNetGain': 0,
        'volatility': 0,
        'yield': 0,
        'asset': {
          'id': 4,
          'name': 'Fixed Interest - United Kingdom',
          'label': 'Fixed Interest - United Kingdom',
          'isComposite': false
        },
        'modelTarget': {
          'minAA': 0,
          'maxAA': 0,
          'bmAA': 0,
          'target': 0
        },
        'currentPosition': {
          'shares': 0,
          'value': 49450.81,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 100
        },
        'proposedPosition': {
          'shares': 0,
          'value': 11963.26,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 24.18
        },
        'subAssetNode': [
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 39,
              'name': 'sac_UK Index Linked Securities',
              'label': 'UK Index Linked Securities',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 49450.81,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 100
            },
            'proposedPosition': {
              'shares': 0,
              'value': 975.95,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 1.97
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 4257,
                  'name': '0898302',
                  'label': 'UNITED KINGDOM(GOVERNMENT OF) 2.5% I/LKD NTS 17/07/2024 GBP100',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 49450.81,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 100
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 49450.81,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 100
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 2161,
                  'name': 'B85SFQ5',
                  'label': 'United Kingdom(Government of) 0.125% Idx/Lkd Snr 22/03/24 GBP100',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 975.95,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.97
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 38,
              'name': 'sac_UK Government Securities',
              'label': 'UK Government Securities',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 9759.53,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 19.73
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 2255,
                  'name': 'BYY5F58',
                  'label': 'United Kingdom(Government of) 1.5% Gilt Bds 22/01/2021 GBP0.01',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 2658.16,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 5.38
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 6417,
                  'name': 'BTHH2R7',
                  'label': 'United Kingdom(Government of) 2% Snr Bds 07/09/2025 GBP100',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 1331.76,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 2.69
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 3346,
                  'name': '3245239',
                  'label': 'United Kingdom(Government of) 4.25% Gilt Bds 07/03/2036 GBP100',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 1745.55,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 3.53
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 1633,
                  'name': 'B1VWPJ5',
                  'label': 'United Kingdom(Government of) 4.5% Gilt Bds 07/12/2042 GBP100',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 1727.74,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 3.49
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 4400,
                  'name': 'B24FF09',
                  'label': 'United Kingdom(Government of) 4.75% Gilt 7/12/2030 GBP0.01',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 2296.32,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 4.64
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 37,
              'name': 'sac_Other UK Fixed Interest',
              'label': 'Other UK Fixed Interest',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 1227.78,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 2.48
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 429,
                  'name': 'B3PZ2H1',
                  'label': 'Rabobank-Cooperatieve Rabobank UA 4.625% Snr MTN 13/01/2021 GBP10002348A',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 284.9,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.58
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 6533,
                  'name': '4817507',
                  'label': 'Wal-Mart Stores Inc 5.75% Snr Bds 19/12/2030 GBP1000',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 208.52,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.42
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 5632,
                  'name': '3115912',
                  'label': 'Glaxosmithkline Capital Plc 5.25% Gtd Snr EMTN 19/12/2033 GBP100000',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 229.68,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.46
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 6898,
                  'name': '0491938',
                  'label': 'National Grid Electricity Trans. 5.875% Snr Bds 02/02/2024 GBP1000 Regs',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 208.16,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.42
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 3009,
                  'name': '3388361',
                  'label': 'Vodafone Group 5.625% Snr EMTN 04/12/25 GBP1000 13 Regs',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 296.52,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.6
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        'proposedVsTarget': 0,
        'proposedVsCurrent': 0,
        'proposedNetGain': 0,
        'volatility': 0,
        'yield': 0,
        'asset': {
          'id': 5,
          'name': 'Fixed Interest - Overseas',
          'label': 'Fixed Interest - Overseas',
          'isComposite': false
        },
        'modelTarget': {
          'minAA': 0,
          'maxAA': 0,
          'bmAA': 0,
          'target': 0
        },
        'currentPosition': {
          'shares': 0,
          'value': 0,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 0
        },
        'proposedPosition': {
          'shares': 0,
          'value': 0,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 0
        },
        'subAssetNode': [
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 36,
              'name': 'sac_Fixed Interest - Overseas',
              'label': 'Fixed Interest - Overseas',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          }
        ]
      },
      {
        'proposedVsTarget': 0,
        'proposedVsCurrent': 0,
        'proposedNetGain': 0,
        'volatility': 0,
        'yield': 0,
        'asset': {
          'id': 6,
          'name': 'Equities - United Kingdom',
          'label': 'Equities - United Kingdom',
          'isComposite': false
        },
        'modelTarget': {
          'minAA': 0,
          'maxAA': 0,
          'bmAA': 0,
          'target': 0
        },
        'currentPosition': {
          'shares': 0,
          'value': 0,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 0
        },
        'proposedPosition': {
          'shares': 0,
          'value': 16042.16,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 32.41
        },
        'subAssetNode': [
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 27,
              'name': 'sac_Consumer Services',
              'label': 'Consumer Services',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 2144.33,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 4.32
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 544,
                  'name': 'BVYVFW2',
                  'label': 'Auto Trader Group Plc ord GBP0.01',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 285.87,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.58
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 5811,
                  'name': 'BMTRW10',
                  'label': 'B & M European Value Retail SA ord GBP0.1 (DI)',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 341.05,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.69
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 2342,
                  'name': 'B8KF9B4',
                  'label': 'WPP Plc ord GBP0.10',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 253.43,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.51
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 2666,
                  'name': '3121522',
                  'label': 'Carnival ord USD1.66',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 303.78,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.61
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 5702,
                  'name': 'BKX5CN8',
                  'label': 'Just Eat ord 1p',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 343.33,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.69
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 3992,
                  'name': 'B2B0DG9',
                  'label': 'Relx Plc GBP0.1444',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 312.75,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.63
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 5026,
                  'name': 'BD6K457',
                  'label': 'Compass Group Plc ord GBP0.1105',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 304.12,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.61
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 26,
              'name': 'sac_Consumer Goods',
              'label': 'Consumer Goods',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 2868.96,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 5.79
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 549,
                  'name': '0454492',
                  'label': 'Imperial Brands Plc GBP0.10',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 287.27,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.58
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 3871,
                  'name': '0682538',
                  'label': 'Persimmon ord GBP0.10',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 283.81,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.57
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 1479,
                  'name': '0673123',
                  'label': 'Associated British Foods ord GBP0.0568',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 159.87,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.32
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 6696,
                  'name': '0287580',
                  'label': 'British American Tobacco ord GBP0.25',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 685.92,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.39
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 6021,
                  'name': '0237400',
                  'label': 'Diageo ord GBP0.28 101/108',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 486,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.98
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 2378,
                  'name': '3064650',
                  'label': 'GKN ord GBP0.10',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 175.61,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.36
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 3510,
                  'name': 'B10RZP7',
                  'label': 'Unilever ord GBP0.031111',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 328.45,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.66
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 4427,
                  'name': 'B24CGK7',
                  'label': 'Reckitt Benckiser Group ord GBP0.10',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 462.03,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.93
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 35,
              'name': 'sac_Utilities',
              'label': 'Utilities',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 25,
              'name': 'sac_Basic Materials',
              'label': 'Basic Materials',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 881.81,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 1.78
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 1992,
                  'name': '0718875',
                  'label': 'Rio Tinto ord GBP0.10',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 560.54,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.13
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 1807,
                  'name': 'B1XZS82',
                  'label': 'Anglo American USD0.54945',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 321.27,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.65
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 29,
              'name': 'sac_Health Care',
              'label': 'Health Care',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 1478.2,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 2.98
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 713,
                  'name': '0925288',
                  'label': 'GlaxoSmithKline ord GBP0.25',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 595.7,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.2
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 3199,
                  'name': '0989529',
                  'label': 'Astrazeneca ord USD0.25',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 579.49,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.17
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 5465,
                  'name': 'B2QKY05',
                  'label': 'Shire ord GBP0.05',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 303.01,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.61
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 34,
              'name': 'sac_Telecommunications',
              'label': 'Telecommunications',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 361.71,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0.73
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 3693,
                  'name': '3091357',
                  'label': 'BT Group ord GBP0.05',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 139.41,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.28
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 2477,
                  'name': 'BH4HKS3',
                  'label': 'Vodafone Group ord USD0.2095238',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 222.3,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.45
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 30,
              'name': 'sac_Industrials',
              'label': 'Industrials',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 1869.37,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 3.78
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 5844,
                  'name': '0182704',
                  'label': 'CRH ord EUR0.32',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 307.18,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.62
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 6131,
                  'name': '0405207',
                  'label': 'Halma ord GBP0.10',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 325.83,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.66
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 6296,
                  'name': 'B19NLV4',
                  'label': 'Experian ord USD0.10',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 336.41,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.68
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 4837,
                  'name': 'B1WY233',
                  'label': 'Smiths Group ord GBP0.375',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 207.09,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.42
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 704,
                  'name': '0822011',
                  'label': 'Smith(DS) ord GBP0.10',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 387.27,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.78
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 3492,
                  'name': '0263494',
                  'label': 'BAE Systems ord GBP0.025',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 305.59,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.62
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 32,
              'name': 'sac_Others',
              'label': 'Others',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 33,
              'name': 'sac_Technology',
              'label': 'Technology',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 375.2,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0.76
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 1648,
                  'name': 'BYZFZ91',
                  'label': 'Sophos Group Plc ord GBP0.03',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 375.2,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.76
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 28,
              'name': 'sac_Financials',
              'label': 'Financials',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 4346.25,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 8.8
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 6899,
                  'name': 'BC4DSV5',
                  'label': 'River & Mercantile Funds ICVC UK Equity Smaller Companies B Inc',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 641.12,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.3
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 3191,
                  'name': '3134865',
                  'label': 'Barclays Plc ord GBP0.25',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 290.55,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.59
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 2543,
                  'name': '0709954',
                  'label': 'Prudential GBP0.05',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 477.64,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.97
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 6336,
                  'name': 'B1YW440',
                  'label': '3I Group ord GBP0.738636',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 315.96,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.64
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 4842,
                  'name': '0870612',
                  'label': 'Lloyds Banking Group ord GBP0.10',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 624.1,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.26
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 3769,
                  'name': '0136701',
                  'label': 'British Land Co ord GBP0.25',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 302.27,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.61
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 4983,
                  'name': 'BZ02MH1',
                  'label': 'Nex Group Plc ord GBP0.175',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 191.15,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.39
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 6621,
                  'name': 'BYT1DJ1',
                  'label': 'Intermediate Capital Group Plc ord GBP0.2625',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 303.99,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.61
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 6746,
                  'name': '0540528',
                  'label': 'HSBC Holdings ord USD0.50',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 888.83,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.8
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 6567,
                  'name': '0560399',
                  'label': 'Legal & General Group ord GBP0.025',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 310.64,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.63
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 31,
              'name': 'sac_Oil & Gas',
              'label': 'Oil & Gas',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 1716.33,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 3.47
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 4213,
                  'name': '0798059',
                  'label': 'BP ord USD0.25',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 567.05,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.15
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 2429,
                  'name': 'B03MM40',
                  'label': 'Royal Dutch Shell Bord EUR0.07',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 1149.28,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 2.32
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        'proposedVsTarget': 0,
        'proposedVsCurrent': 0,
        'proposedNetGain': 0,
        'volatility': 0,
        'yield': 0,
        'asset': {
          'id': 7,
          'name': 'Equities - Overseas',
          'label': 'Equities - Overseas',
          'isComposite': false
        },
        'modelTarget': {
          'minAA': 0,
          'maxAA': 0,
          'bmAA': 0,
          'target': 0
        },
        'currentPosition': {
          'shares': 0,
          'value': 0,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 0
        },
        'proposedPosition': {
          'shares': 0,
          'value': 11799.61,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 23.85
        },
        'subAssetNode': [
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 20,
              'name': 'sac_Equities - Emerging Markets',
              'label': 'Equities - Emerging Markets',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 910.9,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 1.84
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 1263,
                  'name': 'B9DN3X2',
                  'label': 'Lazard Fund Managers Ltd Emerging Markets S GBP Inc',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 515.05,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.04
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 1784,
                  'name': 'BD3HZS1',
                  'label': 'Henderson Investment Funds Ltd Emerging Markets Opp I GBP Inc',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 395.85,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.8
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 19,
              'name': 'sac_Equities - Asia Pacific ex Japan',
              'label': 'Equities - Asia Pacific ex Japan',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 727.16,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 1.47
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 253,
                  'name': 'BP25SS1',
                  'label': 'Blackrock (Luxembourg) S.A. BGF Asian Growth Leaders D2RF GBP Acc',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 339.42,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.69
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 6780,
                  'name': 'B57S0V2',
                  'label': 'First State Investments(UK) Stewart Inv Asia Pac Ldrs B GBP Dis',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 387.74,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0.78
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 21,
              'name': 'sac_Equities - Europe ex UK',
              'label': 'Equities - Europe ex UK',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 2407.06,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 4.86
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 5469,
                  'name': 'B412VB0',
                  'label': 'Henderson Investment Funds Ltd European Selected Opportunities I Inc',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 1193.1,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 2.41
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 3545,
                  'name': 'B993PD0',
                  'label': 'J O Hambro Capital Management Ltd Continental European Y Inc Nav',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 1213.96,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 2.45
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 23,
              'name': 'sac_Equities - Japan',
              'label': 'Equities - Japan',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 1423.35,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 2.88
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 5776,
                  'name': 'BY7RQV4',
                  'label': 'Eastspring Investments Japan Dynamic Rg GBP Cap',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 697.93,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.41
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 5615,
                  'name': 'BYZJQL2',
                  'label': 'Baillie Gifford & Co Japanese Income Growth Y Net Inc',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 725.42,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.47
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 22,
              'name': 'sac_Equities - Global',
              'label': 'Equities - Global',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 24,
              'name': 'sac_Equities - North America',
              'label': 'Equities - North America',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 6331.14,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 12.8
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 3323,
                  'name': 'BGLNPS2',
                  'label': 'Priviledge Sands US Growth I Uh Inc Nav',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 683.14,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.38
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 6725,
                  'name': 'BPFJD41',
                  'label': 'Blackrock Advisors(UK)Ltd Ishares Nrth American Eqty Idx H GBP Acc',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 3159.97,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 6.39
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 6767,
                  'name': 'BC7GWQ4',
                  'label': 'Vulcan Global Value Fund Value Equity II Inc Nav',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 1212.45,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 2.45
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 1118,
                  'name': 'BSMSXW0',
                  'label': 'Iridian Ucits Fund Plc US Equity I GBP Dis',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 1275.58,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 2.58
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        'proposedVsTarget': 0,
        'proposedVsCurrent': 0,
        'proposedNetGain': 0,
        'volatility': 0,
        'yield': 0,
        'asset': {
          'id': 8,
          'name': 'Cash',
          'label': 'Cash Products',
          'isComposite': false
        },
        'modelTarget': {
          'minAA': 0,
          'maxAA': 0,
          'bmAA': 0,
          'target': 0
        },
        'currentPosition': {
          'shares': 0,
          'value': 0,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 0
        },
        'proposedPosition': {
          'shares': 0,
          'value': 1192.48,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 2.41
        },
        'subAssetNode': [
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 18,
              'name': 'sac_Cash',
              'label': 'Cash Products',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 1192.48,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 2.41
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 2741,
                  'name': '98X0684',
                  'label': 'Model Cash GBP',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 1192.48,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 2.41
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        'proposedVsTarget': 0,
        'proposedVsCurrent': 0,
        'proposedNetGain': 0,
        'volatility': 0,
        'yield': 0,
        'asset': {
          'id': 9,
          'name': 'Alternative Investments',
          'label': 'Alternative Investments',
          'isComposite': false
        },
        'modelTarget': {
          'minAA': 0,
          'maxAA': 0,
          'bmAA': 0,
          'target': 0
        },
        'currentPosition': {
          'shares': 0,
          'value': 0,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 0
        },
        'proposedPosition': {
          'shares': 0,
          'value': 8453.29,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 17.09
        },
        'subAssetNode': [
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 14,
              'name': 'sac_Private Equity',
              'label': 'Private Equity',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 15,
              'name': 'sac_Property',
              'label': 'Property',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 1484.81,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 3.01
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 3980,
                  'name': 'BQWJ879',
                  'label': 'F&C Fund Management Ltd Property Growth & Income I Inc',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 507.21,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.03
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 3760,
                  'name': 'BYP82C2',
                  'label': 'Henderson Investment Funds Ltd UK Property PAIF Feeder I GBP Inc Net',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 977.6,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.98
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 13,
              'name': 'sac_Infrastructure',
              'label': 'Infrastructure',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 17,
              'name': 'sac_Total Return',
              'label': 'Total Return',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 11,
              'name': 'sac_Absolute Return',
              'label': 'Absolute Return',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 4504.05,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 9.1
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 5617,
                  'name': 'B7GKYZ5',
                  'label': 'Aspect UCITS Funds Plc Diversified Trends P Instl',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 860.36,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.74
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 6918,
                  'name': 'B2QQPT9',
                  'label': 'BH Global Ltd ord GBP',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 924.98,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.87
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 289,
                  'name': 'BJ04HL4',
                  'label': 'Invesco Fund Managers IP Global Targeted Returns Y Acc',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 904.75,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.83
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 2564,
                  'name': 'BTFRNG5',
                  'label': 'GAM Star Fund Absolute Return Bond Instl GBP Inc II',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 862.56,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.74
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 2808,
                  'name': 'B3V2HM6',
                  'label': 'Marshall Wace Ucits Funds Plc MW Tops Ucits A GBP Acc',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 951.4,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 1.92
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 16,
              'name': 'sac_Structured Products',
              'label': 'Structured Products',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          },
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 12,
              'name': 'sac_Commodities',
              'label': 'Commodities',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 2464.43,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 4.98
            },
            'holdingNode': [
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 4315,
                  'name': 'B4PYKC6',
                  'label': 'Source Physical Markets Secured Gold Lkd Nts 31/12/2100 GBP',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 1109.26,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 2.24
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              },
              {
                'proposedVsTarget': 0,
                'proposedVsCurrent': 0,
                'proposedNetGain': 0,
                'volatility': 0,
                'yield': 0,
                'source': 'S',
                'tradeInstruction': {
                  'rawGain': 0,
                  'transactionType': '',
                  'tradeNominal': 0,
                  'approxProceeds': 0
                },
                'asset': {
                  'id': 4947,
                  'name': 'B285Z72',
                  'label': 'ETFS Metal Securities Ltd ETFS Physical Gold (GBP)',
                  'isComposite': false
                },
                'modelTarget': {
                  'minAA': 0,
                  'maxAA': 0,
                  'bmAA': 0,
                  'target': 0
                },
                'currentPosition': {
                  'shares': 0,
                  'value': 0,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 0
                },
                'proposedPosition': {
                  'shares': 0,
                  'value': 1355.17,
                  'yield': 0,
                  'ctr': 0,
                  'mctr': 0,
                  'weight': 2.74
                },
                'breakdownNode': [
                  {
                    'compositeLabel': '2864701 L16146-P2864701-2864701',
                    'accountId': 33624,
                    'accountName': '2864701',
                    'accountDescription': 'L16146-P2864701-2864701',
                    'proposedVsTarget': 0,
                    'proposedVsCurrent': 0,
                    'proposedNetGain': 0,
                    'volatility': 0,
                    'yield': 0,
                    'currentPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'proposedPosition': {
                      'shares': 0,
                      'value': 0,
                      'yield': 0,
                      'ctr': 0,
                      'mctr': 0,
                      'weight': 0
                    },
                    'tradeInstruction': {
                      'rawGain': 0,
                      'transactionType': '',
                      'tradeNominal': 0,
                      'approxProceeds': 0
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        'proposedVsTarget': 0,
        'proposedVsCurrent': 0,
        'proposedNetGain': 0,
        'volatility': 0,
        'yield': 0,
        'asset': {
          'id': 10,
          'name': 'Unclassified',
          'label': 'Unclassified',
          'isComposite': false
        },
        'modelTarget': {
          'minAA': 0,
          'maxAA': 0,
          'bmAA': 0,
          'target': 0
        },
        'currentPosition': {
          'shares': 0,
          'value': 0,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 0
        },
        'proposedPosition': {
          'shares': 0,
          'value': 0,
          'yield': 0,
          'ctr': 0,
          'mctr': 0,
          'weight': 0
        },
        'subAssetNode': [
          {
            'proposedVsTarget': 0,
            'proposedVsCurrent': 0,
            'proposedNetGain': 0,
            'volatility': 0,
            'yield': 0,
            'asset': {
              'id': 40,
              'name': 'sac_Unclassified',
              'label': 'Unclassified',
              'isComposite': false
            },
            'modelTarget': {
              'minAA': 0,
              'maxAA': 0,
              'bmAA': 0,
              'target': 0
            },
            'currentPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'proposedPosition': {
              'shares': 0,
              'value': 0,
              'yield': 0,
              'ctr': 0,
              'mctr': 0,
              'weight': 0
            },
            'holdingNode': []
          }
        ]
      }
    ],
    'total': {
      'proposedVsTarget': 0,
      'proposedVsCurrent': 0,
      'proposedNetGain': 0,
      'volatility': 0,
      'yield': 0,
      'asset': null,
      'modelTarget': null,
      'currentPosition': {
        'shares': 0,
        'value': 49450.81,
        'yield': 0,
        'ctr': 5.84,
        'mctr': 0,
        'weight': 100
      },
      'proposedPosition': {
        'shares': 0,
        'value': 49450.81,
        'yield': 0,
        'ctr': 0,
        'mctr': 0,
        'weight': 100
      }
    }
  };
}
