import { Action } from '@ngrx/store';

import { HttpResponse } from '@angular/common/http';
import { ReportRequest } from '../../entity/report-request.model';

export const ADD_REPORT_REQUEST = '[Reports] ADD_REPORT_REQUEST';
export const REPORT_REQUEST_COMPLETE = '[Reports] REPORT_REQUEST_COMPLETE';
export const REPORT_REQUEST_ERROR = '[Reports] REPORT_REQUEST_ERROR';

export const CANCEL_ALL_PENDING_REPORTS = '[Reports] CANCEL_ALL_PENDING_REPORTS';

export class AddReportRequest implements Action {
	readonly type = ADD_REPORT_REQUEST;
	constructor(public reportRequest: ReportRequest) {}
}

export class ReportRequestComplete implements Action {
	readonly type = REPORT_REQUEST_COMPLETE;
	constructor(public reportRequestId: string, public response: HttpResponse<Object>) {}
}

export class ReportRequestError implements Action {
	readonly type = REPORT_REQUEST_ERROR;
	constructor(public reportRequestId: string) {}
}

export type All = AddReportRequest | ReportRequestComplete | ReportRequestError;
