import { Action } from '@ngrx/store';
import { ClientDetailsPackage } from '../../entity/client-details.model';

export const GET_CLIENT_DETAILS = '[ClientDetails] GET_CLIENT_DETAILS';
export const GET_CLIENT_DETAILS_SUCCESS = '[ClientDetails]  GET_CLIENT_DETAILS_SUCCESS';
export const GET_CLIENT_DETAILS_ERROR = '[ClientDetails] GET_CLIENT_DETAILS_ERROR';

export class GetClientDetails implements Action {
	readonly type = GET_CLIENT_DETAILS;
	constructor(public clientId: string) {}
}

export class GetClientDetailsSuccess implements Action {
	readonly type = GET_CLIENT_DETAILS_SUCCESS;
	constructor(public clientDetails: ClientDetailsPackage) {}
}

export class GetClientDetailsError implements Action {
	readonly type = GET_CLIENT_DETAILS_ERROR;
	constructor(public error: any) {}
}

export type All = GetClientDetails | GetClientDetailsSuccess | GetClientDetailsError;
