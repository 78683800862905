import { JsonObject, JsonProperty } from 'json2typescript';
import { Asset } from './asset.model';
import { AssetNodeType, AssetTreeNode } from './portfolio-construction-model.model';
import { PortfolioLevel } from './portfolioLevel.enum';

@JsonObject('Boundary')
export class Boundary {

	@JsonProperty('lower', Number)
	lower: number = undefined;

	@JsonProperty('upper', Number)
	upper: number = undefined;
}

@JsonObject('PortfolioAssetNode')
export abstract class AssetNode implements AssetTreeNode {
    assetType: AssetNodeType;
    portfolioLevel: PortfolioLevel;

    @JsonProperty('nodeInfo', Asset)
    asset: Asset = undefined;

    @JsonProperty('currentWeight', Number)
	currentWeight: number = undefined;

	@JsonProperty('saaWeight', Number)
	saaWeight: number = undefined;

	@JsonProperty('taaWeight', Number)
	taaWeight: number = undefined;

	@JsonProperty('saaRisk', Number)
	saaRisk: number = undefined;

	@JsonProperty('saaReturn', Number)
    saaReturn: number = undefined;

    @JsonProperty('taaBoundary', Boundary, true)
    taaBoundary: Boundary = undefined;

    @JsonProperty('saaBoundary', Boundary, true)
	saaBoundary: Boundary = undefined;

    get data() {
        return this;
    }
}

@JsonObject('PortfolioSubAssetClassNode')
export class SubAssetClassNode extends AssetNode {

  visibilityLevel: PortfolioLevel = PortfolioLevel.TAC;
  assetType = AssetNodeType.Sub;
  portfolioLevel = PortfolioLevel.SAC;
  parent: AssetClassNode;

}

@JsonObject('PortfolioAssetClassNode')
export class AssetClassNode extends AssetNode {

  assetType = AssetNodeType.Top;
  portfolioLevel = PortfolioLevel.TAC;

	@JsonProperty('subAssetNode', [SubAssetClassNode])
	subAssetNode: Array<SubAssetClassNode> = undefined;

  get data() {
    return this;
  }

  get children() {
    return this.subAssetNode;
  }

}

@JsonObject('ReadOnlyPortfolioModel')
export class ReadOnlyPortfolioModel implements AssetTreeNode {
    @JsonProperty('accountId', String)
    accountId: string = undefined;

    @JsonProperty('scenarioName', String)
    scenarioName: string = undefined;

    @JsonProperty('risk budget', Number)
    riskBudget: number = undefined;

    @JsonProperty('current total return', Number)
    currentTotalReturn: number = undefined;

    @JsonProperty('proposed total saa risk', Number)
    proposedTotalSaaRisk: number = undefined;

    @JsonProperty('proposed total saa return', Number)
    proposedTotalSaaReturn: number = undefined;

    @JsonProperty('proposed total risk', Number)
	proposedTotalRisk: number = undefined;

	@JsonProperty('topLevelNodes', [AssetClassNode])
    topLevelNode: Array<AssetClassNode> = undefined;

    updateGlobalVisibilityLevel(level: PortfolioLevel, collapse: boolean) {
        this.updateVisibilityLevel(level, this, collapse);
    }

    updateVisibilityLevel(level: PortfolioLevel, node: AssetTreeNode, collapse: boolean) {
        if (node.assetType === AssetNodeType.Sub) {
        const subNode: any = node;
        subNode.visibilityLevel = (level - 1);
        }

        for (const childNode of node.children) {
        childNode.expanded = childNode.portfolioLevel < level ? true : (collapse ? false : childNode.expanded);
        if (childNode.children && childNode.children.length) {
            this.updateVisibilityLevel(level, childNode, collapse);
        }
        }
    }

    get children() {
        return this.topLevelNode;
    }

    set children(children) {
        this.topLevelNode = children;
    }
}




