export function mockApprovalsResults() {
  return [
    {
      id: 1,
      keId: 'KE000AA',
      requestDate: '2017-07-19 10:00:00',
      requester: 'user_a',
      accountId: 'BMID001HH',
      accountName: 'School Fees',
      monitorFlagName: 'AA C Bond',
      reason: 'CGT',
      approver: 'SMSmith',
      status: 'PENDING',
      owner: 'user_c', // owner is named PRC6 (or Business Getter) in backend code, and it is linked to Account ownerId in DB
      client: {
        clientId: 'CID007',
        name: 'Mrs Miggins',
        manager: 'E-JP'
      }
    },
    {
      id: 2,
      keId: 'KE000AB',
      requestDate: '2017-07-19 10:00:00',
      requester: 'user_b',
      accountId: 'BMID001HH',
      accountName: 'College Fees',
      monitorFlagName: 'AA Bond',
      reason: 'Cash Withdrawal',
      approver: 'JCole',
      status: 'APPROVED',
      owner: 'user_a', // owner is named PRC6 (or Business Getter) in backend code, and it is linked to Account ownerId in DB
      client: {
        clientId: 'CID009',
        name: 'Mr Joe Terry',
        manager: 'P-HJ'
      }
    },
    {
      id: 3,
      keId: 'KE000AC',
      requestDate: '2017-07-19 10:00:00',
      requester: 'user_b',
      accountId: 'BMID001GG',
      accountName: 'Cash Flow',
      monitorFlagName: 'Conc',
      reason: 'Closing',
      approver: 'RKelly',
      status: 'PENDING',
      owner: 'user_b', // owner is named PRC6 (or Business Getter) in backend code, and it is linked to Account ownerId in DB
      client: {
        clientId: 'CID008',
        name: 'Mrs Janet Gerrard',
        manager: 'E-UH'
      }
    },
    {
      id: 4,
      keId: 'KE000HG',
      requestDate: '2017-07-19 10:00:00',
      requester: 'user_a',
      accountId: 'BMID001SS',
      accountName: 'Saving',
      monitorFlagName: 'AAEQ',
      reason: 'CGT',
      approver: 'MButler',
      status: 'PENDING',
      owner: 'user_c', // owner is named PRC6 (or Business Getter) in backend code, and it is linked to Account ownerId in DB
      client: {
        clientId: 'CID002',
        name: 'Mr Martin Huth',
        manager: 'K-JP'
      }
    },
    {
      id: 5,
      keId: 'KE000LK',
      requestDate: '2017-07-19 10:00:00',
      requester: 'user_a',
      accountId: 'BMID001FF',
      accountName: 'Pension Fund',
      monitorFlagName: 'Risk',
      reason: 'CGT',
      approver: 'KYassin',
      status: 'PENDING',
      owner: 'E-JP', // owner is named PRC6 (or Business Getter) in backend code, and it is linked to Account ownerId in DB
      client: {
        clientId: 'CID003',
        name: 'Mrs Hayley Berry',
        manager: 'T-JP'
      }
    }
  ];
}
