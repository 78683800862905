import { SettingsService } from './../core/settingsService.service';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as AuthenticationActions from '../core/store/auth/authentication.actions';
import { AuthenticationService } from './authentication.service';
import { Session } from '../core/entity/session.model';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SsoResolver {
  constructor(private authenticationService: AuthenticationService, private store: Store<any>, private settingsService: SettingsService) {
  }

  checkSso() {
    const obs = this.authenticationService.checkSingleSignOn().pipe(take(1), map((session: Session) => {
      if (session && session.user && session.user.userName
        && 'anonymousUser' !== session.user.userName && '' !== session.user.userName) {
        this.store.dispatch(new AuthenticationActions.CheckSingleSignOnSuccess(session));
      }
      else {
        this.store.dispatch(new AuthenticationActions.CheckSingleSignOnError(session));
      }
    }));

    return obs;
  }
}
