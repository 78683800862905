import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../json-convert/DateConvertor';
import { Moment } from 'moment';
import { KnownExceptionRequestDecision } from './known-exception-request-decision.model';
import { ClientDetails } from './client-details.model';

@JsonObject('KnownException')
export class KnownException {

  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('keId', String)
  keId: string = undefined;

  @JsonProperty('monitorFlagName', String)
  monitorFlagName: string = undefined;

  @JsonProperty('monitorFlagType', String)
  monitorFlagType: string = undefined;

  @JsonProperty('accountId', String)
  accountId: string = undefined;

  @JsonProperty('accountName', String, true)
  accountName: string = undefined;

  @JsonProperty('client', ClientDetails, true)
  client: ClientDetails = undefined;

  @JsonProperty('requestDate', DateConverter, true)
  requestDate: Moment = undefined;

  @JsonProperty('expiryDate', DateConverter, true)
  expiryDate: Moment = undefined;

  @JsonProperty('decisionDate', DateConverter, true)
  decisionDate: Moment = undefined;

  @JsonProperty('approver', String, true)
  approver: string = undefined;

  @JsonProperty('requester', String, true)
  requester: string = undefined;

  @JsonProperty('reason', String, true)
  reason: string = undefined;

  @JsonProperty('decisionNote', String, true)
  decisionNote: string = undefined;

  @JsonProperty('status', String)
  status: string = undefined;

  @JsonProperty('owner', String, true)
  owner: string = undefined;

  @JsonProperty('description', String, true)
  description: string = undefined;

  @JsonProperty('maxExpiryDate', DateConverter, true)
  maxExpiryDate: Moment = undefined;

  @JsonProperty('overriddenExpiryDate', DateConverter, true)
  overriddenExpiryDate: Moment = undefined;

  @JsonProperty('excludedHolding', String, true)
  excludedHolding: string = undefined;

  @JsonProperty('keTarget', String, true)
  keTarget: string = undefined;

  @JsonProperty('additionalInfo', String, true)
  additionalInfo: string = undefined;

  @JsonProperty('assetName', String, true)
  assetName: string = undefined;

  KnownExceptionRequestDecisions: KnownExceptionRequestDecision[];

}
