import { JsonConverter, JsonCustomConvert, JsonConvert } from 'json2typescript';

@JsonConverter
export class MapOfMapConverter implements JsonCustomConvert<Map<string, Map<string, string>>> {

  serialize(formComponent: Map<string, Map<string, string>>): any {
      const jsonConvert: JsonConvert = new JsonConvert();
      return jsonConvert.serialize(formComponent);
  }

  deserialize(mapOfMap: any): Map<string, Map<string, string>> {

    if (mapOfMap) {
      const mainMap = new Map<string, Map<string, string>>();
      for (const k of Object.keys(mapOfMap)) {
        const mainMapValues = mapOfMap[k];
        const nestedMap = new Map<string, string>();
        for (const k2 of Object.keys(mainMapValues)) {
          nestedMap.set(k2, mainMapValues[k2]);
        }

        mainMap.set(k, nestedMap);
    }
      return mainMap;
    } else {
      return null;
    }

  }
}
