import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as SearchResultFormActions from './search-result-form.actions';
import { SearchService } from '../../search.service';

export type SearchResultFormAction = SearchResultFormActions.All;

@Injectable()
export class SearchResultFormEffects {

  constructor(
    private actions$: Actions,
    private searchService: SearchService) {
  }

  getSearchResultFormConfig: Observable<SearchResultFormAction> = createEffect(() =>
  this.actions$.pipe(
    ofType(SearchResultFormActions.GET_SEARCH_RESULT_FORM_CONFIG),
    switchMap(() => this.searchService.getSearchResultFormConfig().pipe(
      map(post => new SearchResultFormActions.GetSearchResultFormConfigSuccess(post)),
      catchError((error) => of(new SearchResultFormActions.GetSearchResultFormConfigError(error)))
    ))));

  getAssetsSearchResultFormConfig: Observable<SearchResultFormAction> = createEffect(() =>
  this.actions$.pipe(
    ofType(SearchResultFormActions.GET_ASSETS_SEARCH_RESULT_FORM_CONFIG),
    switchMap(() => this.searchService.getAssetsSearchResultFormConfig().pipe(
      map(post => new SearchResultFormActions.GetAssetsSearchResultFormConfigSuccess(post)),
      catchError((error) => of(new SearchResultFormActions.GetAssetsSearchResultFormConfigError(error)))
    ))));

  getPMPSearchResultFormConfig: Observable<SearchResultFormAction> = createEffect(() =>
  this.actions$.pipe(
    ofType(SearchResultFormActions.GET_PMP_ACCOUNT_SEARCH_RESULT_FORM_CONFIG),
    switchMap(() => this.searchService.getPMPSearchResultFormConfig().pipe(
      map(post => new SearchResultFormActions.GetPMPSearchResultFormConfigSuccess(post)),
      catchError((error) => of(new SearchResultFormActions.GetPMPSearchResultFormConfigError(error)))
    ))));
}
