import { Action } from '@ngrx/store';

import { TradeCashDetails } from '../../entity/trade-cash-details.model';

export const GET_TRADE_CASH_DETAILS = '[TradeCashDetails] GET_TRADE_CASH_DETAILS';
export const GET_TRADE_CASH_DETAILS_SUCCESS = '[TradeCashDetails]  GET_TRADE_CASH_DETAILS_SUCCESS';
export const GET_TRADE_CASH_DETAILS_ERROR = '[TradeCashDetails]  GET_TRADE_CASH_DETAILS_ERROR';

export class GetTradeCashDetails implements Action {
	readonly type = GET_TRADE_CASH_DETAILS;
	constructor() {}
}

export class GetTradeCashDetailsSuccess implements Action {
	readonly type = GET_TRADE_CASH_DETAILS_SUCCESS;
	constructor(public tradeCashDetails: TradeCashDetails) {}
}

export class GetTradeCashDetailsError implements Action {
	readonly type = GET_TRADE_CASH_DETAILS_ERROR;
	constructor(public error: any) {}
}

export type All = GetTradeCashDetails
	| GetTradeCashDetailsSuccess
	| GetTradeCashDetailsError;
