import * as PcmActions from './portfolioConstructionModel.actions';

import { PortfolioConstructionModelState } from './portfolioConstructionModel.state';
import PortfolioConstructionUtil from '../../util/portfolioConstruction/portfolio-construction.util';
import { PortfolioConstructionModel, EditProperty, HoldingClassNode } from '../../entity/portfolio-construction-model.model';

export type PcmAction = PcmActions.All;

export const initialState: PortfolioConstructionModelState = {
  portfolioConstructionModel: undefined,
  optimiserConstraintsConfiguration: undefined,
  optimiserError: undefined,
  accountsUpdated: false,
  isPortfolioReadOnly: false,
  portfolioMessages: undefined
};

export function portfolioConstructionModelReducer(
	state: PortfolioConstructionModelState = initialState, action: PcmAction): PortfolioConstructionModelState {
	switch (action.type) {

    case PcmActions.RESET_STATE: {
      return {...initialState};
    }

    case PcmActions.GET_PORTFOLIO_CONSTRUCTION_MODEL: {
      return {...initialState};
    }

		case PcmActions.GET_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS: {
			return {...state, ...{portfolioConstructionModel: action.pcm},
			...{isPortfolioReadOnly: action.isPortfolioReadOnly}, ...{portfolioMessages: action.portfolioMessages} };
		}

		case PcmActions.GET_PORTFOLIO_CONSTRUCTION_MODEL_ERROR: {
			console.error('Error caught while loading portfolio construction model', action.error);
			return {...state, portfolioConstructionModel: undefined};
		}

		case PcmActions.GET_PORTFOLIO_CONSTRUCTION_PAGE: {
			return {...initialState};
		}

		case PcmActions.GET_PORTFOLIO_CONSTRUCTION_PAGE_ERROR: {
			console.error('Error caught while loading portfolio construction page', action.error);
			return {...state, portfolioConstructionModel: undefined};
		}

		case PcmActions.GET_PORTFOLIO_CONSTRUCTION_MODEL_ADJUSTMENT_SUCCESS: {
			return {...state, ...{portfolioConstructionModel: action.pcm} };
		}

		case PcmActions.GET_PORTFOLIO_CONSTRUCTION_MODEL_ADJUSTMENT_ERROR: {
			console.error('Error caught while loading portfolio construction model', action.error);
			return {...state, portfolioConstructionModel: undefined};
		}

		case PcmActions.GET_NEW_PORTFOLIO_CONSTRUCTION_MODEL: {
			return {...initialState};
		}

		case PcmActions.GET_NEW_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS: {
			return {...state, ...{portfolioConstructionModel: action.pcm} };
		}

		case PcmActions.GET_NEW_PORTFOLIO_CONSTRUCTION_MODEL_ERROR: {
			console.error('Error caught while loading portfolio construction model', action.error);
			return {...state, portfolioConstructionModel: undefined};
		}

		case PcmActions.GET_NEW_PORTFOLIO_CONSTRUCTION_PAGE: {
			return {...initialState};
		}

		case PcmActions.GET_NEW_PORTFOLIO_CONSTRUCTION_PAGE_ERROR: {
			console.error('Error caught while loading the whole data of page', action.error);
			return {...state};
		}

		case PcmActions.ADD_HOLDING: {

			// extract the holdingNode from the PCM with the name of Holding;
			let returnedHoldingNode = PortfolioConstructionUtil.
				extractHoldingNodeFromModel(state.portfolioConstructionModel, action.holdingNode.sedol);

			// if there is no such holding, add it to appropriate SAC
			if (!returnedHoldingNode) {
				returnedHoldingNode = PortfolioConstructionUtil.createHoldingNode(state.portfolioConstructionModel, action.holdingNode);
			} else {
        // this means the asset is already in the pcm
        // check if this is zero weight if so then change newHolding flag to true so that it will be shown
        if (returnedHoldingNode.currentPosition.weight === 0 &&
          returnedHoldingNode.proposedPosition.weight === 0 &&
          returnedHoldingNode.modelTarget.target === 0) {
            returnedHoldingNode.isNewHolding = true;
          }
      }

			// iterate through the accountNames and check if it in the holdingNode's breakdown
			// if it is already there then break else add one more entry with everything empty
			state.portfolioConstructionModel.accountName.forEach(accountDetails =>
				PortfolioConstructionUtil.addBreakDownNode(returnedHoldingNode, accountDetails.id, accountDetails.name, accountDetails.label));

			returnedHoldingNode.source = returnedHoldingNode.breakdownNode.length > 1 ? 'M' : 'S';
      return {...state};
    }

		case PcmActions.RECALCULATE_ALL_SUCCESS: {
      if (action.editProperty !== undefined) {
        state.portfolioConstructionModel.updatedSource = EditProperty[action.editProperty];
      }
			return {...state};
		}

		case PcmActions.RECALCULATE_ALL_ERROR: {
			console.error('Error caught while recalculating error', action.error);
			return {...state, portfolioConstructionModel: undefined};
		}

    // TODO: Reintroduce this reducer when end point correctly returns PCM.
		// case PcmActions.ADJUST_BOUNDARIES_SUCCESS: {
		// 	return {...state, ...{portfolioConstructionModel: action.pcm} };
		// }

		case PcmActions.ADJUST_BOUNDARIES_ERROR: {
			console.error('Error caught while adjusting boundaries', action.error);
			return {...state};
		}

		case PcmActions.UPDATE_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS: {
			return {...state};
		}

		case PcmActions.UPDATE_PORTFOLIO_CONSTRUCTION_MODEL_ERROR: {
			console.error('Error caught while saving portfolio construction model', action.error);
			return {...state, portfolioConstructionModel: undefined};
		}

		case PcmActions.UPDATE_CURRENT_HOLDINGS_SUCCESS: {
			return {...state, ...{portfolioConstructionModel: action.pcm} };
		}

		case PcmActions.UPDATE_CURRENT_HOLDINGS_ERROR: {
			console.error('Error caught while updating current holdings', action.error);
			return {...state, portfolioConstructionModel: undefined};
    }

    case PcmActions.UPDATE_ACCOUNT_HOLDINGS: {
      return {...state, accountsUpdated: false };
    }

    case PcmActions.UPDATE_ACCOUNT_HOLDINGS_SUCCESS: {
			return {...state, accountsUpdated: true };
		}

		case PcmActions.UPDATE_ACCOUNT_HOLDINGS_ERROR: {
			console.error('Error caught while updating current holdings', action.error);
			return {...state};
		}

		case PcmActions.CREATE_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS: {
			state.portfolioConstructionModel.scenarioId = action.scenarioId;
			state.portfolioConstructionModel.isDataChanged = false;
			return {...state};
		}

		case PcmActions.CREATE_PORTFOLIO_CONSTRUCTION_MODEL_ERROR: {
			return {...state, portfolioConstructionModel: undefined};
		}

		case PcmActions.RECALCULATE_PORTFOLIO_CONSTRUCTION_MODEL_TOTAL: {
      const breakdownLevel = action.recalculateData.breakdownLevel;
      const property: EditProperty = action.recalculateData.property;
			const holdingLevel = breakdownLevel.parent as HoldingClassNode;
			const subLevelNode = holdingLevel.parent;
			const topLevelNode = subLevelNode.parent;
      const pcm: PortfolioConstructionModel = action.recalculateData.pcm;

	  let newProposedWeight = breakdownLevel;
	  let newProposedShares;

      switch (property) {
        case EditProperty.weight:
          newProposedWeight = action.recalculateData.value;
        break;

        case EditProperty.shares:
          newProposedShares = action.recalculateData.value;
          newProposedWeight = (pcm.total.proposedPosition.value != 0)
            ? ((action.recalculateData.value * holdingLevel.asset.price) / pcm.total.proposedPosition.value * 100)
            : 0;
        break;

        case EditProperty.value:
          newProposedWeight = (pcm.total.proposedPosition.value != 0)
            ? (action.recalculateData.value / pcm.total.proposedPosition.value) * 100
            : 0;
        break;
      }

			if (pcm.tradeCashEnabled) {

				const newCashWeightToAdd = breakdownLevel.node.proposedPosition.weight - newProposedWeight;
        const cashHoldingNode = PortfolioConstructionUtil.extractHoldingNodeFromModel(pcm, pcm.tradeCashNodeName);
        const pcu = PortfolioConstructionUtil;
				const cashHoldingBreakdownNode = pcu.extractBreakDownNodeFromHolding(cashHoldingNode, breakdownLevel.node.accountName);
        const cashHierarchy = PortfolioConstructionUtil.extractHierarchyFromAssetName(cashHoldingNode.asset.name, pcm);

				const newCashWeight = cashHoldingBreakdownNode.proposedPosition.weight + newCashWeightToAdd;
				if (cashHierarchy.topAssetNode && cashHierarchy.subAssetNode && cashHierarchy.holdingNode) {
					PortfolioConstructionUtil.recalculateTotal(
            cashHoldingBreakdownNode,
            cashHierarchy.holdingNode,
            cashHierarchy.subAssetNode,
            cashHierarchy.topAssetNode,
            pcm,
			newCashWeight,
			newProposedShares,
			false);
				}
			}
			PortfolioConstructionUtil.recalculateTotal(
        breakdownLevel.node,
        holdingLevel,
        subLevelNode,
        topLevelNode,
        pcm,
		newProposedWeight,
		newProposedShares,
		true);
			return {...state, ...{portfolioConstructionModel: pcm} };
		}

		case PcmActions.CHANGE_STARTING_PORTFOLIO_SUCCESS: {
			return {...state };
		}

		case PcmActions.CHANGE_STARTING_PORTFOLIO_ERROR: {
			console.error('Error caught while changing starting portfolio', action.error);
			return {...state, portfolioConstructionModel: undefined};
		}

		case PcmActions.RESET_ACCOUNTS_UPDATED: {
			return {...state, accountsUpdated: false};
		}

    case PcmActions.GET_OPTIMISER_CONSTRAINTS: {
      return {...state, optimiserConstraintsConfiguration: undefined, optimiserError: null};
    }

		case PcmActions.GET_OPTIMISER_CONSTRAINTS_SUCCESS: {
			return {...state, ...{
        optimiserConstraintsConfiguration: action.constraintsConfiguration,
        optimiserError: null
      } };
		}

		case PcmActions.GET_OPTIMISER_CONSTRAINTS_ERROR: {
			console.error('Error caught while loading optimiser constraints configuration', action.error);
			return {...state, portfolioConstructionModel: undefined};
	}

	case PcmActions.SAVE_UPDATE_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS: {
		state.portfolioConstructionModel.isEditable = false;
		return {...state};
	}

	case PcmActions.SAVE_UPDATE_PORTFOLIO_CONSTRUCTION_MODEL_ERROR: {
		console.error('Error caught while save and updating scenario', action.error);
		return {...state};
	}

    case PcmActions.OPTIMISE_PORTFOLIO_ERROR: {
			console.error('Error caught while optimising portfolio', action.error);
      return {...state, optimiserError: action.error};
    }

		case PcmActions.ADJUST_BOUNDARIES_ERROR: {
			console.error('Error caught while optimising portfolio', action.error);
			return {...state};
		}

    case PcmActions.CREATE_MODEL: {
      // state.portfolioConstructionModel.isEditable = false;
      return {...state};
    }
    case PcmActions.CREATE_MODEL_ERROR: {
      console.error('Error caught while creating model', action.error);
      return {...state};
    }

	case PcmActions.APPLY_CHOSEN_RISK_MODEL_SUCCESS: {
		return {...state};
	}

	case PcmActions.APPLY_CHOSEN_RISK_MODEL_ERROR: {
		console.error('Error caught while applying risk model', action.error);
		return {...state};
	}

	case PcmActions.APPLY_CHOSEN_ALPHA_MODEL_SUCCESS: {
		return {...state};
	}

	case PcmActions.APPLY_CHOSEN_ALPHA_MODEL_ERROR: {
		console.error('Error caught while applying risk model', action.error);
		return {...state};
	}
	
		default:
			return state;
	}

}
