import { JsonObject, JsonProperty } from 'json2typescript';
import { WidgetIssue } from '../../shared/entity/widget-issue.model';
import { WidgetAlert } from '../../shared/entity/widget-alert.model';
import { WidgetModelDetail } from '../../shared/entity/widget-profile.model';
import { TableHeaderForm } from '../../shared/entity/generic-form/generic-table-header-form';
import { MapConverter } from '../../shared/json-convert/MapConverter';

@JsonObject('ClientAap')
export class ClientAap {

  @JsonProperty('issues', [WidgetIssue])
  issues: WidgetIssue[] = undefined;

  @JsonProperty('alerts', [WidgetAlert])
  alerts: WidgetAlert[] = undefined;

  @JsonProperty('modelDetails', [WidgetModelDetail])
  modelDetails: WidgetModelDetail[] = undefined;

  @JsonProperty('portfolio', [Object])
  portfolio: Object[] = undefined;

}
