import { Component, HostBinding } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, ActivatedRoute } from '@angular/router';
import { NavActionService } from './core/navigation-floating/nav-action.service';

@Component({
    selector: 'app-bita-zen',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {

  @HostBinding('class.chromeless') chromeless = false;
  loading: boolean;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private navActionService: NavActionService) {
    this.router.events.subscribe((routerEvent: Event) => this.checkRouterEvent(routerEvent));

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.chromeless) {
        this.chromeless = params.chromeless;
      }
    });
  }

  checkRouterEvent(routerEvent: Event): any {
    if (routerEvent instanceof NavigationStart) {
      this.navActionService.isBusy(true);
    }

    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
        this.navActionService.isBusy(false);
    }
  }

}
