import {JsonObject, JsonProperty} from 'json2typescript';
import { EsgProductRestriction } from './esg-product-restriction.model';


@JsonObject('EsgAnalysisReport')
export class EsgAnalysisReport {
  @JsonProperty('productConflictsCount', Number)
  productConflictsCount: number = undefined;

  @JsonProperty('esgPreferenceConflictsCount', Number)
  esgPreferenceConflictsCount: number = undefined;

  @JsonProperty('esgEnvironmentalPillarPreferenceConflictsCount', Number)
  esgEnvironmentalPillarPreferenceConflictsCount: number = undefined;

  @JsonProperty('esgSocialPillarPreferenceConflictsCount', Number)
  esgSocialPillarPreferenceConflictsCount: number = undefined;

  @JsonProperty('esgGovernancePillarPreferenceConflictsCount', Number)
  esgGovernancePillarPreferenceConflictsCount: number = undefined;

  @JsonProperty('conflictedProductRestrictions', [EsgProductRestriction])
  productRestrictions: Array<EsgProductRestriction> = undefined;
}
