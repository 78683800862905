import {Any, JsonObject, JsonProperty} from 'json2typescript';
// import { Any } from 'json2typescript/src/json2typescript/any';

@JsonObject('MetaData')
export class MetaData {

  @JsonProperty('header', String)
  header: string = undefined;

  @JsonProperty('label', String)
  label: string = undefined;

  @JsonProperty('wrapText', Boolean, true)
  wrapText = true;

  @JsonProperty('columnWidth', String, true)
  columnWidth: string = undefined;
}

@JsonObject('BmidHoldingListItem')
export class BmidHoldingListItem {

  @JsonProperty('name', String)
  name: string = undefined;

  @JsonProperty('clientId', String)
  clientId: string = undefined;

  @JsonProperty('accountId', String)
  accountId: string = undefined;

  @JsonProperty('reference', Any, true)
  reference: any = undefined;

  @JsonProperty('value', Any, true)
  value: any = undefined;

  @JsonProperty('deviation', Any, true)
  deviation: String = undefined;

  @JsonProperty('creationDate', String, true)
  creationDate: string = undefined;

  @JsonProperty('keTarget', String, true)
  keTarget: string = undefined;

  @JsonProperty('additionalInfo', String, true)
  additionalInfo: string = undefined;

  constructor() { }

  public getValueByProperty(property): String {
    return this[property];
  }
}

@JsonObject('BmidHoldingListProperty')
export class BmidHoldingListProperty {

  @JsonProperty('clickable', Boolean)
  clickable: boolean = undefined;

  @JsonProperty('redirectPage', String)
  redirectPage: string = undefined;
}

@JsonObject('BmidHoldingList')
export class BmidHoldingList {

  monitorFlagType: string;

  @JsonProperty('meta', [MetaData])
  meta: MetaData[] = undefined;

  @JsonProperty('values', [BmidHoldingListItem])
  values: BmidHoldingListItem[] = undefined;

  @JsonProperty('property', BmidHoldingListProperty)
  property: BmidHoldingListProperty = undefined;

  constructor() {}
}
