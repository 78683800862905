import { Action } from '@ngrx/store';

import * as MonitorFlagTypeActions from './monitor-flag-type.actions';
import { MonitorFlagType } from '../../entity/monitor-flag-type.model';
import { MonitorFlagTypeState } from './monitor-flag-type.state';

export type MonitorFlagTypeAction = MonitorFlagTypeActions.All;
export const initialState: MonitorFlagTypeState = { monitorFlagTypes: new Array<MonitorFlagType>() };

export function monitorFlagTypeReducer(state: MonitorFlagTypeState = initialState, action: MonitorFlagTypeAction): MonitorFlagTypeState {
  switch (action.type) {

    case MonitorFlagTypeActions.GET_ALL_MONITOR_FLAG_TYPES_SUCCESS: {
      return { ...state, ...{monitorFlagTypes : action.allMonitorFlagTypes} };
    }

    case MonitorFlagTypeActions.GET_ALL_MONITOR_FLAG_TYPES_ERROR: {
      console.error('Error caught while loading monitor flag types', action.error);
      return {...initialState};
    }

    default :
        return state;
  }

}

