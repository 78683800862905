import { Action } from '@ngrx/store';

import { SearchResult } from '../../../entity/search-result.model';
import { SearchCriteriaRequest } from '../../../../search/entity/search-criteria-request.model';

export const GET_SEARCH_RESULTS = '[SearchResults] GET_SEARCH_RESULTS';
export const GET_SEARCH_RESULTS_SUCCESS = '[SearchResults]  GET_SEARCH_RESULTS_SUCCESS';
export const GET_SEARCH_RESULTS_ERROR = '[SearchResults] GET_SEARCH_RESULTS_ERROR';

export const GET_SEARCH_TERM_RESULTS = '[SearchResults] GET_SEARCH_TERM_RESULTS';
export const GET_SEARCH_TERM_RESULTS_SUCCESS = '[SearchResults]  GET_SEARCH_TERM_SUCCESS';
export const GET_SEARCH_TERM_RESULTS_ERROR = '[SearchResults] GET_SEARCH_TERM_RESULTS_ERROR';

export class GetSearchResults implements Action {
	readonly type = GET_SEARCH_RESULTS;
	constructor(public searchCriteria: SearchCriteriaRequest) {}
}

export class GetSearchResultsSuccess implements Action {
	readonly type = GET_SEARCH_RESULTS_SUCCESS;
	constructor(public searchResults: SearchResult[]) {}
}

export class GetSearchResultsError implements Action {
	readonly type = GET_SEARCH_RESULTS_ERROR;
	constructor(public error: any) {}
}

export class GetSearchTermResults implements Action {
	readonly type = GET_SEARCH_TERM_RESULTS;
	constructor(public searchTerm: string) {}
}

export class GetSearchTermResultsSuccess implements Action {
	readonly type = GET_SEARCH_TERM_RESULTS_SUCCESS;
	constructor(public searchResults: SearchResult[]) {}
}

export class GetSearchTermResultsError implements Action {
	readonly type = GET_SEARCH_TERM_RESULTS_ERROR;
	constructor(public error: any) {}
}

export type All = GetSearchResults | GetSearchResultsSuccess | GetSearchResultsError
								| GetSearchTermResults | GetSearchTermResultsSuccess | GetSearchTermResultsError;

