import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JsonConvert } from 'json2typescript';
import { saveAs as importedSaveAs } from 'file-saver';
import { JsonConvertService } from '../../shared/json-convert/json-convert.service';
import { ClientPpp } from '../entity/client-ppp.model';
import { environment } from '../../../environments/environment';
import { map, first } from 'rxjs/operators';
import { ClientPppReport, ClientPppBenchmarkGroup, ClientPppReportGroup } from '../entity/client-ppp-report.model';

@Injectable()
export class ClientPppService {
  private jsonConverter: JsonConvert;

  constructor(private httpClient: HttpClient, private jsonConvertService: JsonConvertService) {
    this.jsonConverter =  this.jsonConvertService.getJsonConverter();
  }

  getClientPpp(clientId: string, accountId: string, period?: string): Observable<ClientPpp> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/ppp/client/${clientId}/account/${encodeURIComponent(accountId)}${period ? '/' + period : ''}`)
      .pipe(map(response => this.jsonConverter.deserializeObject(response, ClientPpp)));
  }

  getClientPppReports(): Observable<ClientPppReport> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/ppp/reportConfig`)
      .pipe(map(response => this.jsonConverter.deserializeObject(response, ClientPppReport)));
  }

  getClientPppReport(clientId: string, accountId: string, report: ClientPppReportGroup, benchmark: ClientPppBenchmarkGroup,
    downloadEnabled: boolean) {
    const filename = `${report.reportGroupName}-${benchmark.benchmarkGroupName}.pdf`.replace(/[^a-z0-9]/gi, '_').toLowerCase();

    // tslint:disable-next-line:max-line-length
    this.httpClient.get(`${environment.serverUrl}/restapi/service/ppp/client/${clientId}/account/${encodeURIComponent(accountId)}/report/${report.reportGroupId}/benchmark/${benchmark.benchmarkGroupId}`, { withCredentials: true, responseType: 'blob' as 'json', observe: 'response' }).pipe(
      first(),
      map((response: HttpResponse<Blob>) => {
        return response;
      })).subscribe(res => {
        if (downloadEnabled) {
          importedSaveAs(res.body, filename);
        } else {
          const url = window.URL.createObjectURL(res.body);
          window.open(url, '_blank');
        }
      });
  }
}
