import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('WidgetIssue')
export class WidgetIssue {

    @JsonProperty('id', Number)
    id: number = undefined;

    @JsonProperty('monitorFlagName', String)
    monitorFlagName: string = undefined;

    @JsonProperty('monitorFlagType', String)
    monitorFlagType: string = undefined;

    @JsonProperty('holdingName', String)
    holdingName: string = undefined;

    @JsonProperty('holdingValue', Number)
    holdingValue: number = undefined;

    constructor() {}

 }
