import { ColumnHeaderVisiblePipe } from './pipes/column-header-visible.pipe';
import { SettingsService } from './../core/settingsService.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OwlModule } from 'ngx-owl-carousel';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AlertModule } from 'ngx-bootstrap/alert';

import { DrawerComponent } from './drawer/drawer.component';
import { WidgetDrawerComponent } from './widget-drawer/widget-drawer.component';
import { WidgetComponent } from './widgets/widget/widget.component';
import { WidgetAlertsComponent } from './widgets/widget-alerts/widget-alerts.component';
import { WidgetModelPickerComponent } from './widgets/widget-model-picker/widget-model-picker.component';
import { WidgetDialsComponent } from './widgets/widget-dials/widget-dials.component';
import { WidgetDialComponent } from './widgets/widget-dials/widget-dial/widget-dial.component';
import { WidgetChartsComponent } from './widgets/widget-charts/widget-charts.component';
import { WidgetChartComponent } from './widgets/widget-charts/widget-chart/widget-chart.component';
import { WidgetIssuesComponent } from './widgets/widget-issues/widget-issues.component';
import { WidgetProfileComponent } from './widgets/widget-profile/widget-profile.component';
import { WidgetMonitorComponent } from './widgets/widget-monitor/widget-monitor.component';
import { WidgetMonitorPanelComponent } from './widgets/widget-monitor/widget-monitor-panel/widget-monitor-panel.component';
import { WidgetActionsComponent } from './widgets/widget-actions/widget-actions.component';
import { WidgetRationaleComponent } from './widgets/widget-rationale/widget-rationale.component';
import { DataTableComponent } from './data-table/data-table.component';
import { CriterionComponent } from './criteria/criterion/criterion.component';
import { CriterionMultiComponent } from './criteria/criterion-multi/criterion-multi.component';
import { CriterionCrudComponent } from './criteria/criterion-crud/criterion-crud.component';
import { CriterionGroupComponent } from './criteria/criterion-group/criterion-group.component';
import { BaseFormComponent } from './base-form/base-form.component';
import { BmidHoldingService } from './bmid-holding-list/bmid-holding.service';
import { JsonConvertService } from './json-convert/json-convert.service';
import { MonitorFlagsPositionFilter } from './widgets/widget-monitor/monitor-flags-position-filter.pipe';
import { BmidHoldingListComponent } from './bmid-holding-list/bmid-holding-list.component';
import { KnownExceptionsService } from './account/account-known-exceptions/known-exceptions.service';
import { TabBarComponent } from './tab-bar/tab-bar.component';
import { SharedStoreModule } from './store/shared-store.module';
import { ClientPmpService } from './pmp/pmp.service';
import { ApprovalsService } from '../approvals/approvals.service';
import { SearchService } from '../search/search.service';
import { MonitorService } from '../monitor/monitor.service';
import { AppRouterService } from './router/app-router.service';
import { ListReducerPipe } from './util/pipe/list-reducer.pipe';
import { GetValuesPipe } from './util/pipe/get-values.pipe';
import { IconComponent } from './icon/icon.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { BitaModalWrapperComponent } from './modal/bita-modal/bita-modal-wrapper.component';
import { DebugPanelComponent } from './debug-panel/debug-panel.component';
import { InterfaceOptionsComponent } from './interface-options/interface-options.component';
import { DebounceDirective } from './util/directive/debounce.directive';
import { AddHoldingsComponent } from './add-holdings/add-holdings.component';
import { SearchModelAccountComponent } from './search-model-account/search-model-account.component';
import { AssetResultFilterPipe } from './util/pipe/asset-result-filter.pipe';
import { AccountResultFilterPipe } from './util/pipe/account-result-filter.pipe';
import { TextMaskModule } from 'angular2-text-mask';
import { BitaStatelessModalWrapperComponent } from './modal/bita-stateless-modal-wrapper/bita-stateless-modal-wrapper.component';
import { MomentModule } from 'ngx-moment';
import { AccountKnownExceptionsComponent } from './account/account-known-exceptions/account-known-exceptions.component';
import { MonitorPortfolioComponent } from './account/monitor-portfolio/monitor-portfolio.component';
import { AccountCurrentPortfolioComponent } from './account/account-current-portfolio/account-current-portfolio.component';
// tslint:disable-next-line:max-line-length
import { AddKnownExceptionModalComponent } from './account/account-known-exceptions/add-known-exception-modal/add-known-exception-modal.component';
// tslint:disable-next-line:max-line-length
import { KnownExceptionRequestDecisionModalComponent } from './account/account-known-exceptions/known-exception-request-decision-modal/known-exception-request-decision-modal.component';
import { KnownExceptionTypeTargetPipe } from './util/pipe/known-exception-type-target.pipe';
import { AccountService } from './service/account.service';
import { KnownExceptionDecisionSortPipe } from './util/pipe/known-exception-decision-sort.pipe';
import { TreeTableModule } from 'primeng/treetable';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TabViewModule } from 'primeng/tabview';
import { FileUploadModule } from 'primeng/fileupload';
import {InputSwitchModule} from 'primeng/inputswitch';
import { SharedModule as PrimeSharedModule } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { WidgetCashComponent } from './widgets/widget-cash/widget-cash.component';
import { GenericFormComponent } from './form/generic-form/generic-form.component';
import { AssetListService } from './pmp/asset-list/asset-list.service';
import { GenericFormService } from './form/generic-form.service';
import { GenericFormArrayComponent } from './form/generic-form-array/generic-form-array.component';
import { FormGroupComponent } from './form/form-group/form-group.component';
import { IndicatorComponent } from './indicator/indicator.component';
import { ConfirmLeaveComponent } from './modal/confirm-leave/confirm-leave.component';
import { ClientSummaryService } from '../clients/service/client-summary.service';
import { ClientAapService } from '../clients/service/client-aap.service';
import { ClientPppService } from '../clients/service/client-ppp.service';
import { AccountOverviewModalComponent } from './account-overview-modal/account-overview-modal.component';
import { GenericErrorMessageComponent } from './form/generic-error-message/generic-error-message.component';
import { ImportAssetComponent } from './import-asset/import-asset.component';
import { ImportAssetService } from './import-asset/import-asset.service';
import { EditRationaleComponent } from './edit-rationale/edit-rationale.component';
import { ToSelectItemPipe } from './util/pipe/to-select-item-pipe.pipe';
import { ExportService } from './export/export.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ClientFactComponent } from './client-fact/client-fact.component';
import { ClientHomeService } from './service/client-home.service';
import { AssetService } from './service/asset.service';
import { PmpComponent } from './pmp/pmp.component';
import { EsgThreePillarChartComponent } from './pmp/esg-three-pillar-chart/esg-three-pillar-chart.component';
import { EsgCo2ChartComponent } from './pmp/esg-co2-chart/esg-co2-chart.component';
import { AccountComponent } from './account/account.component';
import { MoveFlatAccountModalComponent } from './account/move-flat-account/move-flat-account-modal.component';
import { MoveMandateAccountModalComponent } from './account/move-mandate-account/move-mandate-account-modal.component';
import { AssetListComponent } from './pmp/asset-list/asset-list.component';
import { AssetComponent } from './pmp/asset-list/asset/asset.component';
import { TopAssetComponent } from './pmp/asset-list/top-asset/top-asset.component';
import { SubAssetComponent } from './pmp/asset-list/sub-asset/sub-asset.component';
import { HoldingAssetComponent } from './pmp/asset-list/holding-asset/holding-asset.component';
import { BreakdownAssetComponent } from './pmp/asset-list/breakdown-asset/breakdown-asset.component';
import { OrderAssetComponent } from './pmp/asset-list/order-asset/order-asset.component';
import { SaveScenarioModalComponent } from './pmp/save-scenario-modal/save-scenario-modal.component';
import { TradeListModalComponent } from './pmp/trade-list-modal/trade-list-modal.component';
import { TradeOrderModalComponent } from './pmp/trade-order-modal/trade-order-modal.component';
import { AssetColumnsModalComponent } from './pmp/asset-columns-modal/asset-columns-modal.component';
import { InvestDivestModalComponent } from './pmp/invest-divest-modal/invest-divest-modal.component';
import { ColumnPositionPipe } from './pipes/column-position.pipe';
import { ColumnWidthPipe } from './pipes/column-width.pipe';
import { ColumnSpanPipe } from './pipes/column-span.pipe';
import { ColumnVisiblePipe } from './pipes/column-visible.pipe';
import { ColumnStickyPipe } from './pipes/column-sticky.pipe';
import { ColumnFirstPipe } from './pipes/column-first.pipe';
import { ColumnLastPipe } from './pipes/column-last.pipe';
import { AddIprFileNotesModalComponent } from './pmp/add-ipr-file-notes-modal/add-ipr-file-notes-modal.component';
import { OptimisationModalComponent } from './pmp/optimisation-modal/optimisation-modal.component';
import { RadarComponent } from './radar/radar.component';
import { VertBarChartComponent } from './vert-bar-chart/vert-bar-chart.component';
import { BuyListService } from '../team-buylist/buy-list.service';
import { BuyListKeFormComponent } from './buy-list-ke-form/buy-list-ke-form.component';
import { ColumnLabelPipe } from './pipes/column-label';
import { ColumnHeaderLabelPipe } from './pipes/column-header-label';
// tslint:disable-next-line:max-line-length
import { AccountKnownExceptionDetailsComponent } from './account/account-known-exceptions/account-known-exception-details/account-known-exception-details.component';
import { AddFlatAccountModalComponent } from './add-flat-account-modal/add-flat-account-modal.component';
import { EsgBarComponent } from './esg-bar/esg-bar.component';
import { SunburstComponent } from '../clients/d3-charts/sunburst/sunburst.component';
import { EsgTopBarComponent } from './pmp/esg-top-bar/esg-top-bar.component';
import { Pillar3Component } from '../clients/d3-charts/pillar3/pillar3.component';
import { ExposuresComponent } from '../clients/d3-charts/exposures/exposures.component';
import { PreferenceComponent } from '../clients/d3-charts/preference/preference.component';
import { ColumnProductConflictComponent } from './pmp/column-product-conflict/column-product-conflict.component';
import { ColumnFactorBarcodeComponent } from './pmp/column-factor-barcode/column-factor-barcode.component';
import { ColumnConflictMasterFlagComponent } from './pmp/column-conflict-master-flag/column-conflict-master-flag.component';
import { ColumnEsgComponent } from './pmp/column-esg/column-esg.component';
import { ColumnCo2Component } from './pmp/column-co2/column-co2.component';
import { EventNotificationService } from './service/event-notification.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { EsgCopyrightComponent } from './esg-copyright/esg-copyright.component';
import { RiskCategoryTooltipPipe } from './pipes/risk-category-tooltip.pipe';
import { ReadOnlyAssetListComponent } from './read-only-portfolio/read-only-asset-list/read-only-asset-list.component';
import { ReadOnlyTopAssetComponent } from './read-only-portfolio/read-only-asset-list/read-only-top-asset/read-only-top-asset.component';
import { ReadOnlySubAssetComponent } from './read-only-portfolio/read-only-asset-list/read-only-sub-asset/read-only-sub-asset.component';
import { DashFilterComponent } from '../dash/dash-filter/dash-filter.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { GenerateAccessTokenComponent } from './account/generate-access-token/generate-access-token.component';
import { GetValuesAssetColumnFilterPipe } from './util/pipe/get-values-asset-column-filter.pipe';
import { GetAssetColumnCategoriesPipe } from './util/pipe/get-asset-column-categories.pipe';
import { SubstituteIfNullOrUndefinedPipe } from './util/pipe/substitute-if-null-or-undefined.pipe';
import { ClientTcfdService } from '../clients/client-tcfd/client-tcfd.service';
import { CircleStatusIndicatorComponent } from './ui-component/circle-status-indicator/circle-status-indicator.component';
import { StatusColorExtractorPipe } from './pipes/status-color-extractor.pipe';
import { ProgressBarComponent } from './ui-component/progress-bar/progress-bar.component';
import { NullOrUndefinedCheckPipe } from './util/pipe/null-or-undefined-check.pipe';
import { PmpClientAccountDetailsComponent } from './pmp/pmp-client-account-details/pmp-client-account-details.component';
import { RiskModelSelectionModalComponent } from './pmp/risk-model-selection-modal/risk-model-selection-modal.component';
import { DataModelService } from '../clients/service/data-model.service';
import { AlphaModelSelectionModalComponent } from './pmp/alpha-model-selection-modal/alpha-model-selection-modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedStoreModule,
    FontAwesomeModule,
    OwlModule,
    UiSwitchModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TextMaskModule,
    MomentModule,
    TooltipModule.forRoot(),
    AlertModule.forRoot(),
    TreeTableModule,
    InputSwitchModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
    TabViewModule,
    FileUploadModule,
    MessageModule,
    MessagesModule,
    PrimeSharedModule,
    ConfirmDialogModule,
    InlineSVGModule.forRoot()
  ],
  exports: [
    CommonModule,
    RouterModule,
    DrawerComponent,
    WidgetDrawerComponent,
    WidgetComponent,
    WidgetAlertsComponent,
    WidgetModelPickerComponent,
    WidgetDialsComponent,
    WidgetDialComponent,
    WidgetChartsComponent,
    WidgetChartComponent,
    WidgetIssuesComponent,
    WidgetProfileComponent,
    WidgetMonitorComponent,
    WidgetMonitorPanelComponent,
    WidgetActionsComponent,
    WidgetCashComponent,
    WidgetRationaleComponent,
    DataTableComponent,
    CriterionComponent,
    CriterionMultiComponent,
    CriterionCrudComponent,
    CriterionGroupComponent,
    BaseFormComponent,
    BmidHoldingListComponent,
    TabBarComponent,
    FontAwesomeModule,
    ListReducerPipe,
    GetValuesPipe,
    GetValuesAssetColumnFilterPipe,
    GetAssetColumnCategoriesPipe,
    IconComponent,
    FormsModule,
    UiSwitchModule,
    // ModalModule,
    BitaModalWrapperComponent,
    ReactiveFormsModule,
    DebugPanelComponent,
    BsDatepickerModule,
    InterfaceOptionsComponent,
    DebounceDirective,
    AddHoldingsComponent,
    SearchModelAccountComponent,
    AssetResultFilterPipe,
    AccountResultFilterPipe,
    TextMaskModule,
    BitaStatelessModalWrapperComponent,
    AccountKnownExceptionsComponent,
    MonitorPortfolioComponent,
    AccountCurrentPortfolioComponent,
    AddKnownExceptionModalComponent,
    KnownExceptionRequestDecisionModalComponent,
    KnownExceptionDecisionSortPipe,
    TreeTableModule,
    TableModule,
    InputSwitchModule,
    DropdownModule,
    MultiSelectModule,
    TabViewModule,
    FileUploadModule,
    MessageModule,
    MessagesModule,
    PrimeSharedModule,
    GenericFormComponent,
    GenericFormArrayComponent,
    FormGroupComponent,
    IndicatorComponent,
    TooltipModule,
    AlertModule,
    AccountOverviewModalComponent,
    GenericErrorMessageComponent,
    ImportAssetComponent,
    EditRationaleComponent,
    ToSelectItemPipe,
    ConfirmDialogModule,
    ClientFactComponent,
    PmpComponent,
    EsgThreePillarChartComponent,
    EsgCo2ChartComponent,
    AccountComponent,
    MoveFlatAccountModalComponent,
    MoveMandateAccountModalComponent,
    AssetListComponent,
    RiskModelSelectionModalComponent,
    AssetComponent,
    TopAssetComponent,
    SubAssetComponent,
    HoldingAssetComponent,
    BreakdownAssetComponent,
    OrderAssetComponent,
    SaveScenarioModalComponent,
    TradeListModalComponent,
    TradeOrderModalComponent,
    AssetColumnsModalComponent,
    InvestDivestModalComponent,
    ColumnPositionPipe,
    ColumnWidthPipe,
    ColumnSpanPipe,
    ColumnVisiblePipe,
    ColumnHeaderVisiblePipe,
    ColumnStickyPipe,
    ColumnFirstPipe,
    ColumnLastPipe,
    AddIprFileNotesModalComponent,
    OptimisationModalComponent,
    BuyListKeFormComponent,
    ColumnLabelPipe,
    ColumnHeaderLabelPipe,
    AccountKnownExceptionDetailsComponent,
    ReadOnlyAssetListComponent,
    ReadOnlyTopAssetComponent,
    ReadOnlySubAssetComponent,
    SunburstComponent,
    EsgTopBarComponent,
    Pillar3Component,
    ExposuresComponent,
    PreferenceComponent,
    ColumnCo2Component,
    EsgCopyrightComponent,
    RiskCategoryTooltipPipe,
    DashFilterComponent,
    InlineSVGModule,
    SubstituteIfNullOrUndefinedPipe,
    CircleStatusIndicatorComponent,
    StatusColorExtractorPipe,
    ProgressBarComponent,
    NullOrUndefinedCheckPipe
  ],
  declarations: [
    RadarComponent,
    DrawerComponent,
    WidgetDrawerComponent,
    WidgetComponent,
    WidgetAlertsComponent,
    WidgetModelPickerComponent,
    WidgetDialsComponent,
    WidgetDialComponent,
    WidgetChartsComponent,
    WidgetChartComponent,
    WidgetIssuesComponent,
    WidgetProfileComponent,
    WidgetMonitorComponent,
    WidgetMonitorPanelComponent,
    WidgetActionsComponent,
    WidgetCashComponent,
    WidgetRationaleComponent,
    DataTableComponent,
    CriterionComponent,
    CriterionMultiComponent,
    CriterionCrudComponent,
    CriterionGroupComponent,
    BaseFormComponent,
    MonitorFlagsPositionFilter,
    BmidHoldingListComponent,
    TabBarComponent,
    ListReducerPipe,
    GetValuesPipe,
    GetValuesAssetColumnFilterPipe,
    GetAssetColumnCategoriesPipe,
    IconComponent,
    BitaModalWrapperComponent,
    DebugPanelComponent,
    InterfaceOptionsComponent,
    DebugPanelComponent,
    DebounceDirective,
    AddHoldingsComponent,
    SearchModelAccountComponent,
    AssetResultFilterPipe,
    AccountResultFilterPipe,
    BitaStatelessModalWrapperComponent,
    AccountKnownExceptionsComponent,
    MonitorPortfolioComponent,
    AccountCurrentPortfolioComponent,
    AddKnownExceptionModalComponent,
    KnownExceptionRequestDecisionModalComponent,
    KnownExceptionTypeTargetPipe,
    KnownExceptionDecisionSortPipe,
    GenericFormComponent,
    GenericFormArrayComponent,
    FormGroupComponent,
    IndicatorComponent,
    ConfirmLeaveComponent,
    AccountOverviewModalComponent,
    GenericErrorMessageComponent,
    ImportAssetComponent,
    EditRationaleComponent,
    ToSelectItemPipe,
    ClientFactComponent,
    PmpComponent,
    EsgThreePillarChartComponent,
    EsgCo2ChartComponent,
    AccountComponent,
    MoveFlatAccountModalComponent,
    MoveMandateAccountModalComponent,
    AssetListComponent,
    RiskModelSelectionModalComponent,
    AlphaModelSelectionModalComponent,
    AssetComponent,
    TopAssetComponent,
    SubAssetComponent,
    HoldingAssetComponent,
    BreakdownAssetComponent,
    OrderAssetComponent,
    SaveScenarioModalComponent,
    TradeListModalComponent,
    TradeOrderModalComponent,
    AssetColumnsModalComponent,
    InvestDivestModalComponent,
    ColumnPositionPipe,
    ColumnWidthPipe,
    ColumnSpanPipe,
    ColumnVisiblePipe,
    ColumnHeaderVisiblePipe,
    ColumnStickyPipe,
    ColumnFirstPipe,
    ColumnLastPipe,
    AddIprFileNotesModalComponent,
    OptimisationModalComponent,
    VertBarChartComponent,
    AddIprFileNotesModalComponent,
    BuyListKeFormComponent,
    ColumnLabelPipe,
    ColumnHeaderLabelPipe,
    AccountKnownExceptionDetailsComponent,
    AddFlatAccountModalComponent,
    ReadOnlyAssetListComponent,
    ReadOnlyTopAssetComponent,
    ReadOnlySubAssetComponent,
    EsgBarComponent,
    SunburstComponent,
    EsgTopBarComponent,
    Pillar3Component,
    ExposuresComponent,
    PreferenceComponent,
    ColumnProductConflictComponent,
    ColumnFactorBarcodeComponent,
    ColumnConflictMasterFlagComponent,
    ColumnEsgComponent,
    ColumnCo2Component,
    EsgCo2ChartComponent,
    EsgCopyrightComponent,
    RiskCategoryTooltipPipe,
    DashFilterComponent,
    GenerateAccessTokenComponent,
    SubstituteIfNullOrUndefinedPipe,
    CircleStatusIndicatorComponent,
    StatusColorExtractorPipe,
    ProgressBarComponent,
    NullOrUndefinedCheckPipe,
    PmpClientAccountDetailsComponent
  ],
  providers: [
    BmidHoldingService,
    JsonConvertService,
    KnownExceptionsService,
    ClientPmpService,
    ApprovalsService,
    SearchService,
    MonitorService,
    AppRouterService,
    AccountService,
    GenericFormService,
    AccountService,
    AssetListService,
    AssetListService,
    ClientSummaryService,
    ClientAapService,
    ClientPppService,
    ImportAssetService,
    ExportService,
    ConfirmationService,
    ClientHomeService,
    AssetService,
    DataModelService,
    { provide: BsLocaleService, useFactory: localeService, deps: [ SettingsService ] },
    BuyListService,
    EventNotificationService,
    ColumnHeaderVisiblePipe,
    ClientTcfdService
  ]
})
export class SharedModule {
}

export function localeService(settingService: SettingsService): BsLocaleService {
  const bsLocaleService = new BsLocaleService();
  bsLocaleService.use(settingService.getLocale());
  return bsLocaleService;
}
