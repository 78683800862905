import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable ,  of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ClientHomeService } from '../../service/client-home.service';
import * as ClientAccountDetailsActions from './client-account-details.actions';

export type ClientAccountDetailsAction = ClientAccountDetailsActions.All;

@Injectable()
export class ClientAccountDetailsEffects {

	constructor(private actions$: Actions,
							private clientHomeService: ClientHomeService) {
	}

	getClientAccountDetails: Observable<ClientAccountDetailsAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(ClientAccountDetailsActions.GET_CLIENT_ACCOUNT_DETAILS),
			switchMap((client: ClientAccountDetailsActions.GetClientAccountDetails) =>
				this.clientHomeService.getClientAccountDetails(client.clientId).pipe(
			map(post => new ClientAccountDetailsActions.GetClientAccountDetailsSuccess(post)),
      catchError((error) => of(new ClientAccountDetailsActions.GetClientAccountDetailsError(error)))
    ))));

	getClientAccountDetailsSummary: Observable<ClientAccountDetailsAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(ClientAccountDetailsActions.GET_CLIENT_ACCOUNT_DETAILS_SUMMARY),
			switchMap((client: ClientAccountDetailsActions.GetClientAccountDetailsSummary) =>
				this.clientHomeService.getClientAccountDetailsSummary(client.clientId).pipe(
			map(post => new ClientAccountDetailsActions.GetClientAccountDetailsSummarySuccess(post)),
      catchError((error) => of(new ClientAccountDetailsActions.GetClientAccountDetailsSummaryError(error)))
    ))));

}
