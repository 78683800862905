import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

import { AccountService } from '../../../service/account.service';
import * as AccountCurrentPortfolioActions from './account-current-portfolio.actions';
import { Store } from '@ngrx/store';
import * as ClientAccountDetailsActions from '../../../../shared/store/client-account-details/client-account-details.actions';
import * as currentPortfolioActions from '../../client-account/account-current-portfolio/account-current-portfolio.actions';
import * as monitorPortfolioActions from '../../client-account/monitor-portfolio/monitor-portfolio.actions';
import { NavActionService } from '../../../../core/navigation-floating/nav-action.service';
import { AccountCurrentPortfolioService } from '../../../account/account-current-portfolio/account-current-portfolio.service';
import { AppRouterService } from '../../../router/app-router.service';

export type AccountCurrentPortfolioAction = AccountCurrentPortfolioActions.All;

@Injectable()
export class AccountCurrentPortfolioEffects {

  constructor(private actions$: Actions,
              private store: Store<any>,
              private clientAccountService: AccountService,
              private navActionService: NavActionService,
              private accountCurrentPortfolioService: AccountCurrentPortfolioService,
              private routerService: AppRouterService) {
  }

  getAccountCurrentPortfolio: Observable<AccountCurrentPortfolioAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountCurrentPortfolioActions.GET_ACCOUNT_CURRENT_PORTFOLIO),
      switchMap((action: AccountCurrentPortfolioActions.GetAccountCurrentPortfolio) =>
      this.clientAccountService.getCurrentPortfolio(action.clientId, action.accountId, action.lookThroughEnabled).pipe(
        map(post => {
          this.accountCurrentPortfolioService.updateLastSavedPortfolioModel(post);
          return new AccountCurrentPortfolioActions.GetAccountCurrentPortfolioSuccess(post);
        }),
        catchError((error) => of(new AccountCurrentPortfolioActions.GetAccountCurrentPortfolioError(error)))
      ))
  ));

    getAccountOverviewConfig: Observable<AccountCurrentPortfolioAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountCurrentPortfolioActions.GET_ACCOUNT_OVERVIEW_CONFIG),
      switchMap((action: AccountCurrentPortfolioActions.GetAccountOverviewConfig) =>
      this.clientAccountService.getAccountOverviewConfig(action.clientId, action.accountId).pipe(
        map(post => new AccountCurrentPortfolioActions.GetAccountOverviewConfigSuccess(post)),
        catchError((error) => of(new AccountCurrentPortfolioActions.GetAccountOverviewConfigError(error)))
      ))
    ));

    createMandate: Observable<AccountCurrentPortfolioAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountCurrentPortfolioActions.CREATE_MANDATE_PORTFOLIO),
      switchMap((action: AccountCurrentPortfolioActions.CreateMandatePortfolio) =>
      this.clientAccountService.createMandate(action.clientId, action.accountOverview).pipe(
        map(post => {

          this.store.dispatch(new ClientAccountDetailsActions.GetClientAccountDetails(action.clientId));
          this.navActionService.addMessage('Success', 'New Bmid created successfully', 'success');
          return new AccountCurrentPortfolioActions.CreateMandatePortfolioSuccess();
        }),
        catchError((error) => {
          this.navActionService.addMessage('Error', 'An error occurred while saving', 'error');
          return of(new AccountCurrentPortfolioActions.CreateMandatePortfolioError(error));
        })
      ))
    ));

    updateMandate: Observable<AccountCurrentPortfolioAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountCurrentPortfolioActions.UPDATE_MANDATE_PORTFOLIO),
      switchMap((action: AccountCurrentPortfolioActions.UpdateMandatePortfolio) =>
      this.clientAccountService.updateMandate(action.clientId, action.accountId, action.current, action.update).pipe(
        map(post => {
          if (action.current.externalId !== action.update.externalId) {
            this.navActionService.addMessage('Success', 'Your request was saved successfully', 'success');
            this.routerService.clientAccountPage(action.clientId, action.update.externalId),
            error => {
             const errorMessage = error.error ? error.error.message : 'An error occurred';
             this.navActionService.addMessage('Error', errorMessage, 'error');
            }
          }
          
          this.store.dispatch(new currentPortfolioActions.GetAccountCurrentPortfolio(action.clientId, action.accountId,
             action.isLookThroughEnabled));
             this.store.dispatch(new monitorPortfolioActions.GetMonitorPortfolio(action.clientId, action.accountId));
             this.navActionService.addMessage('Success', 'Your request was saved successfully', 'success');
          return new AccountCurrentPortfolioActions.UpdateMandatePortfolioSuccess();
        }),
        catchError((error) => {
          this.navActionService.addMessage('Error', 'An error occurred while saving', 'error');
          return of(new AccountCurrentPortfolioActions.UpdateMandatePortfolioError(error));
        })
      ))
    ));

    saveCurrentPortfolio: Observable<AccountCurrentPortfolioAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountCurrentPortfolioActions.SAVE_ACCOUNT_CURRENT_PORTFOLIO),
      switchMap((action: AccountCurrentPortfolioActions.SaveAccountCurrentPortfolio) =>
      this.clientAccountService.saveCurrentPortfolio(action.accountCurrentPortfolio).pipe(
        map(post => {
          this.accountCurrentPortfolioService.updateLastSavedPortfolioModel(post);
          this.navActionService.addMessage('Success', 'Your request was saved successfully', 'success');
          return new AccountCurrentPortfolioActions.SaveAccountCurrentPortfolioSuccess(post);
        }),
        catchError((error) => {
          this.navActionService.addMessage('Error', 'An error occurred while saving', 'error');
          return of(new AccountCurrentPortfolioActions.SaveAccountCurrentPortfolioError(error));
        })
      ))
    ));

    updateAccountId: Observable<AccountCurrentPortfolioAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountCurrentPortfolioActions.UPDATE_ACCOUNT_ID),
      switchMap((action: AccountCurrentPortfolioActions.UpdateAccountId) =>
      this.clientAccountService.updateAccountId(action.clientId, action.accountId, action.newAccountId).pipe(
        map(post => {
          this.routerService.clientAccountPage(action.clientId, action.newAccountId);
          this.navActionService.addMessage('Success', 'Your request was saved successfully', 'success');
          return new AccountCurrentPortfolioActions.UpdateAccountIdSuccess();
        }),
        catchError((error) => {
          this.navActionService.addMessage('Error', 'An error occurred while saving', 'error');
          return of(new AccountCurrentPortfolioActions.UpdateAccountIdError(error));
        })
      ))
    ));

    updateFlatAccount: Observable<AccountCurrentPortfolioAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountCurrentPortfolioActions.UPDATE_FLAT_ACCOUNT),
      switchMap((action: AccountCurrentPortfolioActions.UpdateFlatAccount) =>
      this.clientAccountService.updateFlatAccount(action.clientId, action.accountId, action.update).pipe(
        map(post => {
          if (action.update.externalId) {
            this.routerService.clientAccountPage(action.clientId, action.update.externalId);
          }
          if (action.update.name) {
            action.accountCurrentPortfolio.name = action.update.name;
          }
          this.navActionService.addMessage('Success', 'Account successfully updated', 'success');
          return new AccountCurrentPortfolioActions.UpdateFlatAccountSuccess(action.accountCurrentPortfolio);
        }),
        catchError((error) => {
          this.navActionService.addMessage('Error', 'An error occurred while updating account', 'error');
          return of(new AccountCurrentPortfolioActions.UpdateFlatAccountError(error));
        })
      ))
    ));

    addFlatAccount: Observable<AccountCurrentPortfolioAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountCurrentPortfolioActions.ADD_FLAT_ACCOUNT),
      switchMap((action: AccountCurrentPortfolioActions.AddFlatAccount) =>
      this.clientAccountService.addFlatAccount(action.clientId, action.accountId, action.overview).pipe(
        map(post => {
          this.routerService.clientAccountPage(action.clientId, action.overview.externalId);
          this.store.dispatch(new ClientAccountDetailsActions.GetClientAccountDetails(action.clientId));
          this.navActionService.addMessage('Success', 'New account created successfully', 'success');
          return new AccountCurrentPortfolioActions.AddFlatAccountSuccess();
        }),
        catchError((error) => {
          this.navActionService.addMessage('Error', 'An error occurred while creating account', 'error');
          return of(new AccountCurrentPortfolioActions.AddFlatAccountError(error));
        })
      ))
    ));

}
