import {JsonObject, JsonProperty} from 'json2typescript';

export const KEY_FLAG_MONITOR_WIDGET = 'keyFlagMonitorWidget';
export const NON_KEY_FLAG_MONITOR_WIDGET = 'nonkeyFlagMonitorWidget';
export const DIAL_WIDGET = 'dialWidget';

@JsonObject('WidgetMonitorFlagPosition')
export class WidgetMonitorFlagPosition {

	@JsonProperty('type', String)
	type: string = undefined;

	@JsonProperty('widgets', [String])
	widgets: Array<string> = undefined;

}
