import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import { AnalyticsService, AnalyticsType } from '../../analytics/analytics.service';
import * as AnalyticsActions from './analytics.actions';
import { map, catchError, switchMap } from 'rxjs/operators';

export type AnalyticsAction = AnalyticsActions.All;

@Injectable()
export class AnalyticsEffects {

	constructor(private actions$: Actions,
							private analyticsService: AnalyticsService) {
	}

	getFactAnalytics: Observable<AnalyticsAction> = createEffect(() => 
	this.actions$.pipe(
    ofType(AnalyticsActions.GET_ANALYTICS_FACT),
		switchMap((a: AnalyticsActions.GetAnalyticsFact) => this.analyticsService.getAnalytics(a.pcm, AnalyticsType.FACT).pipe(
      map(fact => new AnalyticsActions.GetAnalyticsFactSuccess(fact)),
      catchError((error) => of(new AnalyticsActions.GetAnalyticsFactError(error)))
    ))));
}
