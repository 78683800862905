export function mockAssetAnalytics() {
    return [
      {
        // this id is only for mock api
        'id': 1,
        'modelDetails': [{
            'key': 'portfolioName',
            'label': 'port Name',
            'value': 'School fee',
            'metaData': null
          },
          {
            'key': 'team',
            'label': 'Office/Team',
            'value': 'MANCHESTER',
            'metaData': {
              'account_id': 21487,
              'account_name': 'P0000709405',
              'team_id': 39,
              'team_name': 'MANCHESTER',
              'team_client_id': 99181,
              'team_client_displayId': 'BLCID000027',
              'team_portfolioId': 'BLMID000027'
            }
          }, {
            'key': 'portfolioManager',
            'label': 'Portfolio Manager',
            'value': 'C0000307790',
            'metaData': null
          }, {
            'key': 'accountType',
            'label': 'Portfolio Type',
            'value': 'General Investment',
            'metaData': null
          }, {
            'key': 'serviceCategory',
            'label': 'Service Category',
            'value': 'Discretionary',
            'metaData': null
          }, {
            'key': 'riskCategory',
            'label': 'Risk Category',
            'value': 'CR',
            'metaData': null
          }
        ],

        'issues': [{
            'id': 2,
            'monitorFlagName': 'Concentration Stock',
            'monitorFlagType': 'holdingExposureOutlier',
            'holdingName': 'BMO REAL ESTATE ORD GBP0.01',
            'holdingValue': 54.05
          }, {
            'id': 1,
            'monitorFlagName': 'Concentration Stock',
            'monitorFlagType': 'holdingExposureOutlier',
            'holdingName': 'AVIVA ORD GBP0.25',
            'holdingValue': 43.27
          }, {
            'id': 3,
            'monitorFlagName': 'Off Research List',
            'monitorFlagType': 'buyListOutlier',
            'holdingName': 'AVIVA ORD GBP0.25',
            'holdingValue': 43.27
          }
        ],
        'alerts': [{
            'id': 1,
            'alertType': 'Single Holder Holding',
            'monitorFlagType': 'rareHoldings',
            'holdingName': 'FRANKLIN TEMPL INT GLOBAL BOND W INC NAV'
          }
        ],
        'portfolio': [
          {
            'classification': 'BOND:INTERNATIONAL FIXED INTEREST',
            // this need to be discussed and agreed
            'icon': 'non-buy-list',
            'assetName': 'BH Macro td UDS',
            'assetIdentifier': 'B1NPGV1',
            'assetCurrency': 'GBP',
            'assetValue': 6627,
            'assetWeight': '4.8%',
            'riskVolatility': '15.9%',
            'riskPreviousVolatility': '14.9%',
            'riskVolatilityChange': '1.00%',
            'riskMaxDrawDown': '24.9%',
            'riskCVaR': '10.6%',
            'riskNegativeReturns': '1.9%',
            'riskBeta': '0',
            'returnThreeMonth': '15.9%',
            'returnOneYear': '15.9%',
            'returnThreeYear': '15.9%',
            'returnSacFromMetadata': 'INTERNATIONAL FIXED INTEREST',
            'returnThreeMonthRelToAssetClass': '15.9%',
            'returnOneYearRelToAssetClass': '15.9%',
            'returnThreeYearRelToAssetClass': '15.9%',
          }
        ]
      }
   ];
}
