import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable , of} from 'rxjs';
import { ClientPmpService } from '../../pmp/pmp.service';
import * as IprFileNotesActions from './ipr-file-notes.actions';
import { map, catchError, switchMap } from 'rxjs/operators';

export type IprFileNotesAction = IprFileNotesActions.All;

@Injectable()
export class IprFileNotesEffects {

	constructor(private actions$: Actions,
							private clientSlwiService: ClientPmpService) {
	}

  getIprFileNotesConfig: Observable<IprFileNotesAction> = createEffect(() =>
   this.actions$.pipe(
    ofType(IprFileNotesActions.GET_IPR_FILE_NOTES_CONFIG),
    switchMap((a: IprFileNotesActions.GetIprFileNotesConfig) =>
      this.clientSlwiService.getIprFileNotesConfig(a.clientId, a.accountId).pipe(
        map(config => new IprFileNotesActions.GetIprFileNotesConfigSuccess(config)),
        catchError((error) => of(new IprFileNotesActions.GetIprFileNotesConfigError(error)))
      ))));
	}
