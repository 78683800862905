import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import {Moment} from 'moment';
import * as moment from 'moment';

@JsonConverter
export class DateConverter implements JsonCustomConvert<Moment> {
  serialize(date: Moment): any {
    return date.toDate();
  }

  deserialize(date: any): Moment {
    if (!moment(date).isValid() && date !== null && date.toString.length > 0) {
      console.log('> Unable to convert date: ' + date);
    }
    return moment(date);
  }
}
