import * as SearchResultFormActions from './search-result-form.actions';
import { SearchResultFormState } from './search-result-form.state';

export type SearchResultFormAction = SearchResultFormActions.All;
export const initialState: SearchResultFormState = {
    searchResultForm: undefined,
    loading: false
};

export function searchResultFormReducer(state: SearchResultFormState = initialState,
    action: SearchResultFormAction): SearchResultFormState {
    switch (action.type) {
      case SearchResultFormActions.GET_SEARCH_RESULT_FORM_CONFIG: {
      return { ...state, ...{ loading: true } };
    }

    case SearchResultFormActions.GET_SEARCH_RESULT_FORM_CONFIG_SUCCESS: {
      return {...state, ...{ searchResultForm: action.searchResultForm }, ...{ loading: false }};
    }

    case SearchResultFormActions.GET_SEARCH_RESULT_FORM_CONFIG_ERROR: {
      console.error('Error caught while loading search result form', action.error);
      return { ...initialState };
    }

    case SearchResultFormActions.GET_ASSETS_SEARCH_RESULT_FORM_CONFIG: {
      return { ...state, ...{ loading: true } };
    }

    case SearchResultFormActions.GET_ASSETS_SEARCH_RESULT_FORM_CONFIG_SUCCESS: {
      return {...state, ...{ searchResultForm: action.searchResultForm }, ...{ loading: false }};
    }

    case SearchResultFormActions.GET_ASSETS_SEARCH_RESULT_FORM_CONFIG_ERROR: {
      console.error('Error caught while loading search result form', action.error);
      return { ...initialState };
    }

    case SearchResultFormActions.GET_PMP_ACCOUNT_SEARCH_RESULT_FORM_CONFIG: {
      return { ...state, ...{ loading: true } };
    }

    case SearchResultFormActions.GET_PMP_ACCOUNT_SEARCH_RESULT_FORM_CONFIG_SUCCESS: {
      return {...state, ...{ searchResultForm: action.searchResultForm }, ...{ loading: false }};
    }

    case SearchResultFormActions.GET_PMP_ACCOUNT_SEARCH_RESULT_FORM_CONFIG_ERROR: {
      console.error('Error caught while loading search result form', action.error);
      return { ...initialState };
    }

    default:
      return state;
  }
}
