import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';
import { AuthenticationService } from '../../../auth/authentication.service';
import * as AuthenticationActions from './authentication.actions';
import { AppRouterService } from '../../../shared/router/app-router.service';
import { map, catchError, switchMap, tap } from 'rxjs/operators';

export type AuthenticationAction = AuthenticationActions.All;

@Injectable()
export class AuthenticationEffects {

	constructor(private actions$: Actions,
							private authenticationService: AuthenticationService,
							private router: Router,
							private appRouterService: AppRouterService) {
	}

	login: Observable<AuthenticationAction> = createEffect(() => 
  this.actions$.pipe(
    ofType(AuthenticationActions.REQUEST_LOGIN),
		switchMap((action: AuthenticationActions.RequestLogin) =>
			this.authenticationService.authenticate(action.userName, action.password).pipe(
        map(session => new AuthenticationActions.RequestLoginSuccess(session)),
        catchError(err => of(new AuthenticationActions.RequestLoginError(err.error.cause)))
      ))));

  logout: Observable<AuthenticationAction> = createEffect(() => 
  this.actions$
  .pipe(
    ofType(AuthenticationActions.REQUEST_LOGOUT),
    switchMap(() =>  this.authenticationService.logout().pipe(
      map(() => {
        this.appRouterService.dashboardPage();
        sessionStorage.clear();
        return new AuthenticationActions.RequestLogoutSuccess();
      }),
      catchError(err => of(new AuthenticationActions.RequestLogoutError(err)))
    )
		)
  ));

  	navigate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.GO),
      map((action: AuthenticationActions.Go) => action.payload),
      tap(({ path, query: queryParams, extras}) => this.router.navigate(path, { queryParams, ...extras }))
    ), { dispatch: false });
}
