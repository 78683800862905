import { JsonObject, JsonProperty } from 'json2typescript';
import { AssetColumnHeader } from './asset-column-header.model';
import { AssetColumnHeaderMapConverter } from '../json-convert/assetColumnHeaderMapConverter';

@JsonObject('AssetColumns')
export class AssetColumns {
  @JsonProperty('columnHeaders', AssetColumnHeaderMapConverter)
  columnHeaders: Map<string, AssetColumnHeader> = undefined;

  @JsonProperty('id', Number, true)
  id: number = undefined;

  @JsonProperty('name', String, true)
  name: string = undefined;

  @JsonProperty('message', String, true)
  message: string = undefined;

  @JsonProperty('isDefault', Boolean, true)
  isDefault: boolean = undefined;
}
