import * as BmidHoldingListActions from './bmid-holding-list.actions';
import { BmidHoldingList } from '../../entity/bmid-holding-list.model';
import { BmidHoldingListState } from './bmid-holding-list.state';

export type BmidHoldingListAction = BmidHoldingListActions.All;
const bmidList = new BmidHoldingList();
bmidList.meta = [];
bmidList.values = [];
export const initialState: BmidHoldingListState = { bmidHoldingList : bmidList, loading: false};

export function bmidHoldingListReducer(state: BmidHoldingListState = initialState, action: BmidHoldingListAction): BmidHoldingListState {
  switch (action.type) {
    case BmidHoldingListActions.GET_BMID_HOLDING_LIST: {
      return {...initialState, ...{loading: true}};
    }

    case BmidHoldingListActions.GET_BMID_HOLDING_LIST_SUCCESS: {
     return {...state, ...{bmidHoldingList: action.bmidHoldingList}, ...{loading: false}};
    }

    default :
      return state;

  }

}
