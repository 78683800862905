import { JsonObject, JsonProperty } from 'json2typescript';
import { Currency } from './currency.model';
import { Asset } from './asset.model';
import { AccountDetails } from './portfolio-construction-model.model';
import { PortfolioLevel } from './portfolioLevel.enum';

@JsonObject('PmpAssetPortfolio')
export class AssetPortfolio {

	@JsonProperty('weight', Number)
	weight: number = undefined;

	@JsonProperty('shares', Number)
	shares: number = undefined;

	@JsonProperty('value', Number)
	value: number = undefined;

	@JsonProperty('yield', Number)
	yield: number = undefined;

	@JsonProperty('ctr', Number)
	ctr: number = undefined;

	@JsonProperty('mctr', Number)
	mctr: number = undefined;

	@JsonProperty('bookCost', Number)
	bookCost: number = undefined;
}

@JsonObject('AssetNode')
export abstract class AssetNode {

  @JsonProperty('type', String)
	type: string = undefined;

	@JsonProperty('asset', Asset)
	asset: Asset = undefined;

	@JsonProperty('currentPosition', AssetPortfolio)
  currentPosition: AssetPortfolio = undefined;

  @JsonProperty('reservedHolding', Boolean, true)
  reservedHolding = false;

  expanded = false;
}

@JsonObject('BreakdownHoldingDataElement')
export class BreakdownHoldingDataElement {

	@JsonProperty('compositeLabel', String)
	compositeLabel: string = undefined;

	@JsonProperty('accountName', String)
	accountName: string = undefined;

	@JsonProperty('accountId', Number)
	accountId: number = undefined;

	@JsonProperty('accountDescription', String, true)
	accountDescription: string = undefined;

	@JsonProperty('currentPosition', AssetPortfolio)
	currentPosition: AssetPortfolio = undefined;
}

@JsonObject('HoldingClassNode')
export class HoldingClassNode extends AssetNode {

	// TODO: should be ENUM
	// values S, M, ST, MT
	@JsonProperty('source', String)
	source: string = undefined;

	// should be a specific monitorFlagType
	@JsonProperty('flagIssue', String)
	flagIssue: string = undefined;

	@JsonProperty('flagIssueName', String)
	flagIssueName: string = undefined;

	@JsonProperty('excludedHolding', Boolean, true)
  excludedHolding: boolean = undefined;

  @JsonProperty('hasBuyListKnownException', Boolean, true)
  hasBuyListKnownException: boolean = undefined;

  @JsonProperty('buyListKnownExceptionStatus', String, true)
  buyListKnownExceptionStatus: string = undefined;

	// either account Level or for lookthrought
	@JsonProperty('breakdownNode', [BreakdownHoldingDataElement], true)
	breakdownNode: Array<BreakdownHoldingDataElement> = undefined;

  isNewHolding = false;

  getCalculatedValue(): any {
    let total = 0;
		for (const breakdownNode of this.breakdownNode) {
			total += breakdownNode.currentPosition.value;
		}
		this.currentPosition.value = total;
		return total;
  }
}

@JsonObject('SubAssetClassNode')
export class SubAssetClassNode extends AssetNode {

  visibilityLevel: PortfolioLevel = PortfolioLevel.TAC;

	@JsonProperty('holdingNode', [HoldingClassNode])
  holdingNode: Array<HoldingClassNode> = undefined;

  getCalculatedValue(): any {
    let total = 0;
		for (const holdingNode of this.holdingNode) {
			total += holdingNode.getCalculatedValue();
		}
		this.currentPosition.value = total;
		return total;
  }

}

@JsonObject('AssetClassNode')
export class AssetClassNode extends AssetNode {

	@JsonProperty('subAssetNode', [SubAssetClassNode])
  subAssetNode: Array<SubAssetClassNode> = undefined;

  getCalculatedValue(): any {
    let total = 0;
		for (const subAsset of this.subAssetNode) {
			total += subAsset.getCalculatedValue();
		}
		this.currentPosition.value = total;
		return total;
  }
}

@JsonObject('CurrentPortfolioModel')
export class CurrentPortfolioModel {

	@JsonProperty('clientId', String)
	clientId: string = undefined;

	@JsonProperty('accountId', String)
  accountId: string = undefined;

  @JsonProperty('name', String)
	name: string = undefined;

	@JsonProperty('accountType', String, true)
  accountType: string = undefined;

  @JsonProperty('accountNamePrefix', String, true)
  accountNamePrefix: string = undefined;

  @JsonProperty('accountNamePrefixEnabled', Boolean)
	accountNamePrefixEnabled: boolean = undefined;

	@JsonProperty('accountEditable', Boolean, true)
  accountEditable: boolean = undefined;

  @JsonProperty('accountHasAcceptedProfile', Boolean)
  accountHasAcceptedProfile: boolean = undefined;

	@JsonProperty('accountDetailsEditable', Boolean, true)
  accountDetailsEditable: boolean = undefined;

  @JsonProperty('accountIdEditable', Boolean, true)
  accountIdEditable: boolean = undefined;

	@JsonProperty('currency', Currency)
  currency: Currency = undefined;

  @JsonProperty('source', String)
	source: string = undefined;

	@JsonProperty('accountRiskCategory', String)
	accountRiskCategory: string = undefined;

	@JsonProperty('accountTaxWrapper', String)
	accountTaxWrapper: string = undefined;

	@JsonProperty('topLevelNode', [AssetClassNode])
	topLevelNode: Array<AssetClassNode> = undefined;

	@JsonProperty('lookthroughEnabled', Boolean)
  lookthroughEnabled: boolean = undefined;

	@JsonProperty('total', AssetPortfolio)
  total: AssetPortfolio = undefined;

  @JsonProperty('accountNames', [AccountDetails])
	accountNames: AccountDetails[] = undefined;

  recalculateValue(): number {
		let total = 0;
		for (const topLevel of this.topLevelNode) {
			total += topLevel.getCalculatedValue();
		}
		this.total.value = total;
		return total;
	}
}
