import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';
import { map, switchMap, catchError} from 'rxjs/operators';
import * as PMPAccountSearchResultsActions from './pmp-search-results.actions';
import { SearchService } from '../../../search/search.service';

export type PMPAccountSearchResultsAction = PMPAccountSearchResultsActions.All;

@Injectable()
export class PMPAccountSearchResultsEffects {

	constructor(private actions$: Actions,
							private searchResultsService: SearchService) {
	}

  getPMPAccountSearchResults: Observable<PMPAccountSearchResultsAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(PMPAccountSearchResultsActions.GET_PMP_ACCOUNT_SEARCH_RESULTS),
      switchMap((action: PMPAccountSearchResultsActions.GetPMPAccountSearchResults) =>
        this.searchResultsService.getPMPSearchResults(action.searchCriteria, action.clientId, action.accountId).pipe(
          map(post => new PMPAccountSearchResultsActions.GetPMPAccountSearchResultsSuccess(post)) ,
          catchError((error) => of(new PMPAccountSearchResultsActions.GetPMPAccountSearchResultsError(error)))
        )
     ))
    );

  getPMPModelSearchResults: Observable<PMPAccountSearchResultsAction> = createEffect(() =>
  this.actions$.pipe(
    ofType(PMPAccountSearchResultsActions.GET_PMP_MODEL_SEARCH_RESULTS),
    switchMap((action: PMPAccountSearchResultsActions.GetPMPModelSearchResults) =>
      this.searchResultsService.getPMPSearchResults(action.searchCriteria, action.clientId, action.accountId).pipe(
        map(post => new PMPAccountSearchResultsActions.GetPMPModelSearchResultsSuccess(post)) ,
        catchError((error) => of(new PMPAccountSearchResultsActions.GetPMPModelSearchResultsError(error)))
      )
    ))
  );
}
