import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('KnownExceptionType')
export class KnownExceptionType {

  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('description', String)
  description: string = undefined;

  @JsonProperty('expiryPeriod', Number)
  expiryPeriod: number = undefined;

  @JsonProperty('target', String, true)
  target: string = undefined;

}
