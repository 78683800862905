import { Action } from '@ngrx/store';

import { KnownExceptionState } from './knownExceptions.state';
import { AddKnownExceptionRequest } from '../../../entity/add-known-exception-request.model';
import { KnownException } from '../../../entity/known-exception.model';
import { KnownExceptionRequestDecision } from '../../../entity/known-exception-request-decision.model';

export const GET_KNOWN_EXCEPTIONS = '[KnownException] GET_KNOWN_EXCEPTIONS';
export const GET_KNOWN_EXCEPTIONS_SUCCESS = '[KnownException]  GET_KNOWN_EXCEPTIONS_SUCCESS';
export const GET_KNOWN_EXCEPTIONS_ERROR = '[KnownException] GET_KNOWN_EXCEPTIONS_ERROR';

export const ADD_KNOWN_EXCEPTION = '[KnownException] ADD_KNOWN_EXCEPTION';
export const ADD_KNOWN_EXCEPTION_SUCCESS = '[KnownException]  ADD_KNOWN_EXCEPTION_SUCCESS';
export const ADD_KNOWN_EXCEPTION_ERROR = '[KnownException] ADD_KNOWN_EXCEPTION_ERROR';

export const DELETE_KNOWN_EXCEPTION = '[KnownException] DELETE_KNOWN_EXCEPTION';
export const DELETE_KNOWN_EXCEPTION_SUCCESS = '[KnownException]  DELETE_KNOWN_EXCEPTION_SUCCESS';
export const DELETE_KNOWN_EXCEPTION_ERROR = '[KnownException] DELETE_KNOWN_EXCEPTION_ERROR';

export const ADD_EXCLUDE_HOLDING_KE = '[KnownException] ADD_EXCLUDE_HOLDING_KE';
export const ADD_EXCLUDE_HOLDING_KE_SUCCESS = '[KnownException]  ADD_EXCLUDE_HOLDING_KE_SUCCESS';
export const ADD_EXCLUDE_HOLDING_KE_ERROR = '[KnownException] ADD_EXCLUDE_HOLDING_KE_ERROR';

export const GET_KNOWN_EXCEPTION_REQUEST_DECISIONS = '[KnownException] GET_KNOWN_EXCEPTION_REQUEST_DECISIONS';
export const GET_KNOWN_EXCEPTION_REQUEST_DECISIONS_SUCCESS = '[KnownException]  GET_KNOWN_EXCEPTION_REQUEST_DECISIONS_SUCCESS';
export const GET_KNOWN_EXCEPTION_REQUEST_DECISIONS_ERROR = '[KnownException] GET_KNOWN_EXCEPTION_REQUEST_DECISIONS_ERROR';

export const GET_KNOWN_EXCEPTION_FOR_APPROVAL = '[KnownException] GET_KNOWN_EXCEPTION_FOR_APPROVAL';
export const GET_KNOWN_EXCEPTION_FOR_APPROVAL_SUCCESS = '[KnownException]  GET_KNOWN_EXCEPTION_FOR_APPROVAL_SUCCESS';
export const GET_KNOWN_EXCEPTION_FOR_APPROVAL_ERROR = '[KnownException] GET_KNOWN_EXCEPTION_FOR_APPROVAL_ERROR';

export const ADD_KNOWN_EXCEPTION_DECISION = '[KnownException] ADD_KNOWN_EXCEPTION_DECISION';
export const ADD_KNOWN_EXCEPTION_DECISION_SUCCESS = '[KnownException]  ADD_KNOWN_EXCEPTION_DECISION_SUCCESS';
export const ADD_KNOWN_EXCEPTION_DECISION_ERROR = '[KnownException] ADD_KNOWN_EXCEPTION_DECISION_ERROR';

export class GetKnownExceptions implements Action {
	readonly type = GET_KNOWN_EXCEPTIONS;
	constructor(public clientId: string, public accountId: string) {}
}

export class GetKnownExceptionsSuccess implements Action {
	readonly type = GET_KNOWN_EXCEPTIONS_SUCCESS;
	constructor(public knownExceptionState: KnownExceptionState) {}
}

export class GetKnownExceptionsError implements Action {
	readonly type = GET_KNOWN_EXCEPTIONS_ERROR;
	constructor(public error: any) {}
}

export class GetKnownExceptionRequestDecisions implements Action {
	readonly type = GET_KNOWN_EXCEPTION_REQUEST_DECISIONS;
	constructor(public knownException: KnownException) {}
}

export class GetKnownExceptionRequestDecisionsSuccess implements Action {
	readonly type = GET_KNOWN_EXCEPTION_REQUEST_DECISIONS_SUCCESS;
	constructor(public knownException: KnownException, public knownExceptionDecision: KnownExceptionRequestDecision[]) {}
}

export class GetKnownExceptionRequestDecisionsError implements Action {
	readonly type = GET_KNOWN_EXCEPTION_REQUEST_DECISIONS_ERROR;
	constructor(public error: any) {}
}

export class AddKnownException implements Action {
	readonly type = ADD_KNOWN_EXCEPTION;
	constructor(public addKnownExceptionRequest: AddKnownExceptionRequest) {}
}

export class AddKnownExceptionSuccess implements Action {
	readonly type = ADD_KNOWN_EXCEPTION_SUCCESS;
	constructor(public knownExceptionState: KnownExceptionState) {}
}

export class AddKnownExceptionError implements Action {
	readonly type = ADD_KNOWN_EXCEPTION_ERROR;
	constructor(public error: any) {}
}

export class DeleteKnownException implements Action {
	readonly type = DELETE_KNOWN_EXCEPTION;
	constructor(public id: number, public clientId: string, public accountId: string) {}
}

export class DeleteKnownExceptionSuccess implements Action {
	readonly type = DELETE_KNOWN_EXCEPTION_SUCCESS;
	constructor(public knownExceptionState: KnownExceptionState) {}
}

export class DeleteKnownExceptionError implements Action {
	readonly type = DELETE_KNOWN_EXCEPTION_ERROR;
	constructor(public error: any) {}
}

export class AddExcludeHoldingKE implements Action {
	readonly type = ADD_EXCLUDE_HOLDING_KE;
	constructor(public addKnownExceptionRequest: AddKnownExceptionRequest) {}
}

export class AddExcludeHoldingKESuccess implements Action {
	readonly type = ADD_EXCLUDE_HOLDING_KE_SUCCESS;
	constructor(public knownExceptionState: KnownExceptionState) {}
}

export class AddExcludeHoldingKEError implements Action {
	readonly type = ADD_EXCLUDE_HOLDING_KE_ERROR;
	constructor(public error: any) {}
}

export type All = GetKnownExceptions | GetKnownExceptionsSuccess | GetKnownExceptionsError
									| GetKnownExceptionRequestDecisions | GetKnownExceptionRequestDecisionsSuccess | GetKnownExceptionRequestDecisionsError
									| AddKnownException | AddKnownExceptionSuccess | AddKnownExceptionError
									| DeleteKnownException | DeleteKnownExceptionSuccess | DeleteKnownExceptionError
									| AddExcludeHoldingKE | AddExcludeHoldingKESuccess | AddExcludeHoldingKEError;
