import { JsonObject, JsonProperty } from 'json2typescript';
import { WidgetModelDetail } from '../../shared/entity/widget-profile.model';

@JsonObject('ClientPppColumn')
export class ClientPppColumn {

  @JsonProperty('header', String)
  header: string = undefined;

  @JsonProperty('label', String)
  label: string = undefined;

  @JsonProperty('alignment', String)
  alignment: string = undefined;

}

@JsonObject('ClientPppTimePeriodBody')
export class ClientPppTimePeriodBody {

  @JsonProperty('absoluteReturn', String)
  absoluteReturn: string = undefined;

  @JsonProperty('relativeReturn', String)
  relativeReturn: string = undefined;

  @JsonProperty('timePeriod', String)
  timePeriod: string = undefined;

  @JsonProperty('microChart', [Number])
  microChart: number[] = undefined;

  @JsonProperty('active', Boolean)
  active: boolean = undefined;

}

@JsonObject('ClientPppTimePeriod')
export class ClientPppTimePeriod {

  @JsonProperty('columnHeader', [[ClientPppColumn]], true)
  columnHeader: ClientPppColumn[] = undefined;

  @JsonProperty('columnFooter', [[ClientPppColumn]], true)
  columnFooter: ClientPppColumn[][] = undefined;

  @JsonProperty('body', [ClientPppTimePeriodBody])
  body: ClientPppTimePeriodBody[] = undefined;

}

@JsonObject('ClientPppPerformanceDetailsBody')
export class ClientPppPerformanceDetailsBody {

  @JsonProperty('finalData', String)
  finalData: string = undefined;

  @JsonProperty('initialData', String)
  initialData: string = undefined;

  @JsonProperty('label', String)
  label: string = undefined;

}

@JsonObject('ClientPppPerformanceDetails')
export class ClientPppPerformanceDetails {

  @JsonProperty('columnHeader', [[ClientPppColumn]], true)
  columnHeader: ClientPppColumn[] = undefined;

  @JsonProperty('columnFooter', [[ClientPppColumn]], true)
  columnFooter: ClientPppColumn[][] = undefined;

  @JsonProperty('body', [ClientPppPerformanceDetailsBody])
  body: ClientPppPerformanceDetailsBody[] = undefined;

}

@JsonObject('ClientPppWorstAndBestPerformersBody')
export class ClientPppWorstAndBestPerformersBody {

  @JsonProperty('bestPerformer', String)
  bestPerformer: string = undefined;

  @JsonProperty('bestPerformerReturn', String)
  bestPerformerReturn: string = undefined;

  @JsonProperty('worstPerformer', String)
  worstPerformer: string = undefined;

  @JsonProperty('worstPerformerReturn', String)
  worstPerformerReturn: string = undefined;

}

@JsonObject('ClientPppWorstAndBestPerformers')
export class ClientPppWorstAndBestPerformers {

  @JsonProperty('columnHeader', [[ClientPppColumn]], true)
  columnHeader: ClientPppColumn[] = undefined;

  @JsonProperty('columnFooter', [[ClientPppColumn]], true)
  columnFooter: ClientPppColumn[][] = undefined;

  @JsonProperty('body', [ClientPppWorstAndBestPerformersBody])
  body: ClientPppWorstAndBestPerformersBody[] = undefined;

}

@JsonObject('ClientPppReturnDial')
export class ClientPppReturnDial {

  @JsonProperty('almostOutlierLowerValue', Number)
  almostOutlierLowerValue: number = undefined;

  @JsonProperty('almostOutlierUpperValue', Number)
  almostOutlierUpperValue: number = undefined;

  @JsonProperty('compliantLowerValue', Number)
  compliantLowerValue: number = undefined;

  @JsonProperty('compliantUpperValue', Number)
  compliantUpperValue: number = undefined;

  @JsonProperty('outlierLowerValue', Number, true)
  outlierLowerValue: number = undefined;

  @JsonProperty('outlierUpperValue', Number, true)
  outlierUpperValue: number = undefined;

  @JsonProperty('return', Number)
  return: number = undefined;

  @JsonProperty('scaleLowerValue', Number)
  scaleLowerValue: number = undefined;

  @JsonProperty('scaleUpperValue', Number)
  scaleUpperValue: number = undefined;

  @JsonProperty('title', String, true)
  title: string = undefined;

  @JsonProperty('unit', String)
  unit: string = undefined;

}

@JsonObject('ClientPppReturnDials')
export class ClientPppReturnDials {

  @JsonProperty('label', String)
  label: string = undefined;

  @JsonProperty('absolute', ClientPppReturnDial)
  absolute: ClientPppReturnDial = undefined;

  @JsonProperty('relative', ClientPppReturnDial)
  relative: ClientPppReturnDial = undefined;

}

@JsonObject('ClientPppPerformanceAssetAnalysisCurrency')
export class ClientPppPerformanceAssetAnalysisCurrency {
  @JsonProperty('currencyCode', String)
  currencyCode: string = undefined;
  @JsonProperty('currencySymbol', String)
  currencySymbol: string = undefined;
}
@JsonObject('ClientPppPerformanceAssetAnalysisHoldingNode')
export class ClientPppPerformanceAssetAnalysisHoldingNode {
  @JsonProperty('assetName', String)
  assetName: string = undefined;
  @JsonProperty('contribution', Number)
  contribution: number = undefined;
  @JsonProperty('gainOrLoss', Number)
  gainOrLoss: number = undefined;
  @JsonProperty('weight', Number)
  weight: number = undefined;
  @JsonProperty('performance', Number)
  performance: number = undefined;
}
@JsonObject('ClientPppPerformanceAssetAnalysisSubAssetNode')
export class ClientPppPerformanceAssetAnalysisSubAssetNode {
  @JsonProperty('assetName', String)
  assetName: string = undefined;
  @JsonProperty('holdingNode', [ClientPppPerformanceAssetAnalysisHoldingNode])
  holdingNode: Array<ClientPppPerformanceAssetAnalysisHoldingNode> = undefined;
}

@JsonObject('ClientPppPerformanceAssetAnalysisTopLevelNodes')
export class ClientPppPerformanceAssetAnalysisTopLevelNodes {
  @JsonProperty('assetName', String)
  assetName: string = undefined;
  @JsonProperty('subAssetNode', [ClientPppPerformanceAssetAnalysisSubAssetNode])
  subAssetNode: Array<ClientPppPerformanceAssetAnalysisSubAssetNode> = undefined;
}
@JsonObject('ClientPppPerformanceAssetAnalysis')
export class ClientPppPerformanceAssetAnalysis {
  @JsonProperty('currency', ClientPppPerformanceAssetAnalysisCurrency)
  currency: ClientPppPerformanceAssetAnalysisCurrency = undefined;
  @JsonProperty('topLevelNodes', [ClientPppPerformanceAssetAnalysisTopLevelNodes])
  topLevelNodes: Array<ClientPppPerformanceAssetAnalysisTopLevelNodes> = undefined;
}
@JsonObject('ClientPpp')
export class ClientPpp {

  @JsonProperty('info', [WidgetModelDetail])
  info: WidgetModelDetail[] = undefined;

  @JsonProperty('timePeriod', ClientPppTimePeriod)
  timePeriod: ClientPppTimePeriod = undefined;

  @JsonProperty('performanceDetails', ClientPppPerformanceDetails)
  performanceDetails: ClientPppPerformanceDetails = undefined;

  @JsonProperty('worstAndBestPerformers', ClientPppWorstAndBestPerformers)
  worstAndBestPerformers: ClientPppWorstAndBestPerformers = undefined;

  @JsonProperty('returnDials', [ClientPppReturnDials])
  returnDials: ClientPppReturnDials[] = undefined;

  @JsonProperty('performanceAssetAnalysis', ClientPppPerformanceAssetAnalysis)
  performanceAssetAnalysis: ClientPppPerformanceAssetAnalysis = undefined;

  @JsonProperty('errorMessage', [String])
  errorMessage: string[] = undefined;

  @JsonProperty('withError', Boolean)
  withError: boolean = undefined;

  @JsonProperty('benchmarkName', String)
  benchmarkName: string = undefined;
}
