import { Action } from '@ngrx/store';

import { WidgetProfile, WidgetModelDetail} from '../../entity/widget-profile.model';

export const GET_WIDGET_PROFILE = '[Profile] GET_WIDGET_PROFILE';
export const GET_WIDGET_PROFILE_SUCCESS = '[Profile]  GET_WIDGET_PROFILE_SUCCESS';
export const GET_WIDGET_PROFILE_ERROR = '[Profile] GET_WIDGET_PROFILE_ERROR';

export class GetWidgetProfile implements Action {
	readonly type = GET_WIDGET_PROFILE;
	constructor(public clientId: string, public accountId: string) {}
}

export class GetWidgetProfileSuccess implements Action {
	readonly type = GET_WIDGET_PROFILE_SUCCESS;
	constructor(public profile: WidgetProfile, public modelDetails: WidgetModelDetail[]) {}
}

export class GetWidgetProfileError implements Action {
	readonly type = GET_WIDGET_PROFILE_ERROR;
	constructor(public error: any) {}
}

export type All = GetWidgetProfile | GetWidgetProfileSuccess | GetWidgetProfileError;
