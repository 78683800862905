import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('ClientStatus')
export class ClientStatus {

	@JsonProperty('label', String)
	label: String = undefined;

	@JsonProperty('selected', Boolean)
	selected: Boolean = undefined;

	@JsonProperty('value', String)
	value: String = undefined;

}
