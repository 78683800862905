import { Action } from '@ngrx/store';

import { AABoundaryDeviation } from '../../entity/aa-boundary-deviation.model';
import { AABoundaryDeviationState } from './aaBoundaryDeviation.state';

export const GET_TAC_LEVEL_AA_BOUNDARY_DEVIATION = '[TacLevelAABoundaryDevaition] GET_TAC_LEVEL_AA_BOUNDARY_DEVIATION';
export const GET_TAC_LEVEL_AA_BOUNDARY_DEVIATION_SUCCESS = '[TacLevelAABoundaryDevaition]  GET_TAC_LEVEL_AA_BOUNDARY_DEVIATION_SUCCESS';
export const GET_TAC_LEVEL_AA_BOUNDARY_DEVIATION_ERROR = '[TacLevelAABoundaryDevaition] GET_TAC_LEVEL_AA_BOUNDARY_DEVIATION_ERROR';

export class GetTacLevelAABoundaryDeviation implements Action {
	readonly type = GET_TAC_LEVEL_AA_BOUNDARY_DEVIATION;
	constructor(public clientId: string, public accountId: string) {}
}

export class GetTacLevelAABoundaryDeviationSuccess implements Action {
	readonly type = GET_TAC_LEVEL_AA_BOUNDARY_DEVIATION_SUCCESS;
	constructor(public aaBoundaryDeviationState: AABoundaryDeviationState) {}
}

export class GetTacLevelAABoundaryDeviationError implements Action {
	readonly type = GET_TAC_LEVEL_AA_BOUNDARY_DEVIATION_ERROR;
	constructor(public error: any) {}
}

export type All = GetTacLevelAABoundaryDeviation
	| GetTacLevelAABoundaryDeviationSuccess
	| GetTacLevelAABoundaryDeviationError;
