import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('WidgetModel')
export class WidgetModel {

    @JsonProperty('id', Number)
    id: number = undefined;

    @JsonProperty('name', String)
    name: string = undefined;

    @JsonProperty('modelPortfolioCategory', String)
    modelPortfolioCategory: string = undefined;
 }


 @JsonObject('AnotherAccountInfo')
export class AnotherAccountInfo {

  @JsonProperty('accountId', Number)
    accountId: number = undefined;

    @JsonProperty('accountInfo', String)
    accountInfo: string = undefined;
 }
