<ng-container *ngIf="sessionDetails | async as session" >
<nav *ngIf="isNavigationVisible" class="navbar navbar-expand-sm">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigationMain" aria-controls="navigationMain" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navigationMain">
    <ul class="navbar-nav mr-auto">
      <ng-container *ngFor="let item of navItems">
        <li class="nav-item nav-item-{{item.url}}" routerLinkActive="active" *ngIf="userHasPermission(item.navId, session) && !isConfigurableItem(item.navId)">
          <a *ngIf="!(item.url == 'admin')" [routerLink]="item.url" class="nav-link">{{item.title}}</a>
          <a *ngIf="item.url == 'admin'" (click)="openAdmin()" class="nav-link">{{item.title}}</a>
        </li>

        <ng-container *ngIf="isAnyConfigurableItemVisible$ | async">
          <li class="nav-item nav-item-{{item.url}}" routerLinkActive="active" *ngIf="isConfigurableItemAvailable(item.navId, session)">
            <a [routerLink]="item.url" class="nav-link">{{item.title}}</a>
          </li>
        </ng-container>
      </ng-container>
    </ul>
    <app-user-options [isNavigationVisible]="isNavigationVisible"></app-user-options>
    <app-quick-search [isNavigationVisible]="showQuickSearchBox && isNavigationVisible"></app-quick-search>
  </div>
</nav>
<div class="copy-right-link text-right">
  <app-copyright></app-copyright>
</div>

</ng-container>
