import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('SearchCriteria')
export class SearchCriteria {

	@JsonProperty('label', String)
	label: string = undefined;

	@JsonProperty('selected', Boolean)
	selected: Boolean = undefined;

	@JsonProperty('value', String)
	value: string = undefined;

}
