import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../json-convert/DateConvertor';

import {Moment} from 'moment';

@JsonObject('Report')
export class Report {

  @JsonProperty('id', String)
  id: string = undefined;

  @JsonProperty('name', String)
  name: string = undefined;

  @JsonProperty('description', String)
  description: string = undefined;

  @JsonProperty('lastRunDate', DateConverter, true)
  lastRunDate: Moment = undefined;

  @JsonProperty('filePath', String, true)
  filePath: string = undefined;

}
