import { AssetColumnHeader } from './../entity/asset-column-header.model';
import { JsonConverter, JsonCustomConvert, JsonConvert, ValueCheckingMode } from 'json2typescript';
import { AssetColumnMapConverter } from './assetColumnMapConverter';
import { AssetColumn } from '../entity/asset-column.model';

@JsonConverter
export class AssetColumnHeaderMapConverter implements JsonCustomConvert<Map<string, AssetColumnHeader>> {

  private jsonConverter: JsonConvert;

  constructor() {
    this.jsonConverter = new JsonConvert();
    this.jsonConverter.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
  }

  serialize(data: Map<string, AssetColumnHeader>): Object {
    const obj = {};
    const self = this;

    data.forEach(function(value, key) {
      const columnObj = {};
      value.columns.forEach(function(columnValue, columnKey) {
        columnObj[columnKey] = self.jsonConverter.serialize(columnValue);
      });
      obj[key] = {...value};
      obj[key].columns = columnObj;
    });

    return obj;
  }

  deserialize(jsonObject: any): Map<string, AssetColumnHeader> {
    if (jsonObject) {
      const mapData = new Map<string, AssetColumnHeader>();
      for (const k of Object.keys(jsonObject)) {
        mapData.set(k, this.jsonConverter.deserializeObject(jsonObject[k], AssetColumnHeader) );
      }
      return mapData;
    } else {
      return null;
    }
  }
}
