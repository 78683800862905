import { Action } from '@ngrx/store';
import { SearchResult } from '../../entity/search-result.model';
import { SearchCriteriaRequest } from '../../../search/entity/search-criteria-request.model';

export const GET_PMP_ACCOUNT_SEARCH_RESULTS = '[SearchResults] GET_PMP_ACCOUNT_SEARCH_RESULTS';
export const GET_PMP_ACCOUNT_SEARCH_RESULTS_SUCCESS = '[SearchResults]  GET_PMP_ACCOUNT_SEARCH_RESULTS_SUCCESS';
export const GET_PMP_ACCOUNT_SEARCH_RESULTS_ERROR = '[SearchResults] GET_PMP_ACCOUNT_SEARCH_RESULTS_ERROR';

export const GET_PMP_MODEL_SEARCH_RESULTS = '[SearchResults] GET_PMP_MODEL_SEARCH_RESULTS';
export const GET_PMP_MODEL_SEARCH_RESULTS_SUCCESS = '[SearchResults]  GET_PMP_MODEL_SEARCH_RESULTS_SUCCESS';
export const GET_PMP_MODEL_SEARCH_RESULTS_ERROR = '[SearchResults] GET_PMP_MODEL_SEARCH_RESULTS_ERROR';

export class GetPMPAccountSearchResults implements Action {
	readonly type = GET_PMP_ACCOUNT_SEARCH_RESULTS;
	constructor(public searchCriteria: SearchCriteriaRequest, public clientId: String, public accountId: String) {}
}

export class GetPMPAccountSearchResultsSuccess implements Action {
	readonly type = GET_PMP_ACCOUNT_SEARCH_RESULTS_SUCCESS;
	constructor(public searchAccountResult: SearchResult[]) {}
}

export class GetPMPAccountSearchResultsError implements Action {
	readonly type = GET_PMP_ACCOUNT_SEARCH_RESULTS_ERROR;
	constructor(public error: any) {}
}

export class GetPMPModelSearchResults implements Action {
	readonly type = GET_PMP_MODEL_SEARCH_RESULTS;
	constructor(public searchCriteria: SearchCriteriaRequest, public clientId: String, public accountId: String) {}
}

export class GetPMPModelSearchResultsSuccess implements Action {
	readonly type = GET_PMP_MODEL_SEARCH_RESULTS_SUCCESS;
	constructor(public searchModelResult: SearchResult[]) {}
}

export class GetPMPModelSearchResultsError implements Action {
	readonly type = GET_PMP_MODEL_SEARCH_RESULTS_ERROR;
	constructor(public error: any) {}
}

export type All = GetPMPAccountSearchResults | GetPMPAccountSearchResultsSuccess | GetPMPAccountSearchResultsError
				| GetPMPModelSearchResults | GetPMPModelSearchResultsSuccess | GetPMPModelSearchResultsError;

