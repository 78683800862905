import { Action } from '@ngrx/store';

import * as AABoundaryDeviationActions from './aaBoundaryDeviation.actions';
import { AABoundaryDeviationState } from './aaBoundaryDeviation.state';
import { AABoundaryDeviation } from '../../entity/aa-boundary-deviation.model';
export type AABoundaryDeviationAction = AABoundaryDeviationActions.All;

export const initialState: AABoundaryDeviationState = { tacLevelCurrentVsTarget : new Array<AABoundaryDeviation>(),
  tacLevelProposedVsTarget: new Array<AABoundaryDeviation>()};

export function aaBoundaryDeviationReducer(
  state: AABoundaryDeviationState = initialState, action: AABoundaryDeviationAction): AABoundaryDeviationState {
  switch (action.type) {

    case AABoundaryDeviationActions.GET_TAC_LEVEL_AA_BOUNDARY_DEVIATION_SUCCESS: {
      return {...state, ...action.aaBoundaryDeviationState};
    }

    case AABoundaryDeviationActions.GET_TAC_LEVEL_AA_BOUNDARY_DEVIATION_ERROR: {
      console.error('Error caught while loading AA boundary deviation', action.error);
      return {...initialState};
    }

    default :
      return state;
  }

}
