import { Action } from '@ngrx/store';

// import { MonitorPortfolio } from '../../../entity/monitor-portfolio.model';
import { DataModelOverallResult as AlphaModelOverallResult } from '../../entity/data-model.model';


export const GET_ALL_ALPHA_MODELS = '[DataModelList] GET_ALL_ALPHA_MODELS';
export const GET_ALL_ALPHA_MODELS_SUCCESS = '[DataModelList]  GET_ALL_ALPHA_MODELS_SUCCESS';
export const GET_ALL_ALPHA_MODELS_ERROR = '[DataModelList] GET_ALL_ALPHA_MODELS_ERROR';
export const UPDATE_CURRENT_ALPHA_SELECTION = '[DataModelList] UPDATE_CURRENT_ALPHA_SELECTION';
export const SORT_ALPHA_SELECTIONS = '[DataModelList] SORT_ALPHA_SELECTIONS';
export const PRECALL_RESET = '[DataModelList] PRECALL_RESET';

export class GetAllAlphaModels implements Action {
  readonly type = GET_ALL_ALPHA_MODELS;
  constructor(public clientId: string, public accountId: string, public scenarioId: number) {}
}

export class GetAllAlphaModelsSuccess implements Action {
	readonly type = GET_ALL_ALPHA_MODELS_SUCCESS;
	constructor(public result: AlphaModelOverallResult) {}
}

export class UpdateCurrentAlphaSelection implements Action {
	readonly type = UPDATE_CURRENT_ALPHA_SELECTION;
	constructor(public newAlphaSelection: number) {}
}

export class SortAlphaSelections implements Action {
	readonly type = SORT_ALPHA_SELECTIONS;
	constructor() {}
}

export class GetAllAlphaModelsError implements Action {
	readonly type = GET_ALL_ALPHA_MODELS_ERROR;
	constructor(public error: any) {}
}

export class PrecallReset implements Action {
	readonly type = PRECALL_RESET;
	constructor() {}
}

export type All = GetAllAlphaModels | GetAllAlphaModelsSuccess | GetAllAlphaModelsError 
					| UpdateCurrentAlphaSelection | SortAlphaSelections
					| PrecallReset;
