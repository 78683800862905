import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';

import {environment} from '../../environments/environment';
import { MockDataService } from '../mock/mock-data.service';

const exportedModules: Array<any> = [HttpClientModule];

if (environment.mock) {
    exportedModules.push(HttpClientInMemoryWebApiModule.forFeature(MockDataService, {passThruUnknownUrl: true}));
}

@NgModule({
  imports: exportedModules
})
export class AppHttpModule {
}
