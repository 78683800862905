import { SubAssetClassNode, AssetPortfolio } from './../../../entity/current-portfolio-model.model';
import { BreakdownHoldingDataElement, CurrentPortfolioModel, HoldingClassNode } from '../../../entity/current-portfolio-model.model';
import { AssetResult, Asset } from '../../../entity/asset.model';

export default class AccountCurrentPortfolioUtil {
  static createHoldingNode(accountCurrentPortfolio: CurrentPortfolioModel, asset: AssetResult): HoldingClassNode {
    let foundSubAssetNode: SubAssetClassNode = null;

    accountCurrentPortfolio.topLevelNode.some(topAssetNode =>
      topAssetNode.subAssetNode.some(subAssetNode => {
        if (subAssetNode.asset.label === asset.subAssetClass) {
          foundSubAssetNode = subAssetNode;
          return true;
        }
      }
      ));

    if (foundSubAssetNode) {
      const newHoldingNode = this.createEmptyHoldingNode(asset);

      accountCurrentPortfolio.accountNames.forEach(accDetails => {
          newHoldingNode.breakdownNode
          .push(AccountCurrentPortfolioUtil.createEmptyBreakdownNode(accDetails.id, accDetails.name, accDetails.label));
        });

      newHoldingNode.source = newHoldingNode.breakdownNode.length > 1 ? 'M' : 'S';
      foundSubAssetNode.holdingNode.push(newHoldingNode);
      return newHoldingNode;
    }
    console.error(`cannot find SAC ${asset.subAssetClass} in the portfolio tree`);
    return null;
  }


  static createEmptyBreakdownNode(accountId: number, accountName: string, accountLabel: string): BreakdownHoldingDataElement {

    const breakdownNode = new BreakdownHoldingDataElement();
    breakdownNode.accountId = accountId;
    breakdownNode.accountName = accountName;
    breakdownNode.compositeLabel = accountLabel;
    breakdownNode.currentPosition = this.zeroWeightAssetPortfolio();
    return breakdownNode;
  }

  static createEmptyHoldingNode(asset: AssetResult): HoldingClassNode {

    const holdingClassNode = new HoldingClassNode();
    holdingClassNode.type = 'holdingClass';
    holdingClassNode.source = 'S';
    holdingClassNode.flagIssue = null;
    holdingClassNode.flagIssueName = null;
    holdingClassNode.asset = new Asset(null, asset.sedol, asset.description, asset.composite, asset.inBuyList);
    holdingClassNode.breakdownNode = new Array<BreakdownHoldingDataElement>();
    holdingClassNode.currentPosition = this.zeroWeightAssetPortfolio();
    holdingClassNode.isNewHolding = true;
    return holdingClassNode;
  }

  static extractHoldingNodeFromModel(portfolioModel: CurrentPortfolioModel,
     holdingNodeName: string): HoldingClassNode {
    let foundHoldingNode: HoldingClassNode = null;
    if (portfolioModel && holdingNodeName) {
      portfolioModel.topLevelNode.some(topAssetNode =>
        topAssetNode.subAssetNode.some(subAssetNode =>
          subAssetNode.holdingNode.some(holdingNode => {
            if (holdingNode.asset.name === holdingNodeName) {
              foundHoldingNode = holdingNode;
              return true;
            }
          })
        ));
    }
    return foundHoldingNode;
  }

  static deleteHoldingNodeFromModel(portfolioModel: CurrentPortfolioModel,
    holdingNodeName: string): boolean {
   let deleted = false;
   if (portfolioModel && holdingNodeName) {
     portfolioModel.topLevelNode.some(topAssetNode =>
       topAssetNode.subAssetNode.some(subAssetNode =>
         subAssetNode.holdingNode.some(holdingNode => {
           if (holdingNode.asset.name === holdingNodeName) {
            const index = subAssetNode.holdingNode.indexOf(holdingNode);
            subAssetNode.holdingNode.splice(index, 1);
            deleted = true;
             return true;
           }
         })
       ));
   }
   return deleted;
 }

  static zeroWeightAssetPortfolio(): AssetPortfolio {
    const assetPortfolio = new AssetPortfolio();
    assetPortfolio.ctr = 0;
    assetPortfolio.mctr = 0;
    assetPortfolio.shares = 0;
    assetPortfolio.value = 0;
    assetPortfolio.weight = 0;
    assetPortfolio.yield = 0;
    assetPortfolio.bookCost = 0;

    return assetPortfolio;
  }

  static clearHoldingsFromCurrentPortfolio(accountCurrentPortfolio: CurrentPortfolioModel): CurrentPortfolioModel {
    if (accountCurrentPortfolio) {
      accountCurrentPortfolio.topLevelNode.forEach(topAssetNode =>
        topAssetNode.subAssetNode.forEach(subAssetNode =>
          subAssetNode.holdingNode.forEach(holdingNode => {

            // setting holding value to 0, as backend api does not handle removal of holdings yet.
            holdingNode.currentPosition.value = 0;
          })
      ));
    }
    return accountCurrentPortfolio;
  }

}
