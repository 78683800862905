import { ValidationErrors, AbstractControl } from '@angular/forms';
import * as moment from 'moment';

export class CustomValidators {

    static numberWithOptionalDecimal(control: AbstractControl): ValidationErrors | null {
        /**
         * @description
         * Validator that requires the control's value to be natural or decimal number.
         */
        const regex = RegExp(/^\d*\.?\d*$/);
        return (control.value === null || regex.test(control.value)) ? null :
        { 'numberWithOptionalDecimal': {
            'message': 'must be natural or decimal number'
        }};
    }

    static futureDate(control: AbstractControl): ValidationErrors | null {
      let validationErrorMessage: string;
      const dateValue = control.value;
      const momentDate = moment(dateValue);
      if (momentDate.isValid() && moment().isAfter(momentDate)) {
        validationErrorMessage = 'has expired';
    }
      return validationErrorMessage ?
      { 'futureDate': {
          'message': validationErrorMessage
      }} : null;
  }
}
