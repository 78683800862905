import * as ApiTradeRationaleActions from './api-trade-rationale.actions';
import { ApiTradeRationaleState } from './api-trade-rationale.state';

export type ApiTradeRationaleAction = ApiTradeRationaleActions.All;
export const initialState: ApiTradeRationaleState = { tradeRationale: undefined, loading: false};

// tslint:disable-next-line:max-line-length
export function apiTradeRationaleReducer(state: ApiTradeRationaleState = initialState, action: ApiTradeRationaleAction): ApiTradeRationaleState {
  switch (action.type) {

    case ApiTradeRationaleActions.GET_API_TRADE_RATIONALE: {
      return {...initialState, ...{loading: true}};
    }

    case ApiTradeRationaleActions.GET_API_TRADE_RATIONALE_SUCCESS: {
      return {...state, ...{tradeRationale: action.tradeRationale}, ...{loading: false}};
    }

    case ApiTradeRationaleActions.GET_API_TRADE_RATIONALE_ERROR: {
      console.error('Error caught while loading API Trade Rationale', action.error);
      return {...initialState};
    }

    default:
      return state;
  }

}
