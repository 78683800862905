import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('Currency')
export class Currency {

	@JsonProperty('currencyCode', String)
	currencyCode: string = undefined;

	@JsonProperty('currencySymbol', String)
	currencySymbol: string = undefined;

	private constructor() {}
}
