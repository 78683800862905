import { Action } from '@ngrx/store';

import * as ProfileActions from './profile.actions';
import { WidgetProfile, WidgetModelDetail } from '../../entity/widget-profile.model';
import { ProfileState } from './profile.state';

export type ProfileAction = ProfileActions.All;
export const initialState: ProfileState = { profile : new WidgetProfile(), modelDetails: [new WidgetModelDetail], loading: false};

export function profileReducer(state: ProfileState = initialState, action: ProfileAction): ProfileState {
  switch (action.type) {

    case ProfileActions.GET_WIDGET_PROFILE: {
      return {...state, ...{loading: true}};

    }

    case ProfileActions.GET_WIDGET_PROFILE_SUCCESS: {
      return {...state, ...{profile: action.profile}, ...{modelDetails: action.modelDetails}, ...{loading: false}};
    }

    case ProfileActions.GET_WIDGET_PROFILE_ERROR: {
      console.error('Error caught while loading Widgets-Profile', action.error);
      return {...initialState};
    }

    default :
      return state;
  }
}
