import * as InterfaceActions from './interface-options.actions';
import { InterfaceState } from './interface-options.state';
import { PortfolioLevel } from '../../entity/portfolioLevel.enum';

export type InterfaceAction = InterfaceActions.All;
export const initialState: InterfaceState = {
  columns: null,
  views: null,
  globalVisibility: PortfolioLevel.TAC,
  trigger: null,
  loading: false
};

export function interfaceReducer(state: InterfaceState = initialState, action: InterfaceAction): InterfaceState {
  switch (action.type) {

    case InterfaceActions.GET_ASSET_COLUMNS: {
      return {...state, ...{loading: true}, ...{trigger: null}};
    }

    case InterfaceActions.GET_ASSET_COLUMNS_SUCCESS: {
      return {...state, ...{columns: action.assetColumns}, ...{loading: false}, ...{trigger: null}};
    }

    case InterfaceActions.GET_ASSET_COLUMNS_ERROR: {
      console.error('Error caught while loading asset columns', action.error);
      return {...initialState};
    }

    case InterfaceActions.GET_INTERFACE_VIEWS: {
      return {...state, ...{loading: true}, ...{trigger: null}};
    }

    case InterfaceActions.GET_INTERFACE_VIEWS_SUCCESS: {
      return {...state, ...{views: action.pmpViews}, ...{loading: false}, ...{trigger: null}};
    }

    case InterfaceActions.DELETE_INTERFACE_VIEW: {
      return {...state, ...{loading: true}, ...{trigger: action.trigger}};
    }

    case InterfaceActions.SAVE_INTERFACE_VIEW: {
      if (action.view.isDefault) {
        state.views.forEach(view => {
          if (view.id !== action.view.id) {
            view.isDefault = false;
          }
        });
      }
      return {...state, ...{loading: true}, ...{trigger: null}};
    }

    case InterfaceActions.GET_INTERFACE_VIEWS_ERROR: {
      console.error('Error caught while loading interface options', action.error);
      return {...initialState};
    }

    case InterfaceActions.GET_ASSET_COLUMNS_SUCCESS: {
      return {...state, ...{columns: action.assetColumns}, ...{loading: false}, ...{trigger: null}};
    }

    case InterfaceActions.GET_ASSET_COLUMNS_ERROR: {
      console.error('Error caught while loading asset columns interface options', action.error);
      return {...initialState};
    }

    case InterfaceActions.SET_ASSET_COLUMNS: {
      return {...state, ...{columns: {...action.assetColumns}}, ...{trigger: action.trigger}};
    }

    case InterfaceActions.RESET_ASSET_COLUMNS: {
      return {...state, ...{columns: null}, ...{trigger: null}};
    }

    case InterfaceActions.SET_GLOBAL_VISIBILITY: {
      return {...state, ...{globalVisibility: action.globalVisibility}, ...{trigger: null}};
    }

    default :
      return state;
  }

}
