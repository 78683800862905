import { JsonObject, JsonProperty } from 'json2typescript';
import { FormComponentBaseConverter } from '../../json-convert/form-component-base-converter';
import { FormComponentBase } from './form-component-element';
import { MapConverter } from '../../json-convert/MapConverter';

// represents row in the form
@JsonObject('FormComponentGroup')
export class FormComponentGroup {

  @JsonProperty('order', Number)
  order: number = undefined;

  @JsonProperty('key', String)
  key: string = undefined;

  @JsonProperty('metaData', MapConverter, true)
  metaData: Map<string, string> = undefined;

  // represents column in each row
  @JsonProperty('components', FormComponentBaseConverter)
  components: FormComponentBase<any>[] = undefined;

  sort() {
    this.components.sort((a, b) => a.order - b.order);
  }

}
