import { Action } from '@ngrx/store';

import * as AlertActions from './alerts.actions';
import { WidgetAlert } from '../../entity/widget-alert.model';
import { AlertState } from './alerts.state';

export type AlertAction = AlertActions.All;
export const initialState: AlertState = { alerts : new Array(), loading: false};

export function alertReducer(state: AlertState = initialState, action: AlertAction): AlertState {
  switch (action.type) {
    case AlertActions.GET_WIDGET_ALERTS: {
      return {...state, ...{loading: true}};
    }

    case AlertActions.GET_WIDGET_ALERTS_SUCCESS: {
      return {...state, ...{alerts: action.alerts}, ...{loading: false}};
    }

    case AlertActions.GET_WIDGET_ALERTS_ERROR: {
      console.error('Error caught while loading Widgets-alerts', action.error);
      return {...initialState};
    }

    default :
      return state;

  }

}
