<form (keydown)="keyDownFunction($event)" *ngIf="authenticationState | async as auth">
  <div class="form-group" *ngIf="localAuthenticationMode">
    <label for="userName" class="sr-only col-form-label">Username</label>
    <input name="userName" [(ngModel)]="userName" class="form-control" placeholder="Username">
  </div>
  <div class="input-group" *ngIf="localAuthenticationMode">
    <input name="password" type="password" class="form-control" placeholder="Password" [(ngModel)]="password">
    <div class="input-group-append">
      <button class="btn btn-success" type="button" (click)="requestLogin()">Go</button>
    </div>
  </div>
  <div class="alert alert-danger" role="alert" *ngIf="auth.error">
    {{auth.error}}
  </div>

  <div *ngIf="!localAuthenticationMode">
    <div *ngFor="let item of availableAuthenticators | keyvalue : returnZero">
      <div class="form-group">
        <button class="btn btn-success" type="button" (click)="openLogin(item.value)">{{item.key}}</button>
      </div>
    </div>
  </div>
</form>

<div class="brand">
  Bitarisk - Better intelligence through analytics
</div>

<div class="warning">Only attempt to login to this system if you are authorised to do so
  <br/>
  <app-copyright></app-copyright>
</div>