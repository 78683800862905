export function mockNewPortfolioConfig() {
  return [
    {
      'formName': 'Portfolio Info',
      'formLabel': 'PORTFOLIO INFO',
      'rowComponents': [
        {
          'order': 1,
          'key': 'firstRow',
          'components': [
            {
              'key': 'portfolioName',
              'label': 'portfolio name',
              'required': true,
              'controlType': 'text',
              'order': 1,
              'disabled': false
            },
            {
              'key': 'portfolioCurrency',
              'label': 'portfolio FX',
              'required': false,
              'order': 2,
              'controlType': 'dropdown',
              'disabled': false,
              'options': [
                {
                  'key': 'gbp',
                  'value': 'GBP'
                },
                {
                  'key': 'usd',
                  'value': 'USD'
                },
                {
                  'key': 'euro',
                  'value': 'EURO'
                }
              ]
            },
            {
              'key': 'serviceCategory',
              'label': 'Service Category',
              'required': false,
              'controlType': 'dropdown',
              'order': 3,
              'options': [
                {
                  'key': 'managed',
                  'value': 'managed'
                },
                {
                  'key': 'executionOnly',
                  'value': 'executionOnly'
                }
              ]
            }
          ],
        },
      ],
      'formValues': {
        'portfolioName': '',
        'portfolioCurrency': '',
        'serviceCategory': '',
      }
    }
  ];
}
