import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable , of} from 'rxjs';

import { KnownExceptionsService } from '../../account/account-known-exceptions/known-exceptions.service';
import * as MonitorFlagTypeActions from './monitor-flag-type.actions';
import { map, catchError, switchMap } from 'rxjs/operators';

export type MonitorFlagTypeAction = MonitorFlagTypeActions.All;

@Injectable()
export class MonitorFlagTypeEffects {

	constructor(private actions$: Actions,
							private knownExceptionsService: KnownExceptionsService) {
	}

	getAllMonitorFlagTypes: Observable<MonitorFlagTypeAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(MonitorFlagTypeActions.GET_ALL_MONITOR_FLAG_TYPES),
      switchMap((a: MonitorFlagTypeActions.GetAllMonitorFlagTypes) => this.knownExceptionsService.getAllMonitorFlagTypes(a.clientId, a.accountId).pipe(
        map(monitorFlagTypes => new MonitorFlagTypeActions.GetAllMonitorFlagsTypesSuccess(monitorFlagTypes)),
        catchError((error) => of(new MonitorFlagTypeActions.GetAllMonitorFlagTypesError(error)))
      ))));

	}
