import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('AssetColumn')
export class AssetColumn {
  @JsonProperty('title', String)
  title: string = undefined;

  @JsonProperty('hidden', Boolean)
  hidden: boolean = undefined;

  @JsonProperty('configurable', Boolean)
  configurable: boolean = undefined;

  @JsonProperty('enabled', Boolean)
  enabled: boolean = undefined;

  @JsonProperty('width', Number)
  width: number = undefined;

  @JsonProperty('justification', String, true)
  justification: string = undefined;
}
