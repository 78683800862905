export function mockClientAccountDetails() {
    return [
        {
            accountId: 'BMID001HH',
            accountType: 'MandateAccount',
            clientId: 'CID1234',
            name: 'School Fees Mandate',
            source: 'BO',
            value: 123123123,
            currency: 'GBP',
            questionnaire: 'questionnaire120918.pdf',
            questionnaireLabel: '12/09/18',
            scenario: 'ref1234',
            scenarioLabel: 'new',
            monitorFlags: [
                {type: 'Risk', title: 'risk', status: 'OUTLIER', keStatus: 'Approved'},
                {type: 'Concentration', title: 'conc', status: 'OUTLIER', keStatus: 'Rejected'},
                {type: 'Yield Concentration', title: 'Yconc', status: 'OUTLIER', keStatus: 'Approved'},
                {type: 'Issue Concentration', title: 'Iconc', status: 'ALMOSTOUTLIER', keStatus: 'Approved'},
                {type: 'Tracking', title: 'track', status: 'NONOUTLIER', keStatus: 'Rejected'},
                {type: 'Buylist', title: 'buy', status: 'OUTLIER', keStatus: 'Approved'},
                {type: 'Max Holding Weight', title: 'mhw', status: 'OUTLIER', keStatus: 'Rejected'},
                {type: 'Aggregated Asset Allocation', title: 'aAA', status: 'NONOUTLIER', keStatus: 'Rejected'},
                {type: 'Sector', title: 'sector', status: 'NONOUTLIER', keStatus: 'Rejected'},
                {type: 'Commonality', title: 'com', status: 'NONOUTLIER', keStatus: 'Approved'},
                {type: 'High Value Holding', title: 'hvh', status: 'OUTLIER', keStatus: 'Rejected'}
            ],
            subAccounts: [
                {
                    accountId: 'ACID0001',
                    accountType: 'FlatAccount',
                    clientId: 'CID1234',
                    name: 'GIA010'
                },
                {
                    accountId: 'ACID0002',
                    accountType: 'FlatAccount',
                    clientId: 'CID1234',
                    name: 'ISA220'
                }
            ]

        },
        {
            accountId: 'BMID001GG',
            accountType: 'MandateAccount',
            clientId: 'CID1234',
            name: 'Test Growth',
            source: 'User',
            value: 77777,
            currency: 'GBP',
            questionnaire: 'questionnaire241018.pdf',
            questionnaireLabel: '24/10/18',
            scenario: 'ref8976',
            scenarioLabel: 'new',
            monitorFlags: [
                {type: 'Risk', title: 'risk', status: 'NONOUTLIER', keStatus: 'Rejected'},
                {type: 'Concentration', title: 'conc', status: 'NONOUTLIER', keStatus: 'Approved'},
                {type: 'Yield Concentration', title: 'Yconc', status: 'OUTLIER', keStatus: 'Rejected'},
                {type: 'Issue Concentration', title: 'Iconc', status: 'OUTLIER', keStatus: 'Approved'},
                {type: 'Tracking', title: 'track', status: 'NONOUTLIER', keStatus: 'Rejected'},
                {type: 'Buylist', title: 'buy', status: 'OUTLIER', keStatus: 'Approved'},
                {type: 'Max Holding Weight', title: 'mhw', status: 'NONOUTLIER', keStatus: 'Approved'},
                {type: 'Aggregated Asset Allocation', title: 'aAA', status: 'OUTLIER', keStatus: 'Rejected'},
                {type: 'Sector', title: 'sector', status: 'OUTLIER', keStatus: 'Approved'},
                {type: 'Commonality', title: 'com', status: 'NONOUTLIER', keStatus: 'Rejected'},
                {type: 'High Value Holding', title: 'hvh', status: 'OUTLIER', keStatus: 'Rejected'}
            ],
            subAccounts: [
                {
                    accountId: 'ACID0019',
                    accountType: 'FlatAccount',
                    clientId: 'CID1234',
                    name: 'ISA115'
                }
            ]
        },
        {
            accountId: 'BMID001TT',
            accountType: 'MandateAccount',
            clientId: 'CID1234',
            name: 'Test Income',
            source: 'User',
            value: 99999,
            currency: 'GBP',
            questionnaire: 'questionnaire.pdf',
            questionnaireLabel: 'blank',
            scenario: 'ref7634',
            scenarioLabel: 'new',
            monitorFlags: [
                {type: 'Risk', title: 'risk', status: 'OUTLIER', keStatus: 'Rejected'},
                {type: 'Concentration', title: 'conc', status: 'NONOUTLIER', keStatus: 'Rejected'},
                {type: 'Yield Concentration', title: 'Yconc', status: 'OUTLIER', keStatus: 'Rejected'},
                {type: 'Issue Concentration', title: 'Iconc', status: 'NONOUTLIER', keStatus: 'Rejected'},
                {type: 'Tracking', title: 'track', status: 'OUTLIER', keStatus: 'Rejected'},
                {type: 'Buylist', title: 'buy', status: 'NONOUTLIER', keStatus: 'Approved'},
                {type: 'Max Holding Weight', title: 'mhw', status: 'OUTLIER', keStatus: 'Approved'},
                {type: 'Aggregated Asset Allocation', title: 'aAA', status: 'NONOUTLIER', keStatus: 'Approved'},
                {type: 'Sector', title: 'sector', status: 'OUTLIER', keStatus: 'Approved'},
                {type: 'Commonality', title: 'com', status: 'NONOUTLIER', keStatus: 'Approved'},
                {type: 'High Value Holding', title: 'hvh', status: 'OUTLIER', keStatus: 'Rejected'}
            ],
            subAccounts: []

        },
        {
            accountId: 'BMID001VV',
            accountType: 'MandateAccount',
            clientId: 'CID1111',
            name: 'Test Option',
            source: 'User',
            value: 5555,
            currency: 'GBP',
            questionnaire: 'questionnaire.pdf',
            questionnaireLabel: 'blank',
            scenario: 'ref8934',
            scenarioLabel: 'new',
            monitorFlags: [
                {type: 'Risk', title: 'risk', status: 'OUTLIER', keStatus: 'Rejected'},
                {type: 'Concentration', title: 'conc', status: 'NONOUTLIER', keStatus: 'Rejected'},
                {type: 'Yield Concentration', title: 'Yconc', status: 'OUTLIER', keStatus: 'Rejected'},
                {type: 'Issue Concentration', title: 'Iconc', status: 'NONOUTLIER', keStatus: 'Rejected'},
                {type: 'Tracking', title: 'track', status: 'ALMOSTOUTLIER', keStatus: 'Rejected'},
                {type: 'Buylist', title: 'buy', status: 'NONOUTLIER', keStatus: 'Approved'},
                {type: 'Max Holding Weight', title: 'mhw', status: 'OUTLIER', keStatus: 'Approved'},
                {type: 'Aggregated Asset Allocation', title: 'aAA', status: 'NONOUTLIER', keStatus: 'Approved'},
                {type: 'Sector', title: 'sector', status: 'ALMOSTOUTLIER', keStatus: 'Approved'},
                {type: 'Commonality', title: 'com', status: 'NONOUTLIER', keStatus: 'Approved'},
                {type: 'High Value Holding', title: 'hvh', status: 'OUTLIER', keStatus: 'Rejected'}
            ],
            subAccounts: []

        }
    ];
}
