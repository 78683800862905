import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('AccountTradeCash')
class AccountTradeCash {

  @JsonProperty('accountId', Number)
  accountId: number = undefined;

  @JsonProperty('accountName', String)
  accountName: string = undefined;

  @JsonProperty('compositeLabel', String)
  compositeLabel: string = undefined;

  @JsonProperty('gain', Number)
  gain: number = undefined;

  @JsonProperty('total', Number)
  total: number = undefined;

  @JsonProperty('totalCash', Number)
  totalCash: number = undefined;

  @JsonProperty('totalCashWeight', Number)
  totalCashWeight: number = undefined;

  @JsonProperty('tradeCash', Number)
  tradeCash: number = undefined;

}

@JsonObject('TradeCashTotal')
class TradeCashTotal {

  @JsonProperty('gain', Number)
  gain: number = undefined;

  @JsonProperty('total', Number)
  total: number = undefined;

  @JsonProperty('totalCash', Number)
  totalCash: number = undefined;

  @JsonProperty('totalCashWeight', Number)
  totalCashWeight: number = undefined;

  @JsonProperty('tradeCash', Number)
  tradeCash: number = undefined;

}

@JsonObject('TradeCashDetails')
export class TradeCashDetails {

	@JsonProperty('accountTradeCashes', [AccountTradeCash], true)
	accountTradeCashes: AccountTradeCash[] = undefined;

  @JsonProperty('label', String)
  label: string = undefined;

  @JsonProperty('total', TradeCashTotal)
  total: TradeCashTotal = undefined;

}
