import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('SearchResult')
export class SearchResult {

  @JsonProperty('id', Number, true)
  internalAccountId: number = undefined;

  @JsonProperty('baseClientDisplayId', String, true)
  baseClientDisplayId: string = undefined;

  @JsonProperty('baseClientName', String, true)
  baseClientName: string = undefined;

  @JsonProperty('accountNumber', String, true)
  accountNumber: string = undefined;

  @JsonProperty('accountName', String, true)
  accountName: string = undefined;

  @JsonProperty('owner', String, true)
  owner: string = undefined;

  @JsonProperty('accountType', String, true)
  accountType: string = undefined;

  @JsonProperty('subAccounts', [SearchResult], true)
  subAccounts: SearchResult[] = undefined;

  @JsonProperty('accountOwner', String, true)
  accountOwner: string = undefined;

  @JsonProperty('accountManager', String, true)
  accountManager: string = undefined;

}


