import { JsonObject, JsonProperty } from 'json2typescript';
import { TcfdPortfolioInfo } from './tcfd-account-details.model';
import { TcfdHeadlineItem } from './tcfd-shared.model';

@JsonObject('TcfdDetailedMetrics')
export class TcfdDetailedMetrics {
  @JsonProperty('emissions', Number)
  emissions: number = undefined;

  @JsonProperty('coverageWithData', Number)
  coverageWithData: number = undefined;

  @JsonProperty('investment', Number)
  investment: number = undefined;
}

@JsonObject('TcfdDetailedHeadLineItem')
export class TcfdDetailedHeadLineItem extends TcfdHeadlineItem {
  @JsonProperty('equityAndBonds', TcfdDetailedMetrics, true)
  equityAndBonds: TcfdDetailedMetrics = undefined;

  @JsonProperty('funds', TcfdDetailedMetrics, true)
  funds: TcfdDetailedMetrics = undefined;

  @JsonProperty('sovereign', TcfdDetailedMetrics, true)
  sovereign: TcfdDetailedMetrics = undefined;

  @JsonProperty('difference', Number, true)
  difference: number = undefined;

  @JsonProperty('valuePortfolio', Number, true)
  valuePortfolio: number = undefined;

}

@JsonObject('TcfdDetailedHeadLine')
export class TcfdDetailedHeadLine {
  @JsonProperty('title', String)
  title: string = undefined;

  @JsonProperty('note', String, true)
  note: string = "";

  @JsonProperty('headlineItems', [TcfdDetailedHeadLineItem])
  headlineItems: TcfdDetailedHeadLineItem = undefined;

  @JsonProperty('currency', String)
  currency: string = undefined;

  @JsonProperty('currencySymbol', String)
  currencySymbol: string = undefined;
}

@JsonObject('TcfdDetailed')
export class TcfdDetailed {
  @JsonProperty('portfolioInfo', TcfdPortfolioInfo)
  portfolioInfo: TcfdPortfolioInfo = undefined;

  @JsonProperty('ghgEmissions', TcfdDetailedHeadLine)
  ghgEmissions: TcfdDetailedHeadLine = undefined;

  @JsonProperty('carbonFootprint', TcfdDetailedHeadLine)
  carbonFootprint: TcfdDetailedHeadLine = undefined;

  @JsonProperty('weightedAverageCarbonIntensity', TcfdDetailedHeadLine)
  weightedAverageCarbonIntensity: TcfdDetailedHeadLine = undefined;

  @JsonProperty('climateValueAtRisk', TcfdDetailedHeadLine, true)
  climateValueAtRisk: TcfdDetailedHeadLine = undefined;

  @JsonProperty('impliedTemperatureRise', TcfdDetailedHeadLine, true)
  impliedTemperatureRise: TcfdDetailedHeadLine = undefined;

  @JsonProperty('specificExposures', TcfdDetailedHeadLine)
  specificExposures: TcfdDetailedHeadLine = undefined;
}
