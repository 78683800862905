import { TcfdHistory } from './../entity/tcfd/tcfd-history.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JsonConvertService } from '../../shared/json-convert/json-convert.service';
import { TcfdDashboard } from '../entity/tcfd/tcfd-dashboard.model';
import { TcfdDetailed } from '../entity/tcfd/tcfd-detailed.model';
import { environment } from '../../../environments/environment';
import { TcfdAssets } from '../entity/tcfd/tcfd-asset.model';
import { AllClientTcfdFilterConfiguration, ClientTcfdFilterConfiguration } from '../entity/tcfd/tcfd-shared.model';

@Injectable()
export class ClientTcfdService {

  private jsonConverter: JsonConvert;

  constructor(
    private http: HttpClient,
    private jsonConvertService: JsonConvertService
  ) {
    this.jsonConverter = this.jsonConvertService.getJsonConverter();
  }

  loadTcfdDashboardData(clientId: string, accountId: string, fromReportDate: string): Observable<TcfdDashboard> {

    var url = `${environment.serverUrl}/restapi/service/tcfd/client/${clientId}/account/${encodeURIComponent(accountId)}/dashboard`;
    url = fromReportDate ? url + '/' + fromReportDate : url;

    // return this.http.get('/assets/mockData/tcfdDashboard.json')
    return this.http.get(url)
    .pipe(
      map((response) => {
        return this.jsonConverter.deserializeObject(response, TcfdDashboard);
      })
    );
  }

  loadTcfdDetailedData(clientId: string, accountId: string, fromReportDate: string): Observable<TcfdDetailed> {
    // return this.http.get('/assets/mockData/tcfdDetailed.json')
    var url = `${environment.serverUrl}/restapi/service/tcfd/client/${clientId}/account/${encodeURIComponent(accountId)}/detailedMetrics`;
    url = fromReportDate ? url + '/' + fromReportDate : url;

    return this.http.get(url)
    .pipe(
      map((response) => {
        return this.jsonConverter.deserializeObject(response, TcfdDetailed);
      })
    );
  }


  loadTcfdHistoryData(clientId: string, accountId: string, fromReportDate: string, toReportDate: string): Observable<TcfdHistory> {
    // return this.http.get(document.baseURI + 'assets/mockData/tcfdHistory.json')
    // tslint:disable-next-line:max-line-length
    var url = `${environment.serverUrl}/restapi/service/tcfd/client/${clientId}/account/${encodeURIComponent(accountId)}/history`;
    url = fromReportDate ? url + '/fromDate/' + fromReportDate : url;
    url = toReportDate ? url + '/toDate/' + toReportDate : url;
    
    return this.http.get(url)
    .pipe(
      map((response) => {
        return this.jsonConverter.deserializeObject(response, TcfdHistory);
      })
    );
  }

  loadTcfdAssetData(clientId: string, accountId: string):
  Observable<TcfdAssets> {
    // return this.http.get('/assets/mockData/tcfdAsset.json')
    return this.http.get(`${environment.serverUrl}/restapi/service/tcfd/client/${clientId}/account/${encodeURIComponent(accountId)}/assetContributions`)
    .pipe(
      map((response) => {
        return this.jsonConverter.deserializeObject(response, TcfdAssets);
      })
    );
  }


  exportCsv(clientId: string, accountId: string) : Observable<any> {
    return this.http.post(`${environment.serverUrl}/restapi/service/tcfd/client/${clientId}/account/${encodeURIComponent(accountId)}/createCsv`, {},
    { responseType: 'blob' as 'json', observe: 'response'}).pipe(
      map((response: HttpResponse<Blob>) => {
        return response;
      }));
  }

  getFilterConfiguration(clientId: string, accountId: string): Observable<AllClientTcfdFilterConfiguration> {
    return this.http.get(`${environment.serverUrl}/restapi/service/tcfd/client/${clientId}/account/${encodeURIComponent(accountId)}/filter/config`).pipe(
      map(response => {
        const results: ClientTcfdFilterConfiguration[] = response as ClientTcfdFilterConfiguration[];
        this.jsonConverter.deserializeArray(results, ClientTcfdFilterConfiguration);
        var a = new AllClientTcfdFilterConfiguration();
        a.clientTcfdFilter = results[0];
        a.clientTcfdHistoryFilter = results[1];
        return a;
      }));
  }

}
