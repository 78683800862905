import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { KnownExceptionsService } from '../../../account/account-known-exceptions/known-exceptions.service';
import * as KnownExceptionTypesActions from './knownExceptionTypes.actions';

export type KnownExceptionTypeAction = KnownExceptionTypesActions.All;

@Injectable()
export class KnownExceptionTypesEffects {

	constructor(private actions$: Actions,
							private knownExceptionsService: KnownExceptionsService) {
	}

	getAllKnownExceptionTypes: Observable<KnownExceptionTypeAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(KnownExceptionTypesActions.GET_KNOWN_EXCEPTION_TYPES),
      switchMap(() =>  this.knownExceptionsService.getAllKnownExceptionTypes().pipe(
        map(knownExceptionTypes => new KnownExceptionTypesActions.GetKnownExceptionTypesSuccess(knownExceptionTypes)),
        catchError((error) => of(new KnownExceptionTypesActions.GetKnownExceptionTypesError(error)))
      )
    )));
}
