import { Pipe, PipeTransform } from '@angular/core';
import { AssetColumnHeader } from '../entity/asset-column-header.model';
import { AssetColumn } from '../entity/asset-column.model';
import { AssetColumns } from '../entity/asset-columns.model';

@Pipe({
  name: 'columnHeaderVisible'
})
export class ColumnHeaderVisiblePipe implements PipeTransform {
  transform(columns: AssetColumns, header: string): boolean {


    if (columns && columns.columnHeaders) {

      const headerObject = columns.columnHeaders.get(header);
      const headerVisible = headerObject && !headerObject.hidden;

    let atLeastOneChildVisible = false;
    if (headerObject ) {
      headerObject.columns.forEach((value: AssetColumn, key: string) => {
        if (value.enabled) {
          atLeastOneChildVisible = true;
        }
      });
    }

    return headerVisible && atLeastOneChildVisible;
  }
  return false;
  }
}
