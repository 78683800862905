export function mockSearchResults() {
  return [
    {
      clientId: 'DR001',
      clientName: 'Miss Eva Green',
      accountId: 'BMID001HH',
      accountName: 'Boat Fund',
      clientOwner: 'E-JP', // owner is named PRC6 (or Business Getter) in backend code, and it is linked to Account ownerId in DB
      clientType: 'Charity',
      clientStatus: 'Live',
      subAccounts: [
        {accountId: 'ACID001', accountName: 'GIA010'},
        {accountId: 'ACID002', accountName: 'ISA220'}
      ]
    },
    {
      clientId: 'NM002',
      clientName: 'Mrs Miggins',
      accountId: 'BMID001GG',
      accountName: 'School Fees Mandate',
      clientOwner: 'E-JP', // owner is named PRC6 (or Business Getter) in backend code, and it is linked to Account ownerId in DB
      clientType: 'Trust',
      clientStatus: 'Questionnaire',
      subAccounts: [
        {accountId: 'ACID007', accountName: 'ISA098'}
      ]
    },
    {
      clientId: 'IE003',
      clientName: 'Lady Violet Green',
      accountId: 'BMID038YH',
      accountName: 'Ski Lodge',
      clientOwner: 'E-JP', // owner is named PRC6 (or Business Getter) in backend code, and it is linked to Account ownerId in DB
      clientType: 'Company',
      clientStatus: 'Proposal',
      subAccounts: [
        {accountId: 'ACID004', accountName: 'GIA004'}
      ]
    },
    {
      clientId: 'IE003',
      clientName: 'Lady Violet Green',
      accountId: 'BMID071HX',
      accountName: 'Shooting Fund',
      clientOwner: 'E-JP', // owner is named PRC6 (or Business Getter) in backend code, and it is linked to Account ownerId in DB
      clientType: 'Individual',
      clientStatus: 'Inquiry',
      subAccounts: [
        {accountId: 'ACID005', accountName: 'GIA001'}
      ]
    }
  ];
}
