export function mockMonitorCriterions() {
  return {
    id: '1',
    preSet :
    [
      {
        label: 'all',
        parameters:
        {
          branches: ['Key:LONDON', 'Key:LIVERPOOL', 'Key:MANCHESTER'],
          managers: ['Key:AGB', 'Key:AMR', 'Key:DJR'],
          models: ['Key:LOW INC', 'Key:MED INC', 'Key:HI INC'],
          serviceCategories: ['Key:ADVISORY', 'Key:DISCRETIONARY', 'Key:NON CUSTODY']
        },
        statusGroup:
        {
          genericParams: '1',
          outlierReportParams: '21'
        }
      },
      {
        label: 'mine',
        parameters:
        {
          branches: ['Key:LONDON', 'Key:LIVERPOOL'],
          managers: ['Key:AGB', 'Key:DJR'],
          models: ['Key:LOW INC', 'Key:MED INC', 'Key:HI INC'],
          serviceCategories: ['Key:ADVISORY', 'Key:DISCRETIONARY', 'Key:NON CUSTODY']
        },
        statusGroup:
        {
            genericParams: '2',
            outlierReportParams: '21'
        }
      },
      {
        label: 'London',
        parameters:
        {
          branches: ['Key:LONDON'],
          managers: ['Key:DJR'],
          models: ['Key:LOW INC', 'Key:MED INC', 'Key:HI INC'],
          serviceCategories: ['Key:ADVISORY', 'Key:DISCRETIONARY']
        },
        statusGroup:
        {
            genericParams: '3',
            outlierReportParams: '22'
        }
      },
      {
        label: 'custom',
        parameters:
        {
          branches: [],
          managers: [],
          models: [],
          serviceCategories: []
        },
        statusGroup:
        {
            genericParams: '',
            outlierReportParams: ''
        }
      }
    ],

    monitorParameters:
    {
      branches:
        [
            { label: 'LONDON', value: 'Key:LONDON' },
            { label: 'LIVERPOOL', value: 'Key:LIVERPOOL' },
            { label: 'MANCHESTER', value: 'Key:MANCHESTER' }
        ],
        managers:
        [
            { label: 'AGB', value: 'Key:AGB' },
            { label: 'AMR', value: 'Key:AMR' },
            { label: 'DJR', value: 'Key:DJR' }
        ],
        models:
        [
            { label: 'LOW INC', value: 'Key:LOW INC' },
            { label: 'MED INC', value: 'Key:MED INC' },
            { label: 'HI INC', value: 'Key:HI INC' }
        ],
        serviceCategories:
        [
            { label: 'ADVISORY', value: 'Key:ADVISORY' },
            { label: 'DISCRETIONARY', value: 'Key:DISCRETIONARY' },
            { label: 'NON CUSTODY', value: 'Key:NON CUSTODY' }
        ]
    },

    statusGroup:
    {
      genericParams:
      [
          { label: 'Outliers - excluding KE', value: '1' },
          { label: 'Outliers - including KE', value: '2' },
          { label: 'OK and Approved KE Portfolios', value: '3' },
          { label: 'All Portfolios', value: '4'}
      ],
      outlierReportParams:
      [
          { label: 'All outliers', value: '21' },
          { label: 'Only outliers over 20 business days', value: '22' }
      ]
  },

    reportSelection:
    [
      {
        id: 12395,
        name: 'MIS-Traffic-Lights',
        description: 'Traffic light report',
        filePath: '/generated_reports/generate324234234234234'
      },
      {
        id: 12346,
        name: 'teamReport',
        description: 'Team report',
        filePath: '/generated_reports/generate32423423234244'
      },
      {
        id: 12347,
        name: 'MIS-Risk',
        description: 'Risk report',
        filePath: '/generated_reports/generate32423234234234'
      },
      {
        id: 12348,
        name: 'MIS-Tracking',
        description: 'Tracking error report',
        filePath: '/generated_reports/generate325644564234234'
      },
      {
        id: 12349,
        name: 'MIS-Known-Exception',
        description: 'Known Exceptions Live Report',
        filePath: '/generated_reports/generate324234564554234'
      }
    ]
  };
}
