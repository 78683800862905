import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable , of} from 'rxjs';
import { ClientPmpService } from '../../../shared/pmp/pmp.service';
import * as MonitorFlagActions from './monitor-flag.actions';
import { map, catchError, switchMap } from 'rxjs/operators';

export type MonitorFlagAction = MonitorFlagActions.All;

@Injectable()
export class MonitorFlagEffects {

	constructor(private actions$: Actions,
							private clientSlwiService: ClientPmpService) {
	}

	getAllWidgetMonitorFlagRisk: Observable<MonitorFlagAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(MonitorFlagActions.GET_ALL_WIDGET_MONITOR_FLAGS),
      switchMap((a: MonitorFlagActions.GetAllWidgetMonitorFlags) =>
			this.clientSlwiService.getAllMonitorFlag(a.clientId, a.accountId).pipe(
        map(widgetMonitorFlag => new MonitorFlagActions.GetAllWidgetMonitorFlagsSuccess(widgetMonitorFlag)),
        catchError((error) => of(new MonitorFlagActions.GetAllWidgetMonitorFlagsError(error)))
      )
			)
    ));

	}
