import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ReadOnlyPortfolioModel } from '../entity/read-only-portfolio.model';
import { JsonConvertService } from '../json-convert/json-convert.service';

@Injectable({
  providedIn: 'root'
})
export class ReadOnlyPortfolioService {
  private jsonConverter: JsonConvert;

  constructor(
    private httpClient: HttpClient,
    private jsonConvertService: JsonConvertService
  ) {
    this.jsonConverter =  this.jsonConvertService.getJsonConverter();
   }

  getReadOnlyPortfolioModel(clientId: string, accountId: string, scenarioName: string): Observable<ReadOnlyPortfolioModel> {
		return this.httpClient
		.get(`${environment.serverUrl}/restapi/service/portfolioReadOnly/client/${clientId}/account/${encodeURIComponent(accountId)}/scenario/${scenarioName}`).pipe(
			map(response => {
				const pcm = this.jsonConverter.deserializeObject(response, ReadOnlyPortfolioModel);
				return pcm;
      })
    );
  }
}
