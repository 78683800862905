import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { JsonConvert } from 'json2typescript';

import { map } from 'rxjs/operators';
import { JsonConvertService } from '../../shared/json-convert/json-convert.service';
import { AssetResult } from '../../shared/entity/asset.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class AssetService {
  private jsonConverter: JsonConvert;

  constructor(private httpClient: HttpClient, private jsonConvertService: JsonConvertService) {
    this.jsonConverter =  this.jsonConvertService.getJsonConverter();
  }

  getAllAssets(clientId?: string, accountId?: string): Observable<AssetResult[]>  {

    let params = {};
    if (clientId && accountId) {
      params = {
        clientId: clientId,
        accountId: accountId
       };
    }

    return this.httpClient.post(`${environment.serverUrl}/restapi/service/search/assets`,
    { sedol: '',
      description: '',
      currency: '',
      subAssetClass: '',
      composite: '',
      includeNonBuyList: true}, {params: params}).pipe(
      map(response => {
        const assetResults: AssetResult[] = response as AssetResult[];
        return this.jsonConverter.deserializeArray(assetResults, AssetResult);
      }));

  }

}
