export function mockClientFileNotes() {
  return [
      {
        id: 'FN1234',
        clientId: 'CID1234',
        accountId: 'BMID001HH',
        fileNoteType: 'Key Q',
        fileNoteDesc: 'testing12',
        fileNoteContent: 'There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain',
        lastModifiedDate: '2017-07-19 10:00:00',
        lastModifiedBy: 'user_a'
      },
      {
        id: 'FN4321',
        clientId: 'CID1234',
        accountId: 'BMID001GG',
        fileNoteType: 'Test Q',
        fileNoteDesc: 'testing23',
        fileNoteContent: 'There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain',
        lastModifiedDate: '2016-12-19 10:00:00',
        lastModifiedBy: 'user_b'
      },
      {
        id: 'FN3456',
        clientId: 'CID1234',
        accountId: 'BMID001TT',
        fileNoteType: 'Tester Q',
        fileNoteDesc: 'testing34',
        fileNoteContent: 'There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain',
        lastModifiedDate: '2016-10-10 10:00:00',
        lastModifiedBy: 'user_c'
      },
      {
        id: 'FN3496',
        clientId: 'CID9999',
        accountId: 'BMID001VV',
        fileNoteType: 'Tester Q',
        fileNoteDesc: 'testing45',
        fileNoteContent: 'There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain',
        lastModifiedDate: '2017-07-19 10:00:00',
        lastModifiedBy: 'user_c'
      }
  ];
}
