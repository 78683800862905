import { Action } from '@ngrx/store';

import * as KnownExceptionActions from './knownExceptions.actions';
import { KnownExceptionState } from './knownExceptions.state';
import { KnownException } from '../../../entity/known-exception.model';

export type KnownExceptionAction = KnownExceptionActions.All;
export const initialState: KnownExceptionState = {  pendingKnownExceptions : new Array<KnownException>(),
                                                    pendingReviewKnownExceptions : new Array<KnownException>(),
                                                    pausedKnownExceptions : new Array<KnownException>(),
                                                    currentKnownExceptions : new Array<KnownException>(),
                                                    rejectedKnownExceptions : new Array<KnownException>(),
                                                    reversedKnownExceptions : new Array<KnownException>(),
                                                    historyKnownExceptions : new Array<KnownException>(),
                                                    addKnownExceptionSuccess: false,
                                                    errorMessage: null,
                                                  };

export function knownExceptionReducer(state: KnownExceptionState = initialState, action: KnownExceptionAction): KnownExceptionState {
  switch (action.type) {

    case KnownExceptionActions.GET_KNOWN_EXCEPTIONS_SUCCESS: {
      return {...state,
        ...{pendingKnownExceptions: action.knownExceptionState.pendingKnownExceptions},
        ...{pendingReviewKnownExceptions: action.knownExceptionState.pendingReviewKnownExceptions},
        ...{pausedKnownExceptions: action.knownExceptionState.pausedKnownExceptions},
        ...{currentKnownExceptions: action.knownExceptionState.currentKnownExceptions},
        ...{rejectedKnownExceptions: action.knownExceptionState.rejectedKnownExceptions},
        ...{reversedKnownExceptions: action.knownExceptionState.reversedKnownExceptions},
        ...{historyKnownExceptions: action.knownExceptionState.historyKnownExceptions}
    };
    }

    case KnownExceptionActions.GET_KNOWN_EXCEPTIONS_ERROR: {
      console.error('Error caught while loading knownExceptions', action.error);
      return {...initialState};
    }

    case KnownExceptionActions.ADD_KNOWN_EXCEPTION: {
      return {...state, ...{addKnownExceptionSuccess: false}, ...{errorMessage: null},

      };
    }
    case KnownExceptionActions.ADD_KNOWN_EXCEPTION_SUCCESS: {
      return {...state, ...{addKnownExceptionSuccess: true},
        ...{pendingKnownExceptions: action.knownExceptionState.pendingKnownExceptions},
        ...{pendingReviewKnownExceptions: action.knownExceptionState.pendingReviewKnownExceptions},
        ...{pausedKnownExceptions: action.knownExceptionState.pausedKnownExceptions},
        ...{currentKnownExceptions: action.knownExceptionState.currentKnownExceptions},
        ...{rejectedKnownExceptions: action.knownExceptionState.rejectedKnownExceptions},
        ...{reversedKnownExceptions: action.knownExceptionState.reversedKnownExceptions},
        ...{historyKnownExceptions: action.knownExceptionState.historyKnownExceptions}
          };
    }

    case KnownExceptionActions.ADD_KNOWN_EXCEPTION_ERROR: {
      console.error('Error caught while adding knownExceptions', action.error);
      return {...state, ...{addKnownExceptionSuccess: false}, ...{errorMessage: action.error}};
    }

     case KnownExceptionActions.ADD_KNOWN_EXCEPTION: {
      return {...state, ...{addKnownExceptionSuccess: false}, ...{errorMessage: null},

      };
    }
    case KnownExceptionActions.ADD_KNOWN_EXCEPTION_SUCCESS: {
      return {...state, ...{addKnownExceptionSuccess: true},
        ...{pendingKnownExceptions: action.knownExceptionState.pendingKnownExceptions},
        ...{pendingReviewKnownExceptions: action.knownExceptionState.pendingReviewKnownExceptions},
        ...{pausedKnownExceptions: action.knownExceptionState.pausedKnownExceptions},
        ...{currentKnownExceptions: action.knownExceptionState.currentKnownExceptions},
        ...{rejectedKnownExceptions: action.knownExceptionState.rejectedKnownExceptions},
        ...{reversedKnownExceptions: action.knownExceptionState.reversedKnownExceptions},
        ...{historyKnownExceptions: action.knownExceptionState.historyKnownExceptions}
          };
    }

    case KnownExceptionActions.ADD_KNOWN_EXCEPTION_ERROR: {
      console.error('Error caught while adding knownExceptions', action.error);
      return {...state, ...{addKnownExceptionSuccess: false}, ...{errorMessage: action.error}};
    }

    case KnownExceptionActions.DELETE_KNOWN_EXCEPTION_SUCCESS: {
      return {...state, ...{addKnownExceptionSuccess: true},
        ...{pendingKnownExceptions: action.knownExceptionState.pendingKnownExceptions},
        ...{pendingReviewKnownExceptions: action.knownExceptionState.pendingReviewKnownExceptions},
        ...{pausedKnownExceptions: action.knownExceptionState.pausedKnownExceptions},
        ...{currentKnownExceptions: action.knownExceptionState.currentKnownExceptions},
        ...{rejectedKnownExceptions: action.knownExceptionState.rejectedKnownExceptions},
        ...{reversedKnownExceptions: action.knownExceptionState.reversedKnownExceptions},
        ...{historyKnownExceptions: action.knownExceptionState.historyKnownExceptions}
          };
    }

    case KnownExceptionActions.DELETE_KNOWN_EXCEPTION_ERROR: {
      console.error('Error caught while deleting knownExceptions', action.error);
      return {...state, ...{addKnownExceptionSuccess: false}, ...{errorMessage: action.error}};
    }

    case KnownExceptionActions.ADD_EXCLUDE_HOLDING_KE: {
      return {...state, ...{addKnownExceptionSuccess: false}, ...{errorMessage: null},

      };
    }
    case KnownExceptionActions.ADD_EXCLUDE_HOLDING_KE_SUCCESS: {
      return {...state, ...{addKnownExceptionSuccess: true},
        ...{pendingKnownExceptions: action.knownExceptionState.pendingKnownExceptions},
        ...{pendingReviewKnownExceptions: action.knownExceptionState.pendingReviewKnownExceptions},
        ...{pausedKnownExceptions: action.knownExceptionState.pausedKnownExceptions},
        ...{currentKnownExceptions: action.knownExceptionState.currentKnownExceptions},
        ...{rejectedKnownExceptions: action.knownExceptionState.rejectedKnownExceptions},
        ...{reversedKnownExceptions: action.knownExceptionState.reversedKnownExceptions},
        ...{historyKnownExceptions: action.knownExceptionState.historyKnownExceptions}
          };
    }

    case KnownExceptionActions.ADD_EXCLUDE_HOLDING_KE_ERROR: {
      console.error('Error caught while adding knownExceptions', action.error);
      return {...state, ...{addKnownExceptionSuccess: false}, ...{errorMessage: action.error}};
    }

    case KnownExceptionActions.GET_KNOWN_EXCEPTION_REQUEST_DECISIONS_SUCCESS: {
      const ke = action.knownException;
      ke.KnownExceptionRequestDecisions = action.knownExceptionDecision;
      return {...state };
    }

    case KnownExceptionActions.GET_KNOWN_EXCEPTION_REQUEST_DECISIONS_ERROR: {
      console.error('Error caught while retrieving ker decisions', action.error);
      return {...state };
    }

    default :
      return state;
  }

}

