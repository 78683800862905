import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JsonConvert } from 'json2typescript';
import { JsonConvertService } from '../../shared/json-convert/json-convert.service';
import { ClientAap } from '../entity/client-aap.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ClientAapService {
  private jsonConverter: JsonConvert;

  constructor(private httpClient: HttpClient, private jsonConvertService: JsonConvertService) {
    this.jsonConverter =  this.jsonConvertService.getJsonConverter();
  }

  getClientAap(clientId: string, accountId: string, scenarioId: string): Observable<ClientAap> {

    let params = new HttpParams();

    if (scenarioId) {
      params = params.append('sid', scenarioId);
    }

    return this.httpClient.get(`${environment.serverUrl}/restapi/service/aap/client/${clientId}/account/${encodeURIComponent(accountId)}`, {
      params
    })
      .pipe(map(response => this.jsonConverter.deserializeObject(response, ClientAap)));
  }
}
