import { Action } from '@ngrx/store';
import { GenericFormConfiguration } from '../../entity/generic-form/generic-form.model';

export const GET_IPR_FILE_NOTES_CONFIG = '[IprFileNotes] GET_IPR_FILE_NOTES_CONFIG';
export const GET_IPR_FILE_NOTES_CONFIG_SUCCESS = '[IprFileNotes] GET_IPR_FILE_NOTES_CONFIG_SUCCESS';
export const GET_IPR_FILE_NOTES_CONFIG_ERROR = '[IprFileNotes] GET_IPR_FILE_NOTES_CONFIG_ERROR';

export class GetIprFileNotesConfig implements Action {
	readonly type = GET_IPR_FILE_NOTES_CONFIG;
	constructor(public clientId: string, public accountId: string) {}
}

export class GetIprFileNotesConfigSuccess implements Action {
	readonly type = GET_IPR_FILE_NOTES_CONFIG_SUCCESS;
	constructor(public iprFileNotesConfig: GenericFormConfiguration) {}
}

export class GetIprFileNotesConfigError implements Action {
	readonly type = GET_IPR_FILE_NOTES_CONFIG_ERROR;
	constructor(public error: any) {
	}
}

export type All = GetIprFileNotesConfig | GetIprFileNotesConfigSuccess | GetIprFileNotesConfigError;
