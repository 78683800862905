import { Action } from '@ngrx/store';

import { WidgetModel } from '../../entity/widget-model.model';
import { ModelState } from './models.state';

export const GET_WIDGET_MODELS = '[WidgetModel] GET_WIDGET_MODELS';
export const GET_WIDGET_MODELS_SUCCESS = '[WidgetModel] GET_WIDGET_MODELS_SUCCESS';
export const GET_WIDGET_MODELS_ERROR = '[WidgetModel] GET_WIDGET_MODELS_ERROR';

export class GetWidgetModels implements Action {
	readonly type = GET_WIDGET_MODELS;
	constructor(public clientId: string, public accountId: string) {}
}

export class GetWidgetModelsSuccess implements Action {
	readonly type = GET_WIDGET_MODELS_SUCCESS;
	constructor(public models: ModelState) {}
}

export class GetWidgetModelsError implements Action {
	readonly type = GET_WIDGET_MODELS_ERROR;
	constructor(public error: any) {
	}
}

export type All = GetWidgetModels | GetWidgetModelsSuccess | GetWidgetModelsError;
