import { JsonConverter, JsonCustomConvert, JsonConvert, ValueCheckingMode } from 'json2typescript';
import { AssetColumn } from '../entity/asset-column.model';

@JsonConverter
export class AssetColumnMapConverter implements JsonCustomConvert<Map<string, AssetColumn>> {

  private jsonConverter: JsonConvert;

  constructor() {
    this.jsonConverter = new JsonConvert();
    this.jsonConverter.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
  }

  serialize(data: Map<string, AssetColumn>): Object {
    const obj = {};

    data.forEach(function(value, key) {
      obj[key] = value;
    });

    return obj;
  }

  deserialize(jsonObject: any): Map<string, AssetColumn> {
    if (jsonObject) {
      const mapData = new Map<string, AssetColumn>();
      for (const k of Object.keys(jsonObject)) {
        mapData.set(k, this.jsonConverter.deserializeObject(jsonObject[k], AssetColumn) );
      }
      return mapData;
    } else {
      return null;
    }
  }
}
