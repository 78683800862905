import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import * as currentPortfolioActions from '../account-current-portfolio/account-current-portfolio.actions';
import { KnownExceptionsService } from '../../../account/account-known-exceptions/known-exceptions.service';
import * as KnownExceptionActions from './knownExceptions.actions';
import * as monitorPortfolioActions from '../monitor-portfolio/monitor-portfolio.actions';

import { map, catchError, switchMap } from 'rxjs/operators';
import { NavActionService } from '../../../../core/navigation-floating/nav-action.service';

export type KnownExceptionAction = KnownExceptionActions.All;

@Injectable()
export class KnownExceptionEffects {

	constructor(private actions$: Actions,
							private knownExceptionsService: KnownExceptionsService,
              private store: Store<any>,
              private navActionService: NavActionService) {
	}

	getAllKnownException: Observable<KnownExceptionAction> = createEffect(() => 
  this.actions$.pipe(
    ofType(KnownExceptionActions.GET_KNOWN_EXCEPTIONS),
    switchMap((action: KnownExceptionActions.GetKnownExceptions) =>
    this.knownExceptionsService.getAllKnownException(action.clientId, action.accountId).pipe(
      map(knownExceptions => new KnownExceptionActions.GetKnownExceptionsSuccess(knownExceptions)),
      catchError((error) => of(new KnownExceptionActions.GetKnownExceptionsError(error)))
    )
  )));

	addKnownException: Observable<KnownExceptionAction> = createEffect(() => 
  this.actions$.pipe(
    ofType(KnownExceptionActions.ADD_KNOWN_EXCEPTION),
    switchMap((ke: KnownExceptionActions.AddKnownException) =>
      this.knownExceptionsService.addKnownException(ke.addKnownExceptionRequest).pipe(
      map(knownExceptions => {
        this.store.dispatch(new monitorPortfolioActions.GetMonitorPortfolio(ke.addKnownExceptionRequest.clientId,
             ke.addKnownExceptionRequest.accountId));
        return new KnownExceptionActions.AddKnownExceptionSuccess(knownExceptions); }),
        catchError((error) => {
          this.navActionService.addMessage('Error', 'Cannot add Known Exception', 'error');
          return of(new KnownExceptionActions.AddKnownExceptionError(error));
        })
    ))
  ));

	deleteKnownException: Observable<KnownExceptionAction> = createEffect(() => 
  this.actions$.pipe(
    ofType(KnownExceptionActions.DELETE_KNOWN_EXCEPTION),
    switchMap((ke: KnownExceptionActions.DeleteKnownException) =>  this.knownExceptionsService.deleteKnownException(ke.id).pipe(
      map(knownExceptions => {
        this.store.dispatch(new monitorPortfolioActions.GetMonitorPortfolio(ke.clientId,
          ke.accountId));
        this.store.dispatch(new currentPortfolioActions.GetAccountCurrentPortfolio(ke.clientId,
          ke.accountId, false));
        return new KnownExceptionActions.DeleteKnownExceptionSuccess(knownExceptions); }),
        catchError((error) => of(new KnownExceptionActions.DeleteKnownExceptionError(error)))

    )
	)));

	addExcludeHoldingKnownException: Observable<KnownExceptionAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(KnownExceptionActions.ADD_EXCLUDE_HOLDING_KE),
      switchMap((ke: KnownExceptionActions.AddKnownException) =>
      this.knownExceptionsService.addExcludeHoldingKnownException(ke.addKnownExceptionRequest).pipe(
        map(knownExceptions => {
          this.store.dispatch(new monitorPortfolioActions.GetMonitorPortfolio(ke.addKnownExceptionRequest.clientId,
            ke.addKnownExceptionRequest.accountId));
          this.store.dispatch(new currentPortfolioActions.GetAccountCurrentPortfolio(ke.addKnownExceptionRequest.clientId,
            ke.addKnownExceptionRequest.accountId, false));
          return new KnownExceptionActions.AddExcludeHoldingKESuccess(knownExceptions); }),
          catchError((error) => of(new KnownExceptionActions.AddExcludeHoldingKEError(error)))
      )
)));

	getAllKnownExceptionDecision: Observable<KnownExceptionAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(KnownExceptionActions.GET_KNOWN_EXCEPTION_REQUEST_DECISIONS),
      switchMap((action: KnownExceptionActions.GetKnownExceptionRequestDecisions) =>
      this.knownExceptionsService.getKnownExceptionRequestDecisions(action.knownException.id).pipe(
        map(knownExceptionDecisions =>
          new KnownExceptionActions.GetKnownExceptionRequestDecisionsSuccess(action.knownException, knownExceptionDecisions)),
          catchError((error) => of(new KnownExceptionActions.GetKnownExceptionRequestDecisionsError(error)))
      )
    )));

}
