export function mockClientPortfolios() {
  return [
    { 'id': '123',
      'displayId': 'clientId123',
      'portfolios': [
        {
          'portfolioName': 'portfolioName1',
          'portfolioCurrency': 'gbp',
          'serviceCategory': 'managed',
        },
        {
          'portfolioName': 'portfolioName2',
          'portfolioCurrency': 'usd',
          'serviceCategory': 'executionOnly',
        },
        {
          'portfolioName': 'portfolioName3',
          'portfolioCurrency': 'euro',
          'serviceCategory': 'managed',
        }
      ]
  }
  ];
}
