import { Action } from '@ngrx/store';

import { BmidHoldingList } from '../../entity/bmid-holding-list.model';

export const GET_BMID_HOLDING_LIST = '[BmidHoldingList] GET_BMID_HOLDING_LIST';
export const GET_BMID_HOLDING_LIST_SUCCESS = '[BmidHoldingList]  GET_BMID_HOLDING_LIST_SUCCESS';
export const GET_BMID_HOLDING_LIST_ERROR = '[BmidHoldingList] GET_BMID_HOLDING_LIST_ERROR';

export class GetBmidHoldingList implements Action {
  readonly type = GET_BMID_HOLDING_LIST;
  constructor(public monitorFlagName: string, public chartType: string, public outlierStatusType, public withKE) {}
}

export class GetBmidHoldingListSuccess implements Action {
  readonly type = GET_BMID_HOLDING_LIST_SUCCESS;
  constructor(public bmidHoldingList: BmidHoldingList) { }
}

export class GetBmidHoldingListError implements Action {
  readonly type = GET_BMID_HOLDING_LIST_ERROR;
}

export type All = GetBmidHoldingList | GetBmidHoldingListSuccess | GetBmidHoldingListError;
