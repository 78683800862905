import { Action } from '@ngrx/store';

import { PortfolioConstructionModel, EditProperty } from '../../entity/portfolio-construction-model.model';
import { AssetResult } from '../../entity/asset.model';
import { StartingPortfolioType } from '../../../shared/entity/starting-portfolio-type.enum';
import { AssetValue } from '../../entity/asset-value.model';
import { GenericFormConfiguration } from '../../entity/generic-form/generic-form.model';
import { PortfolioMessage } from '../../entity/portfolio-message-model';

export const GET_PORTFOLIO_CONSTRUCTION_MODEL = '[WidgetPortfolioConstruction] GET_PORTFOLIO_CONSTRUCTION_MODEL';
export const GET_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS = '[WidgetPortfolioConstruction] GET_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS';
export const GET_PORTFOLIO_CONSTRUCTION_MODEL_ERROR = '[WidgetPortfolioConstruction] GET_PORTFOLIO_CONSTRUCTION_MODEL_ERROR';

export const GET_PORTFOLIO_CONSTRUCTION_PAGE = '[WidgetPortfolioConstruction] GET_PORTFOLIO_CONSTRUCTION_PAGE';
export const GET_PORTFOLIO_CONSTRUCTION_PAGE_SUCCESS = '[WidgetPortfolioConstruction] GET_PORTFOLIO_CONSTRUCTION_PAGE_SUCCESS';
export const GET_PORTFOLIO_CONSTRUCTION_PAGE_ERROR = '[WidgetPortfolioConstruction] GET_PORTFOLIO_CONSTRUCTION_PAGE_ERROR';

export const GET_PORTFOLIO_CONSTRUCTION_MODEL_ADJUSTMENT = '[WidgetPortfolioConstruction] GET_PORTFOLIO_CONSTRUCTION_MODEL_ADJUSTMENT';
export const GET_IMPORTED_TARGET_WEIGHT = '[WidgetPortfolioConstruction] GET_IMPORTED_TARGET_WEIGHT';
export const GET_IMPORTED_TARGET_WEIGHT_SUCCESS = '[WidgetPortfolioConstruction] GET_IMPORTED_TARGET_WEIGHT_SUCCESS';
export const GET_IMPORTED_TARGET_WEIGHT_ERROR = '[WidgetPortfolioConstruction] GET_IMPORTED_TARGET_WEIGHT_ERROR';
export const GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH = '[WidgetPortfolioConstruction] GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH';
export const GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH_REVERT =
	'[WidgetPortfolioConstruction] GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH_REVERT';
// tslint:disable-next-line:max-line-length
export const GET_PORTFOLIO_CONSTRUCTION_MODEL_ADJUSTMENT_SUCCESS = '[WidgetPortfolioConstruction] GET_PORTFOLIO_CONSTRUCTION_MODEL_ADJUSTMENT_SUCCESS';
// tslint:disable-next-line:max-line-length
export const GET_PORTFOLIO_CONSTRUCTION_MODEL_ADJUSTMENT_ERROR = '[WidgetPortfolioConstruction] GET_PORTFOLIO_CONSTRUCTION_MODEL_ADJUSTMENT_ERROR';

export const GET_NEW_PORTFOLIO_CONSTRUCTION_MODEL = '[WidgetPortfolioConstruction] GET_NEW_PORTFOLIO_CONSTRUCTION_MODEL';
export const GET_NEW_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS = '[WidgetPortfolioConstruction] GET_NEW_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS';
export const GET_NEW_PORTFOLIO_CONSTRUCTION_MODEL_ERROR = '[WidgetPortfolioConstruction] GET_NEW_PORTFOLIO_CONSTRUCTION_MODEL_ERROR';

export const GET_NEW_PORTFOLIO_CONSTRUCTION_PAGE = '[WidgetPortfolioConstruction] GET_NEW_PORTFOLIO_CONSTRUCTION_PAGE';
export const GET_NEW_PORTFOLIO_CONSTRUCTION_PAGE_SUCCESS = '[WidgetPortfolioConstruction] GET_NEW_PORTFOLIO_CONSTRUCTION_PAGE_SUCCESS';
export const GET_NEW_PORTFOLIO_CONSTRUCTION_PAGE_ERROR = '[WidgetPortfolioConstruction] GET_NEW_PORTFOLIO_CONSTRUCTION_PAGE_ERROR';

export const ADD_HOLDING = '[WidgetPortfolioConstruction] ADD_HOLDING';
export const ADD_HOLDING_SUCCESS = '[WidgetPortfolioConstruction] ADD_HOLDING_SUCCESS';
export const ADD_HOLDING_ERROR = '[WidgetPortfolioConstruction] ADD_HOLDING_ERROR';

export const RECALCULATE_ALL = '[WidgetPortfolioConstruction] RECALCULATE_ALL';
export const RECALCULATE_ALL_SUCCESS = '[WidgetPortfolioConstruction] RECALCULATE_ALL_SUCCESS';
export const RECALCULATE_ALL_ERROR = '[WidgetPortfolioConstruction] RECALCULATE_ALL_ERROR';

export const ADJUST_BOUNDARIES = '[WidgetPortfolioConstruction] ADJUST_BOUNDARIES';
export const ADJUST_BOUNDARIES_SUCCESS = '[WidgetPortfolioConstruction] ADJUST_BOUNDARIES_SUCCESS';
export const ADJUST_BOUNDARIES_ERROR = '[WidgetPortfolioConstruction] ADJUST_BOUNDARIES_ERROR';

export const UPDATE_ACCOUNT_HOLDINGS = '[WidgetPortfolioConstruction] UPDATE_ACCOUNT_HOLDINGS';
export const UPDATE_ACCOUNT_HOLDINGS_SUCCESS = '[WidgetPortfolioConstruction] UPDATE_ACCOUNT_HOLDINGS_SUCCESS';
export const UPDATE_ACCOUNT_HOLDINGS_ERROR = '[WidgetPortfolioConstruction] UPDATE_ACCOUNT_HOLDINGS_ERROR';

export const GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH_SUCCESS =
	'[WidgetPortfolioConstruction] GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH_SUCCESS';
export const GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH_ERROR =
	'[WidgetPortfolioConstruction] GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH_ERROR';

export const GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH_REVERT_SUCCESS =
	'[WidgetPortfolioConstruction] GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH_REVERT_SUCCESS';
export const GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH_REVERT_ERROR =
	'[WidgetPortfolioConstruction] GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH_REVERT_ERROR';

export const UPDATE_PORTFOLIO_CONSTRUCTION_MODEL = '[WidgetPortfolioConstruction] UPDATE_PORTFOLIO_CONSTRUCTION_MODEL';
export const UPDATE_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS = '[WidgetPortfolioConstruction] UPDATE_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS';
export const UPDATE_PORTFOLIO_CONSTRUCTION_MODEL_ERROR = '[WidgetPortfolioConstruction] UPDATE_PORTFOLIO_CONSTRUCTION_MODEL_ERROR';

export const SAVE_UPDATE_PORTFOLIO_CONSTRUCTION_MODEL = '[WidgetPortfolioConstruction] SAVE_UPDATE_PORTFOLIO_CONSTRUCTION_MODEL';
// tslint:disable-next-line:max-line-length
export const SAVE_UPDATE_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS = '[WidgetPortfolioConstruction] SAVE_UPDATE_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS';
// tslint:disable-next-line:max-line-length
export const SAVE_UPDATE_PORTFOLIO_CONSTRUCTION_MODEL_ERROR = '[WidgetPortfolioConstruction] SAVE_UPDATE_PORTFOLIO_CONSTRUCTION_MODEL_ERROR';

export const CREATE_PORTFOLIO_CONSTRUCTION_MODEL = '[WidgetPortfolioConstruction] CREATE_PORTFOLIO_CONSTRUCTION_MODEL';
export const CREATE_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS = '[WidgetPortfolioConstruction] CREATE_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS';
export const CREATE_PORTFOLIO_CONSTRUCTION_MODEL_ERROR = '[WidgetPortfolioConstruction] CREATE_PORTFOLIO_CONSTRUCTION_MODEL_ERROR';

export const UPDATE_CURRENT_HOLDINGS = '[WidgetPortfolioConstruction] UPDATE_CURRENT_HOLDINGS';
export const UPDATE_CURRENT_HOLDINGS_SUCCESS = '[WidgetPortfolioConstruction] UPDATE_CURRENT_HOLDINGS_SUCCESS';
export const UPDATE_CURRENT_HOLDINGS_ERROR = '[WidgetPortfolioConstruction] UPDATE_CURRENT_HOLDINGS_ERROR';

export const UPDATE_CURRENT_HOLDINGS_AND_OPEN_SCENARIO =
'[WidgetPortfolioConstruction] UPDATE_CURRENT_HOLDINGS_AND_OPEN_SCENARIO';
export const UPDATE_CURRENT_HOLDINGS_AND_OPEN_SCENARIO_SUCCESS =
'[WidgetPortfolioConstruction] UPDATE_CURRENT_HOLDINGS_AND_OPEN_SCENARIO_SUCCESS';
export const UPDATE_CURRENT_HOLDINGS_AND_OPEN_SCENARIO_ERROR =
'[WidgetPortfolioConstruction] UPDATE_CURRENT_HOLDINGS_AND_OPEN_SCENARIO_ERROR';


export const RESET_PORTFOLIO_ADJUSTMENTS = '[WidgetPortfolioConstruction] RESET_PORTFOLIO_ADJUSTMENTS';
export const RESET_PORTFOLIO_ADJUSTMENTS_SUCCESS = '[WidgetPortfolioConstruction] RESET_PORTFOLIO_ADJUSTMENTS_SUCCESS';
export const RESET_PORTFOLIO_ADJUSTMENTS_ERROR = '[WidgetPortfolioConstruction] RESET_PORTFOLIO_ADJUSTMENTS_ERROR';

export const CHANGE_STARTING_PORTFOLIO = '[WidgetPortfolioConstruction] CHANGE_STARTING_PORTFOLIO';
export const CHANGE_STARTING_PORTFOLIO_SUCCESS = '[WidgetPortfolioConstruction] CHANGE_STARTING_PORTFOLIO_SUCCESS';
export const CHANGE_STARTING_PORTFOLIO_ERROR = '[WidgetPortfolioConstruction] CHANGE_STARTING_PORTFOLIO_ERROR';

export const CHANGE_TARGET_MODEL = '[WidgetPortfolioConstruction] CHANGE_TARGET_MODEL';
export const CHANGE_TARGET_MODEL_SUCCESS = '[WidgetPortfolioConstruction] CHANGE_TARGET_MODEL_SUCCESS';
export const CHANGE_TARGET_MODEL_ERROR = '[WidgetPortfolioConstruction] CHANGE_TARGET_MODEL_ERROR';

export const CHANGE_TARGET_MODEL_FROM_ACCOUNT = '[WidgetPortfolioConstruction] CHANGE_TARGET_MODEL_FROM_ACCOUNT';
export const CHANGE_TARGET_MODEL_FROM_ACCOUNT_SUCCESS = '[WidgetPortfolioConstruction] CHANGE_TARGET_MODEL_FROM_ACCOUNT_SUCCESS';
export const CHANGE_TARGET_MODEL_FROM_ACCOUNT_ERROR = '[WidgetPortfolioConstruction] CHANGE_TARGET_MODEL_FROM_ACCOUNT_ERROR';

export const CHANGE_TARGET_MODEL_FROM_MODEL = '[WidgetPortfolioConstruction] CHANGE_TARGET_MODEL_FROM_MODEL';
export const CHANGE_TARGET_MODEL_FROM_MODEL_SUCCESS = '[WidgetPortfolioConstruction] CHANGE_TARGET_MODEL_FROM_MODEL_SUCCESS';
export const CHANGE_TARGET_MODEL_FROM_MODEL_ERROR = '[WidgetPortfolioConstruction] CHANGE_TARGET_MODEL_FROM_MODEL_ERROR';

// tslint:disable-next-line:max-line-length
export const RECALCULATE_PORTFOLIO_CONSTRUCTION_MODEL_TOTAL = '[WidgetPortfolioConstruction] RECALCULATE_PORTFOLIO_CONSTRUCTION_MODEL_TOTAL';

export const RESET_STATE = '[WidgetPortfolioConstruction] RESET_STATE';
export const RESET_ACCOUNTS_UPDATED = '[WidgetPortfolioConstruction] RESET_ACCOUNTS_UPDATED';

export const GET_OPTIMISER_CONSTRAINTS = '[WidgetPortfolioConstruction] GET_OPTIMISER_CONSTRAINTS';
export const GET_OPTIMISER_CONSTRAINTS_SUCCESS = '[WidgetPortfolioConstruction] GET_OPTIMISER_CONSTRAINTS_SUCCESS';
export const GET_OPTIMISER_CONSTRAINTS_ERROR = '[WidgetPortfolioConstruction] GET_OPTIMISER_CONSTRAINTS_ERROR';

export const OPTIMISE_PORTFOLIO = '[WidgetPortfolioConstruction] OPTIMISE_PORTFOLIO';
export const OPTIMISE_PORTFOLIO_SUCCESS = '[WidgetPortfolioConstruction] OPTIMISE_PORTFOLIO_SUCCESS';
export const OPTIMISE_PORTFOLIO_ERROR = '[WidgetPortfolioConstruction] OPTIMISE_PORTFOLIO_ERROR';

export const CREATE_MODEL = '[WidgetPortfolioConstruction] CREATE_MODEL';
export const CREATE_MODEL_SUCCESS = '[WidgetPortfolioConstruction] CREATE_MODEL_SUCCESS';
export const CREATE_MODEL_ERROR = '[WidgetPortfolioConstruction] CREATE_MODEL_ERROR';

export const SAVE_TRADES_ON_SERVER = '[WidgetPortfolioConstruction] SAVE_TRADE_ON_SERVER';
export const SAVE_TRADES_ON_SERVER_SUCCESS = '[WidgetPortfolioConstruction] SAVE_TRADE_ON_SERVER_SUCCESS';
export const SAVE_TRADES_ON_SERVER_ERROR = '[WidgetPortfolioConstruction] SAVE_TRADE_ON_SERVER_ERROR';

export const APPLY_CHOSEN_RISK_MODEL = '[WidgetPortfolioConstruction] APPLY_CHOSEN_RISK_MODEL';
export const APPLY_CHOSEN_RISK_MODEL_SUCCESS = '[WidgetPortfolioConstruction]  APPLY_CHOSEN_RISK_MODEL_SUCCESS';
export const APPLY_CHOSEN_RISK_MODEL_ERROR = '[WidgetPortfolioConstruction] APPLY_CHOSEN_RISK_MODEL_ERROR';

export const APPLY_CHOSEN_ALPHA_MODEL = '[WidgetPortfolioConstruction] APPLY_CHOSEN_ALPHA_MODEL';
export const APPLY_CHOSEN_ALPHA_MODEL_SUCCESS = '[WidgetPortfolioConstruction]  APPLY_CHOSEN_ALPHA_MODEL_SUCCESS';
export const APPLY_CHOSEN_ALPHA_MODEL_ERROR = '[WidgetPortfolioConstruction] APPLY_CHOSEN_ALPHA_MODEL_ERROR';

export class GetPortfolioConstructionModel implements Action {
	readonly type = GET_PORTFOLIO_CONSTRUCTION_MODEL;

	constructor(public clientId: string, public accountId: string, public scenarioId: number) {}
}

export class GetPortfolioConstructionModelSuccess implements Action {
	readonly type = GET_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS;
	constructor(public pcm: PortfolioConstructionModel, public isPortfolioReadOnly: boolean, public portfolioMessages: PortfolioMessage[]) {}
}

export class GetPortfolioConstructionModelError implements Action {
	readonly type = GET_PORTFOLIO_CONSTRUCTION_MODEL_ERROR;
	constructor(public error: any) {
	}
}

export class GetPortfolioConstructionPage implements Action {
	readonly type = GET_PORTFOLIO_CONSTRUCTION_PAGE;
	constructor(public clientId: string, public accountId: string, public scenarioId: number, public pdfRequest: boolean) {}
}

export class GetPortfolioConstructionPageSuccess implements Action {
	readonly type = GET_PORTFOLIO_CONSTRUCTION_PAGE_SUCCESS;
	constructor() {}
}

export class GetPortfolioConstructionPageError implements Action {
	readonly type = GET_PORTFOLIO_CONSTRUCTION_PAGE_ERROR;
	constructor(public error: any) {}
}

export class GetNewPortfolioConstructionModel implements Action {
	readonly type = GET_NEW_PORTFOLIO_CONSTRUCTION_MODEL;
	constructor(public clientId: string, public accountId: string) {}
}

export class GetNewPortfolioConstructionModelSuccess implements Action {
	readonly type = GET_NEW_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS;
	constructor(public pcm: PortfolioConstructionModel) {}
}

export class GetNewPortfolioConstructionModelError implements Action {
	readonly type = GET_NEW_PORTFOLIO_CONSTRUCTION_MODEL_ERROR;
	constructor(public error: any) {}
}

export class GetNewPortfolioConstructionPage implements Action {
	readonly type = GET_NEW_PORTFOLIO_CONSTRUCTION_PAGE;
	constructor(public clientId: string, public accountId: string, public pdfRequest: boolean) {}
}

export class GetNewPortfolioConstructionPageSuccess implements Action {
	readonly type = GET_NEW_PORTFOLIO_CONSTRUCTION_PAGE_SUCCESS;
	constructor() {}
}

export class GetNewPortfolioConstructionPageError implements Action {
	readonly type = GET_NEW_PORTFOLIO_CONSTRUCTION_PAGE_ERROR;
	constructor(public error: any) {}
}

export class GetPortfolioConstructionModelAdjustment implements Action {
	readonly type = GET_PORTFOLIO_CONSTRUCTION_MODEL_ADJUSTMENT;
	constructor(public clientId: string, public accountId: string, public pcm: PortfolioConstructionModel) {}
}

export class GetImportedTargetWeight implements Action {
	readonly type = GET_IMPORTED_TARGET_WEIGHT;
	constructor(public importedAssets: Array<AssetValue>, public pcm: PortfolioConstructionModel) {}
}

export class GetImportedTargetWeightSuccess implements Action {
	readonly type = GET_IMPORTED_TARGET_WEIGHT_SUCCESS;
	constructor() {}
}

export class GetImportedTargetWeightError implements Action {
	readonly type = GET_IMPORTED_TARGET_WEIGHT_ERROR;
	constructor(public error: any) {}
}

export class GetPortfolioConstructionModelLookThrough implements Action {
	readonly type = GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH;
	constructor(public pcm: PortfolioConstructionModel) {}
}

export class GetPortfolioConstructionModelLookThroughSuccess implements Action {
	readonly type = GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH_SUCCESS;
	constructor() {}
}

export class GetPortfolioConstructionModelLookThroughError implements Action {
	readonly type = GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH_ERROR;
	constructor(public error: any) {}
}

export class GetPortfolioConstructionModelLookThroughRevert implements Action {
	readonly type = GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH_REVERT;
	constructor(public pcm: PortfolioConstructionModel) {}
}

export class GetPortfolioConstructionModelLookThroughRevertSuccess implements Action {
	readonly type = GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH_REVERT_SUCCESS;
	constructor() {}
}

export class GetPortfolioConstructionModelLookThroughRevertError implements Action {
	readonly type = GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH_REVERT_ERROR;
	constructor(public error: any) {}
}

export class GetPortfolioConstructionModelAdjustmentSuccess implements Action {
	readonly type = GET_PORTFOLIO_CONSTRUCTION_MODEL_ADJUSTMENT_SUCCESS;
	constructor(public pcm: PortfolioConstructionModel) {}
}

export class GetPortfolioConstructionModelAdjustmentError implements Action {
	readonly type = GET_PORTFOLIO_CONSTRUCTION_MODEL_ADJUSTMENT_ERROR;
	constructor(public error: any) {}
}

export class AddHolding implements Action {
	readonly type = ADD_HOLDING;
	constructor(public holdingNode: AssetResult) {}
}

export class ResetPortfolioAdjustments implements Action {
	readonly type = RESET_PORTFOLIO_ADJUSTMENTS;
	constructor(public pcm: PortfolioConstructionModel) {}
}

export class ResetPortfolioAdjustmentsSuccess implements Action {
	readonly type = RESET_PORTFOLIO_ADJUSTMENTS_SUCCESS;
	constructor() {}
}

export class ResetPortfolioAdjustmentsError implements Action {
	readonly type = RESET_PORTFOLIO_ADJUSTMENTS_ERROR;
	constructor(public error: any) {}
}

export class RecalculateAll implements Action {
	readonly type = RECALCULATE_ALL;
	constructor(
    public pcm: PortfolioConstructionModel,
    public overrideCurrentEditProperty?: EditProperty,
    public newEditProperty?: EditProperty
  ) {}
}

export class RecalculateAllSuccess implements Action {
	readonly type = RECALCULATE_ALL_SUCCESS;
	constructor(public editProperty?: EditProperty) {}
}

export class RecalculateAllError implements Action {
	readonly type = RECALCULATE_ALL_ERROR;
	constructor(public error: any) {}
}

export class AdjustBoundaries implements Action {
	readonly type = ADJUST_BOUNDARIES;
	constructor(public pcm: PortfolioConstructionModel) {}
}

export class AdjustBoundariesSuccess implements Action {
	readonly type = ADJUST_BOUNDARIES_SUCCESS;
	constructor(public pcm: PortfolioConstructionModel) {}
}

export class AdjustBoundariesError implements Action {
	readonly type = ADJUST_BOUNDARIES_ERROR;
	constructor(public error: any) {}
}

export class UpdateAccountHoldings implements Action {
	readonly type = UPDATE_ACCOUNT_HOLDINGS;
	constructor(public pcm: PortfolioConstructionModel) {}
}

export class UpdateAccountHoldingsSuccess implements Action {
	readonly type = UPDATE_ACCOUNT_HOLDINGS_SUCCESS;
	constructor() {}
}

export class UpdateAccountHoldingsError implements Action {
	readonly type = UPDATE_ACCOUNT_HOLDINGS_ERROR;
	constructor(public error: any) {}
}

export class UpdatePortfolioConstructionModel implements Action {
	readonly type = UPDATE_PORTFOLIO_CONSTRUCTION_MODEL;
	constructor(public pcm: PortfolioConstructionModel) {}
}

export class UpdatePortfolioConstructionModelSuccess implements Action {
	readonly type = UPDATE_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS;
	constructor() {}
}

export class UpdatePortfolioConstructionModelError implements Action {
	readonly type = UPDATE_PORTFOLIO_CONSTRUCTION_MODEL_ERROR;
	constructor(public error: any) {}
}

export class SaveUpdatePortfolioConstructionModel implements Action {
	readonly type = SAVE_UPDATE_PORTFOLIO_CONSTRUCTION_MODEL;
	constructor(public pcm: PortfolioConstructionModel) {}
}

export class SaveUpdatePortfolioConstructionModelSuccess implements Action {
	readonly type = SAVE_UPDATE_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS;
	constructor(public pcm: PortfolioConstructionModel) {}
}

export class SaveUpdatePortfolioConstructionModelError implements Action {
	readonly type = SAVE_UPDATE_PORTFOLIO_CONSTRUCTION_MODEL_ERROR;
	constructor(public error: any) {}
}

export class CreatePortfolioConstructionModel implements Action {
	readonly type = CREATE_PORTFOLIO_CONSTRUCTION_MODEL;
	constructor(public pcm: PortfolioConstructionModel) {}
}

export class CreatePortfolioConstructionModelSuccess implements Action {
	readonly type = CREATE_PORTFOLIO_CONSTRUCTION_MODEL_SUCCESS;
	constructor(public scenarioId: string) {}
}

export class CreatePortfolioConstructionModelError implements Action {
	readonly type = CREATE_PORTFOLIO_CONSTRUCTION_MODEL_ERROR;
	constructor(public error: any) {}
}

export class UpdateCurrentHoldings implements Action {
	readonly type = UPDATE_CURRENT_HOLDINGS;
	constructor(public pcm: PortfolioConstructionModel) {}
}

export class UpdateCurrentHoldingsSuccess implements Action {
	readonly type = UPDATE_CURRENT_HOLDINGS_SUCCESS;
	constructor(public pcm: PortfolioConstructionModel) {}
}

export class UpdateCurrentHoldingsError implements Action {
	readonly type = UPDATE_CURRENT_HOLDINGS_ERROR;
	constructor(public error: any) {}
}

export class UpdateCurrentHoldingsAndOpenScenario implements Action {
	readonly type = UPDATE_CURRENT_HOLDINGS_AND_OPEN_SCENARIO;
	constructor(public pcm: PortfolioConstructionModel) {}
}

export class UpdateCurrentHoldingsAndOpenScenarioSuccess implements Action {
	readonly type = UPDATE_CURRENT_HOLDINGS_AND_OPEN_SCENARIO_SUCCESS;
	constructor(public pcm: PortfolioConstructionModel) {}
}

export class UpdateCurrentHoldingsAndOpenScenarioError implements Action {
	readonly type = UPDATE_CURRENT_HOLDINGS_AND_OPEN_SCENARIO_ERROR;
	constructor(public error: any) {}
}

export class RecalculatePortfolioConstructionModelTotal implements Action {
	readonly type = RECALCULATE_PORTFOLIO_CONSTRUCTION_MODEL_TOTAL;
	constructor(public recalculateData: any) {}
}

export class ChangeStartingPortfolio implements Action {
	readonly type = CHANGE_STARTING_PORTFOLIO;
	constructor(public startingPortfolioType: StartingPortfolioType, public currentPcm: PortfolioConstructionModel) {}
}

export class ChangeStartingPortfolioSuccess implements Action {
	readonly type = CHANGE_STARTING_PORTFOLIO_SUCCESS;
	constructor() {}
}

export class ChangeStartingPortfolioError implements Action {
	readonly type = CHANGE_STARTING_PORTFOLIO_ERROR;
	constructor(public error: any) {}
}

export class ChangeTargetModel implements Action {
	readonly type = CHANGE_TARGET_MODEL;
	constructor(public modelId: number, public pcm: PortfolioConstructionModel) {}
}

export class ChangeTargetModelSuccess implements Action {
	readonly type = CHANGE_TARGET_MODEL_SUCCESS;
	constructor() {}
}

export class ChangeTargetModelError implements Action {
	readonly type = CHANGE_TARGET_MODEL_ERROR;
	constructor(public error: any) {}
}

export class ChangeTargetModelFromAccount implements Action {
	readonly type = CHANGE_TARGET_MODEL_FROM_ACCOUNT;
	constructor(public accountId: number) {}
}

export class ChangeTargetModelFromAccountSuccess implements Action {
	readonly type = CHANGE_TARGET_MODEL_FROM_ACCOUNT_SUCCESS;
	constructor() {}
}

export class ChangeTargetModelFromAccountError implements Action {
	readonly type = CHANGE_TARGET_MODEL_FROM_ACCOUNT_ERROR;
	constructor(public error: any) {}
}

export class ChangeTargetModelFromModel implements Action {
	readonly type = CHANGE_TARGET_MODEL_FROM_MODEL;
	constructor(public id: number) {}
}

export class ChangeTargetModelFromModelSuccess implements Action {
	readonly type = CHANGE_TARGET_MODEL_FROM_MODEL_SUCCESS;
	constructor() {}
}

export class ChangeTargetModelFromModelError implements Action {
	readonly type = CHANGE_TARGET_MODEL_FROM_MODEL_ERROR;
	constructor(public error: any) {}
}

export class ResetState implements Action {
  readonly type = RESET_STATE;
  constructor() {}
}

export class ResetAccountsUpdated implements Action {
  readonly type = RESET_ACCOUNTS_UPDATED;
  constructor() {}
}

export class GetOptimiserConstraints implements Action {
	readonly type = GET_OPTIMISER_CONSTRAINTS;
	constructor(public pcm: PortfolioConstructionModel) {}
}

export class GetOptimiserConstraintsSuccess implements Action {
	readonly type = GET_OPTIMISER_CONSTRAINTS_SUCCESS;
	constructor(public constraintsConfiguration: GenericFormConfiguration) {}
}

export class GetOptimiserConstraintsError implements Action {
	readonly type = GET_OPTIMISER_CONSTRAINTS_ERROR;
	constructor(public error: any) {
	}
}

export class OptimisePortfolio implements Action {
	readonly type = OPTIMISE_PORTFOLIO;
	constructor(public constraintConfiguration: any, public pcm: PortfolioConstructionModel) {}
}

export class OptimisePortfolioSuccess implements Action {
	readonly type = OPTIMISE_PORTFOLIO_SUCCESS;
	constructor() {}
}

export class OptimisePortfolioError implements Action {
	readonly type = OPTIMISE_PORTFOLIO_ERROR;
	constructor(public error: any) {
	}
}

export class CreateModel implements Action {
	readonly type = CREATE_MODEL;
	constructor(public clientId: string, public accountId: string, public pcm: PortfolioConstructionModel) {}
}

export class CreateModelSuccess implements Action {
	readonly type = CREATE_MODEL_SUCCESS;
	constructor() {}
}

export class CreateModelError implements Action {
	readonly type = CREATE_MODEL_ERROR;
	constructor(public error: any) {
	}
}

export class ApplyChosenRiskModel implements Action {
	readonly type = APPLY_CHOSEN_RISK_MODEL;
	constructor(public pcm: PortfolioConstructionModel, public chosenRiskModel: number) {}
  }
  
  export class ApplyChosenRiskModelSuccess implements Action {
	  readonly type = APPLY_CHOSEN_RISK_MODEL_SUCCESS;
	  constructor() {}
  }
  
  export class ApplyChosenRiskModelError implements Action {
	  readonly type = APPLY_CHOSEN_RISK_MODEL_ERROR;
	  constructor(public error: any) {}
  }

  export class ApplyChosenAlphaModel implements Action {
	readonly type = APPLY_CHOSEN_ALPHA_MODEL;
	constructor(public pcm: PortfolioConstructionModel, public chosenAlphaModel: number) {}
  }
  
  export class ApplyChosenAlphaModelSuccess implements Action {
	  readonly type = APPLY_CHOSEN_ALPHA_MODEL_SUCCESS;
	  constructor() {}
  }
  
  export class ApplyChosenAlphaModelError implements Action {
	  readonly type = APPLY_CHOSEN_ALPHA_MODEL_ERROR;
	  constructor(public error: any) {}
  }

  export class SaveTradesOnServer implements Action {
	  readonly type = SAVE_TRADES_ON_SERVER;
	  constructor(public csvData: string, public mode: string) {}
  }

  export class SaveTradesOnServerSuccess implements Action {
	  readonly type = SAVE_TRADES_ON_SERVER_SUCCESS;
	  constructor() {}
  }

  export class SaveTradesOnServerError implements Action {
	  readonly type = SAVE_TRADES_ON_SERVER_ERROR;
	  constructor(public error: any) {}
  }

export type All = GetPortfolioConstructionModel | GetPortfolioConstructionModelSuccess | GetPortfolioConstructionModelError
								| GetPortfolioConstructionPage | GetPortfolioConstructionPageSuccess | GetPortfolioConstructionPageError
								| GetNewPortfolioConstructionModel| GetNewPortfolioConstructionModelSuccess| GetNewPortfolioConstructionPageError
								| GetPortfolioConstructionModelLookThrough | GetPortfolioConstructionModelLookThroughSuccess
								| GetPortfolioConstructionModelLookThroughError
								| GetPortfolioConstructionModelLookThroughRevert | GetPortfolioConstructionModelLookThroughRevertSuccess
								| GetPortfolioConstructionModelLookThroughRevertError
								| GetNewPortfolioConstructionPage| GetNewPortfolioConstructionPageSuccess| GetNewPortfolioConstructionModelError
								| GetPortfolioConstructionModelAdjustment | GetPortfolioConstructionModelAdjustmentSuccess
								| GetPortfolioConstructionModelAdjustmentError
								| AddHolding
								| ResetPortfolioAdjustments | ResetPortfolioAdjustmentsSuccess | ResetPortfolioAdjustmentsError
                | RecalculateAll | RecalculateAllSuccess | RecalculateAllError
                | AdjustBoundaries | AdjustBoundariesSuccess | AdjustBoundariesError
                | UpdateAccountHoldings | UpdateAccountHoldingsSuccess | UpdateAccountHoldingsError
								| UpdatePortfolioConstructionModel | UpdatePortfolioConstructionModelSuccess | UpdatePortfolioConstructionModelError
								| SaveUpdatePortfolioConstructionModel | SaveUpdatePortfolioConstructionModelSuccess | SaveUpdatePortfolioConstructionModelError
								| CreatePortfolioConstructionModel | CreatePortfolioConstructionModelSuccess | CreatePortfolioConstructionModelError
								| RecalculatePortfolioConstructionModelTotal
								| ChangeStartingPortfolio | ChangeStartingPortfolioSuccess | ChangeStartingPortfolioError
								| ChangeTargetModel | ChangeTargetModelSuccess | ChangeTargetModelError
                | ChangeTargetModelFromAccount | ChangeTargetModelFromAccountSuccess
                | ChangeTargetModelFromAccountError | UpdateCurrentHoldings | UpdateCurrentHoldingsSuccess
                | UpdateCurrentHoldingsError
                | UpdateCurrentHoldingsAndOpenScenario | UpdateCurrentHoldingsAndOpenScenarioSuccess
                | UpdateCurrentHoldingsAndOpenScenarioError
                | GetImportedTargetWeight | GetImportedTargetWeightError | GetImportedTargetWeightSuccess
                | ResetState | ResetAccountsUpdated
                | GetOptimiserConstraints | GetOptimiserConstraintsError | GetOptimiserConstraintsSuccess
				| OptimisePortfolio | OptimisePortfolioError | OptimisePortfolioSuccess
				| ChangeTargetModelFromModel | ChangeTargetModelFromModelSuccess | ChangeTargetModelFromModelError
				| SaveTradesOnServer | SaveTradesOnServerSuccess | SaveTradesOnServerError
				| CreateModel | CreateModelSuccess | CreateModelError
				| ApplyChosenRiskModel | ApplyChosenRiskModelSuccess | ApplyChosenRiskModelError
				| ApplyChosenAlphaModel | ApplyChosenAlphaModelSuccess | ApplyChosenAlphaModelError;
