import { Action } from '@ngrx/store';

import * as ModelActions from './models.actions';
import { WidgetModel, AnotherAccountInfo } from '../../entity/widget-model.model';
import { ModelState } from './models.state';

export type ModelAction = ModelActions.All;
export const initialState: ModelState = { availableModels : new Array<WidgetModel>(), selectedModel: new WidgetModel(),
   anotherAccountInfo: new AnotherAccountInfo()};

export function modelReducer(state: ModelState = initialState, action: ModelAction): ModelState {
	switch (action.type) {

		case ModelActions.GET_WIDGET_MODELS_SUCCESS: {
			return {...state, ...action.models };
		}

		case ModelActions.GET_WIDGET_MODELS_ERROR: {
			console.error('Error caught while loading Widgets-models', action.error);
			return {...state, availableModels: new Array()};
		}

		default :
			return state;
	}

}

