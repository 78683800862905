import {JsonObject, JsonProperty} from 'json2typescript';
import {User} from '../../shared/entity/user.model';
import { Configuration } from './configuration.model';
import { AuthenticationConfig } from  './authenticationConfig.model';

@JsonObject('Session')
export class Session {

  @JsonProperty('user', User)
  user: User = undefined;

  @JsonProperty('lastAccessedClientId', String)
  lastAccessedClientId: string = undefined;

  @JsonProperty('lastAccessedClientList', [String], true)
  lastAccessedClientList: String[] = [];

  @JsonProperty('configuration', Configuration, true)
  configuration: Configuration = undefined;

  @JsonProperty('authenticationConfig', AuthenticationConfig, true)
  authenticationConfig: AuthenticationConfig = undefined;

  constructor(user?: User, lastAccessedClientId?: string, lastAccessedClientList?: String[], configuration?: Configuration, authenticationConfig?: AuthenticationConfig) {
    this.user = user;
    this.lastAccessedClientId = lastAccessedClientId;
    this.lastAccessedClientList = lastAccessedClientList;
    this.configuration = configuration;
    this.authenticationConfig = authenticationConfig;
  }
 }
