import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('WidgetAlert')
export class WidgetAlert {

    @JsonProperty('id', Number)
    id: number = undefined;

    @JsonProperty('alertType', String)
    alertType: string = undefined;

    @JsonProperty('holdingName', String)
    holdingName: string = undefined;

    @JsonProperty('monitorFlagType', String)
    monitorFlagType: string = undefined;

    constructor() {}

 }
