import { Action } from '@ngrx/store';

import * as SearchMetaDataActions from './search-criteria.actions';
import { SearchMetaData } from '../../../entity/search-metadata.model';
import { SearchMetaDataState } from './search-criteria.state';

export type SearchMetaDataAction = SearchMetaDataActions.All;
export const initialState: SearchMetaDataState = { searchMetaData: new SearchMetaData(), loading: false};

export function searchMetaDataReducer(state: SearchMetaDataState = initialState, action: SearchMetaDataAction):
SearchMetaDataState {
    switch (action.type) {

      case SearchMetaDataActions.GET_SEARCH_META_DATA: {
        return {...state, ...{loading: true}};
      }

      case SearchMetaDataActions.GET_SEARCH_META_DATA_SUCCESS: {
        return {...state, ...{searchMetaData: action.searchMetaData}, ...{loading: false}};
      }

      case SearchMetaDataActions.GET_SEARCH_META_DATA_ERROR: {
        console.error('Error caught while loading Search-MetaData', action.error);
        return {...initialState};
      }

      default :
        return state;
    }
}
