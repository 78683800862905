export function mockBmidHoldingList() {
  return {
    meta: [
      {header: 'name', label: 'Name', wrapText: false},
      {header: 'accountId', label: 'Bmid', wrapText: false},
      {header: 'value', label: 'Value', wrapText: true},
      {header: 'deviation', label: 'Deviation'}
    ],
    values: [
      {name: 'J Terry', clientId: 'SLW1821778', accountId: 'BMID007XT', value: 9999.99, deviation: 12},
      {name: 'F Lampard', clientId: 'SLW2132612', accountId: 'BMID000DT', value: 8888.88, deviation: 10},
      {name: 'S Gerrard', clientId: 'SLW5538883', accountId: 'BMID000EG', value: 5555.55, deviation: 8},
      {name: 'W Rooney', clientId: 'SLW5195932', accountId: 'BMID000EX', value: 7777.77, deviation: 0}
    ]
  };
}
