import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as Path from '../../core/routing/routing.const';
import * as RouterActions from '../../core/store/router/router.actions';
import * as ClientNavigationContext from '../navigation-context/client-navigation-context.const';

@Injectable()
export class AppRouterService {
  public static readonly MODELS_P_AND_A_PAGE = 'models.p&a';
  public static readonly MODELS_P_AND_P_PAGE = 'models.p&p';

  constructor(private store: Store<any>) {}

  loginPage(query?: object) {
    this.url(Path.LOG_IN_PAGE, query);
  }

  localLoginPage(query?: object) {
    var queryNew = { local: true };
    if (query) {
      for (const key in query) {
        queryNew[key] = query[key];
      }
    }
    this.url(Path.LOG_IN_PAGE, queryNew);
  }

  dashboardPage(query?: object) {
    this.url(Path.DASHBOARD_PAGE, query);
  }

  clientHomePage(clientId: string, query?: object) {
    this.url(Path.CLIENT_PAGE + `/${clientId}`, query);
  }

  modelsHomePage(clientId?: string, query?: object) {
    if (clientId) {
      this.url(Path.MODELS_PAGE + `/${clientId}`, query);
    } else {
      this.url(Path.MODELS_PAGE + `/home`, query);
    }
  }
  modelsPAndAPage(clientId: string, accountId: string, query?: object) {
    this.url(Path.MODELS_PAGE + `/${clientId}/account/${accountId}`, query);
  }
  modelsPAndPPage(clientId: string, accountId: string, query?: object) {
    this.url(Path.MODELS_PAGE + `/${clientId}/pmp/${accountId}`, query);
  }

  clientAccountPage(clientId: string, accountId: string, query?: object) {
    this.url(Path.CLIENT_PAGE + `/${clientId}/${ClientNavigationContext.ACCOUNT}/${accountId}`, query);
  }

  clientPmpPage(clientId: string, accountId: string, query?: object) {
    this.url(Path.CLIENT_PAGE + `/${clientId}/${ClientNavigationContext.PMP}/${accountId}`, query);
  }

  clientAapPage(clientId: string, accountId: string, query?: object) {
    this.url(Path.CLIENT_PAGE + `/${clientId}/${ClientNavigationContext.AAP}/${accountId}`, query);
  }

  clientPppPage(clientId: string, accountId: string, query?: object) {
    this.url(Path.CLIENT_PAGE + `/${clientId}/${ClientNavigationContext.PPP}/${accountId}`, query);
  }

  modelAapPage(clientId: string, accountId: string, query?: object) {
    this.url(Path.MODELS_PAGE + `/${clientId}/${ClientNavigationContext.AAP}/${accountId}`, query);
  }

  modelAccountPage(clientId: string, accountId: string, query?: object) {
    this.url(Path.MODELS_PAGE + `/${clientId}/${ClientNavigationContext.ACCOUNT}/${accountId}`, query);
  }

  modelPmpPage(clientId: string, accountId: string, query?: object) {
    this.url(Path.MODELS_PAGE + `/${clientId}/${ClientNavigationContext.PMP}/${accountId}`, query);
  }

  questionnairePage(clientId: string, accountId: string, query?: object) {
    this.url(Path.CLIENT_PAGE + `/${clientId}/${ClientNavigationContext.QUESTIONNAIRE}/${accountId}`, query);
  }

  clientEsgPage(clientId: string, accountId: string, query?: object) {
    this.url(Path.CLIENT_PAGE + `/${clientId}/${ClientNavigationContext.ESG_QUESTIONNAIRE}/${accountId}`, query);
  }

  clientEarrowPage(clientId: string, accountId: string, query?: object) {
    this.url(Path.CLIENT_PAGE + `/${clientId}/${ClientNavigationContext.EARROW}/${accountId}`, query);
  }

  clientEco2Page(clientId: string, accountId: string, query?: object) {
    this.url(Path.CLIENT_PAGE + `/${clientId}/${ClientNavigationContext.ECO2}/${accountId}`, query);
  }

  clientEconflictPage(clientId: string, accountId: string, query?: object) {
    this.url(Path.CLIENT_PAGE + `/${clientId}/${ClientNavigationContext.ESG_CONFLICT_REPORT}/${accountId}`, query);
  }
  clientERQConflictPage(clientId: string, accountId: string, query?: object) {
    this.url(Path.CLIENT_PAGE + `/${clientId}/${ClientNavigationContext.ERQ_CONFLICT}/${accountId}`, query);
  }

  clientEsmilePage(clientId: string, accountId: string, query?: object) {
    this.url(Path.CLIENT_PAGE + `/${clientId}/${ClientNavigationContext.ESG_OVERALL_IMPACT_REPORT}/${accountId}`, query);
  }

  questionnaireSummaryPage(clientId: string, accountId: string, query?: object) {
    const questionnaire = ClientNavigationContext.QUESTIONNAIRE;
    const profile = ClientNavigationContext.PROFILE;
    this.url(Path.CLIENT_PAGE + `/${clientId}/${questionnaire}/${accountId}/${profile}`, query);
  }

  clientPmpWithScenarioPage(clientId: string, accountId: string, scenarioId: number, query?: object) {
    this.url(Path.CLIENT_PAGE +
       `/${clientId}/${ClientNavigationContext.PMP}/${accountId}/${ClientNavigationContext.SCENARIO}/${scenarioId}`, query);
  }

  modelPmpWithScenarioPage(clientId: string, accountId: string, scenarioId: number, query?: object) {
    this.url(Path.MODELS_PAGE +
       `/${clientId}/${ClientNavigationContext.PMP}/${accountId}/${ClientNavigationContext.SCENARIO}/${scenarioId}`, query);
  }

  clientReportPage(clientId: string, query?: object) {
    this.url(Path.CLIENT_PAGE + `/${clientId}/${ClientNavigationContext.REPORT}`, query);
  }

  searchPage(query?: object) {
    this.url(Path.SEARCH_PAGE, query);
  }

  buyListPage(clientId: string, accountId: string, sedol?: string, query?: object) {
    let url = `${Path.BUY_LIST}/client/${clientId}/account/${accountId}`;

    if (sedol) {
      url += `/asset/${sedol}`;
    }

    this.url(url, query);
  }

  clientTcfdOverallPage(clientId: string, accountId: string, query?: object) {
    this.url(Path.CLIENT_PAGE + `/${clientId}/${ClientNavigationContext.TCFD_DASHBOARD}/${accountId}`, query);
  }

  clientTcfdDetailedPage(clientId: string, accountId: string, query?: object) {
    this.url(Path.CLIENT_PAGE + `/${clientId}/${ClientNavigationContext.TCFD_DETAILED}/${accountId}`, query);
  }

  clientTcfdHistoryPage(clientId: string, accountId: string, query?: object) {
    this.url(Path.CLIENT_PAGE + `/${clientId}/${ClientNavigationContext.TCFD_HISTORY}/${accountId}`, query);
  }

  clientTcfdAssetPage(clientId: string, accountId: string, query?: object) {
    this.url(Path.CLIENT_PAGE + `/${clientId}/${ClientNavigationContext.TCFD_ASSET}/${accountId}`, query);
  }

  accessDeniedPage(query?: object) {
    this.url(Path.ACCESS_DENIED, query);
  }

  pageNotFound(query?: object) {
    this.url(Path.PAGE_NOT_FOUND, query);
  }

  url(url: string, query?: any) {
    this.store.dispatch(new RouterActions.Go({
      path: [url, {}],
      query: query,
      extras: { replaceUrl: false }
    }));
  }

}
