import { Injectable, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { empty, Subject } from 'rxjs';

import { takeUntil, map, catchError } from 'rxjs/operators';
import { saveAs as importedSaveAs } from 'file-saver';
import { NavActionService } from '../../core/navigation-floating/nav-action.service';

export class TableCell {
  constructor(public index: number, public data: string) {}
}

export class TableData {
  constructor(public headers: TableCell[], public data: TableCell[][]) {}
}

@Injectable()
export class ExportService implements OnDestroy {

  public static readonly DEFAULT_PAGE_OPTION = {
    'format': 'A4',
    'printBackground': true
  };

  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(@Inject(DOCUMENT) document: any, private httpClient: HttpClient,
  private navActionService: NavActionService) {}

  private readRowCells(cells: HTMLCollectionOf<HTMLTableCellElement | HTMLTableHeaderCellElement>): TableCell[] {
    const rowData = [];
    for (let cellIndex = 0; cellIndex < cells.length; cellIndex++) {
      const cell = cells.item(cellIndex);
      const exportable = cell.attributes.getNamedItem('exportable');
      const colspan = cell.colSpan;

      if (!exportable || (exportable && exportable.value !== 'false')) {
        let text = '';
        const exportValue = cell.attributes.getNamedItem('exportValue');
        if (exportValue) {
          text = exportValue.value;
        } else {
          text = cell.innerText;
        }
        // if (text !== '') {
          rowData.push({index: cellIndex, data: text.trim()});

          if (colspan > 1) {
            for (let colIndex = 1; colIndex < colspan; colIndex++) {
              rowData.push({index: cellIndex, data: ''});
            }
          }
        // }
      }
    }

    return rowData;
  }

  private readTable(table: HTMLTableElement): TableData {
    const headers = this.readRowCells(table.rows.item(0).cells);

    const dataRows = [];
    for (let rowIndex = 1; rowIndex < table.rows.length; rowIndex++) {
      const rowData = this.readRowCells(table.rows.item(rowIndex).cells);

      dataRows.push(rowData);
    }

    return new TableData(headers, dataRows);
  }

  private csvTryQuote(data: string) {
    if (data.includes(',')) {
      return '"' + data.replace(/"/g, '""') + '"';
    }

    return data.replace(/"/g, '""');
  }

  private toCsv(data: TableData) {
    let csv = '\ufeff';
    const csvSeparator = ',';

    data.headers.forEach((cell, i) => {
      csv += this.csvTryQuote(cell.data);

      if (i < (data.headers.length - 1)) {
        csv += csvSeparator;
      }
    });

    data.data.forEach((row, j) => {
      csv += '\n';

      row.forEach((cell, i) => {
        csv += this.csvTryQuote(cell.data);

        if (i < (row.length - 1)) {
          csv += csvSeparator;
        }
      });
    });

    return csv;
  }

  private toJson(data: TableData) {
    const rows = [];

    data.data.forEach((row, j) => {
      const obj = {};

      row.forEach((cell, i) => {
        obj[data.headers[i].data] = cell.data;
      });

      rows.push(obj);
    });

    return JSON.stringify(rows);
  }

  private updateUrlParameter(uri, key, value) {
    const i = uri.indexOf('#');
    const hash = i === -1 ? ''  : uri.substr(i);
          uri = i === -1 ? uri : uri.substr(0, i);

    const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
        uri = uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
        uri = uri + separator + key + '=' + value;
    }
    return uri + hash;
}

  export(data: string, mimeTypeAndCharset: string, filename: string) {
    console.log('export data:', data);

    const blob = new Blob([data], {
      type: mimeTypeAndCharset
    });

    //deprecated navigator.msSaveOrOpenBlob
    // if (window.navigator.msSaveOrOpenBlob) {
    //   navigator.msSaveOrOpenBlob(blob, filename);
    // } else {
      const link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link);
      if (link.download !== undefined) {
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', filename);
        link.click();
      } else {
        data = mimeTypeAndCharset + ',' + data;
        window.open(encodeURI(data));
      }

      document.body.removeChild(link);
    // }
  }

  exportCsv(table: HTMLTableElement, filename: string) {
    const csv = this.toCsv(this.readTable(table));
    this.export(csv, 'text/csv;charset=utf-8;', filename);
  }

  exportMultipleCsv(tables: HTMLTableElement[], filename: string) {
    let csv = this.generateCsvData(tables);
    this.export(csv, 'text/csv;charset=utf-8;', filename);
  }

  generateCsvData(tables: HTMLTableElement[]) {
    let csv = '';
    tables.forEach(table => {
      csv += this.toCsv(this.readTable(table));
      csv += '\n';
    });
    return csv;
  }

  exportJson(table: HTMLTableElement, filename: string) {
    const json = this.toCsv(this.readTable(table));
    this.export(json, 'application/json;charset=utf-8;', filename);
  }

  urlToPDF(filename: string, url: string = document.location.href,
    downloadEnabled: boolean, landscape: boolean = true,
     pageOption: any = ExportService.DEFAULT_PAGE_OPTION) {
    url = this.updateUrlParameter(url, 'pdf', true);
    pageOption.landscape = landscape;

    this.httpClient.post(`${environment.serverUrl}/restapi/service/report/pdf/url`, {
      'url': url,
      'pageOption': pageOption,
      'fileName': filename
      }, { withCredentials: true, responseType: 'blob' as 'json', observe: 'response' }).pipe(
        takeUntil(this.ngUnsubscribe),
        map((response: HttpResponse<Blob>) => {
          return response;
        }),
        catchError((err: HttpErrorResponse) => {
          this.navActionService.addMessage('Error', 'An error has occurred while exporting as PDF', 'error');
            console.error('An error has occurred: ', err);
          // empty observable
          return empty();
        })).subscribe(res => {
          if (downloadEnabled) {
            importedSaveAs(res.body, `${filename}.pdf`);
          } else {
            const reportUrl = window.URL.createObjectURL(res.body);
            window.open(reportUrl, '_blank');
          }
          });
  }

  htmlToPDF(filename: string, content: string = document.querySelector('html').outerHTML) {
    this.httpClient.post(`${environment.serverUrl}/restapi/service/report/pdf/html`, {
      'content': content,
      'fileName': filename
    }, { responseType: 'blob' as 'json', observe: 'response' }).pipe(
      takeUntil(this.ngUnsubscribe),
      map((response: HttpResponse<Blob>) => {
        return response;
      })).subscribe(res => importedSaveAs(res.body, `${filename}.pdf`));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.unsubscribe();
  }
}
