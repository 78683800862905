import { BehaviorSubject, Observable } from 'rxjs';
import { Configuration } from './entity/configuration.model';
import { Injectable } from '@angular/core';
import { AuthenticationState } from './store/auth/authentication.state';
import { Store } from '@ngrx/store';
import { AppData } from './store/appState.model';
import DefaultConfigurationFactory from './factory/default-configuration.factory';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
import * as ConfigurationKeys from '../core/entity/configuration.const';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  currentLocale = 'en-gb';

  private authenticationState: Observable<AuthenticationState>;

  currentConfiguration: BehaviorSubject<Configuration> =
    new BehaviorSubject<Configuration>(DefaultConfigurationFactory.getDefaultConfiguration());

  constructor(private store: Store<AppData>) {
    defineLocale('en-gb', enGbLocale);
    this.authenticationState = this.store.select('authentication');
    this.authenticationState.subscribe(auth => {

      if (auth.session && auth.session.configuration && (auth.session.configuration instanceof Configuration)) {
        const config = auth.session.configuration;
        this.setLocale(config.getConfig(ConfigurationKeys.UI_LOCALE));
        this.currentConfiguration.next(config);
      }
      });
  }

  setLocale(locale: string) {
    // currently we only support two locales, en-us and en-gb. default is en-gb
    // system config has the most priority and then the browser setting.
    if ((locale && locale.toLowerCase() === 'en-us')
        || (!!!locale && navigator.language.toLowerCase() === 'en-us')
      ) {
      this.currentLocale = locale;
    } else {
      this.currentLocale = 'en-gb';
    }

    moment.locale(this.currentLocale);
  }
  getLocale() {
    return this.currentLocale;
  }

  getConfig(): Observable<Configuration> {
   return this.currentConfiguration.asObservable();
  }

}
