
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DataModelService } from '../../../clients/service/data-model.service';
import * as AlphaModelListActions from './alpha-model-selection.actions';

export type DataModelListAction = AlphaModelListActions.All;

@Injectable()
export class AlphaModelListEffects {

	constructor(private actions$: Actions,
							private dataModelService: DataModelService) {
	}

	getAllAlphaModels: Observable<DataModelListAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(AlphaModelListActions.GET_ALL_ALPHA_MODELS),
      switchMap((a: AlphaModelListActions.GetAllAlphaModels ) =>
      this.dataModelService.getAllAlphaModels(a.clientId, a.accountId, a.scenarioId).pipe(
			map(post => new AlphaModelListActions.GetAllAlphaModelsSuccess(post)),
      catchError((error) => of(new AlphaModelListActions.GetAllAlphaModelsError(error)))
    ))));

	
}
