import { AssetResult, Asset } from './../../../entity/asset.model';
import { AccountOverview } from './../../../entity/account/accountOverview.model';
import { AccountOverviewConfig } from './../../../entity/account/accountOverviewConfig.model';
import { Action } from '@ngrx/store';

import { CurrentPortfolioModel } from '../../../entity/current-portfolio-model.model';

export const GET_ACCOUNT_CURRENT_PORTFOLIO = '[AccountCurrentPortfolio] GET_ACCOUNT_CURRENT_PORTFOLIO';
export const GET_ACCOUNT_CURRENT_PORTFOLIO_SUCCESS = '[AccountCurrentPortfolio]  GET_ACCOUNT_CURRENT_PORTFOLIO_SUCCESS';
export const GET_ACCOUNT_CURRENT_PORTFOLIO_ERROR = '[AccountCurrentPortfolio] GET_ACCOUNT_CURRENT_PORTFOLIO_ERROR';

export const GET_ACCOUNT_OVERVIEW_CONFIG = '[AccountCurrentPortfolio] GET_ACCOUNT_OVERVIEW_CONFIG';
export const GET_ACCOUNT_OVERVIEW_CONFIG_SUCCESS = '[AccountCurrentPortfolio]  GET_ACCOUNT_OVERVIEW_CONFIG_SUCCESS';
export const GET_ACCOUNT_OVERVIEW_CONFIG_ERROR = '[AccountCurrentPortfolio] GET_ACCOUNT_OVERVIEW_CONFIG_ERROR';

export const CREATE_MANDATE_PORTFOLIO = '[AccountCurrentPortfolio] CREATE_MANDATE_PORTFOLIO';
export const CREATE_MANDATE_PORTFOLIO_SUCCESS = '[AccountCurrentPortfolio]  CREATE_MANDATE_PORTFOLIO_SUCCESS';
export const CREATE_MANDATE_PORTFOLIO_ERROR = '[AccountCurrentPortfolio] CREATE_MANDATE_PORTFOLIO_ERROR';

export const UPDATE_MANDATE_PORTFOLIO = '[AccountCurrentPortfolio] UPDATE_MANDATE_PORTFOLIO';
export const UPDATE_MANDATE_PORTFOLIO_SUCCESS = '[AccountCurrentPortfolio]  UPDATE_MANDATE_PORTFOLIO_SUCCESS';
export const UPDATE_MANDATE_PORTFOLIO_ERROR = '[AccountCurrentPortfolio] UPDATE_MANDATE_PORTFOLIO_ERROR';

export const ADD_HOLDING = '[AccountCurrentPortfolio] ADD_HOLDING';
export const ADD_HOLDING_SUCCESS = '[AccountCurrentPortfolio] ADD_HOLDING_SUCCESS';
export const ADD_HOLDING_ERROR = '[AccountCurrentPortfolio] ADD_HOLDING_ERROR';

export const REMOVE_HOLDING = '[AccountCurrentPortfolio] REMOVE_HOLDING';
export const REMOVE_HOLDING_SUCCESS = '[AccountCurrentPortfolio] REMOVE_HOLDING_SUCCESS';
export const REMOVE_HOLDING_ERROR = '[AccountCurrentPortfolio] REMOVE_HOLDING_ERROR';

export const CLEAR_HOLDINGS = '[AccountCurrentPortfolio] CLEAR_HOLDINGS';
export const CLEAR_HOLDINGS_SUCCESS = '[AccountCurrentPortfolio] CLEAR_HOLDINGS_SUCCESS';
export const CLEAR_HOLDINGS_ERROR = '[AccountCurrentPortfolio] CLEAR_HOLDINGS_ERROR';

export const SAVE_ACCOUNT_CURRENT_PORTFOLIO = '[AccountCurrentPortfolio] SAVE_ACCOUNT_CURRENT_PORTFOLIO';
export const SAVE_ACCOUNT_CURRENT_PORTFOLIO_SUCCESS = '[AccountCurrentPortfolio]  SAVE_ACCOUNT_CURRENT_PORTFOLIO_SUCCESS';
export const SAVE_ACCOUNT_CURRENT_PORTFOLIO_ERROR = '[AccountCurrentPortfolio] SAVE_ACCOUNT_CURRENT_PORTFOLIO_ERROR';

export const UPDATE_ACCOUNT_ID = '[AccountCurrentPortfolio] UPDATE_ACCOUNT_ID';
export const UPDATE_ACCOUNT_ID_SUCCESS = '[AccountCurrentPortfolio]  UPDATE_ACCOUNT_ID_SUCCESS';
export const UPDATE_ACCOUNT_ID_ERROR = '[AccountCurrentPortfolio] UPDATE_ACCOUNT_ID_ERROR';

export const ADD_FLAT_ACCOUNT = '[AccountCurrentPortfolio] ADD_FLAT_ACCOUNT';
export const ADD_FLAT_ACCOUNT_SUCCESS = '[AccountCurrentPortfolio]  ADD_FLAT_ACCOUNT_SUCCESS';
export const ADD_FLAT_ACCOUNT_ERROR = '[AccountCurrentPortfolio] ADD_FLAT_ACCOUNT_ERROR';

export const UPDATE_FLAT_ACCOUNT = '[AccountCurrentPortfolio] UPDATE_FLAT_ACCOUNT';
export const UPDATE_FLAT_ACCOUNT_SUCCESS = '[AccountCurrentPortfolio]  UPDATE_FLAT_ACCOUNT_SUCCESS';
export const UPDATE_FLAT_ACCOUNT_ERROR = '[AccountCurrentPortfolio] UPDATE_FLAT_ACCOUNT_ERROR';

export class GetAccountCurrentPortfolio implements Action {
  readonly type = GET_ACCOUNT_CURRENT_PORTFOLIO;
  constructor(public clientId, public accountId: string, public lookThroughEnabled: boolean) {}
}

export class GetAccountCurrentPortfolioSuccess implements Action {
  readonly type = GET_ACCOUNT_CURRENT_PORTFOLIO_SUCCESS;
  constructor(public accountCurrentPortfolio: CurrentPortfolioModel) {}
}

export class GetAccountCurrentPortfolioError implements Action {
  readonly type = GET_ACCOUNT_CURRENT_PORTFOLIO_ERROR;
  constructor(public error: any) {}
}

export class GetAccountOverviewConfig implements Action {
  readonly type = GET_ACCOUNT_OVERVIEW_CONFIG;
  constructor(public clientId: string, public accountId: string) {}
}

export class GetAccountOverviewConfigSuccess implements Action {
  readonly type = GET_ACCOUNT_OVERVIEW_CONFIG_SUCCESS;
  constructor(public accountOverviewConfig: AccountOverviewConfig) {}
}

export class GetAccountOverviewConfigError implements Action {
  readonly type = GET_ACCOUNT_OVERVIEW_CONFIG_ERROR;
  constructor(public error: any) {}
}

export class CreateMandatePortfolio implements Action {
  readonly type = CREATE_MANDATE_PORTFOLIO;
  constructor(public clientId: string, public accountOverview: AccountOverview) {}
}

export class CreateMandatePortfolioSuccess implements Action {
  readonly type = CREATE_MANDATE_PORTFOLIO_SUCCESS;
  constructor() {}
}

export class CreateMandatePortfolioError implements Action {
  readonly type = CREATE_MANDATE_PORTFOLIO_ERROR;
  constructor(public error: any) {}
}

export class UpdateMandatePortfolio implements Action {
  readonly type = UPDATE_MANDATE_PORTFOLIO;
  constructor(public clientId: string, public accountId: string,
     public current: AccountOverview, public update: AccountOverview,
     public isLookThroughEnabled) {}
}

export class UpdateMandatePortfolioSuccess implements Action {
  readonly type = UPDATE_MANDATE_PORTFOLIO_SUCCESS;
  constructor() {}
}

export class UpdateMandatePortfolioError implements Action {
  readonly type = UPDATE_MANDATE_PORTFOLIO_ERROR;
  constructor(public error: any) {}
}

export class AddHolding implements Action {
  readonly type = ADD_HOLDING;
  constructor(public asset: AssetResult, public value: number, public accountName: string) {}
}

export class AddHoldingSuccess implements Action {
  readonly type = ADD_HOLDING_SUCCESS;
  constructor() {}
}

export class AddHoldingError implements Action {
  readonly type = ADD_HOLDING_ERROR;
  constructor(public error: any) {}
}

export class RemoveHolding implements Action {
  readonly type = REMOVE_HOLDING;
  constructor(public asset: Asset) {}
}

export class RemoveHoldingSuccess implements Action {
  readonly type = REMOVE_HOLDING_SUCCESS;
  constructor() {}
}

export class RemoveHoldingError implements Action {
  readonly type = REMOVE_HOLDING_ERROR;
  constructor(public error: any) {}
}

export class ClearHoldings implements Action {
  readonly type = CLEAR_HOLDINGS;
  constructor() {}
}

export class ClearHoldingsSuccess implements Action {
  readonly type = CLEAR_HOLDINGS_SUCCESS;
  constructor() {}
}

export class ClearHoldingsError implements Action {
  readonly type = CLEAR_HOLDINGS_ERROR;
  constructor(public error: any) {}
}

export class SaveAccountCurrentPortfolio implements Action {
  readonly type = SAVE_ACCOUNT_CURRENT_PORTFOLIO;
  constructor(public clientId, public accountId: string, public accountCurrentPortfolio: CurrentPortfolioModel) {}
}

export class SaveAccountCurrentPortfolioSuccess implements Action {
  readonly type = SAVE_ACCOUNT_CURRENT_PORTFOLIO_SUCCESS;
  constructor(public accountCurrentPortfolio: CurrentPortfolioModel) {}
}

export class SaveAccountCurrentPortfolioError implements Action {
  readonly type = SAVE_ACCOUNT_CURRENT_PORTFOLIO_ERROR;
  constructor(public error: any) {}
}

export class UpdateAccountId implements Action {
  readonly type = UPDATE_ACCOUNT_ID;
  constructor(public clientId: string, public accountId: string, public newAccountId: string) {}
}

export class UpdateAccountIdSuccess implements Action {
  readonly type = UPDATE_ACCOUNT_ID_SUCCESS;
  constructor() {}
}

export class UpdateAccountIdError implements Action {
  readonly type = UPDATE_ACCOUNT_ID_ERROR;
  constructor(public error: any) {}
}

export class UpdateFlatAccount implements Action {
  readonly type = UPDATE_FLAT_ACCOUNT;
  constructor(public clientId: string, public accountId: string, public update: AccountOverview,
    public accountCurrentPortfolio: CurrentPortfolioModel) {}
}

export class UpdateFlatAccountSuccess implements Action {
  readonly type = UPDATE_FLAT_ACCOUNT_SUCCESS;
  constructor(public accountCurrentPortfolio: CurrentPortfolioModel) {}
}

export class UpdateFlatAccountError implements Action {
  readonly type = UPDATE_FLAT_ACCOUNT_ERROR;
  constructor(public error: any) {}
}

export class AddFlatAccount implements Action {
  readonly type = ADD_FLAT_ACCOUNT;
  constructor(public clientId: string, public accountId: string, public overview: AccountOverview) {}
}

export class AddFlatAccountSuccess implements Action {
  readonly type = ADD_FLAT_ACCOUNT_SUCCESS;
  constructor() {}
}

export class AddFlatAccountError implements Action {
  readonly type = ADD_FLAT_ACCOUNT_ERROR;
  constructor(public error: any) {}
}

export type All = GetAccountCurrentPortfolio | GetAccountCurrentPortfolioSuccess | GetAccountCurrentPortfolioError
                | GetAccountOverviewConfig | GetAccountOverviewConfigSuccess | GetAccountOverviewConfigError
                | CreateMandatePortfolio | CreateMandatePortfolioSuccess | CreateMandatePortfolioError
                | UpdateMandatePortfolio | UpdateMandatePortfolioSuccess | UpdateMandatePortfolioError
                | AddHolding | AddHoldingSuccess | AddHoldingError
                | RemoveHolding | RemoveHoldingSuccess | RemoveHoldingError
                | ClearHoldings | ClearHoldingsSuccess | ClearHoldingsError
                | SaveAccountCurrentPortfolio | SaveAccountCurrentPortfolioSuccess | SaveAccountCurrentPortfolioError
                | UpdateAccountId | UpdateAccountIdSuccess | UpdateAccountIdError
                | AddFlatAccount | AddFlatAccountSuccess | AddFlatAccountError
                | UpdateFlatAccount | UpdateFlatAccountSuccess | UpdateFlatAccountError;
