import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('TableHeaderForm')
export class TableHeaderForm {

  @JsonProperty('field', String)
  field: string = undefined;

  @JsonProperty('header', String)
  header: string = undefined;
}
