import { JsonObject, JsonProperty } from 'json2typescript';
import { EsgFactor } from './esg-factor.model';


@JsonObject('EsgPillarScores')
export class EsgPillarScores {
  @JsonProperty('environmental', Number, true)
  environmental: number = undefined;

  @JsonProperty('social', Number, true)
  social: number = undefined;

  @JsonProperty('governance', Number, true)
  governance: number = undefined;
}


@JsonObject('EsgBand')
export class EsgBand {
  @JsonProperty('name', Number, true)
  index: number = undefined;

  @JsonProperty('shortName', String, true)
  shortName: string = undefined;

  @JsonProperty('longName', String, true)
  longName: string = undefined;
}


@JsonObject('EsgBandExposure')
export class EsgBandExposure {
  @JsonProperty('esgBand', EsgBand, true)
  esgBand: EsgBand = undefined;

  @JsonProperty('exposure', Number, true)
  exposure: number = undefined;
}


@JsonObject('EsgPillarExposure')
export class EsgPillarExposure {
  @JsonProperty('environmental', [EsgBandExposure], true)
  environmental: Array<EsgBandExposure> = undefined;

  @JsonProperty('social', [EsgBandExposure], true)
  social: Array<EsgBandExposure> = undefined;

  @JsonProperty('governance', [EsgBandExposure], true)
  governance: Array<EsgBandExposure> = undefined;
}


@JsonObject('Co2Contribution')
export class Co2Contribution {
  @JsonProperty('name', String, true)
  name: string = undefined;

  @JsonProperty('contribution', Number, true)
  contribution: number = undefined;
}


@JsonObject('EsgFactorPreferenceConflictCounts')
export class EsgFactorPreferenceConflictCounts {
  @JsonProperty('conflicted', Number, true)
  conflicted: number = undefined;

  @JsonProperty('nonConflicted', Number, true)
  nonConflicted: number = undefined;

  @JsonProperty('noData', Number, true)
  noData: number = undefined;

  @JsonProperty('missingData', Number, true)
  missingData: number = undefined;
}


@JsonObject('EsgFactorPreferenceStatus')
export class EsgFactorPreferenceStatus {
  @JsonProperty('factorId', Number, true)
  factorId: number = undefined;

  @JsonProperty('pillar', String, true)
  pillar: string = undefined;

  @JsonProperty('factorName', String, true)
  factorName: string = undefined;

  @JsonProperty('minimumGoodnessBand', Number, true)
  minimumGoodnessBand: number = undefined;

  @JsonProperty('statuses', EsgFactorPreferenceConflictCounts, true)
  statuses: EsgFactorPreferenceConflictCounts = undefined;

  @JsonProperty('iconName', String, true)
  iconName: string = undefined;

  @JsonProperty('themePillar', Boolean, true)
  themePillar: boolean = undefined;
}


@JsonObject('EsgFactorPreferenceStatusSummary')
export class EsgFactorPreferenceStatusSummary {
  @JsonProperty('esgFactorPreferenceStatuses', [EsgFactorPreferenceStatus])
  esgFactorPreferenceStatuses: Array<EsgFactorPreferenceStatus> = undefined;
}


@JsonObject('PortfolioEsgSummary')
export class PortfolioEsgSummary {
  @JsonProperty('overallPillarScores', EsgPillarScores, true)
  overallPillarScores: EsgPillarScores = undefined;

  @JsonProperty('pillarExposure', EsgPillarExposure, true)
  pillarExposure: EsgPillarExposure = undefined;

  @JsonProperty('co2Contribution', Co2Contribution, true)
  co2Contribution: Co2Contribution = undefined;

  @JsonProperty('factorPreferenceStatuses', EsgFactorPreferenceStatusSummary, true)
  factorPreferenceStatuses: EsgFactorPreferenceStatusSummary = undefined;
}


@JsonObject('PortfolioEsgSummaryComparison')
export class PortfolioEsgSummaryComparison {
  @JsonProperty('current', PortfolioEsgSummary, true)
  current: PortfolioEsgSummary = undefined;

  @JsonProperty('proposed', PortfolioEsgSummary, true)
  proposed: PortfolioEsgSummary = undefined;

  @JsonProperty('proposedVsTarget', PortfolioEsgSummary, true)
  proposedVsTarget: PortfolioEsgSummary = undefined;

  @JsonProperty('proposedVsCurrent', PortfolioEsgSummary, true)
  proposedVsCurrent: PortfolioEsgSummary = undefined;

  @JsonProperty('target', PortfolioEsgSummary, true)
  target: PortfolioEsgSummary = undefined;
}


@JsonObject('EsgSummary')
export class EsgSummary {
  @JsonProperty('portfolioEsgSummaries', PortfolioEsgSummaryComparison, true)
  portfolioEsgSummaryComparison: PortfolioEsgSummaryComparison = undefined;

  @JsonProperty('numberOfPillarFactors', Number, true)
  numberOfPillarFactors: number = undefined;

  @JsonProperty('pillarFactors', [EsgFactor])
  pillarFactors: Array<EsgFactor> = undefined;
}
