import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('MandateAccountDetails')
export class MandateAccountDetails {

    @JsonProperty('accountId', String)
    accountId: string = undefined;

    @JsonProperty('accountName', String)
    accountName: string = undefined;
}

@JsonObject('MandateDestinationSearch')
export class MandateDestinationSearch {

    @JsonProperty('clientDisplayId', String)
    clientId: string = undefined;

    @JsonProperty('mandateDestinationList', [MandateAccountDetails])
    mandateDestinationList: MandateAccountDetails[] = undefined;

    @JsonProperty('searchResultMessage', String)
    searchResultMessage: string = undefined;
}


export class AccountUid {

    aid: string;
    cid: string;

    setAid(aid: string) {
        this.aid = aid;
    }

    setCid(cid: string) {
        this.cid = cid;
    }

    constructor(aid: string, cid: string) {
        this.aid = aid;
        this.cid = cid;
    }
}

export class AccountMoveData {

    sources: AccountUid[];
    destination: AccountUid;

    setSources(sources: AccountUid[]) {
        this.sources = sources;
    }

    setDestination(destination: AccountUid) {
        this.destination = destination;
    }
}
