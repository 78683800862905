import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JsonConvert } from 'json2typescript';
import { JsonConvertService } from '../shared/json-convert/json-convert.service';
import { KnownException } from '../shared/entity/known-exception.model';
import { ApprovalsMetaData } from './entity/approvals-metadata.model';
import { ApprovalCriteriaRequest } from './entity/approval-criteria-request.model';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { TableHeaderForm } from '../shared/entity/generic-form/generic-table-header-form';
import { GenericFormConfiguration } from '../shared/entity/generic-form/generic-form.model';

@Injectable()
export class ApprovalsService {
  private jsonConverter: JsonConvert;

  constructor(private httpClient: HttpClient, private jsonConvertService: JsonConvertService) {
    this.jsonConverter =  this.jsonConvertService.getJsonConverter();
  }

  getApprovalsResults(approvalCriteria: ApprovalCriteriaRequest): Observable<KnownException[]> {

    return this.httpClient.post(`${environment.serverUrl}/restapi/service/approvals/search`, approvalCriteria).pipe(
      map(response => {
        const approvalsResults: KnownException[] = response as KnownException[];
        return this.jsonConverter.deserializeArray(approvalsResults, KnownException);
      }));
  }

  getApprovalsResultsForm(): Observable<TableHeaderForm[]> {

    return this.httpClient.get(`${environment.serverUrl}/restapi/service/approvals/search/searchResultsConfig`).pipe(
      map(response => {
        const approvalsResultsForm: TableHeaderForm[] = response as TableHeaderForm[];
        return this.jsonConverter.deserializeArray(approvalsResultsForm, TableHeaderForm);
      }));
  }

  getApprovalsMetaData(): Observable<ApprovalsMetaData> {

    return this.httpClient.get(`${environment.serverUrl}/restapi/service/approvals/configuration`).pipe(
      map(response => this.jsonConverter.deserializeObject(response, ApprovalsMetaData)));
  }

  getApprovalsSearchCriteria(): Observable<GenericFormConfiguration> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/approvals/searchCriteriaConfig`).pipe(
      map(response => this.jsonConverter.deserializeObject(response['filters'], GenericFormConfiguration)));
  }
}
