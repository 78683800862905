import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { RootStoreModule } from './store/root-store.module';
import { NavigationMainComponent } from './navigation-main/navigation-main.component';
import { NavigationFloatingComponent } from './navigation-floating/navigation-floating.component';
import { QuickSearchComponent } from './quick-search/quick-search.component';
import { UserOptionsComponent } from './user-options/user-options.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthInterceptor } from '../auth/auth.interceptor';
import { ToastModule } from 'primeng/toast';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { NavActionService } from './navigation-floating/nav-action.service';
import { MessageService } from 'primeng/api';
import { SettingsService } from './settingsService.service';
import { InterfaceOptionsService } from './interface-options.service';
import { NavigationComponent } from './navigation/navigation.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthModule } from '../auth/auth.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    FontAwesomeModule,
    RootStoreModule,
    ToastModule,
    BsDropdownModule.forRoot(),
    AuthModule
  ],
  exports: [
    NavigationMainComponent,
    NavigationFloatingComponent,
    BsDropdownModule,
    ToastModule
  ],
  declarations: [
    NavigationMainComponent,
    NavigationFloatingComponent,
    QuickSearchComponent,
    UserOptionsComponent,
    NavigationComponent,
    AccessDeniedComponent,
    PageNotFoundComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    NavActionService,
    MessageService,
    SettingsService,
    InterfaceOptionsService,
    {provide: LOCALE_ID,
      deps: [SettingsService],
      useFactory: getLocale
    }
  ]
})

export class CoreModule { }

export function getLocale(settingsService: SettingsService) {
  return settingsService.getLocale();
}
