import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { JsonConvert } from 'json2typescript';
import { JsonConvertService } from '../../shared/json-convert/json-convert.service';
import { ClientSummary } from '../entity/client-summary.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ClientSummaryService {
  private jsonConverter: JsonConvert;

  constructor(private httpClient: HttpClient, private jsonConvertService: JsonConvertService) {
    this.jsonConverter =  this.jsonConvertService.getJsonConverter();
  }

  getClientSummary(clientId: string): Observable<ClientSummary> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/client/${clientId}/summary`)
      .pipe(map(response => this.jsonConverter.deserializeObject(response, ClientSummary)));
  }
}
