import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { CurrentPortfolioModel } from '../../entity/current-portfolio-model.model';
import { Store } from '@ngrx/store';
import { AccountCurrentPortfolioState } from '../../store/client-account/account-current-portfolio/account-current-portfolio.state';
import { getAccountCurrentPortfolio } from '../../store/shared-app.state';

@Injectable({
  providedIn: 'root'
})
export class AccountCurrentPortfolioService {

  portfolioChangedSubject: Subject<boolean> = new Subject();
  lastSavedPortfolioModel: Map<string, number>;

  currentPortfolio: Observable<AccountCurrentPortfolioState>;

  constructor(private store: Store<any>) {

    this.currentPortfolio = this.store.select(getAccountCurrentPortfolio);

    this.currentPortfolio.subscribe(portfolio => {

      if (this.detectChange(portfolio.accountCurrentPortfolio)) {
        this.portfolioChangedSubject.next(true);
      } else {
        this.portfolioChangedSubject.next(false);
      }
    });

  }

  updateLastSavedPortfolioModel(currentPortfolioModel: CurrentPortfolioModel) {
    this.lastSavedPortfolioModel = this.flatMap(currentPortfolioModel);
  }

  public detectChange(firstPortfolio: CurrentPortfolioModel): boolean {
    const portfolioOneFlatMap = this.flatMap(firstPortfolio);

    if (!this.lastSavedPortfolioModel) {
      return false;
    }

    if (portfolioOneFlatMap.size !== this.lastSavedPortfolioModel.size) {
      return true;
    }

    for (const key of Array.from( portfolioOneFlatMap.keys())) {
      const firstHoldingValue = portfolioOneFlatMap.get(key);
      const secondHoldingValue = this.lastSavedPortfolioModel.get(key);

      if (secondHoldingValue === undefined) {
        return true;
      }

      if (firstHoldingValue !== secondHoldingValue) {
        return true;
      }
    }

    return false;
  }

  public flatMap(currentPortfolioModel: CurrentPortfolioModel): Map<string, number> {
    const resultMap = new Map<string, number>();

    if (currentPortfolioModel && currentPortfolioModel.topLevelNode) {
      currentPortfolioModel.topLevelNode.forEach(topAssetNode =>
        topAssetNode.subAssetNode.forEach(subAssetNode =>
          subAssetNode.holdingNode.forEach(holdingNode => {
            resultMap.set(holdingNode.asset.name, holdingNode.currentPosition.value );
          })
        ));
    }
    return resultMap;
  }
}
