import { Output, Injectable, EventEmitter, Directive } from '@angular/core';
import { TradeRow } from '../../../shared/entity/trade-row.model';
import { TradeRationaleType } from '../../../shared/entity/trade-rationale.model';

@Directive()
@Injectable()
export class AssetListService {

  selectedHoldingId = '';
  checked = false;

  @Output()
  selectedHoldingEvent = new EventEmitter<string>();

  @Output()
  toggleSelectAllAssetsToTradeEvent = new EventEmitter<Array<TradeRow>>();

  @Output()
  tradeRationaleEvent = new EventEmitter<TradeRationaleType>();

  @Output()
  tradeRationaleTextEvent = new EventEmitter<{rationaleText: string, holdingGuid: string}>();

  @Output()
  updateListStateEvent = new EventEmitter<void>();

  @Output()
  exportAssetListEvent = new EventEmitter<void>();

  expandSelectedHoldingNode(selectedHoldingId: string) {
    this.selectedHoldingId = selectedHoldingId;
    this.selectedHoldingEvent.emit(selectedHoldingId);
  }

  selectAllAssetsForTrade(checked: boolean, assetList: TradeRow[]) {
    this.checked = checked;
    this.toggleSelectAllAssetsToTradeEvent.emit(assetList);
  }

  setTradeRationale(tradeRationale: TradeRationaleType) {
    this.tradeRationaleEvent.next(tradeRationale);
  }

  setTradeRationaleText(rationaleText: string, holdingId: string) {
    this.tradeRationaleTextEvent.next({ rationaleText: rationaleText, holdingGuid: holdingId });
  }

  updateListState() {
    this.updateListStateEvent.next();
  }

  exportAssetList() {
    this.exportAssetListEvent.next();
  }
}
