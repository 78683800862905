export function mockProfiles() {
    return [
        {
            id: 1,
            accountName: 'BMID034FG',
            office: 'London',
            clientOwner: 'Andrew Newton',
            bitaRiskCategory: 'RC2',
            backOfficeRiskCategory: 'RC2',
            bitaModel: 'Med Low',
            backOfficeModel: 'Med Low',
            bitaObjective: 'Growth',
            backOfficeObjective: 'Income',
            bitaModelId: 'WMA Bal',
            backOfficeModelId: 'WMA Bal'
        },
        {
            id: 2,
            accountName: 'BMID037AA',
            office: 'Edinburgh',
            clientOwner: 'Bill Gates',
            bitaRiskCategory: 'RC2',
            backOfficeRiskCategory: 'RC3',
            bitaModel: 'Med Low',
            backOfficeModel: 'Med High',
            bitaObjective: 'Income',
            backOfficeObjective: 'Income',
            bitaModelId: 'WMA Bal',
            backOfficeModelId: 'WMA Inc'
        },
        {
            id: 3,
            accountName: 'BMID011BB',
            office: 'Cardiff',
            clientOwner: 'Tim Jones',
            bitaRiskCategory: '',
            backOfficeRiskCategory: '',
            bitaModel: '',
            backOfficeModel: '',
            bitaObjective: '',
            backOfficeObjective: '',
            bitaModelId: '',
            backOfficeModelId: ''
        }
   ];
}
