export function mockAssetAnalyticsColumnHeaders() {
    return [
      {
        // this id is only for mock api
        'id': 1,
        'columnHeaders': {
          'instruments': {
            'title': 'INSTRUMENTS',
            'hidden': false,
            'columns': {
              'classification': {
                'title': 'classification',
                'hidden': false,
                'configurable': false,
                'enabled': true,
                'width': 220
              },
              'icon': {
                'title': 'icon',
                'hidden': false,
                'configurable': true,
                'enabled': true,
                'width': 80
              },
              'name': {
                'title': 'name',
                'hidden': false,
                'configurable': false,
                'enabled': true,
                'width': 40
              },
              'identifier': {
                'title': 'identifier',
                'hidden': false,
                'configurable': false,
                'enabled': true,
                'width': 80
              },
              'currency': {
                'title': 'currency',
                'hidden': false,
                'configurable': true,
                'enabled': true,
                'width': 80
              },
              'value': {
                'title': 'value',
                'hidden': false,
                'configurable': true,
                'enabled': true,
                'width': 80
              },
              'weight': {
                'title': 'weight',
                'hidden': false,
                'configurable': true,
                'enabled': true,
                'width': 80
              },
              'mctr': {
                'title': 'MCTR',
                'hidden': false,
                'configurable': true,
                'enabled': true,
                'width': 70
              },
              'ctr': {
                'title': 'CTR',
                'hidden': false,
                'configurable': false,
                'enabled': true,
                'width': 70
              },
            }
          },
          'risk': {
            'title': 'RISK',
            'hidden': false,
            'columns': {
              'volatility': {
                'title': 'vol current model',
                'hidden': false,
                'configurable': false,
                'enabled': true,
                'width': 60
              },
              'previousVolatility': {
                'title': 'vol previous model',
                'hidden': false,
                'configurable': true,
                'enabled': true,
                'width': 60
              },
              'volatilityChange': {
                'title': 'vol change',
                'hidden': false,
                'configurable': false,
                'enabled': true,
                'width': 90
              },
              'maxDrawDown': {
                'title': 'max draw down',
                'hidden': false,
                'configurable': false,
                'enabled': true,
                'width': 50
              },
              'cVar': {
                'title': 'CVaR',
                'hidden': false,
                'configurable': true,
                'enabled': true,
                'width': 80
              },
              'negativeReturns': {
                'title': '% -ve returns',
                'hidden': false,
                'configurable': true,
                'enabled': true,
                'width': 80
              },
              'beta': {
                'title': 'BETA',
                'hidden': false,
                'configurable': false,
                'enabled': true,
                'width': 80
              }
            }
          },
          'return': {
            'title': 'RETURN',
            'hidden': false,
            'columns': {
              'threeMonthReturn': {
                'title': 'over last 3 mths',
                'hidden': false,
                'configurable': true,
                'enabled': true,
                'width': 50
              },
              'oneYearReturn': {
                'title': 'over last 1 year',
                'hidden': false,
                'configurable': true,
                'enabled': true,
                'width': 60
              },
              'threeYearReturn': {
                'title': 'over last 3 years',
                'hidden': false,
                'configurable': true,
                'enabled': true,
                'width': 50
              },
              'sacFromMetadata': {
                'title': 'SUB ASSET CLASS',
                'hidden': false,
                'configurable': true,
                'enabled': true,
                'width': 50
              },
              'threeMonthRelToAssetClass': {
                'title': 'relative to asset class 3 mths',
                'hidden': false,
                'configurable': true,
                'enabled': true,
                'width': 50
              },
              'oneYearRelToAssetClass': {
                'title': 'relative to asset class 12` mths',
                'hidden': false,
                'configurable': true,
                'enabled': true,
                'width': 60
              },
              'threeYearRelToAssetClass': {
                'title': 'relative to asset class 36 mths',
                'hidden': false,
                'configurable': true,
                'enabled': true,
                'width': 50
              }
            }
          }

        }
      }

   ];
}
