import { JsonObject, JsonProperty } from 'json2typescript';
import { Currency } from './currency.model';
import { Asset } from './asset.model';
import { RiskBoundary } from './risk-boundary.model';
import { TreeNode } from 'primeng/api';
import { PortfolioLevel } from './portfolioLevel.enum';
import { TradeRationaleType } from './trade-rationale.model';
import { DateConverter } from '../json-convert/DateConvertor';
import { Moment } from 'moment';
import { EsgHoldingData } from './esg-holding-data.model';
import { EsgSummary } from './esg-summary.model';

export interface AssetTreeNode extends TreeNode {
  assetType?: AssetNodeType;
  portfolioLevel?: PortfolioLevel;
  children?: AssetTreeNode[];
}

export enum EditProperty {
  weight,
  shares,
  value
}

export enum AssetNodeType {
  Top,
  Sub,
  Holding,
  Breakdown,
  Order
}

export enum TransactionType {
  Buy = 'Buy',
  Sell = 'Sell',
  Invest = 'Invest',
  Raise = 'Raise'
}

export enum OrderInclusionType {
  None = 'None',
  Pending = 'Pending',
  Candidate = 'Candidate',
  Both = 'Both'
}

@JsonObject('ModelTarget')
export class ModelTarget {

  @JsonProperty('minAA', Number)
  minAA: number = undefined;

  @JsonProperty('maxAA', Number)
  maxAA: number = undefined;

  @JsonProperty('bmAA', Number)
  bmAA: number = undefined;

  @JsonProperty('target', Number)
  target: number = undefined;

}

@JsonObject('OptimiserBoundary')
export class OptimiserBoundary {

  @JsonProperty('min', Number)
  min: number = undefined;

  @JsonProperty('max', Number)
  max: number = undefined;

  @JsonProperty('fix', Number, true)
  fix: number = undefined;

  @JsonProperty('alpha', Number, true)
  alpha: number = undefined;

}

@JsonObject('OrderNode')
export class OrderNode implements AssetTreeNode {
  assetType = AssetNodeType.Order;
  portfolioLevel = PortfolioLevel.BREAK_DOWN_ELEMENT;

  parent: BreakdownHoldingDataElement;

  @JsonProperty('state', String)
  state: string = undefined;

  @JsonProperty('shares', Number)
  shares: number = undefined;

  @JsonProperty('value', Number)
  value: number = undefined;

  @JsonProperty('orderReference', String)
  orderReference: string = undefined;

  get data() {
    return this;
  }
}

@JsonObject('AssetPortfolio')
export class AssetPortfolio {

  @JsonProperty('weight', Number)
  weight: number = undefined;

  @JsonProperty('shares', Number)
  shares: number = undefined;

  @JsonProperty('value', Number)
  value: number = undefined;

  @JsonProperty('yield', Number)
  yield: number = undefined;

  @JsonProperty('ctr', Number)
  ctr: number = undefined;

  @JsonProperty('mctr', Number)
  mctr: number = undefined;

  @JsonProperty('bookCost', Number)
  bookCost: number = undefined;

  @JsonProperty('valIncrease', Number, true)
  valIncrease: number = undefined;

}

@JsonObject('AssetTransaction')
export class AssetTransaction {

  @JsonProperty('rawGain', Number)
  rawGain: number = undefined;

  // TODO: should be enum of BUY/SELL
  @JsonProperty('transactionType', String)
  transactionType: string = undefined;

  @JsonProperty('transactionType', String, true)
  transactionTypeId: string = undefined;

  @JsonProperty('tradeNominal', Number)
  tradeNominal: number = undefined;

  @JsonProperty('approxProceeds', Number)
  approxProceeds: number = undefined;

  @JsonProperty('rationaleId', String)
  rationaleId: string = undefined;

  @JsonProperty('message', String)
  message: string = undefined;

}

@JsonObject('PmpAssetNode')
export abstract class AssetNode implements AssetTreeNode {

  assetType: AssetNodeType;
  portfolioLevel: PortfolioLevel;

  @JsonProperty('proposedVsTarget', Number)
  proposedVsTarget: number = undefined;

  @JsonProperty('proposedVsCurrent', Number)
  proposedVsCurrent: number = undefined;

  @JsonProperty('proposedNetGain', Number)
  proposedNetGain: number = undefined;

  @JsonProperty('volatility', Number)
  volatility: number = undefined;

  @JsonProperty('yield', Number)
  yield: number = undefined;

  @JsonProperty('asset', Asset)
  asset: Asset = undefined;

  @JsonProperty('modelTarget', ModelTarget)
  modelTarget: ModelTarget = undefined;

  @JsonProperty('optimiserBoundary', OptimiserBoundary, true)
  optimiserBoundary: OptimiserBoundary = undefined;

  @JsonProperty('currentPosition', AssetPortfolio)
  currentPosition: AssetPortfolio = undefined;

  @JsonProperty('proposedPosition', AssetPortfolio)
  proposedPosition: AssetPortfolio = undefined;

  @JsonProperty('excludedFromTrading', Boolean, true)
  excludedFromTrading: boolean = undefined;

  get data() {
    return this;
  }

}

@JsonObject('PmpBreakdownHoldingDataElement')
export class BreakdownHoldingDataElement implements AssetTreeNode {

  assetType = AssetNodeType.Breakdown;
  portfolioLevel = PortfolioLevel.BREAK_DOWN_ELEMENT;
  tradeRationale: TradeRationaleType;
  guid: String;

  parent: HoldingClassNode;

  @JsonProperty('orderNode', [OrderNode], true)
  orderNode: Array<OrderNode> = undefined;

  @JsonProperty('compositeLabel', String)
  compositeLabel: string = undefined;

  @JsonProperty('accountName', String)
  accountName: string = undefined;

  @JsonProperty('accountId', Number)
  accountId: number = undefined;

  @JsonProperty('proposedVsTarget', Number, true)
  proposedVsTarget: number = undefined;

  @JsonProperty('proposedVsCurrent', Number, true)
  proposedVsCurrent: number = undefined;

  @JsonProperty('proposedNetGain', Number, true)
  proposedNetGain: number = undefined;

  @JsonProperty('volatility', Number, true)
  volatility: number = undefined;

  @JsonProperty('yield', Number, true)
  yield: number = undefined;

  @JsonProperty('currentPosition', AssetPortfolio)
  currentPosition: AssetPortfolio = undefined;

  @JsonProperty('proposedPosition', AssetPortfolio, true)
  proposedPosition: AssetPortfolio = undefined;

  @JsonProperty('tradeInstruction', AssetTransaction, true)
  tradeInstruction: AssetTransaction = undefined;

  @JsonProperty('isReservedHolding', Boolean, true)
  isReservedHolding: boolean = undefined;

  get transactionType(): TransactionType {
    return this.isAssetBuy ? TransactionType.Buy : TransactionType.Sell;
  }

  get tradeValue(): number {
    return this.currentPosition.value - this.proposedPosition.value;
  }

  get tradeShares(): number {
    return this.currentPosition.shares - this.proposedPosition.shares;
  }

  get proposedVsCurrentValues(): number {
    return this.proposedPosition.value - this.currentPosition.value;
  }

  get isFullSale(): boolean {
    return this.proposedPosition.weight === 0 || this.proposedPosition.value === 0;
  }

  get isAssetBuy(): boolean {
    return this.proposedVsCurrentValues > 0;
  }

  get isAssetSell(): boolean {
    return this.proposedVsCurrentValues < 0;
  }

  get isAssetBuySell(): boolean {
    return this.isAssetBuy || this.isAssetSell;
  }

  get data() {
    return this;
  }

  get children() {
    return this.orderNode;
  }

  // TODO: Shall we move this to a general utility?
  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line no-bitwise
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  constructor() {
    this.guid = this.newGuid();
  }

}

@JsonObject('PmpHoldingClassNode')
export class HoldingClassNode extends AssetNode {

  assetType = AssetNodeType.Holding;
  portfolioLevel = PortfolioLevel.HOLDING;

  parent: SubAssetClassNode;

  // TODO: should be ENUM
  // values S, M, ST,MT
  @JsonProperty('source', String, true)
  source: string = undefined;

  // either account Level or for lookthrough
  @JsonProperty('breakdownNode', [BreakdownHoldingDataElement])
  breakdownNode: Array<BreakdownHoldingDataElement> = undefined;

  @JsonProperty('tradeInstruction', AssetTransaction)
  tradeInstruction: AssetTransaction = undefined;

  @JsonProperty('esgData', EsgHoldingData, true)
  esgData: EsgHoldingData = undefined;

  @JsonProperty('isNewHolding', Boolean, true)
  isNewHolding = false;

  @JsonProperty('flagIssue', String, true)
  flagIssue = undefined;

  @JsonProperty('numberOfAccountsHolding', Number, true)
  numberOfAccountsHolding: number = undefined;

  @JsonProperty('numberOfAccountsHoldingOwner', Number, true)
  numberOfAccountsHoldingOwner: number = undefined;

  @JsonProperty('isDnt', Boolean, true)
  isDnt = false;

  @JsonProperty('oneYearRelPerformance', Number, true)
  oneYearRelPerformance: number = undefined;

  @JsonProperty('oneYearAbsPerformance', Number, true)
  oneYearAbsPerformance: number = undefined;

  @JsonProperty('threeYearRelPerformance', Number, true)
  threeYearRelPerformance: number = undefined;

  @JsonProperty('threeYearAbsPerformance', Number, true)
  threeYearAbsPerformance: number = undefined;

  @JsonProperty('isRestrictedHolding', Boolean, true)
  isRestrictedHolding = false;

  get children() {
    return this.breakdownNode as TreeNode[];
  }

}

@JsonObject('PmpSubAssetClassNode')
export class SubAssetClassNode extends AssetNode {

  visibilityLevel: PortfolioLevel = PortfolioLevel.TAC;
  assetType = AssetNodeType.Sub;
  portfolioLevel = PortfolioLevel.SAC;

  parent: AssetClassNode;

  @JsonProperty('holdingNode', [HoldingClassNode])
  holdingNode: Array<HoldingClassNode> = undefined;

  getCalculatedProposedWeight(): number {
    let total = 0;
    for (const holdingNode of this.holdingNode) {
      total += holdingNode.proposedPosition.weight;
    }
    this.proposedPosition.weight = total;
    return total;
  }

  get children() {
    return this.holdingNode;
  }

}

@JsonObject('PmpAssetClassNode')
export class AssetClassNode extends AssetNode {

  assetType = AssetNodeType.Top;
  portfolioLevel = PortfolioLevel.TAC;

  @JsonProperty('subAssetNode', [SubAssetClassNode])
  subAssetNode: Array<SubAssetClassNode> = undefined;

  getCalculatedProposedWeight(): number {
    let total = 0;
    for (const subLevelNode of this.subAssetNode) {
      total += subLevelNode.getCalculatedProposedWeight();
    }
    this.proposedPosition.weight = total;
    return total;
  }

  get data() {
    return this;
  }

  get children() {
    return this.subAssetNode;
  }

}

@JsonObject('AccountDetails')
export class AccountDetails {

  @JsonProperty('id', Number)
  id: number = undefined;

  @JsonProperty('name', String)
  name: string = undefined;

  @JsonProperty('label', String)
  label: string = undefined;

}

@JsonObject('RiskTotal')
export class RiskTotal {

  @JsonProperty('currentPortfolioTotalRisk', Number)
  currentPortfolioTotalRisk: number = undefined;

  @JsonProperty('currentPortfolioTotalYield', Number)
  currentPortfolioTotalYield: number = undefined;

  @JsonProperty('currentPortfolioTotalReturn', Number, true)
  currentPortfolioTotalReturn: number = undefined;

  @JsonProperty('currentPortfolioRiskCategory', String)
  currentPortfolioRiskCategory: string = undefined;

  @JsonProperty('riskBoundaryLower', Number)
  riskBoundaryLower: number = undefined;

  @JsonProperty('riskBoundaryUpper', Number)
  riskBoundaryUpper: number = undefined;

  @JsonProperty('proposedPortfolioTotalRisk', Number)
  proposedPortfolioTotalRisk: number = undefined;

  @JsonProperty('proposedPortfolioTotalYield', Number)
  proposedPortfolioTotalYield: number = undefined;

  @JsonProperty('proposedPortfolioRiskCategory', String)
  proposedPortfolioRiskCategory: string = undefined;

  @JsonProperty('proposedPortfolioTotalReturn', Number, true)
  proposedPortfolioTotalReturn: number = undefined;

  @JsonProperty('riskBudget', Number)
  riskBudget: number = undefined;

  @JsonProperty('modelRiskCategory', String)
  modelRiskCategory: string = undefined;

}

@JsonObject('TradeWarning')
export class TradeWarning {

  @JsonProperty('tradeWarningMessage', String)
  tradeWarningMessage: string = undefined;

  @JsonProperty('tradeWarningType', String)
  tradeWarningType: string = undefined;

}

@JsonObject('InvestDivestValue')
export class InvestDivestValue {

  @JsonProperty('accountName', String)
  accountName: string = undefined;

  @JsonProperty('investValue', Number)
  investValue: number = undefined;

  @JsonProperty('divestValue', Number)
  divestValue: number = undefined;

}

@JsonObject('PortfolioConstructionModelTotal')
export class PortfolioConstructionModelTotal {
  @JsonProperty('currentPosition', AssetPortfolio)
  currentPosition: AssetPortfolio = undefined;

  @JsonProperty('proposedPosition', AssetPortfolio)
  proposedPosition: AssetPortfolio = undefined;

  @JsonProperty('proposedVsTarget', Number)
  proposedVsTarget: number = undefined;

  @JsonProperty('proposedVsCurrent', Number)
  proposedVsCurrent: number = undefined;

  @JsonProperty('modelTarget', Number)
  modelTarget: number = undefined;

  @JsonProperty('optimiserBoundary', OptimiserBoundary, true)
  optimiserBoundary: OptimiserBoundary = undefined;

  @JsonProperty('assetTransactionTotal', AssetTransaction, true)
  assetTransactionTotal: AssetTransaction = undefined;

  @JsonProperty('currentPortfolioTotalRisk', Number, true)
  currentPortfolioTotalRisk: number = undefined;

  @JsonProperty('currentPortfolioRiskCategory', String, true)
  currentPortfolioRiskCategory: string = undefined;

  @JsonProperty('currentPortfolioTotalYield', Number, true)
  currentPortfolioTotalYield: number = undefined;

  @JsonProperty('riskBoundary', RiskBoundary, true)
  riskBoundary: RiskBoundary = undefined;

  @JsonProperty('riskBudget', Number, true)
  riskBudget: number = undefined;

  @JsonProperty('modelRiskCategory', String, true)
  modelRiskCategory: string = undefined;

  @JsonProperty('proposedPortfolioTotalRisk', Number, true)
  proposedPortfolioTotalRisk: number = undefined;

  @JsonProperty('proposedPortfolioTotalYield', Number, true)
  proposedPortfolioTotalYield: number = undefined;

  @JsonProperty('remainingCapitalGainsTaxAllowance', Number, true)
  remainingCapitalGainsTaxAllowance: number = undefined;

}

@JsonObject('TradeOrderInstructionRationale')
export class TradeOrderInstructionRationale {

  @JsonProperty('reason', String)
  reason: string = undefined;

  @JsonProperty('note', String)
  note: string = undefined;

}

@JsonObject('TradeOrderInstruction')
export class TradeOrderInstruction {

  @JsonProperty('accountId', String)
  accountId: string = undefined;

  @JsonProperty('clientId', String)
  clientId: string = undefined;

  @JsonProperty('clientName', String)
  clientName: string = undefined;

	@JsonProperty('portfolioId', String)
	portfolioId: string = undefined;

  @JsonProperty('assetId', String)
  assetId: String = undefined;

  @JsonProperty('assetName', String, true)
  assetName: String = undefined;

  @JsonProperty('currency', Currency, true)
  currency: Currency = undefined;
  
  @JsonProperty('value', Number)
  value: number = undefined;

  @JsonProperty('shares', Number)
  shares: number = undefined;

  @JsonProperty('direction', String)
  direction: string = undefined;

  @JsonProperty('directionId', String, true)
  directionId: string = undefined;

  @JsonProperty('contractReference', String, true)
  contractReference: string = undefined;

  @JsonProperty('worksetReference', String, true)
  worksetReference: string = undefined;

  @JsonProperty('worksetSequence', Number, true)
  worksetSequence: number = undefined;

  @JsonProperty('rationale', TradeOrderInstructionRationale)
  rationale: TradeOrderInstructionRationale = undefined;

}

@JsonObject('TradeOrderError')
export class TradeOrderError {

  @JsonProperty('message', String)
  message: string = undefined;

  @JsonProperty('httpCode', Number)
  httpCode: number = undefined;

}

@JsonObject('TradeOrderErrorResponses')
export class TradeOrderErrorResponses {

  @JsonProperty('error', [TradeOrderError])
  error: TradeOrderError[] = undefined;
}

@JsonObject('TradeOrderWorkset')
export class TradeOrderWorkset {

  @JsonProperty('requestDate', DateConverter, true)
  requestDate: Moment = undefined;

  @JsonProperty('requestBy', String)
  requestBy: string = undefined;

  @JsonProperty('accepted', Boolean)
  accepted: boolean = undefined;

  @JsonProperty('errorResponses', TradeOrderErrorResponses)
  errorResponses: TradeOrderErrorResponses = undefined;

  @JsonProperty('order', [TradeOrderInstruction])
  order: TradeOrderInstruction[] = undefined;

}

@JsonObject('PortfolioConstructionModel')
export class PortfolioConstructionModel implements AssetTreeNode {

  @JsonProperty('clientId', String)
  clientId: string = undefined;

  @JsonProperty('clientStatus', String)
  clientStatus: string = undefined;

  @JsonProperty('accountId', String)
  accountId: string = undefined;

  @JsonProperty('accountName', [AccountDetails])
  accountName: AccountDetails[] = undefined;

  @JsonProperty('isEditable', Boolean, true)
  isEditable = true;

  @JsonProperty('accountType', String, true)
  accountType: string = undefined;

  @JsonProperty('scenarioId', String)
  scenarioId: string = undefined;

  @JsonProperty('scenarioName', String)
  scenarioName: string = undefined;

  @JsonProperty('targetModelName', String)
  targetModelName: string = undefined;

  @JsonProperty('currency', Currency)
  currency: Currency = undefined;

  @JsonProperty('topLevelNode', [AssetClassNode])
  topLevelNode: Array<AssetClassNode> = undefined;

  @JsonProperty('lookthroughEnabled', Boolean)
  lookthroughEnabled: boolean = undefined;

  @JsonProperty('isBenchmarkAsStartingPortfolio', Boolean)
  isBenchmarkAsStartingPortfolio: boolean = undefined;

  @JsonProperty('tradeCashEnabled', Boolean)
  tradeCashEnabled: boolean = undefined;

  @JsonProperty('tradeCashNodeName', String)
  tradeCashNodeName: string = undefined;

  @JsonProperty('total', PortfolioConstructionModelTotal)
  total: PortfolioConstructionModelTotal = undefined;

  @JsonProperty('riskTotal', RiskTotal, true)
  riskTotal: RiskTotal = undefined;

  @JsonProperty('isDataChanged', Boolean)
  isDataChanged: boolean = undefined;

  @JsonProperty('oneClickExpandAllEnabled', Boolean)
  oneClickExpandAllEnabled: boolean = undefined;

  @JsonProperty('hasTradeWarnings', Boolean, true)
  hasTradeWarnings: boolean = undefined;

  @JsonProperty('tradeWarning', [TradeWarning], true)
  tradeWarning: TradeWarning[] = undefined;

  @JsonProperty('holdingDate', DateConverter, true)
  holdingDate: Moment = undefined;

  @JsonProperty('investDivestValue', [InvestDivestValue])
  investDivestValue: InvestDivestValue[] = undefined;

  @JsonProperty('orderInclusion', String)
  orderInclusion: string = undefined;

  @JsonProperty('tradeOrderInstruction', [TradeOrderInstruction])
  tradeOrderInstruction: TradeOrderInstruction[] = undefined;

  @JsonProperty('tradeOrderWorkset', [TradeOrderWorkset])
  tradeOrderWorkset: TradeOrderWorkset[] = undefined;

  @JsonProperty('updatedSource', String, true)
  updatedSource: string = undefined;

  @JsonProperty('esgSummary', EsgSummary, true)
  esgSummary: EsgSummary = undefined;

  recalculatedProposedWeight(): number {
    let total = 0;
    for (const subLevelNode of this.topLevelNode) {
      total += subLevelNode.getCalculatedProposedWeight();
    }
    this.total.proposedPosition.weight = total;
    return total;
  }

  updateGlobalVisibilityLevel(level: PortfolioLevel, collapse: boolean) {
    this.updateVisibilityLevel(level, this, collapse);
  }

  updateVisibilityLevel(level: PortfolioLevel, node: AssetTreeNode, collapse: boolean) {
    if (node.assetType === AssetNodeType.Sub) {
      const subNode: any = node;
      subNode.visibilityLevel = (level - 1);
    }

    for (const childNode of node.children) {
      childNode.expanded = childNode.portfolioLevel < level ? true : (collapse ? false : childNode.expanded);
      if (childNode.children && childNode.children.length) {
        this.updateVisibilityLevel(level, childNode, collapse);
      }
    }
  }

  tradesAvailable(): boolean {
    for (const topAsset of this.topLevelNode) {
      for (const subAsset of topAsset.subAssetNode) {
        for (const holdingAsset of subAsset.holdingNode) {
          for (const breakdownAsset of holdingAsset.breakdownNode) {
            if (breakdownAsset.isAssetBuySell) {
              return true;
            }
          }
        }
      }
    }

    return false;
  }

  isTradeScenario(): boolean {
    return this.tradeOrderWorkset && this.tradeOrderWorkset.length > 0;
  }

  get children() {
    return this.topLevelNode;
  }

  set children(children) {
    this.topLevelNode = children;
  }

}
