import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JsonConvertService } from '../shared/json-convert/json-convert.service';
import { JsonConvert } from 'json2typescript';
import { Configuration } from '../core/entity/configuration.model';
import { AuthenticationConfig } from  '../core/entity/authenticationConfig.model';
import { Session } from '../core/entity/session.model';
import { User } from '../shared/entity/user.model';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthenticationService {
	private jsonConverter: JsonConvert;
	private loginOAuth2Url: string;
	private loginUrl: string;
	private logoutUrl: string;
	private checkSingleSignOnUrl: string;

	constructor(private location: Location, private httpClient: HttpClient, private jsonConvertService: JsonConvertService) {
		const serverUrl = `${environment.serverUrl}`;
		this.jsonConverter = this.jsonConvertService.getJsonConverter();
		this.loginOAuth2Url = serverUrl + location.prepareExternalUrl('signin/oauth2');
		this.loginUrl = serverUrl + location.prepareExternalUrl('login');
		this.logoutUrl = serverUrl + location.prepareExternalUrl('logout');
		this.checkSingleSignOnUrl = serverUrl + location.prepareExternalUrl('messenger/welcome');
		// console.log('loginUrl: ' + this.loginUrl);
		// console.log('logoutUrl: ' + this.logoutUrl);
		// console.log('checkSingleSignOnUrl: ' + this.checkSingleSignOnUrl);
	}

	authenticate(userName: string, password: string) {

    const encodedUserName = encodeURIComponent(userName);
    const encodedPassword = encodeURIComponent(password);
    const payload = `username=${encodedUserName}&password=${encodedPassword}`;
    return this.httpClient.post(this.loginUrl, payload,
			{
				headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
			}).pipe(map(response => {

				return this.parseSession(response);
			},
			error => console.error(error)
			));
	}

	checkSingleSignOn(): Observable<Session> {
		return this.httpClient.get(this.checkSingleSignOnUrl).pipe(map(response => {
			return this.parseSession(response);
		},
			error => console.error(error)
			));
	}

	logout() {
		console.log('logoutUrl: ' + this.logoutUrl);
		return this.httpClient.post(this.logoutUrl, {});
	}

	checkSessionDetails() {
		return this.httpClient.get(this.checkSingleSignOnUrl);
	}

	private parseSession(response: Object): Session {
		let user: User = response as User;
		user = this.jsonConverter.deserializeObject(user, User);

		let configuration = response['configuration'] as Configuration;
		if (configuration) {
			configuration = this.jsonConverter.deserializeObject(configuration, Configuration);
		}
		const session: Session = new Session();
		session.user = user;
		session.configuration = configuration;
		const accessDetails: Object[] = response['accessDetails'] as Object[];
		session.lastAccessedClientId = accessDetails.filter(details => 'lastAccessedClientId' === details['field'])
      .map(details => details['value'])[0];

		if (!session.lastAccessedClientList) {
			session.lastAccessedClientList = [];
		}

		// add all clientIDs returned from the end-point
		accessDetails.filter(details => 'lastAccessedClientId' === details['field'])
					 .map(details => details['value'])
					 .forEach(v => session.lastAccessedClientList.push(v));

		// check if multiple authenticatior are configureaed
		let authenticationConfig: AuthenticationConfig = response as AuthenticationConfig;
		if (authenticationConfig) {
			session.authenticationConfig =  this.jsonConverter.deserializeObject(authenticationConfig, AuthenticationConfig);
		}
		return session;
	}

	getLoginUrl(): string {
		return this.loginUrl;
	}

	getExternalLoginUrl(): string {
		return this.loginOAuth2Url;
	}

}
