import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { ClientAapService } from '../../service/client-aap.service';
import * as ClientAapActions from './client-aap.actions';
import { NavActionService } from '../../../core/navigation-floating/nav-action.service';

export type ClientAapAction = ClientAapActions.All;

@Injectable()
export class ClientAapEffects {

	constructor(private actions$: Actions,
              private aapService: ClientAapService,
              private navActionService: NavActionService) {
	}

  getClientAap: Observable<ClientAapAction> = createEffect(() =>

  this.actions$
    .pipe(
      ofType(ClientAapActions.GET_CLIENT_AAP),
      switchMap((a: ClientAapActions.GetClientAap) =>
      this.aapService.getClientAap(a.clientId, a.accountId, a.scenarioId)
      .pipe(map(post => new ClientAapActions.GetClientAapSuccess(post)),
        catchError((error) => {
          const errorMessage = error.error ? error.error.message : 'An error occurred';
          this.navActionService.addMessage('Error', errorMessage, 'error');
          return of(new ClientAapActions.GetClientAapError(error))
        }
      )))
    ));
}
