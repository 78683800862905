import { JsonObject, JsonProperty } from 'json2typescript';
import { MapConverter } from '../../shared/json-convert/MapConverter';


@JsonObject('ClientSummaryConfigItem')
export class ClientSummaryConfigItem {

  @JsonProperty('id', String)
  id: string  = undefined;

  @JsonProperty('label', String)
  label: string  = undefined;

  @JsonProperty('fields', MapConverter)
  fields: Map<string, string> = undefined;

}

@JsonObject('ClientSummaryConfig')
export class ClientSummaryConfig {

  @JsonProperty('ClientInformation', ClientSummaryConfigItem, true)
  InvestmentParameters: ClientSummaryConfigItem  = undefined;

  @JsonProperty('PortfolioInformation', ClientSummaryConfigItem, true)
  PortfolioInformation: ClientSummaryConfigItem  = undefined;

}

@JsonObject('ClientSummaryPortfolio')
export class ClientSummaryPortfolio {

  @JsonProperty('bmid', String)
  bmid: string  = undefined;

  @JsonProperty('description', String)
  description: string  = undefined;

  @JsonProperty('currency', String)
  currency: string  = undefined;

  @JsonProperty('feesStatus', String)
  feesStatus: string  = undefined;

  @JsonProperty('questionnaireStatus', String)
  questionnaireStatus: string  = undefined;

  @JsonProperty('esgQuestionnaireStatus', String)
  esgQuestionnaireStatus: string  = undefined;

  @JsonProperty('profileStatus', String)
  profileStatus: string  = undefined;

  @JsonProperty('profileStatusDate', String)
  profileStatusDate: string  = undefined;

  @JsonProperty('riskCategory', String)
  riskCategory: string  = undefined;

  @JsonProperty('model', String)
  model: string  = undefined;

  @JsonProperty('modelId', String)
  modelId: string  = undefined;

  @JsonProperty('objective', String)
  objective: string  = undefined;

  @JsonProperty('timeHorizon', String)
  timeHorizon: string  = undefined;

}

@JsonObject('ClientSummaryData')
export class ClientSummaryData {

  @JsonProperty('clientId', String)
  clientId: string  = undefined;

  @JsonProperty('office', String)
  office: string  = undefined;

  @JsonProperty('owner', String)
  owner: string  = undefined;

  @JsonProperty('status', String)
  status: string  = undefined;

  @JsonProperty('clientType', String)
  clientType: string  = undefined;

  @JsonProperty('source', String)
  source: string  = undefined;

  @JsonProperty('kyc', String)
  kyc: string  = undefined;

  @JsonProperty('portfolios', [ClientSummaryPortfolio], true)
  portfolios: ClientSummaryPortfolio[] = [];

}

@JsonObject('ClientSummary')
export class ClientSummary {

  @JsonProperty('config', ClientSummaryConfig, true)
  config: ClientSummaryConfig = undefined;

  @JsonProperty('data', ClientSummaryData, true)
  data: ClientSummaryData = undefined;

}
