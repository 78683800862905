import { Configuration } from '../entity/configuration.model';
import * as ConfigurationKeys from '../entity/configuration.const';


export default class DefaultConfigurationFactory {

  static getDefaultConfiguration(): Configuration {

    const config = new Configuration();

    const userConfig = new Map<string, string>();
    const systemConfig = new Map<string, string>();
    systemConfig.set(ConfigurationKeys.DASHBOARD_PAGE_ACCOUNT_REDIRECT_TARGET, '');
    systemConfig.set(ConfigurationKeys.PMP_PAGE_APP_WIDGET_MODEL_PICKER_VISIBLE, 'false');
    systemConfig.set(ConfigurationKeys.CLIENT_HOME_PAGE_DISPLAY_OLD_SCENARIOS, 'false');
    systemConfig.set(ConfigurationKeys.PMP_PAGE_APP_WIDGET_ACTIONS_VISIBLE, 'false');
    systemConfig.set(ConfigurationKeys.CLIENT_QUESTIONNAIRE_ENABLED, 'true');
    systemConfig.set(ConfigurationKeys.CLIENT_PROFILE_PAGE_CONFIRM_LABEL, 'CONFIRM PROFILE');
    systemConfig.set(ConfigurationKeys.CLIENT_PROFILE_PAGE_AUTO_CONFIRM_LABEL, '');
    systemConfig.set(ConfigurationKeys.CLIENT_PROFILE_PAGE_ADJUSTMENT_TYPES_LABEL_TAA, 'RISK PROFILE ADJUSTMENT');
    systemConfig.set(ConfigurationKeys.CLIENT_PROFILE_PAGE_ADJUSTMENT_TYPES_LABEL_SAA, '');
    systemConfig.set(ConfigurationKeys.PMP_PAGE_KEY_MONITOR_WIDGET_VISIBLE, 'true');
    systemConfig.set(ConfigurationKeys.PMP_PAGE_NON_KEY_MONITOR_WIDGET_VISIBLE, 'true');
    systemConfig.set(ConfigurationKeys.PMP_PAGE_TRADE_RATIONALE_ENABLED, 'true');
    systemConfig.set(ConfigurationKeys.PMP_PAGE_RISK_CATEGORY_VISIBLE, 'true');
    systemConfig.set(ConfigurationKeys.PMP_PAGE_INVESTMENT_PROPOSAL_ENABLED, 'true');
    systemConfig.set(ConfigurationKeys.PMP_INVEST_DIVEST_ENABLED, 'true');
    systemConfig.set(ConfigurationKeys.PMP_IPR_REQUIRES_COMPLETED_QUESTIONNAIRE, 'true');
    systemConfig.set(ConfigurationKeys.CLIENT_HOME_PAGE_EDIT_CLIENT_ENABLED, 'true');
    systemConfig.set(ConfigurationKeys.CLIENT_HOME_PAGE_ADD_BMID_ENABLED, 'false');
    systemConfig.set(ConfigurationKeys.CLIENT_HOME_PAGE_FILE_NOTES_ENABLED, 'true');
    systemConfig.set(ConfigurationKeys.CLIENT_HOME_PAGE_DISPLAY_SOURCE_COLUMN, 'true');
    systemConfig.set(ConfigurationKeys.UI_LOCALE, 'en-gb');
    systemConfig.set(ConfigurationKeys.CLIENT_QUESTIONNAIRE_PRINT_ENABLED, 'true');
    systemConfig.set(ConfigurationKeys.PMP_PAGE_FILE_NOTES_ENABLED, 'false');
    systemConfig.set(ConfigurationKeys.ASSET_SEARCH_DEFAULT_BUYLIST, 'true');
    systemConfig.set(ConfigurationKeys.BULK_TRADE_RATIONAL_ENABLED, 'true');
    systemConfig.set(ConfigurationKeys.BULK_TRADE_PRICE_CURRENCY_OVERRIDE, '');
    systemConfig.set(ConfigurationKeys.TRADE_ORDER_DIRECTION_ID_MAP, '');
    systemConfig.set(ConfigurationKeys.TRADE_ORDER_SAVE_ON_SERVER, 'false');
    systemConfig.set(ConfigurationKeys.TRADE_ORDER_FUND_RAISE_AND_INVEST, 'true');
    systemConfig.set(ConfigurationKeys.CLIENT_SEARCH_ACCOUNT_OWNER_MANAGER_ENABLED, 'false');
    systemConfig.set(ConfigurationKeys.PORTFOLIO_LOOKTHROUGH_ENABLED, 'true');
    systemConfig.set(ConfigurationKeys.ASSET_SEARCH_EPIC_ENABLED, 'false');
    systemConfig.set(ConfigurationKeys.ASSET_ANALYTICS_ENABLED, 'false');
    systemConfig.set(ConfigurationKeys.ESG_ENABLED, 'false');
    systemConfig.set(ConfigurationKeys.KNOWN_EXCEPTIONS_HOLDING_EXCLUSION_ENABLED, 'false');
    systemConfig.set(ConfigurationKeys.CLIENT_ADD_ACCOUNT_ENABLED, 'false');
    systemConfig.set(ConfigurationKeys.BUYLIST_PRINT_EDITABLE_FORM_ENABLED, 'true');
    systemConfig.set(ConfigurationKeys.PPP_FIRST_RATE_URL, '');
    systemConfig.set(ConfigurationKeys.NEW_CLIENT_ADD_BUTTON_LABEL, 'ADD');
    systemConfig.set(ConfigurationKeys.ESG_QUESTIONNAIRE_PAGE_SEND_ENABLED, 'false');
    systemConfig.set(ConfigurationKeys.ESG_KEY_CHANGES_VISIBLE, 'true');
    systemConfig.set(ConfigurationKeys.ESG_PRODUCT_PREFERENCES_VISIBLE, 'true');
    systemConfig.set(ConfigurationKeys.ESG_PREFERENCES_VISIBLE, 'true');
    systemConfig.set(ConfigurationKeys.ESG_CO2_EXPOSURE_VISIBLE, 'true');
    systemConfig.set(ConfigurationKeys.ESG_IMPACT_REPORT_STOCKS_VISIBLE, 'true');
    systemConfig.set(ConfigurationKeys.ESG_KEY_METRICS_VISIBLE, 'true');
    systemConfig.set(ConfigurationKeys.ESG_IMPACT_REPORT_PREFERENCES_VISIBLE, 'true');
    systemConfig.set(ConfigurationKeys.REPORTS_DOWNLOAD_ENABLED, 'true');
    systemConfig.set(ConfigurationKeys.PMI_ENABLED, 'false');

    config.systemConfig = systemConfig;
    config.userConfig = userConfig;

    return config;

  }
}
