import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('User')
export class User {

  @JsonProperty('username', String)
  userName: String = undefined;

  @JsonProperty('authorities', [String])
  authorities: Array<String> = undefined;

  constructor(userName?: String, authorities?: Array<String>) {
    this.userName = userName;
    this.authorities = authorities;
  }
}
