import { Action } from '@ngrx/store';

import { AssetColumns } from '../../entity/asset-columns.model';
import { PortfolioLevel } from '../../entity/portfolioLevel.enum';
import { UserPreferenceCategory } from '../../entity/user-preference-category.enum';

export const GET_ASSET_COLUMNS = '[InterfaceOptions] GET_ASSET_COLUMNS';
export const GET_ASSET_COLUMNS_SUCCESS = '[InterfaceOptions] GET_ASSET_COLUMNS_SUCCESS';
export const GET_ASSET_COLUMNS_ERROR = '[InterfaceOptions] GET_ASSET_COLUMNS_ERROR';
export const SET_ASSET_COLUMNS = '[InterfaceOptions] SET_ASSET_COLUMNS';
export const SET_ASSET_COLUMNS_SUCCESS = '[InterfaceOptions] SET_ASSET_COLUMNS_SUCCESS';
export const SET_ASSET_COLUMNS_ERROR = '[InterfaceOptions] SET_ASSET_COLUMNS_ERROR';
export const RESET_ASSET_COLUMNS = '[InterfaceOptions] RESET_ASSET_COLUMNS';
export const GET_INTERFACE_VIEWS = '[InterfaceOptions] GET_INTERFACE_VIEWS';
export const GET_INTERFACE_VIEWS_SUCCESS = '[InterfaceOptions]  GET_INTERFACE_VIEWS_SUCCESS';
export const GET_INTERFACE_VIEWS_ERROR = '[InterfaceOptions] GET_INTERFACE_VIEWS_ERROR';
export const DELETE_INTERFACE_VIEW = '[InterfaceOptions] DELETE_INTERFACE_VIEW';
export const DELETE_INTERFACE_VIEW_SUCCESS = '[InterfaceOptions] DELETE_INTERFACE_VIEW_SUCCESS';
export const DELETE_INTERFACE_VIEW_ERROR = '[InterfaceOptions] DELETE_INTERFACE_VIEW_ERROR';
export const SAVE_INTERFACE_VIEW = '[InterfaceOptions] SAVE_INTERFACE_VIEW';
export const SAVE_INTERFACE_VIEW_SUCCESS = '[InterfaceOptions] SAVE_INTERFACE_VIEW_SUCCESS';
export const SAVE_INTERFACE_VIEW_ERROR = '[InterfaceOptions] SAVE_INTERFACE_VIEW_ERROR';
export const SET_GLOBAL_VISIBILITY = '[InterfaceOptions] SET_GLOBAL_VISIBILITY';
export const SET_GLOBAL_VISIBILITY_SUCCESS = '[InterfaceOptions] SET_GLOBAL_VISIBILITY_SUCCESS';
export const SET_GLOBAL_VISIBILITY_ERROR = '[InterfaceOptions] SET_GLOBAL_VISIBILITY_ERROR';

export class GetAssetColumns implements Action {
	readonly type = GET_ASSET_COLUMNS;
	constructor(public category: UserPreferenceCategory) {}
}

export class GetAssetColumnsSuccess implements Action {
	readonly type = GET_ASSET_COLUMNS_SUCCESS;
	constructor(public assetColumns: AssetColumns) {}
}

export class GetAssetColumnsError implements Action {
	readonly type = GET_ASSET_COLUMNS_ERROR;
	constructor(public error: any) {}
}

export class SetAssetColumns implements Action {
	readonly type = SET_ASSET_COLUMNS;
	constructor(public assetColumns: AssetColumns, public trigger: string) {}
}

export class SetAssetColumnsSuccess implements Action {
	readonly type = SET_ASSET_COLUMNS_SUCCESS;
	constructor(public assetColumns: AssetColumns) {}
}

export class SetAssetColumnsError implements Action {
	readonly type = SET_ASSET_COLUMNS_ERROR;
	constructor(public error: any) {}
}

export class ResetAssetColumns implements Action {
	readonly type = RESET_ASSET_COLUMNS;
	constructor() {}
}

export class GetInterfaceViews implements Action {
	readonly type = GET_INTERFACE_VIEWS;
	constructor(public category: UserPreferenceCategory) {}
}

export class GetInterfaceViewsSuccess implements Action {
	readonly type = GET_INTERFACE_VIEWS_SUCCESS;
	constructor(public pmpViews: AssetColumns[]) {}
}

export class GetInterfaceViewsError implements Action {
	readonly type = GET_INTERFACE_VIEWS_ERROR;
	constructor(public error: any) {}
}

export class DeleteInterfaceView implements Action {
	readonly type = DELETE_INTERFACE_VIEW;
	constructor(public view: AssetColumns, public category: UserPreferenceCategory, public trigger: string) {}
}

export class DeleteInterfaceViewSuccess implements Action {
	readonly type = DELETE_INTERFACE_VIEW_SUCCESS;
	constructor() {}
}

export class DeleteInterfaceViewError implements Action {
	readonly type = DELETE_INTERFACE_VIEW_ERROR;
	constructor(public error: any) {}
}

export class SaveInterfaceView implements Action {
	readonly type = SAVE_INTERFACE_VIEW;
	constructor(public view: AssetColumns, public loadDefault: boolean, public category: UserPreferenceCategory) {}
}

export class SaveInterfaceViewSuccess implements Action {
	readonly type = SAVE_INTERFACE_VIEW_SUCCESS;
	constructor() {}
}

export class SaveInterfaceViewError implements Action {
	readonly type = SAVE_INTERFACE_VIEW_ERROR;
	constructor(public error: any) {}
}

export class SetGlobalVisibility implements Action {
	readonly type = SET_GLOBAL_VISIBILITY;
	constructor(public globalVisibility: PortfolioLevel) {}
}

export class SetGlobalVisibilitySuccess implements Action {
	readonly type = SET_GLOBAL_VISIBILITY_SUCCESS;
	constructor(public globalVisibility: PortfolioLevel) {}
}

export class SetGlobalVisibilityError implements Action {
	readonly type = SET_GLOBAL_VISIBILITY_ERROR;
	constructor(public error: any) {}
}

export type All = GetAssetColumns | GetAssetColumnsSuccess | GetAssetColumnsError |
                  SetAssetColumns | SetAssetColumnsSuccess | SetAssetColumnsError |
                  ResetAssetColumns |
                  GetInterfaceViews | GetInterfaceViewsSuccess | GetInterfaceViewsError |
                  DeleteInterfaceView | DeleteInterfaceViewSuccess | DeleteInterfaceViewError |
                  SaveInterfaceView | SaveInterfaceViewSuccess | SaveInterfaceViewError |
                  SetGlobalVisibility | SetGlobalVisibilitySuccess | SetGlobalVisibilityError;
