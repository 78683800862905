import { JsonObject, JsonProperty } from 'json2typescript';
import { EsgFactor } from './esg-factor.model';
import { EsgAnalysisReport } from './esg-analysis-report.model';

@JsonObject('EsgHoldingData')
export class EsgHoldingData {
  @JsonProperty('pillarFactors', [EsgFactor])
  pillarFactors: Array<EsgFactor> = undefined;

  @JsonProperty('co2ContributionFactor', EsgFactor)
  co2ContributionFactor: EsgFactor = undefined;

  @JsonProperty('co2ExposureFactor', EsgFactor)
  co2ExposureFactor: EsgFactor = undefined;

  @JsonProperty('esgAnalysisReport', EsgAnalysisReport)
  esgAnalysisReport: EsgAnalysisReport = undefined;
}
