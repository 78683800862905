export function mockApprovalsMetaData() {
    return {
        owners: ['E-JP', 'user_a', 'user_b'],
        monitorFlags: ['AA C Bond', 'Conc', 'Risk', 'AAEQ'],
        teams: ['Gregg Henderson - Regional', 'Mike Connor - Edinburgh', 'John Hair - London', 'Darren Ripton - London'],
        managers: ['E-JP', 'P-HJ', 'E-UH', 'K-JP', 'T-JP'],
        reasons: ['CGT', 'Cash Withdrawal', 'Closing', 'New Client'],
        requesters: ['user_a', 'user_b', 'user_c'],
        keStatuses: ['Pending', 'Paused', 'Approved', 'Rejected'],
        approvers: ['user_a', 'user_b', 'user_c']
      };
}
