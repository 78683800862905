import { Action } from '@ngrx/store';

import * as MonitorFlagActions from './monitor-flag.actions';
import { WidgetMonitorFlag } from '../../entity/widget-monitor-flag.model';
import { MonitorFlagState } from './monitor-flag.state';
import { WidgetMonitorFlagPosition } from '../../entity/widget-monitor-position.model';

export type MonitorFlagAction = MonitorFlagActions.All;
export const initialState: MonitorFlagState = { monitorFlags: new Array<WidgetMonitorFlag>(),
                                                positions: new Array<WidgetMonitorFlagPosition>()
                                              };

export function monitorFlagReducer(state: MonitorFlagState = initialState, action: MonitorFlagAction): MonitorFlagState {
  switch (action.type) {

    case MonitorFlagActions.GET_ALL_WIDGET_MONITOR_FLAGS_SUCCESS: {
      return {...state, ...action.monitorFlagState};
    }

    case MonitorFlagActions.GET_ALL_WIDGET_MONITOR_FLAGS_ERROR: {
      console.error('Error caught while loading Widget monitor flags', action.error);
      return {...initialState};
    }

    default :
        return state;
  }

}

