import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable ,  of,   } from 'rxjs';

import { ClientHomeService } from '../../service/client-home.service';
import * as ClientDetailsActions from './client-details.actions';
import { map, catchError, switchMap } from 'rxjs/operators';
import { NavActionService } from '../../../core/navigation-floating/nav-action.service';

export type ClientDetailsAction = ClientDetailsActions.All;

@Injectable()
export class ClientDetailsEffects {

	constructor(private actions$: Actions,
              private navActionService: NavActionService,
							private clientHomeService: ClientHomeService) {
	}

	getClientDetails: Observable<ClientDetailsAction> = createEffect(() => 
  this.actions$.pipe(
    ofType(ClientDetailsActions.GET_CLIENT_DETAILS),
    switchMap((client: ClientDetailsActions.GetClientDetails) =>  this.clientHomeService.getClientDetails(client.clientId).pipe(
      map(post => new ClientDetailsActions.GetClientDetailsSuccess(post)),
      catchError((error) => {
        const errorMessage = error.error ? error.error.message : 'An error occurred while retrieving client details';
        this.navActionService.addMessage('Error', errorMessage, 'error');
        return of(new ClientDetailsActions.GetClientDetailsError(error));
      })
    )
		)
  ));

}
