import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../../shared/json-convert/DateConvertor';
import { Moment } from 'moment';

@JsonObject('ClientPortfolioScenario')
export class ClientScenario {

  @JsonProperty('scenarioId', Number)
  id: number = undefined;

  @JsonProperty('accountId', String)
  accountId: string = undefined;

  @JsonProperty('clientId', String)
  clientId: string = undefined;

  @JsonProperty('scenarioName', String)
  scenarioName: string = undefined;

  @JsonProperty('scenarioType', String)
  scenarioType: string = undefined;

  @JsonProperty('scenarioStatus', String)
  scenarioStatus: string = undefined;

  @JsonProperty('lastModifiedDate', DateConverter)
  lastModifiedDate: Moment = undefined;

  @JsonProperty('lastModifiedBy', String)
  lastModifiedBy: string = undefined;

  @JsonProperty('age', String, true)
  age: string = undefined;

  @JsonProperty('isOutOfDate', Boolean, true)
  isOutOfDate: boolean = undefined;

}
