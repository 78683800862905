import { JsonObject, JsonProperty } from 'json2typescript';
import { TcfdPortfolioInfo } from './tcfd-account-details.model';




@JsonObject('TcfdAssetSpecificExposures')
export class TcfdAssetSpecificExposures {
  @JsonProperty('name', String)
  name: string = undefined;

  @JsonProperty('indicatorColour', String, true)
  indicatorColour: string = undefined;

  @JsonProperty('value', Number, true)
  value: number = undefined;

}

@JsonObject('TcfdAssetMetrics')
export class TcfdAssetMetrics {
  @JsonProperty('holdingName', String)
  holdingName: string = undefined;

  @JsonProperty('holdingLabel', String)
  holdingLabel: string = undefined;

  @JsonProperty('esgAssetType', String)
  esgAssetType: string = undefined;

  @JsonProperty('esgAssetTypeName', String, true)
  esgAssetTypeName: string = undefined;

  @JsonProperty('currency', String)
  currency: string = undefined;

  @JsonProperty('currencySymbol', String)
  currencySymbol: string = undefined;

  @JsonProperty('value', Number)
  value: number = undefined;

  @JsonProperty('weight', Number)
  weight: number = undefined;

  @JsonProperty('GHGemissionScope12', Number)
  GHGemissionScope12: number = undefined;

  @JsonProperty('GHGemissionScope3', Number)
  GHGemissionScope3: number = undefined;

  @JsonProperty('GHGemissionProduction', Number)
  GHGemissionProduction: number = undefined;

  @JsonProperty('carbonFootprintScope12', Number)
  carbonFootprintScope12: number = undefined;

  @JsonProperty('carbonFootprintScope3', Number)
  carbonFootprintScope3: number = undefined;

  @JsonProperty('carbonFootprintProduction', Number)
  carbonFootprintProduction: number = undefined;

  @JsonProperty('WACIscope12', Number)
  WACIscope12: number = undefined;

  @JsonProperty('WACIscope3', Number)
  WACIscope3: number = undefined;

  @JsonProperty('WACIproduction', Number)
  WACIproduction: number = undefined;

  @JsonProperty('coveragePI', Number)
  coveragePI: number = undefined;

  @JsonProperty('ITRwithDataLEQ', Number, true)
  ITRwithDataLEQ: number = undefined;

  @JsonProperty('ITRwithDataLEQP', Number, true)
  ITRwithDataLEQP: number = undefined;

  ITR: number = undefined;

  @JsonProperty('ITRwithDataSB', Number, true)
  ITRwithDataSB: number = undefined;

  @JsonProperty('CVAR_1_5Orderly', Number, true)
  CVAR_1_5Orderly: number = undefined;

  @JsonProperty('CVAR_2_0Orderly', Number, true)
  CVAR_2_0Orderly: number = undefined;

  @JsonProperty('CVAR_1_5Disorderly', Number, true)
  CVAR_1_5Disorderly: number = undefined;

  @JsonProperty('CVAR_2_0Disorderly', Number, true)
  CVAR_2_0Disorderly: number = undefined;

  @JsonProperty('CVAR_3_0NDC', Number, true)
  CVAR_3_0NDC: number = undefined;

  @JsonProperty('CVAR_3_0CurrentPolicies', Number, true)
  CVAR_3_0CurrentPolicies: number = undefined;

  @JsonProperty('specificExposureDetails', [TcfdAssetSpecificExposures], true)
  specificExposureDetails: TcfdAssetSpecificExposures[] = undefined;

  @JsonProperty('specificExposures', [String], true)
  specificExposures: string[] = undefined;

  @JsonProperty('messages', [String])
  messages: string[] = undefined;

  itrValue(): number {
    return (this.esgAssetTypeName === 'LEQ') ? this.ITRwithDataLEQ
          :(this.esgAssetTypeName === 'LEQP') ? this.ITRwithDataLEQP
          :(this.esgAssetTypeName === 'SB') ? this.ITRwithDataSB
          : this.ITR;
  }
}


@JsonObject('TcfdAssets')
export class TcfdAssets {

  @JsonProperty('portfolioInfo', TcfdPortfolioInfo)
  portfolioInfo: TcfdPortfolioInfo = undefined;

  @JsonProperty('assets', [TcfdAssetMetrics])
  assets: TcfdAssetMetrics[] = undefined;

  @JsonProperty('summary', TcfdAssetMetrics)
  summary: TcfdAssetMetrics = undefined;

}
