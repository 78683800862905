import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as SearchFormActions from './search-form.actions';
import { SearchService } from '../../search.service';

export type SearchFormAction = SearchFormActions.All;

@Injectable()
export class SearchFormEffects {

  constructor(
    private actions$: Actions,
    private searchService: SearchService) {
  }

  getSearchFormConfig: Observable<SearchFormAction> = createEffect(() =>
  this.actions$.pipe(
    ofType(SearchFormActions.GET_SEARCH_FORM_CONFIG),
    switchMap((action: SearchFormActions.GetSearchFormConfig) => this.searchService.getSearchFormConfig().pipe(
      map(post => new SearchFormActions.GetSearchFormConfigSuccess(post)),
      catchError((error) => of(new SearchFormActions.GetSearchFormConfigError()))
    ))));

  getPMPAccountSearchFormConfig: Observable<SearchFormAction> = createEffect(() =>
  this.actions$.pipe(
    ofType(SearchFormActions.GET_PMP_ACCOUNT_SEARCH_FORM_CONFIG),
    switchMap((action: SearchFormActions.GetPMPAccountSearchFormConfig) => this.searchService.getPMPAccountSearchFormConfig().pipe(
      map(post => new SearchFormActions.GetPMPAccountSearchFormConfigSuccess(post)),
      catchError((error) => of(new SearchFormActions.GetPMPAccountSearchFormConfigError()))
    ))));

    getAssetSearchFormConfig: Observable<SearchFormAction> = createEffect(() =>
  this.actions$.pipe(
    ofType(SearchFormActions.GET_ASSET_SEARCH_FORM_CONFIG),
    switchMap((action: SearchFormActions.GetAssetSearchFormConfig) => this.searchService.getAssetSearchFormConfig().pipe(
      map(post => new SearchFormActions.GetAssetSearchFormConfigSuccess(post)),
      catchError((error) => of(new SearchFormActions.GetAssetSearchFormConfigError()))
    ))));
}
