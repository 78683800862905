import { InMemoryDbService } from 'angular-in-memory-web-api';

import { mockIssues } from './data/widgetIssues';
import { mockAlerts } from './data/widgetAlerts';
import { mockBmidHoldingList } from './data/bmidHoldingList';
import { mockProfiles } from './data/widgetProfile';
import { mockMonitorFlags } from './data/widgetMonitorFlags';
import { mockModels } from './data/widgetModelPicker';
import { mockMonitorFlagValues } from './data/d3MonitorFlagValues';
import { mockAssetRiskContributions } from './data/assetRiskContributions';
import { mockAABoundaryDeviations } from './data/aaBoundaryDeviations';
import { mockClientDetails } from './data/clientHomeDetails';
import { mockClientAccountDetails } from './data/clientHomeAccountDetails';
import { mockSearchResults } from './data/searchResults';
import { mockApprovalsResults } from './data/approvalsResults';
import { mockReports } from './data/reports';
import { mockPortfolioConstructionModel } from './data/portfolioConstructionModel';
import { mockClientFileNotes } from './data/clientFileNotes';
import { mockClientScenarios } from './data/clientScenarios';
import { mockMonitorPortfolioTable } from './data/monitorPortfolioTable';
import { mockKnownExceptions } from './data/knownExceptions';
import { mockAccountCurrentPortfolio } from './data/accountCurrentPortfolio';
import { mockAccountCurrentPortfolioLookThrough } from './data/accountCurrentPortfolio_LookThrough';
import { mockSuccessfulLogin } from './data/successfulLogin';
import { mockApprovalsMetaData } from './data/approvalsMetaData';
import { mockSearchMetaData } from './data/searchMetaData';
import { mockMonitorCriterions } from './data/monitorCriterions';
import { mockSuccessfulScenarioStatusUpdate } from './data/successfulScenarioStatusUpdate';
import { mockKnownExceptionTypes } from './data/knownExceptionTypes';
import {mockMonitorFlagTypes} from './data/monitorFlagTypes';
import { mockNewClientConfig } from './data/newClientConfig';
import { mockNewPortfolioConfig } from './data/newPortfolioConfig';
import { mockClientPortfolios } from './data/clientPortfolios';
import { mockInterfaceColumns } from './data/interfaceColumns';
import { mockAssetAnalytics } from './data/assetAnalytics';
import { mockAssetAnalyticsColumnHeaders } from './data/mockAssetAnalyticsColumnHeaders';
import { mockAssetAnalyticsColumns } from './data/mockAssetAnalyticsColumns';
import { mockPortfolioPerformance } from './data/ppp.mock';

export class MockDataService implements InMemoryDbService {

  createDb() {
    const widgetIssues = mockIssues();
    const widgetAlerts = mockAlerts();
    const profiles = mockProfiles();
    const monitorFlag = mockMonitorFlags();
    const models = mockModels();
    const dashMetricValues = mockMonitorFlagValues();
    const clientDetails = mockClientDetails();
    const bmidHoldingList = mockBmidHoldingList();
    const aaBoundaryDeviations = mockAABoundaryDeviations();
  	const assetRiskContributions = mockAssetRiskContributions();
    const clientAccountDetails = mockClientAccountDetails();
    const searchResults = mockSearchResults();
    const approvalsResults = mockApprovalsResults();
    const clientFileNotes = mockClientFileNotes();
    const clientScenarios = mockClientScenarios();
    const reports = mockReports();
    const pcm = mockPortfolioConstructionModel();
    const monitorPortfolio = mockMonitorPortfolioTable();
    const knownExceptions = mockKnownExceptions();
    const accountCurrentPortfolio = mockAccountCurrentPortfolio();
    const accountCurrentPortfolioLookThrough = mockAccountCurrentPortfolioLookThrough();
    const successfulLogin = mockSuccessfulLogin();
    const approvalsMetaData = mockApprovalsMetaData();
    const searchMetaData = mockSearchMetaData();
    const monitorCriterions = mockMonitorCriterions();
    const successfulScenarioStatusUpdate = mockSuccessfulScenarioStatusUpdate();
    const knownExceptionTypes = mockKnownExceptionTypes();
    const monitorFlagTypes = mockMonitorFlagTypes();
    const newClientConfig = mockNewClientConfig();
    const newPortfolioConfig = mockNewPortfolioConfig();
    const clientPortfolios = mockClientPortfolios();
    const interfaceColumns = mockInterfaceColumns();
    const assetAnalytics = mockAssetAnalytics();
    const assetAnalyticsColumnHeaders = mockAssetAnalyticsColumnHeaders();
    const assetAnalyticsColumns = mockAssetAnalyticsColumns();
    const portfolioPerformance = mockPortfolioPerformance();

    return {
      widgetIssues,
      widgetAlerts,
      bmidHoldingList,
      profiles,
      monitorFlag,
      dashMetricValues,
      models,
      clientDetails,
      assetRiskContributions,
      aaBoundaryDeviations,
      clientAccountDetails,
      searchResults,
      approvalsResults,
      clientFileNotes,
      clientScenarios,
      reports,
      monitorPortfolio,
      knownExceptions,
      pcm,
      accountCurrentPortfolio,
      accountCurrentPortfolioLookThrough,
      successfulLogin,
      approvalsMetaData,
      searchMetaData,
      monitorCriterions,
      successfulScenarioStatusUpdate,
      knownExceptionTypes,
      monitorFlagTypes,
      newClientConfig,
      newPortfolioConfig,
      clientPortfolios,
      interfaceColumns,
      assetAnalytics,
      assetAnalyticsColumnHeaders,
      assetAnalyticsColumns,
      portfolioPerformance
    };
  }

}
