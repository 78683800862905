import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable , of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';

import { ClientPmpService } from '../../../shared/pmp/pmp.service';
import * as PcmActions from './portfolioConstructionModel.actions';
import * as ProfileActions from '../profile/profile.actions';
import * as MonitorFlagActions from '../monitor-flags/monitor-flag.actions';
import * as IssueActions from '../issues/issues.actions';
import * as AlertActions from '../alerts/alerts.actions';
import * as ModelActions from '../models/models.actions';
import * as AABoundaryDeviationActions from '../aaBoundaryDeviation/aaBoundaryDeviation.actions';
import * as AssetRiskContributionActions from '../assetRiskContribution/assetRiskContribution.actions';
import * as PortfolioConstructionModelActions from './portfolioConstructionModel.actions';
import * as TradeCashDetailsActions from '../trade-cash-details/trade-cash-details.actions';
import * as TradeRationaleActions from '../trade-rationale/trade-rationale.actions';
import { NavActionService } from '../../../core/navigation-floating/nav-action.service';
import { TreeNode } from 'primeng/api';
import { AppRouterService } from '../../router/app-router.service';
import { SlwiState } from '../portfolioConstructionModel/portfolioConstructionModel.state';
import { DataModelService } from '../../../clients/service/data-model.service';

export type PcmAction = PcmActions.All;

@Injectable()
export class PortfolioConstructionModelEffects {

	constructor(private actions$: Actions,
		private clientSlwiService: ClientPmpService,
		private store: Store<any>,
		private navActionService: NavActionService,
    private appRouterService: AppRouterService) {
	}

	getPortfolioConstructionModel: Observable<PcmAction> = createEffect(() => 
  this.actions$.pipe(
    ofType(PcmActions.GET_PORTFOLIO_CONSTRUCTION_MODEL),
		switchMap((a: PcmActions.GetPortfolioConstructionModel) =>
			this.clientSlwiService.getPortfolioConstructionModel(a.clientId, a.accountId, a.scenarioId).pipe(
				map(pcm => new PcmActions.GetPortfolioConstructionModelSuccess(pcm, false, undefined)),
        catchError((error) => of(new PcmActions.GetPortfolioConstructionModelError(error)))
      ))));

	getPortfolioConstructionPage: Observable<PcmAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(PcmActions.GET_PORTFOLIO_CONSTRUCTION_PAGE),
      switchMap((a: PcmActions.GetPortfolioConstructionPage) =>
			this.clientSlwiService.getPortfolioConstructionPage(a.clientId, a.accountId, a.scenarioId, a.pdfRequest).pipe(
				map(slwiState => {
					this.disassembleData(slwiState);
					return new PcmActions.GetPortfolioConstructionPageSuccess();
				}),
        catchError((error) => of(new PcmActions.GetPortfolioConstructionPageError(error)))
      ))));

  getNewPortfolioConstructionModel: Observable<PcmAction> = createEffect(() => 
  this.actions$
  .pipe(
    ofType(PcmActions.GET_NEW_PORTFOLIO_CONSTRUCTION_MODEL),
		switchMap((a: PcmActions.GetNewPortfolioConstructionModel) =>
			this.clientSlwiService.getNewPortfolioConstructionModel(a.clientId, a.accountId).pipe(
				map(pcm => new PcmActions.GetNewPortfolioConstructionModelSuccess(pcm)),
        catchError((error) => of(new PcmActions.GetNewPortfolioConstructionModelError(error)))
      ))));

	getPortfolioConstructionModelAdjustment: Observable<PcmAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(PcmActions.GET_PORTFOLIO_CONSTRUCTION_MODEL_ADJUSTMENT),
			switchMap((a: PcmActions.GetPortfolioConstructionModelAdjustment) =>
				this.clientSlwiService.getPortfolioConstructionModelAdjustment(a.clientId, a.accountId, a.pcm).pipe(
					map(pcm => new PcmActions.GetPortfolioConstructionModelAdjustmentSuccess(pcm)),
          catchError((error) => of(new PcmActions.GetPortfolioConstructionModelAdjustmentError(error)))
        ))));

	getImportedTargetWeight: Observable<PcmAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(PcmActions.GET_IMPORTED_TARGET_WEIGHT),
			switchMap((a: PcmActions.GetImportedTargetWeight) =>
				this.clientSlwiService.importAssetForTargetModel(a.importedAssets).pipe(
					map(slwiState => {
						this.restorePcmUiState(slwiState, a.pcm);
						this.disassembleData(slwiState);
						return new PcmActions.GetImportedTargetWeightSuccess();
					}),
          catchError((error) => of(new PcmActions.GetImportedTargetWeightError(error)))
        ))));

	getPortfolioConstructionModelLookThrough: Observable<PcmAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(PcmActions.GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH),
			switchMap((a: PcmActions.GetPortfolioConstructionModelLookThrough) =>
				this.clientSlwiService.getPortfolioConstructionModelLookThrough(a.pcm).pipe(
					map(slwiState => {
            this.restorePcmUiState(slwiState, a.pcm);
						this.disassembleData(slwiState);
						return new PcmActions.GetPortfolioConstructionModelLookThroughSuccess();
					}),
          catchError((error) => of(new PcmActions.GetPortfolioConstructionModelLookThroughError(error)))
        ))));

	getPortfolioConstructionModelLookThroughRevert: Observable<PcmAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(PcmActions.GET_PORTFOLIO_CONSTRUCTION_MODEL_LOOKTHROUGH_REVERT),
			switchMap((a: PcmActions.GetPortfolioConstructionModelLookThroughRevert) =>
				this.clientSlwiService.getPortfolioConstructionModelLookThroughRevert(a.pcm).pipe(
					map(slwiState => {
            this.restorePcmUiState(slwiState, a.pcm);
            this.disassembleData(slwiState);
						return new PcmActions.GetPortfolioConstructionModelLookThroughRevertSuccess();
					}),
          catchError((error) => of(new PcmActions.GetPortfolioConstructionModelLookThroughRevertError(error)))
        ))));

  resetPortfolioAdjustments: Observable<PcmAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(PcmActions.RESET_PORTFOLIO_ADJUSTMENTS),
			switchMap((a: PcmActions.ResetPortfolioAdjustments) =>
				this.clientSlwiService.resetPortfolioAdjustments(a.pcm).pipe(
					map(slwiState => {
            this.restorePcmUiState(slwiState, a.pcm);
						this.disassembleData(slwiState);
						return new PcmActions.ResetPortfolioAdjustmentsSuccess();
					}),
					catchError((error) => {
						this.dispatchAllErrorActions(error);
						return of(new PcmActions.ResetPortfolioAdjustmentsError(error));
					}
				)))));

	recalculateAll: Observable<PcmAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(PcmActions.RECALCULATE_ALL),
			switchMap((a: PcmActions.RecalculateAll) =>
				this.clientSlwiService.recalculateAll(a.pcm, a.overrideCurrentEditProperty).pipe(
					map(slwiState => {
            this.restorePcmUiState(slwiState, a.pcm);
            this.disassembleData(slwiState);
						return new PcmActions.RecalculateAllSuccess(a.newEditProperty);
					}),
					catchError((error) => {
						this.dispatchAllErrorActions(error);
						return of(new PcmActions.RecalculateAllError(error));
					}
        )))));

  updateAccountHoldings: Observable<PcmAction> = createEffect(() =>
  this.actions$.pipe(
    ofType(PcmActions.UPDATE_ACCOUNT_HOLDINGS),
    switchMap((a: PcmActions.UpdateAccountHoldings) =>
      this.clientSlwiService.updateAccountHoldings(a.pcm).pipe(
        map(slwiState => {
          this.restorePcmUiState(slwiState, a.pcm);
          this.disassembleData(slwiState);
          return new PcmActions.UpdateAccountHoldingsSuccess();
        }),
        catchError((error) => {
          this.navActionService.addMessage('Error', 'An error occurred while updating holdings', 'error');
          return of(new PcmActions.UpdateAccountHoldingsError(error));
        }
      )))));

	getNewPortfolioConstructionPageData: Observable<PcmAction> = createEffect(() =>
	this.actions$.pipe(
    ofType(PcmActions.GET_NEW_PORTFOLIO_CONSTRUCTION_PAGE),
		switchMap((a: PcmActions.GetNewPortfolioConstructionPage) =>
			this.clientSlwiService.getNewPortfolioConstructionPageData(a.clientId, a.accountId, a.pdfRequest).pipe(
				map(slwiState => {
					this.disassembleData(slwiState);
					return new PcmActions.GetNewPortfolioConstructionPageSuccess();
				}),
				catchError((error) => {
					this.dispatchAllErrorActions(error);
					return of(new PcmActions.GetNewPortfolioConstructionPageError(error));
				}
      )
    ))));

	savePortfolioConstructionModel: Observable<PcmAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(PcmActions.UPDATE_PORTFOLIO_CONSTRUCTION_MODEL),
			switchMap((a: PcmActions.UpdatePortfolioConstructionModel) =>
				this.clientSlwiService.savePortfolioConstructionModel(a.pcm).pipe(
					map(slwiState => {
						this.navActionService.addMessage('Success', 'Your request was saved successfully', 'success');

            this.restorePcmUiState(slwiState, a.pcm);
            this.disassembleData(slwiState);

						return new PcmActions.UpdatePortfolioConstructionModelSuccess(); }),
					catchError((error) => {
						this.navActionService.addMessage('Error', 'An error occurred while saving', 'error');
						return of(new PcmActions.UpdatePortfolioConstructionModelError(error));
					}
					)))));

  saveUpdatePortfolioConstructionModel: Observable<PcmAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(PcmActions.SAVE_UPDATE_PORTFOLIO_CONSTRUCTION_MODEL),
      switchMap((a: PcmActions.SaveUpdatePortfolioConstructionModel) =>
        this.clientSlwiService.saveUpdatePortfolioConstructionModel(a.pcm).pipe(
          map(pcm => {
            this.navActionService.addMessage('Success', 'Your request was saved successfully', 'success');
            return new PcmActions.SaveUpdatePortfolioConstructionModelSuccess(a.pcm); }),
          catchError((error) => {
            console.log(error);
            const errorMessage = error.error;
            const errorMsgJson = JSON.parse(error.error);
            console.log(errorMsgJson.message);
            const errorMsgJsonMsg = errorMsgJson.message;
            this.navActionService.addMessage('Error', errorMsgJsonMsg, 'error');
            return of(new PcmActions.SaveUpdatePortfolioConstructionModelError(error));
          }
          )))));

	createPortfolioConstructionModel: Observable<PcmAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(PcmActions.CREATE_PORTFOLIO_CONSTRUCTION_MODEL),
			switchMap((a: PcmActions.CreatePortfolioConstructionModel) =>
				this.clientSlwiService.createPortfolioConstructionModel(a.pcm).pipe(
					map(scenarioId => {
            if (a.pcm.clientStatus === 'Template') {
              this.appRouterService.modelPmpWithScenarioPage(a.pcm.clientId, a.pcm.accountId, +scenarioId);
            } else {
              this.appRouterService.clientPmpWithScenarioPage(a.pcm.clientId, a.pcm.accountId, +scenarioId);
            }
						this.navActionService.addMessage('Success', 'Your request was saved successfully', 'success');
						return new PcmActions.CreatePortfolioConstructionModelSuccess(scenarioId); }),
					catchError((error) => {
						this.navActionService.addMessage('Error', 'An error occurred while copying', 'error');
						return of(new PcmActions.CreatePortfolioConstructionModelError(error));
					}
          )))));

  updateCurrentHoldings: Observable<PcmAction> = createEffect(() =>
  this.actions$.pipe(
    ofType(PcmActions.UPDATE_CURRENT_HOLDINGS),
    switchMap((a: PcmActions.UpdatePortfolioConstructionModel) =>
      this.clientSlwiService.updateCurrentHoldings(a.pcm).pipe(
        map(pcm => {
          this.navActionService.addMessage('Success', 'Your request was saved successfully', 'success');
          return new PcmActions.UpdateCurrentHoldingsSuccess(a.pcm); }),
        catchError((error) => {
          this.navActionService.addMessage('Error', 'An error occurred while saving', 'error');
          return of(new PcmActions.UpdateCurrentHoldingsError(error));
        }
        )))));

  updateCurrentHoldingsAndOpenNewScenario: Observable<PcmAction> = createEffect(() =>
  this.actions$.pipe(
    ofType(PcmActions.UPDATE_CURRENT_HOLDINGS_AND_OPEN_SCENARIO),
    switchMap((a: PcmActions.UpdateCurrentHoldingsAndOpenScenario) =>
      this.clientSlwiService.updateCurrentHoldingsOpenNew(a.pcm).pipe(
        map(result => {
          this.appRouterService.clientPmpWithScenarioPage(a.pcm.clientId, a.pcm.accountId, result.sid);
          this.navActionService.addMessage('Success', 'Your request was saved successfully', 'success');
          return new PcmActions.UpdateCurrentHoldingsAndOpenScenarioSuccess(a.pcm); }),
        catchError((error) => {
          this.navActionService.addMessage('Error', 'An error occurred while saving', 'error');
          return of(new PcmActions.UpdateCurrentHoldingsAndOpenScenarioError(error));
        }
        )))));

	changeStartingPortfolio: Observable<PcmAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(PcmActions.CHANGE_STARTING_PORTFOLIO),
			switchMap((a: PcmActions.ChangeStartingPortfolio) =>
				this.clientSlwiService.changeStartingPortfolio(a.startingPortfolioType).pipe(
					map(slwiState => {
            this.restorePcmUiState(slwiState, a.currentPcm);
						this.disassembleData(slwiState);
						return new PcmActions.ChangeStartingPortfolioSuccess();
					}),
          catchError((error) => of(new PcmActions.ChangeStartingPortfolioError(error)))
        ))));

	changeTargetModel: Observable<PcmAction> = createEffect(() =>
	this.actions$.pipe(
    ofType(PcmActions.CHANGE_TARGET_MODEL),
		switchMap((a: PcmActions.ChangeTargetModel) =>
			this.clientSlwiService.changeTargetModel(a.modelId, a.pcm).pipe(
				map(slwiState => {
					this.restorePcmUiState(slwiState, a.pcm);
					this.disassembleData(slwiState);
					return new PcmActions.ChangeTargetModelSuccess();
				}),
        catchError((error) => of(new PcmActions.ChangeTargetModelError(error)))
      ))));

	changeTargetModelFromAccount: Observable<PcmAction> = createEffect(() =>
	this.actions$.pipe(
    ofType(PcmActions.CHANGE_TARGET_MODEL_FROM_ACCOUNT),
		switchMap((a: PcmActions.ChangeTargetModelFromAccount) =>
			this.clientSlwiService.changeTargetModelFromAccount(a.accountId).pipe(
				map(slwiState => {
					this.disassembleData(slwiState);
					return new PcmActions.ChangeTargetModelFromAccountSuccess();
				}),
        catchError((error) => of(new PcmActions.ChangeTargetModelFromAccountError(error)))
	  ))));

	  changeTargetModelFromModel: Observable<PcmAction> = createEffect(() =>
	  this.actions$.pipe(
      ofType(PcmActions.CHANGE_TARGET_MODEL_FROM_MODEL),
		  switchMap((a: PcmActions.ChangeTargetModelFromModel) =>
			  this.clientSlwiService.changeTargetModelFromModel(a.id).pipe(
				  map(slwiState => {
					  this.disassembleData(slwiState);
					  return new PcmActions.ChangeTargetModelFromModelSuccess();
				  }),
		  catchError((error) => of(new PcmActions.ChangeTargetModelFromModelError(error)))
		))));

      adjustBoundaries: Observable<PcmAction> = createEffect(() =>
        this.actions$.pipe(
          ofType(PcmActions.ADJUST_BOUNDARIES),
          switchMap((a: PcmActions.AdjustBoundaries) =>
            this.clientSlwiService.adjustBoundaries(a.pcm).pipe(
              map(slwiState => {
                // TODO: Reintroduce these when end point correctly returns PCM.
                // this.restorePcmUiState(slwiState, a.pcm);
                // this.disassembleData(slwiState);
                return new PcmActions.AdjustBoundariesSuccess(a.pcm);
              }),
              catchError((error) => {
                this.dispatchAllErrorActions(error);
                return of(new PcmActions.RecalculateAllError(error));
              }
            )))));

  getOptimiserConstraintsConfiguration: Observable<PcmAction> = createEffect(() =>
  this.actions$.pipe(
    ofType(PcmActions.GET_OPTIMISER_CONSTRAINTS),
    switchMap((a: PcmActions.GetOptimiserConstraints) =>
      this.clientSlwiService.getOptimiserConstraintsConfiguration(a.pcm).pipe(
        map(configuration => new PcmActions.GetOptimiserConstraintsSuccess(configuration)),
        catchError((error) => of(new PcmActions.GetOptimiserConstraintsError(error)))
      ))));

  optimisePortfolio: Observable<PcmAction> = createEffect(() =>
  this.actions$.pipe(
    ofType(PcmActions.OPTIMISE_PORTFOLIO),
    switchMap((a: PcmActions.OptimisePortfolio) =>
      this.clientSlwiService.optimisePortfolio(a.constraintConfiguration).pipe(
        map(slwiState => {
		  this.navActionService.addMessage('Success', 'Your portfolio was optimised successfully', 'success');
		  this.restorePcmUiState(slwiState, a.pcm);
		  this.disassembleData(slwiState);
          return new PcmActions.OptimisePortfolioSuccess(); }),
        catchError((error) => {
          return of(new PcmActions.OptimisePortfolioError(error));
        }
        )))));


  createModel: Observable<PcmAction> = createEffect(() =>
  this.actions$.pipe(
    ofType(PcmActions.CREATE_MODEL),
    switchMap((a: PcmActions.CreateModel) =>
      this.clientSlwiService.createModel(a.clientId, a.accountId, a.pcm).pipe(
        map(scenarioId => {
          // this.appRouterService.clientPmpWithScenarioPage(a.pcm.clientId, a.pcm.accountId, +scenarioId);
          this.navActionService.addMessage('Success', 'SPM model/ scenario has been created', 'success');

          return new PcmActions.CreateModelSuccess(); }),
        catchError((error) => {
          this.navActionService.addMessage('Error', 'An error occurred while saving', 'error');
          return of(new PcmActions.CreateModelError(error));
        }
        )))));

  applyChosenRiskModel: Observable<PcmAction> = createEffect(() =>
  this.actions$.pipe(
    ofType(PcmActions.APPLY_CHOSEN_RISK_MODEL),
    switchMap((a: PcmActions.ApplyChosenRiskModel) =>
      this.clientSlwiService.applyChosenRiskModel(a.pcm, a.chosenRiskModel).pipe(
        map(slwiState => {
      this.navActionService.addMessage('Success', 'Successfully Applied Chosen Risk Model', 'success');
      this.restorePcmUiState(slwiState, a.pcm);
      this.disassembleData(slwiState);
          return new PcmActions.ApplyChosenRiskModelSuccess(); }),
        catchError((error) => {
          return of(new PcmActions.ApplyChosenRiskModelError(error));
        }
        )))));
  
  applyChosenAlphaModel: Observable<PcmAction> = createEffect(() =>
  this.actions$.pipe(
    ofType(PcmActions.APPLY_CHOSEN_ALPHA_MODEL),
    switchMap((a: PcmActions.ApplyChosenAlphaModel) =>
      this.clientSlwiService.applyChosenAlphaModel(a.pcm, a.chosenAlphaModel).pipe(
        map(slwiState => {
      this.navActionService.addMessage('Success', 'Successfully Applied Chosen Alpha Model', 'success');
      this.restorePcmUiState(slwiState, a.pcm);
      this.disassembleData(slwiState);
          return new PcmActions.ApplyChosenAlphaModelSuccess(); }),
        catchError((error) => {
          return of(new PcmActions.ApplyChosenAlphaModelError(error));
        }
        )))));

  private restorePcmUiState(slwiState, oldPcm) {
    this.nodeIterator(slwiState.portfolioConstructionModel, oldPcm);
  }

  private nodeIterator(node: TreeNode, compareNode: TreeNode) {
    node.children.forEach((childNode, index) => {
      if (compareNode.children) {
        const matchingNode = compareNode.children[index];

        if (matchingNode) {
          childNode.expanded = matchingNode.expanded;

          if (childNode.children && childNode.children.length) {
            this.nodeIterator(childNode, matchingNode);
          }
        }
      }
    });
  }

	private disassembleData(slwiState: SlwiState) {
		this.store.dispatch(new ProfileActions.GetWidgetProfileSuccess(slwiState.clientProfile, slwiState.clientModelDetails));
		this.store.dispatch(new MonitorFlagActions.GetAllWidgetMonitorFlagsSuccess(slwiState.monitorFlags));
		this.store.dispatch(new IssueActions.GetWidgetIssuesSuccess(slwiState.issues));
		this.store.dispatch(new AlertActions.GetWidgetAlertsSuccess(slwiState.alerts));
		this.store.dispatch(new ModelActions.GetWidgetModelsSuccess(slwiState.models));
		this.store.dispatch(new AABoundaryDeviationActions.GetTacLevelAABoundaryDeviationSuccess(slwiState.aaBoundaryDeviations));
		this.store.dispatch(new AssetRiskContributionActions.GetAssetLevelAssetRiskContributionSuccess(slwiState.assetRiskContribution));
    this.store.dispatch(new PortfolioConstructionModelActions.GetPortfolioConstructionModelSuccess(
		slwiState.portfolioConstructionModel, slwiState.isPortfolioReadOnly, slwiState.portfolioMessages));
    this.store.dispatch(new TradeCashDetailsActions.GetTradeCashDetailsSuccess(slwiState.tradeCashDetails));
    this.store.dispatch(new TradeRationaleActions.GetTradeRationaleSuccess(slwiState.tradeRationale));
	}

	private dispatchAllErrorActions(error) {

		let errorMessage = 'An error occurred';
		if (error.error && error.error.message) {
			errorMessage = error.error.message;
    }

		this.navActionService.addMessage('Error', errorMessage, 'error');
		this.store.dispatch(new ProfileActions.GetWidgetProfileError(error));
		this.store.dispatch(new MonitorFlagActions.GetAllWidgetMonitorFlagsError(error));
		this.store.dispatch(new IssueActions.GetWidgetIssuesError(error));
		this.store.dispatch(new AlertActions.GetWidgetAlertsError(error));
		this.store.dispatch(new ModelActions.GetWidgetModelsError(error));
		this.store.dispatch(new AABoundaryDeviationActions.GetTacLevelAABoundaryDeviationError(error));
		this.store.dispatch(new AssetRiskContributionActions.GetAssetLevelAssetRiskContributionError(error));
		this.store.dispatch(new PortfolioConstructionModelActions.GetPortfolioConstructionModelError(error));
	}
}
