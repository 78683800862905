export function mockKnownExceptions() {
  return {

    pendingKnownExceptions : [
    {
      monitorFlagName: 'Risk',
      id: 'KE031VV',
      accountId: 'BMID001HH',
      requestDate: '2017-11-10 18:20:30.590',
      expiryDate: '2019-03-13 00:00:00',
      decisionDate: '2017-11-13 17:27:30',
      approver: 'user_c',
      requester: 'PersonA',
      reason: 'The account at goal level is now fully invested in line with the relevant mode',
      decisionNote: 'Cash to be paid away',
      status: 'Pending',
    },
    {
      monitorFlagName: 'Conentration',
      id: 'KE031VW',
      accountId: 'BMID001HH',
      requestDate: '2017-11-24 17:22:30.590',
      expiryDate: '2018-01-24 00:00:00',
      decisionDate: '2017-11-10 17:27:30',
      approver: 'user_d',
      requester: 'Personb',
      reason: 'Non-Discretionary or Custody account or holding',
      decisionNote: 'CGT restrictions',
      status: 'Pending',
    }],

    pendingReviewKnownExceptions: [
      {
        monitorFlagName: 'Effective Sector',
        id: 'KE033AA',
        accountId: 'BMID001HH',
        requestDate: '2017-08-27 17:25:30.590',
        expiryDate: '2018-02-13 00:00:00',
        decisionDate: '2017-10-13 17:27:30',
        approver: 'user_e',
        requester: 'PersonA',
        reason: 'Other',
        decisionNote: 'Terminating Portfolio',
        status: 'Pending',
      },
      {
        monitorFlagName: 'Buy-List',
        id: 'KE241VW',
        accountId: 'BMID001HH',
        requestDate: '2017-11-09 13:25:30.590',
        expiryDate: '2018-07-02 00:00:00',
        decisionDate: '2017-11-10 17:27:30',
        approver: 'user_t',
        requester: 'Personb',
        reason: 'Other',
        decisionNote: 'PSG-PM comm on...he 2-3 months.',
        status: 'Pending',
      }],

    pausedKnownExceptions: [
      {
        monitorFlagName: 'Max Holding Weight',
        id: 'KE033AT',
        accountId: 'BMID001HH',
        requestDate: '2017-11-03 19:20:30.590',
        expiryDate: '2018-06-04 00:00:00',
        decisionDate: '2017-11-13 17:27:30',
        approver: 'user_h',
        requester: 'PersonA',
        reason: 'Cheque or new monies received from client yet to be fully invested',
        decisionNote: 'PSG-PM comm on...he 2-3 months.',
        status: 'Pending',
      },
      {
        id: 2323,
        monitorFlagName: 'Effective AA - Cash',
        keId: 'KE221VT',
        accountId: 'BMID001HH',
        requestDate: '2017-10-05 12:44:30.590',
        expiryDate: '2018-03-05 00:00:00',
        decisionDate: '2017-11-13 14:12:30',
        approver: 'user_w',
        requester: 'Personb',
        reason: 'Non-Discretionary or Custody account or holding',
        decisionNote: 'PSG-PM comm on...he 2-3 months.',
        status: 'Pending',
      }],

    currentKnownExceptions: [
      {
        monitorFlagName: 'Effective AA - Equity',
        id: 'KE044RR',
        accountId: 'BMID001HH',
        requestDate: '2017-11-04 15:45:30.590',
        expiryDate: '2018-03-04 00:00:00',
        decisionDate: '2017-11-13 23:27:30',
        approver: 'user_r',
        requester: 'PersonA',
        reason: 'The account at goal level is now fully invested in line with the relevant mode',
        decisionNote: 'PSG-PM comm on...he 2-3 months.',
        status: 'Pending',
      },
      {
        monitorFlagName: 'Effective AA - Bond',
        id: 'KE222EE',
        accountId: 'BMID001HH',
        requestDate: '2017-11-03 12:27:30.590',
        expiryDate: '2018-03-13 00:00:00',
        decisionDate: '2017-11-13 14:27:30',
        approver: 'user_y',
        requester: 'Personb',
        reason: 'Terminating Portfolio',
        decisionNote: 'PSG-PM comm on...he 2-3 months.',
        status: 'Pending',
      }],

    rejectedKnownExceptions: [
      {
        monitorFlagName: 'Effective AA - Volatility',
        id: 'KE067UY',
        accountId: 'BMID001HH',
        requestDate: '2017-10-26 15:27:30.590',
        expiryDate: '2018-03-13 00:00:00',
        decisionDate: '2017-11-13 17:11:30',
        approver: 'user_q',
        requester: 'PersonA',
        reason: 'Migrated KE',
        decisionNote: 'PSG-PM comm on...he 2-3 months.',
        status: 'Pending',
      },
      {
        monitorFlagName: 'Effective Holding Commonality',
        id: 'KE223TR',
        accountId: 'BMID001HH',
        requestDate: '2017-10-20 21:34:30.590',
        expiryDate: '2018-03-28 00:00:00',
        decisionDate: '2017-11-13 22:43:30',
        approver: 'user_w',
        requester: 'Personb',
        reason: 'Migrated KE',
        decisionNote: 'PSG-PM comm on...he 2-3 months.',
        status: 'Pending',
      }],

    reversedKnownExceptions: [
      {
        monitorFlagName: 'Concentration',
        id: 'KE078NN',
        accountId: 'BMID001HH',
        requestDate: '2017-11-12 14:27:30.590',
        expiryDate: '2018-03-29 00:00:00',
        decisionDate: '2017-11-13 17:27:30',
        approver: 'user_u',
        requester: 'PersonA',
        reason: 'Terminating Portfolio',
        decisionNote: 'PSG-PM comm on...he 2-3 months.',
        status: 'Pending',
      },
      {
        monitorFlagName: 'Buy-List',
        id: 'KE212DF',
        accountId: 'BMID001HH',
        requestDate: '2017-11-15 22:27:30.590',
        expiryDate: '2018-03-10 00:00:00',
        decisionDate: '2017-11-13 22:27:30',
        approver: 'user_m',
        requester: 'Personb',
        reason: 'Migrated KE',
        decisionNote: 'PSG-PM comm on...he 2-3 months.',
        status: 'Pending',
      }],


    historyKnownExceptions: [
      {
        monitorFlagName: 'Effective Holding Commonality',
        id: 'KE098DD',
        accountId: 'BMID001HH',
        requestDate: '2017-11-06 12:27:30.590',
        expiryDate: '2018-03-07 00:00:00',
        decisionDate: '2017-11-13 12:27:30',
        approver: 'user_d',
        requester: 'PersonL',
        reason: 'Terminating Portfolio',
        decisionNote: 'PSG-PM comm on...he 2-3 months.',
        status: 'Pending',
      },
      {
        monitorFlagName: 'Risk',
        id: 'KE200LK',
        accountId: 'BMID001HH',
        requestDate: '2017-11-04 21:27:30.590',
        expiryDate: '2018-03-01 00:00:00',
        decisionDate: '2017-11-13 21:27:30',
        approver: 'user_s',
        requester: 'Personbd',
        reason: 'New account ongoing PM review',
        decisionNote: 'PSG-PM comm on...he 2-3 months.',
        status: 'Pending',
      },
      {
        monitorFlagName: 'Buy-list',
        id: 'KE098DD',
        accountId: 'BMID001HH',
        requestDate: '2017-11-14 21:27:30.590',
        expiryDate: '2018-03-12 00:00:00',
        decisionDate: '2017-11-13 21:27:30',
        approver: 'user_p',
        requester: 'PersonAB',
        reason: 'Migrated KE',
        decisionNote: 'PSG-PM comm on...he 2-3 months.',
        status: 'Pending',
      },
      {
        monitorFlagName: 'Max Holding Weight',
        id: 'KE200LK',
        accountId: 'BMID001HH',
        requestDate: '2017-11-01 17:27:30.590',
        expiryDate: '2018-03-01 00:00:00',
        decisionDate: '2017-11-13 17:27:30',
        approver: 'user_h',
        requester: 'Personbc',
        reason: 'New account ongoing PM review',
        decisionNote: 'PSG-PM comm on...he 2-3 months.',
        status: 'Pending',
      }],
  };
}
