import { Action } from '@ngrx/store';
import { AnalyticObj } from '../../analytics/analytics.service';
import { PortfolioConstructionModel } from '../../entity/portfolio-construction-model.model';

export const GET_ANALYTICS_FACT = '[Analytics] GET_ANALYTICS_FACT';
export const GET_ANALYTICS_FACT_SUCCESS = '[Analytics]  GET_ANALYTICS_FACT_SUCCESS';
export const GET_ANALYTICS_FACT_ERROR = '[Analytics] GET_ANALYTICS_FACT_ERROR';

export class GetAnalyticsFact implements Action {
	readonly type = GET_ANALYTICS_FACT;
	constructor(public pcm: PortfolioConstructionModel, public analyticsType: string) {}
}

export class GetAnalyticsFactSuccess implements Action {
	readonly type = GET_ANALYTICS_FACT_SUCCESS;
	constructor(public fact: AnalyticObj) {}
}

export class GetAnalyticsFactError implements Action {
	readonly type = GET_ANALYTICS_FACT_ERROR;
	constructor(public error: any) {}
}

export type All = GetAnalyticsFact | GetAnalyticsFactSuccess | GetAnalyticsFactError;
