import {JsonObject, JsonProperty} from 'json2typescript';
import { MapConverter } from '../../shared/json-convert/MapConverter';

@JsonObject('AuthenticationConfig')
export class AuthenticationConfig {

  @JsonProperty('authenticationServices', MapConverter)
  private authenticationServices: Map<string, string> = undefined;

  private localAuthenticationMode: boolean;

  // multiAuthenticationServices: boolean = false;

  constructor(authenticationServices?: Map<string, string>) {
    this.authenticationServices = authenticationServices;
  }

  getAuthenticationServices() : Map<string, string> {
    return this.authenticationServices;
  }

  isLocalAuthenticationMode(): boolean {
    if (this.localAuthenticationMode === undefined) {
      this.localAuthenticationMode = this.authenticationServices === undefined || this.authenticationServices.size === 0;
    }
    return this.localAuthenticationMode;
  }
}