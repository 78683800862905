

import * as RiskModelListActions from './risk-model-selection.actions';

export type DataModelListAction = RiskModelListActions.All;

import { DataModelResultListState } from './data-model-selection.state';
import { DataModelService } from '../../../clients/service/data-model.service';

export const initialRiskState: DataModelResultListState  = { dataModels: new Array(), loading: false, selected: null, loaded: false, requireReload: false};

export function riskModelListReducer(state: DataModelResultListState = initialRiskState, action: DataModelListAction):
DataModelResultListState {
    switch (action.type) {

      case RiskModelListActions.GET_ALL_RISK_MODELS: {
        return {...state, ...{loading: true}};

      }

      case RiskModelListActions.GET_ALL_RISK_MODELS_SUCCESS: {
        return {...state, ...{dataModels: action.result.dataModels}, ...{loading: false}, ...{selected: action.result.selected}, ...{loaded: true}};
      }

      case RiskModelListActions.GET_ALL_RISK_MODELS_ERROR: {
        console.error('Error caught while loading risk models list', action.error);
        return {...state};
      }

      case RiskModelListActions.UPDATE_CURRENT_RISK_SELECTIONS: {
        return { ...state, ...{selected: action.newRiskSelection}}
      }

      case RiskModelListActions.SORT_RISK_SELECTIONS: {
        const dataModels = state.dataModels;
        DataModelService.sortDataModelsBySelected(dataModels, state.selected);
        return { ...state, ...{dataModels: dataModels}}
      }

      case RiskModelListActions.PRECALL_RESET: {
        return { ...state, ...{requireReload: true}};
      }

      default :
        return state;
    }
}
