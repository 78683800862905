import * as TradeRationaleActions from './trade-rationale.actions';
import { TradeRationaleState } from './trade-rationale.state';

export type TradeRationaleAction = TradeRationaleActions.All;
export const initialState: TradeRationaleState = { tradeRationale: undefined, loading: false};

// tslint:disable-next-line:max-line-length
export function tradeRationaleReducer(state: TradeRationaleState = initialState, action: TradeRationaleAction): TradeRationaleState {
  switch (action.type) {

    case TradeRationaleActions.GET_TRADE_RATIONALE: {
      return {...state, ...{loading: true}};

    }

    case TradeRationaleActions.GET_TRADE_RATIONALE_SUCCESS: {
      return {...state, ...{tradeRationale: action.tradeRationale}, ...{loading: false}};
    }

    case TradeRationaleActions.GET_TRADE_RATIONALE_ERROR: {
      console.error('Error caught while loading Trade Rationale', action.error);
      return {...initialState};
    }

    default:
      return state;
  }

}
