import { Component } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})

export class NavigationComponent {

  navItems = [
    {
      'url': 'dash',
      'title': 'Dash'
    },
    {
      'url': 'clients',
      'title': 'Clients'
    },
    {
      'url': 'monitor',
      'title': 'Monitor'
    }
  ];

}
