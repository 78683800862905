import { Component, OnDestroy, HostBinding } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavActionService, NavAction } from './nav-action.service';
import { MessageService } from 'primeng/api';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-navigation-floating',
  templateUrl: './navigation-floating.component.html',
  styleUrls: ['./navigation-floating.component.scss']
})

export class NavigationFloatingComponent implements OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private statusSubscription: Subscription;
  private busySubscription: Subscription;
  private messageSubscription: Subscription;
  private actionsSubscription: Subscription;

  private animated = true;

  actions: NavAction[];

  // TODO: Implement sticky messages in service call
  isSticky = false;

  @HostBinding('class.show-actions') showActions = false;
  @HostBinding('class.animate') isBusy: boolean;
  @HostBinding('class.status-success') successState: boolean;
  @HostBinding('class.status-warn') warnState: boolean;
  @HostBinding('class.status-error') errorState: boolean;
  @HostBinding('class.status-info') infoState: boolean;

  faPowerOff = faPowerOff;

  constructor(private navService: NavActionService, private messageService: MessageService) {
    this.statusSubscription = this.navService.getStatus().pipe(takeUntil(this.ngUnsubscribe)).subscribe(status => {
      this.successState = false;
      this.warnState = false;
      this.errorState = false;
      this.infoState = false;

      switch (status) {
        case 'success':
          this.successState = true;
          break;
        case 'warn':
          this.warnState = true;
          break;
        case 'error':
          this.errorState = true;
          break;
        case 'info':
          this.infoState = true;
          break;
      }
    });

    this.messageSubscription = this.navService.getMessage().pipe(takeUntil(this.ngUnsubscribe)).subscribe(message => {
      this.messageService.clear();
      this.messageService.add(message);
    });

    this.busySubscription = this.navService.getBusy().pipe(takeUntil(this.ngUnsubscribe)).subscribe(busy => {
      this.isBusy = busy;
    });

    this.actionsSubscription = this.navService.getActions().pipe(takeUntil(this.ngUnsubscribe)).subscribe(actions => {
      this.animated = this.actions && this.actions.length ? false : true;

      if (actions && this.animated) {
        const previousState = this.showActions;
        const timeout = this.animated ? 300 + (100 * actions.length) : 0;

        this.showActions = false;

        setTimeout(() => {
          this.actions = actions;
          this.showActions = previousState;
        }, timeout);
      } else {
        this.actions = actions;
      }

      this.animated = true;
    });

    this.navService.getShowActions().pipe(takeUntil(this.ngUnsubscribe)).subscribe(show => {
      this.showActions = show;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  messageClosed() {
    this.isBusy = false;
    this.infoState = false;
    this.successState = false;
    this.warnState = false;
    this.errorState = false;
  }

  triggerAction(action: number) {
    if (this.actions[action].disabled !== true) {
      this.navService.triggerNavAction(action);
    }
  }

  toggleActions() {
    this.showActions = !this.showActions;
  }
}
