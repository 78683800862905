import { Action } from '@ngrx/store';

import { WidgetIssue } from '../../entity/widget-issue.model';

export const GET_WIDGET_ISSUES = '[Issue] GET_WIDGET_ISSUES';
export const GET_WIDGET_ISSUES_SUCCESS = '[Issue]  GET_WIDGET_ISSUES_SUCCESS';
export const GET_WIDGET_ISSUES_ERROR = '[Issue] GET_WIDGET_ISSUES_ERROR';

export class GetWidgetIssues implements Action {
	readonly type = GET_WIDGET_ISSUES;
	constructor(public clientId: string, public accountId: string) {}
}

export class GetWidgetIssuesSuccess implements Action {
	readonly type = GET_WIDGET_ISSUES_SUCCESS;
	constructor(public issues: WidgetIssue[]) {}
}

export class GetWidgetIssuesError implements Action {
	readonly type = GET_WIDGET_ISSUES_ERROR;
	constructor(public error: any) {}
}

export type All = GetWidgetIssues | GetWidgetIssuesSuccess | GetWidgetIssuesError;
