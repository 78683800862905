import { JsonConverter, JsonCustomConvert, JsonConvert, ValueCheckingMode } from 'json2typescript';
// tslint:disable-next-line:max-line-length
import { FormComponentBase, TextboxFormComponent, DropdownFormComponent, TextAreaFormComponent, HiddenFormComponent } from '../entity/generic-form/form-component-element';
import { NumberFormComponent, SwitchTextFormComponent, OptionFormComponent } from '../entity/generic-form/form-component-element';
import { DateFormComponent, SwitchFormComponent, MultiSelectFormComponent } from '../entity/generic-form/form-component-element';

@JsonConverter
export class FormComponentBaseConverter implements JsonCustomConvert<FormComponentBase<any>[]> {
  private jsonConverter: JsonConvert;

  constructor() {
    this.jsonConverter = new JsonConvert();
    this.jsonConverter.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
  }
    serialize(formComponent: FormComponentBase<any>[]): any {
        return this.jsonConverter.serialize(formComponent);
    }

    deserialize(formComponent: any): FormComponentBase<any>[] {
        const components = [];
        formComponent.forEach(element => {
          try {
            if (element.controlType === 'text') {
              components.push(this.jsonConverter.deserializeObject(element, TextboxFormComponent));
            } else if (element.controlType === 'textarea') {
              components.push(this.jsonConverter.deserializeObject(element, TextAreaFormComponent));
            } else if (element.controlType === 'switch') {
              components.push(this.jsonConverter.deserializeObject(element, SwitchFormComponent));
            } else if (element.controlType === 'switchText') {
              components.push(this.jsonConverter.deserializeObject(element, SwitchTextFormComponent));
            } else if (element.controlType === 'date') {
              components.push(this.jsonConverter.deserializeObject(element, DateFormComponent));
            } else if (element.controlType === 'dropdown') {
              components.push(this.jsonConverter.deserializeObject(element, DropdownFormComponent));
            } else if (element.controlType === 'multiSelect') {
              components.push(this.jsonConverter.deserializeObject(element, MultiSelectFormComponent));
            } else if (element.controlType === 'number') {
              components.push(this.jsonConverter.deserializeObject(element, NumberFormComponent));
            } else if (element.controlType === 'option') {
              components.push(this.jsonConverter.deserializeObject(element, OptionFormComponent));
            } else if (element.controlType === 'hidden') {
              components.push(this.jsonConverter.deserializeObject(element, HiddenFormComponent));
            }
          } catch (e) {
            throw new TypeError(e);
          }
        });

       return components;
    }
}
