import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';
import { ClientPmpService } from '../../../shared/pmp/pmp.service';
import * as AssetRiskContributionActions from './assetRiskContribution.actions';
import { map, catchError, switchMap } from 'rxjs/operators';

export type AssetRiskContributionAction = AssetRiskContributionActions.All;

@Injectable()
export class AssetRiskContributionEffects {

	constructor(private actions$: Actions,
							private clientSlwiService: ClientPmpService) {
	}

	getAllAssetRiskContributions: Observable<AssetRiskContributionAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetRiskContributionActions.GET_TAC_LEVEL_ASSET_RISK_CONTRIBUTION),
      switchMap((a: AssetRiskContributionActions.GetAssetLevelAssetRiskContribution) =>
			this.clientSlwiService.getAllAssetRiskContribution(a.clientId, a.accountId).pipe(
        map(assetRiskContributions => new AssetRiskContributionActions.GetAssetLevelAssetRiskContributionSuccess(assetRiskContributions)),
        catchError((error) => of(new AssetRiskContributionActions.GetAssetLevelAssetRiskContributionError(error)))
      ))
    ));
}
