import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../json-convert/DateConvertor';
import { Moment } from 'moment';

@JsonObject('KnownExceptionRequestDecision')
export class KnownExceptionRequestDecision {

  @JsonProperty('knownExceptionId', Number)
  knownExceptionId: number = undefined;

  @JsonProperty('approver', String)
  approver: string = undefined;

  @JsonProperty('status', String)
  status: string = undefined;

  @JsonProperty('reason', String)
  reason: string = undefined;

  @JsonProperty('decisionDate', DateConverter, true)
  decisionDate: Moment = undefined;

}

@JsonObject('AddKnownExceptionRequestDecision')
export class AddKnownExceptionRequestDecision {

  constructor(public knownExceptionId: number,
              public action: string,
              public reason: string,
              public overridenExpiryDate: Moment) {}

}
