
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DataModelService } from '../../../clients/service/data-model.service';
import * as RiskModelListActions from './risk-model-selection.actions';

export type DataModelListAction = RiskModelListActions.All;

@Injectable()
export class RiskModelListEffects {

	constructor(private actions$: Actions,
							private dataModelService: DataModelService) {
	}

	getAllRiskModels: Observable<DataModelListAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(RiskModelListActions.GET_ALL_RISK_MODELS),
      switchMap((a: RiskModelListActions.GetAllRiskModels ) =>
      this.dataModelService.getAllRiskModels(a.clientId, a.accountId, a.scenarioId).pipe(
			map(post => new RiskModelListActions.GetAllRiskModelsSuccess(post)),
      catchError((error) => of(new RiskModelListActions.GetAllRiskModelsError(error)))
    ))));

	
}
