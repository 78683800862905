import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JsonConvert } from 'json2typescript';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AssetColumns } from '../shared/entity/asset-columns.model';
import { UserPreferenceCategory } from '../shared/entity/user-preference-category.enum';
import { JsonConvertService } from '../shared/json-convert/json-convert.service';
import { environment } from '../../environments/environment';

@Injectable()
export class InterfaceOptionsService {
	private jsonConverter: JsonConvert;

  private horizontalDrawerSubject = new BehaviorSubject<boolean>(false);
  private verticalDrawerSubject = new BehaviorSubject<boolean>(true);

  constructor(
    private httpClient: HttpClient,
    private jsonConvertService: JsonConvertService) {
    this.jsonConverter =  this.jsonConvertService.getJsonConverter();
  }

  toggleHorizontalDrawerState(open: boolean) {
    this.horizontalDrawerSubject.next(open);
  }

  getHorizontalDrawerState(): Observable<any> {
    return this.horizontalDrawerSubject.asObservable();
  }

  toggleVerticalDrawerState(open: boolean) {
    this.verticalDrawerSubject.next(open);
  }

  getVerticalDrawerState(): Observable<any> {
    return this.verticalDrawerSubject.asObservable();
  }

  getInterfaceOptions(category: UserPreferenceCategory): Observable<AssetColumns> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/userPreference/${category}`).pipe(
			map(response => this.jsonConverter.deserializeObject(response, AssetColumns)));
  }

  getInterfaceViewsList(category: UserPreferenceCategory): Observable<AssetColumns[]> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/userPreference/${category}/list`)
    .pipe(
			map(response => {
        const results: AssetColumns[] = response as AssetColumns[];
        return this.jsonConverter.deserializeArray(results, AssetColumns);
      })
    );
  }

	deleteInterfaceView(option: AssetColumns): Observable<AssetColumns> {
    const payload = this.jsonConverter.serialize(option);
    return this.httpClient
    .post(`${environment.serverUrl}/restapi/service/userPreference/delete`, payload).pipe(
      map(response => option)
    );
	}

  saveInterfaceView(option: AssetColumns, category: UserPreferenceCategory): Observable<AssetColumns> {
    const columns = Object.assign(new AssetColumns(), option);
    const payload = this.jsonConverter.serialize(columns);
    return this.httpClient.post(`${environment.serverUrl}/restapi/service/userPreference/${category}/save`,
     payload).pipe(
      map(response => columns)
    );
	}

}
