import { PortfolioConstructionModel, HoldingClassNode, SubAssetClassNode, OptimiserBoundary } from '../../entity/portfolio-construction-model.model';
import { BreakdownHoldingDataElement, AssetPortfolio, ModelTarget } from '../../entity/portfolio-construction-model.model';
import { AssetTransaction } from '../../entity/portfolio-construction-model.model';
import { AssetResult, Asset } from '../../entity/asset.model';

export default class PortfolioConstructionUtil {

  static extractBreakDownNodeFromHolding(holdingNode: HoldingClassNode, accountName: string): BreakdownHoldingDataElement {
    let foundBreakDownNode: BreakdownHoldingDataElement = null;
    if (holdingNode) {
      holdingNode.breakdownNode.forEach(breakdownNode => {

        if (accountName === breakdownNode.accountName ) {
          foundBreakDownNode = breakdownNode;
        }
      });
    }
    return foundBreakDownNode;
  }

  static extractHoldingNodeFromModel(pcm: PortfolioConstructionModel, holdingNodeName: string): HoldingClassNode {
    let foundHoldingNode: HoldingClassNode = null;
    if (pcm && holdingNodeName) {
      pcm.topLevelNode.some(topAssetNode =>
        topAssetNode.subAssetNode.some(subAssetNode =>
          subAssetNode.holdingNode.some(holdingNode => {
            if (holdingNode.asset.name === holdingNodeName) {
              foundHoldingNode = holdingNode;
              return true;
            }
          })
        ));
    }
    return foundHoldingNode;
  }

  static extractHoldingNodeFromModelLabel(pcm: PortfolioConstructionModel, holdingNodeName: string): HoldingClassNode {
    let foundHoldingNode: HoldingClassNode = null;
    if (pcm && holdingNodeName) {
      pcm.topLevelNode.some(topAssetNode =>
        topAssetNode.subAssetNode.some(subAssetNode =>
          subAssetNode.holdingNode.some(holdingNode => {
            if (holdingNode.asset.label === holdingNodeName) {
              subAssetNode.parent = topAssetNode;
              holdingNode.parent = subAssetNode;
              foundHoldingNode = holdingNode;
              return true;
            }
          })
        ));
    }
    return foundHoldingNode;
  }

  static hasTradableAssets(pcm: PortfolioConstructionModel): boolean {
    let result = false;
    if (pcm) {
      pcm.topLevelNode.some(topAssetNode => {
          return topAssetNode.subAssetNode.some(subAssetNode => {
            return subAssetNode.holdingNode.some(holdingNode => {
              return holdingNode.breakdownNode.some(breakdownNode => {
                if (breakdownNode.proposedVsCurrent !== 0) {
                  result = true;
                  return true;
                }
              });
            });
          });
        });
    }
    return result;
  }

  static extractAllBreakDownNodesFromPortfolioConstructionModel(pcm: PortfolioConstructionModel): BreakdownHoldingDataElement[] {
    const tradeNodeList: Array<BreakdownHoldingDataElement> = new Array<BreakdownHoldingDataElement>();
    if (pcm) {
      pcm.topLevelNode.filter(node => !node.excludedFromTrading).forEach(topAssetNode => {
        return topAssetNode.subAssetNode.forEach(subAssetNode => {
          return subAssetNode.holdingNode.forEach(holdingNode => {
            return holdingNode.breakdownNode.forEach(breakdownNode => {
              tradeNodeList.push(breakdownNode);
              return true;
            });
          });
        });
      });
    }
    return tradeNodeList;
  }

  static extractHierarchyFromAssetName(assetName: string, pcm: PortfolioConstructionModel): any {

    let foundTopAssetNode = null;
    let foundSubAssetNode = null;
    let foundHoldingNode = null;

    pcm.topLevelNode.some(topAssetNode =>
      topAssetNode.subAssetNode.some(subAssetNode =>
        subAssetNode.holdingNode.some(holdingNode => {

            if (assetName === holdingNode.asset.name) {
              foundTopAssetNode = topAssetNode;
              foundSubAssetNode = subAssetNode;
              foundHoldingNode = holdingNode;
              return true;
            }
        })
      ));

    return {
        topAssetNode: foundTopAssetNode,
        subAssetNode: foundSubAssetNode,
        holdingNode: foundHoldingNode,
      };
  }

  static createHoldingNode(pcm: PortfolioConstructionModel, assetResult: AssetResult): HoldingClassNode {

    let foundSubAssetNode: SubAssetClassNode = null;

    pcm.topLevelNode.some(topAssetNode =>
      topAssetNode.subAssetNode.some(subAssetNode => {
        if (subAssetNode.asset.label === assetResult.subAssetClass) {
          foundSubAssetNode = subAssetNode;
          return true;
        }
      }

      ));

    if (foundSubAssetNode) {
      const newHoldingNode = this.createEmptyHoldingNode(assetResult);

      foundSubAssetNode.holdingNode.push(newHoldingNode);
      return newHoldingNode;
    }

    return null;
  }

  static createEmptyHoldingNode(assetResult: AssetResult): HoldingClassNode {

    const holdingClassNode = new HoldingClassNode();
    holdingClassNode.asset = new Asset(null, assetResult.sedol, assetResult.description, assetResult.composite, assetResult.inBuyList);
    holdingClassNode.asset.price = assetResult.price;
    holdingClassNode.breakdownNode = new Array<BreakdownHoldingDataElement>();
    holdingClassNode.currentPosition = this.zeroWeightAssetPortfolio();
    holdingClassNode.proposedPosition = this.zeroWeightAssetPortfolio();
    holdingClassNode.modelTarget = this.zeroWeightModelTarget();
    holdingClassNode.tradeInstruction = new AssetTransaction();
    holdingClassNode.tradeInstruction.rawGain = 0;
    holdingClassNode.tradeInstruction.transactionType = '';
    holdingClassNode.tradeInstruction.tradeNominal = 0;
    holdingClassNode.tradeInstruction.approxProceeds = 0;
    holdingClassNode.tradeInstruction.rationaleId = null;
    holdingClassNode.tradeInstruction.message = null;
    holdingClassNode.isNewHolding = true;
    holdingClassNode.proposedVsTarget = 0;
    holdingClassNode.proposedVsCurrent = 0;
    holdingClassNode.proposedNetGain = 0;
    holdingClassNode.volatility = 0;
    holdingClassNode.yield = 0;
    holdingClassNode.optimiserBoundary = new OptimiserBoundary();
    holdingClassNode.optimiserBoundary.min = 0;
    holdingClassNode.optimiserBoundary.max = 0;
    holdingClassNode.optimiserBoundary.alpha = null;
    holdingClassNode.optimiserBoundary.fix = null;
    return holdingClassNode;
  }

  static createEmptyBreakdownNode(accountId: number, accountName: string, accountLabel: string): BreakdownHoldingDataElement {

    const breakdownNode = new BreakdownHoldingDataElement();
    breakdownNode.accountId = accountId;
    breakdownNode.accountName = accountName;
    breakdownNode.compositeLabel = accountLabel;
    breakdownNode.currentPosition = this.zeroWeightAssetPortfolio();
    breakdownNode.proposedPosition = this.zeroWeightAssetPortfolio();
    breakdownNode.tradeInstruction = new AssetTransaction();
    breakdownNode.tradeInstruction.rawGain = 0;
    breakdownNode.tradeInstruction.transactionType = '';
    breakdownNode.tradeInstruction.tradeNominal = 0;
    breakdownNode.tradeInstruction.approxProceeds = 0;
    breakdownNode.tradeInstruction.rationaleId = null;
    breakdownNode.tradeInstruction.message = null;
    return breakdownNode;
  }

  static addBreakDownNode(holdingNode: HoldingClassNode, accountId: number, accountName: string, accountLabel: string) {

    const containsBreakDownNode = holdingNode.breakdownNode.some(breakDownNode => breakDownNode.accountName === accountName);

    if (!containsBreakDownNode) {
      holdingNode.isNewHolding = true;
      const breakdownNode = this.createEmptyBreakdownNode(accountId, accountName, accountLabel);
      holdingNode.breakdownNode.push(breakdownNode);
    }

    holdingNode.source = holdingNode.breakdownNode.length > 1 ? 'M' : 'S';
  }


  static zeroWeightAssetPortfolio(): AssetPortfolio {
    const assetPortfolio = new AssetPortfolio();
    assetPortfolio.ctr = 0;
    assetPortfolio.mctr = 0;
    assetPortfolio.shares = 0;
    assetPortfolio.value = 0;
    assetPortfolio.weight = 0;
    assetPortfolio.yield = 0;
    assetPortfolio.bookCost = 0;

    return assetPortfolio;
  }

  static zeroWeightModelTarget(): ModelTarget {
    const modelTarget = new ModelTarget();
    modelTarget.bmAA = 0;
    modelTarget.maxAA = 0;
    modelTarget.minAA = 0;
    modelTarget.target = 0;

    return modelTarget;
  }

  static recalculateTotal(breakdownLevel, holdingLevel, subLevelNode, topLevelNode, pcm, newProposedWeight, newProposedShares, calcTotal=true) {

    // Breakdown level
    breakdownLevel.proposedPosition.yield = 0;
    breakdownLevel.proposedPosition.mctr = 0;
    breakdownLevel.proposedPosition.ctr = 0;
    breakdownLevel.proposedPosition.weight = newProposedWeight;
    breakdownLevel.proposedPosition.value = pcm.total.proposedPosition.value * (breakdownLevel.proposedPosition.weight / 100);
    if (newProposedShares) {
      breakdownLevel.proposedPosition.shares = newProposedShares;
    } else if (holdingLevel.asset.price) {
      breakdownLevel.proposedPosition.shares = Math.round(breakdownLevel.proposedPosition.value / holdingLevel.asset.price);
    }

    // Holding level
    PortfolioConstructionUtil.recalculateAssetTotal(holdingLevel, holdingLevel.breakdownNode);

    // SAC level
    PortfolioConstructionUtil.recalculateAssetTotal(subLevelNode, subLevelNode.holdingNode);

    // TAC level
    PortfolioConstructionUtil.recalculateAssetTotal(topLevelNode, topLevelNode.subAssetNode);

    if (!calcTotal) {
      return;
    }
    // Total level
    let totalWeight = 0;
    let totalValue = 0;

    for (const topAssetNode of pcm.topLevelNode) {
      totalWeight += parseFloat(topAssetNode.proposedPosition.weight);
      totalValue += parseFloat(topAssetNode.proposedPosition.value);
    }

    pcm.total.proposedPosition.weight = totalWeight;
    pcm.total.proposedPosition.value = totalValue;
  }

  static recalculateAssetTotal(node, children) {

    let weight = 0;
    let value = 0;

    for (const child of children) {
      weight += parseFloat(child.proposedPosition.weight);
      value += parseFloat(child.proposedPosition.value);
    }

    node.proposedPosition.weight = weight;
    node.proposedPosition.value = value;

    node.proposedVsTarget = weight - node.modelTarget.target;
    node.proposedVsCurrent = weight - node.currentPosition.weight;
  }

}
