import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { AppData } from '../../core/store/appState.model';
import * as AuthenticationActions from '../../core/store/auth/authentication.actions';
import { AuthenticationState } from '../../core/store/auth/authentication.state';
import { AuthenticationConfig } from  '../../core/entity/authenticationConfig.model';
import { AuthenticationService } from '../authentication.service';
import * as Path from '../../core/routing/routing.const';
import { AppRouterService } from '../../shared/router/app-router.service';
import { filter, take } from 'rxjs/operators';
import * as ConfigurationKeys from '../../core/entity/configuration.const';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {

  constructor(private appRouterService: AppRouterService,
              private store: Store<AppData>,
              private authenticationService: AuthenticationService,
              private activatedRoute: ActivatedRoute) {}

  static CACHED_AUTHENTICATION_CONFIG: AuthenticationConfig;
  forceLocalMode: boolean = false;
  localAuthenticationMode: boolean = true;
  availableAuthenticators: Map<string, string>;

  userName: string;
  password: string;
  redirect;

  @Input()
  authenticationState: Observable<AuthenticationState>;

  ngOnInit() {
    this.authenticationState = this.store.select('authentication');
    this.store.dispatch(new AuthenticationActions.ClearError());

    this.configAuthenticationOptions(LoginComponent.CACHED_AUTHENTICATION_CONFIG);

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const redirectUrl = params['redirect'];
      if (redirectUrl) {
        this.redirect = decodeURI(redirectUrl);
      }

      this.forceLocalMode = params['local'] as boolean;
      console.log('2 params: ', params, '; redirectUrl:', this.redirect, '; forceLocalMode:', this.forceLocalMode);
    });

    this.authenticationState.pipe(filter(auth => auth.session !== undefined && !!auth.session.authenticationConfig), take(1))
                            .subscribe(auth => {
                              console.log('3 In Login; about to check authenticationConfig');
                              if (LoginComponent.CACHED_AUTHENTICATION_CONFIG === undefined) {
                                this.configAuthenticationOptions(auth.session.authenticationConfig);
                              }
                            });

    this.authenticationState.pipe(
    filter(auth => auth.session !== undefined  && auth.session.user !== undefined
      && !!auth.session.configuration),
    take(1))
    .subscribe(
      auth => {
        if (!!this.redirect) {
          const urlElements: string[] = this.redirect.split('?');

          if (urlElements.length === 1) {
            this.appRouterService.url(this.redirect);
            console.log('4 redirectUrl: ', this.redirect);
            return;
          }
          // query parameter part of url gets percent encoded, for example & into %26 and = into %3D
          // so the query parameter needs to be passed as object
          const query = this.buildQuery(urlElements[1]);
          this.appRouterService.url(this.redirect, query);
          console.log('5 redirectUrl with query: ', urlElements[0], '; query:' , query);
          return;
        } else {
          const landingPage = auth.session.configuration.getConfig(ConfigurationKeys.DEFAULT_LANDING_PAGE);
          if (Path.MODELS_PAGE === landingPage) {
            this.appRouterService.modelsHomePage();
            return;
          } else {
            this.appRouterService.dashboardPage();
            return;
          }
        }
      }
    );
  }

  returnZero(): number {
    return 0;
  }

  buildQuery(str: string): object {
    const query = {};
    if (!!str) {
      const queryElements = str.split('&');
      for (let i = 0; i < queryElements.length; i++) {
        const queryElement = queryElements[i].split('=');
        if (queryElement.length > 1) {
          query[queryElement[0]] = queryElement[1];
        }
        else {
          console.error('invalid query element: ', queryElement);
        }
      }
      return query;
    }
  }

  configAuthenticationOptions(authenticationConfig: AuthenticationConfig) {
    if (this.authenticationService===undefined || authenticationConfig) {
      this.localAuthenticationMode = this.forceLocalMode || authenticationConfig.isLocalAuthenticationMode();
      this.availableAuthenticators = authenticationConfig.getAuthenticationServices();
      LoginComponent.CACHED_AUTHENTICATION_CONFIG = authenticationConfig;
      console.log('(AAA) Configured authentication config; localMode: ', this.localAuthenticationMode);
    }
  }

  openLogin(authenticator: string) {
    if (authenticator == 'local') {
      this.requestLocalLogin();
    }
    else {
      this.requestOauth2Login();
    }
  }

  requestLocalLogin() {
      this.localAuthenticationMode = true;
      var query = {};
      if (!!this.redirect) {
        const urlElements: string[] = this.redirect.split('?');
        if (urlElements.length === 1) {
          query['redirect'] = this.redirect;
        }
        else {
          query = this.buildQuery(urlElements[1]);
        }
      }
      console.log('7 requestLocalLogin; redirect:', this.redirect, '; query:' , query);
      this.appRouterService.localLoginPage(query);
  }

  requestOauth2Login() {
    const externalLoginUrl = this.authenticationService.getExternalLoginUrl();
    window.location.href = externalLoginUrl;
  }

  requestLogin() {
    this.store.dispatch(new AuthenticationActions.ClearError());
    this.store.dispatch(new AuthenticationActions.RequestLogin(this.userName, this.password));
  }

  public ngOnDestroy() {
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.requestLogin();
    }
  }
}
