import * as PcmActions from './readOnlyPortfolio.actions';
import { ReadOnlyPortfolioState } from './readOnlyPortfolio.state';

export type PcmAction = PcmActions.All;

export const initialState: ReadOnlyPortfolioState = {
    readOnlyPortfolioModel: undefined
};

export function readOnlyPortfolioReducer (
    state: ReadOnlyPortfolioState = initialState,
    action: PcmAction): ReadOnlyPortfolioState {
        switch (action.type) {
            case PcmActions.GET_READ_ONLY_PORTFOLIO_MODEL: {
                return {...initialState};
            }

            case PcmActions.GET_READ_ONLY_PORTFOLIO_MODEL_SUCCESS: {
                return {...state, ...{readOnlyPortfolioModel: action.pcm}};
            }

            case PcmActions.GET_READ_ONLY_PORTFOLIO_MODEL_ERROR: {
                console.error('Error caught while loading read-only portfolio model', action.error);
                return {...state, readOnlyPortfolioModel: undefined};
            }

            default:
			return state;
        }
}
