import * as IprFileNotesActions from './ipr-file-notes.actions';
import { IprFileNotesState } from './ipr-file-notes.state';

export type IprFileNotesAction = IprFileNotesActions.All;
export const initialState: IprFileNotesState = { config: undefined };

export function iprFileNotesReducer(state: IprFileNotesState = initialState, action: IprFileNotesAction): IprFileNotesState {
  switch (action.type) {
    case IprFileNotesActions.GET_IPR_FILE_NOTES_CONFIG_SUCCESS: {
			return {...state, ...{config: action.iprFileNotesConfig}};
    }

    case IprFileNotesActions.GET_IPR_FILE_NOTES_CONFIG_ERROR: {
      console.error('Error caught while changing starting portfolio', action.error);
			return {...state, config: undefined};
		}

    default :
        return state;
  }

}

