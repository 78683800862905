import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import localeGB from '@angular/common/locales/en-GB';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { AppHttpModule } from './http/app-http.module';

import { SsoResolver } from './auth/sso-resolver.service';
import { DashModalComponent } from './dash/dash-modal/dash-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment } from '../environments/environment';

registerLocaleData(localeGB);

@NgModule({
  declarations: [
    AppComponent,
    DashModalComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    AppHttpModule,
    FontAwesomeModule
  ],
  exports: [
    AuthModule
  ],
  providers: [SsoResolver,
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [SsoResolver],  multi: true },
    { provide: APP_BASE_HREF, useValue: environment.baseHref}
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
export function init_app(ssoResolver: SsoResolver) {
  return () => ssoResolver.checkSso();
}
