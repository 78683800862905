export function mockInterfaceColumns() {
    return [
    {
      'id': 1,
      'columnHeaders': {
        'instruments': {
          'title': 'INSTRUMENTS',
          'hidden': false,
          'columns': {
            'assetName': {
              'title': 'Asset Name',
              'hidden': false,
              'configurable': false,
              'enabled': true,
              'width': 220
            },
            'volatility': {
              'title': '%vol',
              'hidden': false,
              'configurable': false,
              'enabled': true,
              'width': 40
            },
            'yield': {
              'title': 'yield',
              'hidden': false,
              'configurable': false,
              'enabled': true,
              'width': 80
            }
          }
        },
        'currentPortfolio': {
          'title': 'CURRENT PORTFOLIO',
          'hidden': false,
          'columns': {
            'weight': {
              'title': 'weight',
              'hidden': false,
              'configurable': false,
              'enabled': true,
              'width': 60
            },
            'shares': {
              'title': 'shares',
              'hidden': false,
              'configurable': true,
              'enabled': true,
              'width': 60
            },
            'value': {
              'title': 'value',
              'hidden': false,
              'configurable': false,
              'enabled': true,
              'width': 90
            },
            'ctr': {
              'title': 'CTR',
              'hidden': false,
              'configurable': false,
              'enabled': true,
              'width': 50
            },
            'bookCost': {
              'title': 'book cost',
              'hidden': false,
              'configurable': true,
              'enabled': true,
              'width': 80
            },
            'yield': {
              'title': 'yield',
              'hidden': false,
              'configurable': false,
              'enabled': true,
              'width': 80
            }
          }
        },
        'profile': {
          'title': 'PROFILE PARAMETERS',
          'hidden': false,
          'columns': {
            'minAA': {
              'title': 'min AA',
              'hidden': false,
              'configurable': true,
              'enabled': true,
              'width': 50
            },
            'maxAA': {
              'title': 'max AA',
              'hidden': false,
              'configurable': true,
              'enabled': true,
              'width': 60
            },
            'aa': {
              'title': 'AA',
              'hidden': false,
              'configurable': true,
              'enabled': true,
              'width': 50
            }
          }
        },
        'target': {
          'title': 'TARGET',
          'hidden': false,
          'columns': {
            'model': {
              'title': 'model',
              'hidden': false,
              'configurable': false,
              'enabled': true,
              'width': 100
            }
          }
        },
        'proposedPortfolio': {
          'title': 'PROPOSED PORTFOLIO',
          'hidden': false,
          'columns': {
            'weight': {
              'title': 'weight',
              'hidden': false,
              'configurable': false,
              'enabled': true,
              'width': 60
            },
            'shares': {
              'title': 'weight',
              'hidden': false,
              'configurable': false,
              'enabled': true,
              'width': 60
            },
            'value': {
              'title': 'value',
              'hidden': false,
              'configurable': true,
              'enabled': true,
              'width': 90
            },
            'mctr': {
              'title': 'MCTR',
              'hidden': false,
              'configurable': true,
              'enabled': true,
              'width': 50
            },
            'ctr': {
              'title': 'CTR',
              'hidden': false,
              'configurable': false,
              'enabled': true,
              'width': 50
            },
            'yield': {
              'title': 'yield',
              'hidden': false,
              'configurable': false,
              'enabled': true,
              'width': 80
            }
          }
        },
        'proposed': {
          'title': 'Proposed',
          'hidden': false,
          'columns': {
            'vsTarget': {
              'title': 'vs target',
              'hidden': false,
              'configurable': false,
              'enabled': true,
              'width': 60
            }
          }
        },
        'change': {
          'title': 'Change',
          'hidden': false,
          'columns': {
            'buySell': {
              'title': 'buy/sell',
              'hidden': false,
              'configurable': false,
              'enabled': true,
              'width': 60
            }
          }
        },
        'rawGain': {
          'title': '',
          'hidden': false,
          'columns': {
            'rawGain': {
              'title': 'raw gain',
              'hidden': false,
              'configurable': true,
              'enabled': true,
              'width': 60
            }
          }
        },
        'tickToTrade': {
          'title': '',
          'hidden': false,
          'columns': {
            'tickToTrade': {
              'title': 'tick to trade',
              'hidden': false,
              'configurable': true,
              'enabled': true,
              'width': 100
            }
          }
        },
        'tradeNominal': {
          'title': '',
          'hidden': false,
          'columns': {
            'tradeNominal': {
              'title': 'trade nominal',
              'hidden': false,
              'configurable': true,
              'enabled': true,
              'width': 100
            }
          }
        },
        'approxProceeds': {
          'title': '',
          'hidden': false,
          'columns': {
            'approxProceeds': {
              'title': 'approx proceeds',
              'hidden': false,
              'configurable': true,
              'enabled': true,
              'width': 100
            }
          }
        },
        'tradeRationale': {
          'title': '',
          'hidden': false,
          'columns': {
            'tradeRationale': {
              'title': 'Trade Rationale',
              'hidden': false,
              'configurable': false,
              'enabled': true,
              'width': 200
            }
          }
        },
        'rationaleFreeText': {
          'title': '',
          'hidden': false,
          'columns': {
            'rationaleFreeText': {
              'title': 'Rationale Free Text',
              'hidden': false,
              'configurable': false,
              'enabled': true,
              'width': 200
            }
          }
        },
        'other': {
          'title': 'OTHER',
          'hidden': false,
          'columns': {
            'ocfValue': {
              'title': 'OCF Value',
              'hidden': false,
              'configurable': true,
              'enabled': true,
              'width': 100
            },
            'bondRating': {
              'title': 'Bond Rating',
              'hidden': false,
              'configurable': true,
              'enabled': true,
              'width': 100
            },
            'bondMaturity': {
              'title': 'Bond Maturity',
              'hidden': false,
              'configurable': true,
              'enabled': true,
              'width': 100
            }
          }
        }
      }

    }
  ];
}
