export function mockKnownExceptionTypes() {
  return [
    {
      id: 1,
      description: 'Cash level is currently held at client instruction',
      expiryPeriod: 120,
      target: 'monitor'
    },

    {
      id: 2,
      description: 'The CPM continues to gradually invest towards the model in line with client wishes',
      expiryPeriod: 180,
      target: 'monitor'
    },

    {
      id: 3,
      description: 'Cash to be paid away',
      expiryPeriod: 90,
      target: 'monitor'
    },

    {
      id: 4,
      description: 'CGT restrictions',
      expiryPeriod: 360,
      target: 'monitor'
    },

    {
      id: 5,
      description: 'Terminating Portfolio',
      expiryPeriod: 180,
      target: 'monitor'
    },

    {
      id: 16,
      description: 'Execution-Only holding',
      expiryPeriod: 365,
      target: 'excludedHolding'
    },

    {
      id: 17,
      description: 'Cherished holding',
      expiryPeriod: 365,
      target: 'excludedHolding'
    },

    {
      id: 18,
      description: 'Other Exclusion',
      expiryPeriod: 365,
      target: 'excludedHolding'
    },


  ];

}
