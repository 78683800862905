import {JsonObject, JsonProperty} from 'json2typescript';
import { ClientType } from './client-type.model';
import { ClientStatus } from './client-status.model';

@JsonObject('SearchMetaData')
export class SearchMetaData {

  // TODO: 'owners' is currently missing from the API
	// @JsonProperty('owners', [String])
	// owners: String[] = undefined;

	@JsonProperty('clientTypes', [ClientType], true)
	clientTypes: ClientType[] = undefined;

	@JsonProperty('status', [ClientStatus], true)
	status: ClientStatus[] = undefined;

}
