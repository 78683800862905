import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../../shared/json-convert/DateConvertor';
import { Moment } from 'moment';

@JsonObject('ClientNote')
export class ClientNote {

  @JsonProperty('fileNoteId', Number)
  fileNoteId: number = undefined;

  @JsonProperty('accountId', String)
  accountId: string = undefined;

  @JsonProperty('clientId', String)
  clientId: string = undefined;

  @JsonProperty('fileNoteType', String)
  fileNoteType: string = undefined;

  @JsonProperty('fileNoteContent', String)
  fileNoteDesc: string = undefined;

  @JsonProperty('fileNoteContent', String)
  fileNoteContent: string = undefined;

  @JsonProperty('lastModifiedDate', DateConverter)
  lastModifiedDate: Moment = undefined;

  @JsonProperty('lastModifiedBy', String)
  lastModifiedBy: string = undefined;

  @JsonProperty('isCurrent', Boolean)
  isCurrent: boolean = undefined;

}
