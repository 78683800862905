import { JsonObject, JsonProperty } from 'json2typescript';
import { Moment } from 'moment';
import { DateConverter } from '../../../shared/json-convert/DateConvertor';
import { GenericFormConfiguration } from '../../../shared/entity/generic-form/generic-form.model';

@JsonObject('TcfdStyleInfo')
export class TcfdStyleInfo {
  @JsonProperty('displayLabelOnly', Boolean, true)
  displayLabelOnly: boolean = undefined;

  @JsonProperty('spaceBottom', Boolean, true)
  spaceBottom: boolean = undefined;

  @JsonProperty('style', String, true)
  style: string = undefined;

}

@JsonObject('TcfdHeadlineItem')
export class TcfdHeadlineItem {

  @JsonProperty('assetTypeLabel', String)
  assetTypeLabel: string = undefined;
  @JsonProperty('headline', String)
  headline: string = undefined;

  @JsonProperty('portfolio', Number, true)
  portfolio: number = undefined;

  @JsonProperty('benchmark', Number, true)
  benchmark: number = undefined;

  @JsonProperty('relative', Number, true)
  relative: number = undefined;

  @JsonProperty('coveragePortfolio', Number, true)
  coveragePortfolio: number = undefined;

  @JsonProperty('coverageBenchmark', Number, true)
  coverageBenchmark: number = undefined;

  @JsonProperty('styleInfo', TcfdStyleInfo, true)
  styleInfo: TcfdStyleInfo = undefined;
}

@JsonObject('assetValueChange')
export class AssetValueChange {
  @JsonProperty('esgAssetType', String)
  esgAssetType: string = undefined;

  @JsonProperty('esgAssetTypeName', String)
  esgAssetTypeName: string = undefined;

  @JsonProperty('currencySymbol', String)
  currencySymbol: string = undefined;

  @JsonProperty('startValue', Number)
  startValue: number = undefined;

  @JsonProperty('startWeight', Number)
  startWeight: number = undefined;

  @JsonProperty('endValue', Number)
  endValue: number = undefined;

  @JsonProperty('endWeight', Number)
  endWeight: number = undefined;

  @JsonProperty('relative', Number)
  relative: number = undefined;

  @JsonProperty('coverageScope123', Number, true)
  coverageScope123WithData: number = undefined;

  @JsonProperty('coverageScope12', Number, true)
  coverageScope12WithData: number = undefined;

  @JsonProperty('coverageNoData', Number, true)
  coverageNoData: number = undefined;
}

@JsonObject('assetValueChanges')
export class AssetValueChanges {

  @JsonProperty('title', String, true)
  title: string = undefined;

  @JsonProperty('portfolioId', String, true)
  portfolioId: string = undefined;

  @JsonProperty('currency', String)
  currency: string = undefined;

  @JsonProperty('reportDate', DateConverter)
  reportDate: Moment = undefined;

  @JsonProperty('startDate', DateConverter)
  startDate: Moment = undefined;

  @JsonProperty('startTotal', Number)
  startTotal: number = undefined;

  @JsonProperty('endDate', DateConverter)
  endDate: Moment = undefined;

  @JsonProperty('endTotal', Number)
  endTotal: number = undefined;

  @JsonProperty('assetTypeValueChanges', [AssetValueChange])
  assetTypeValueChanges: AssetValueChange[] = undefined;
}

@JsonObject('GhgHeadlineAdjustedMetrics')
export class GhgHeadlineAdjustedMetrics {
  @JsonProperty('value', Number, true)
  value: number = undefined;

  @JsonProperty('indicatorColour', String, true)
  indicatorColour: string = undefined;
}



@JsonObject('TcfdHeadlineChangeMetrics')
export class TcfdHeadlineChangeMetrics {
  @JsonProperty('startValue', Number)
  startValue: number = undefined;

  @JsonProperty('endValue', Number)
  endValue: number = undefined;

  @JsonProperty('relative', Number)
  relative: number = undefined;

  @JsonProperty('indicatorColour', String, true)
  indicatorColour: string = undefined;
}


@JsonObject('GhgHeadlineMetrics')
export class GhgHeadlineMetrics {

  @JsonProperty('assetTypeLabel', String)
  assetTypeLabel: string = undefined;

  @JsonProperty('headline', String)
  headline: string = undefined;

  @JsonProperty('valueWithData', Number)
  valueWithData: number = undefined;

  @JsonProperty('ghgEmissions', TcfdHeadlineChangeMetrics)
  ghgEmissions: TcfdHeadlineChangeMetrics = undefined;

  @JsonProperty('coverage', TcfdHeadlineChangeMetrics)
  coverage: TcfdHeadlineChangeMetrics = undefined;

  @JsonProperty('ghgEmissionsAdjusted', GhgHeadlineAdjustedMetrics)
  ghgEmissionsAdjusted: GhgHeadlineAdjustedMetrics = undefined;

  @JsonProperty('ghgEmissionsAdjustedChange', GhgHeadlineAdjustedMetrics)
  ghgEmissionsAdjustedChange: GhgHeadlineAdjustedMetrics = undefined;

  @JsonProperty('styleInfo', TcfdStyleInfo, true)
  styleInfo: TcfdStyleInfo = undefined;
}

@JsonObject('GhgEmissionHistoryDataPoints')
export class GhgEmissionHistoryDataPoints {
  @JsonProperty('date', DateConverter)
  date: Moment = undefined;

  @JsonProperty('value', Number)
  value: number = undefined;
}

@JsonObject('HighEmissionHistoryMetrics')
export class HighEmissionHistoryMetrics {
  @JsonProperty('headline', String)
  headline: string = undefined;

  @JsonProperty('dataPoints', [GhgEmissionHistoryDataPoints])
  dataPoints: GhgEmissionHistoryDataPoints[] = undefined;

  @JsonProperty('isGrossValue', Boolean, true)
  isGrossValue: boolean = undefined;
}
@JsonObject('GhgHistoryMetrics')
export class GhgHistoryMetrics {
  @JsonProperty('title', String)
  title: string = undefined;

  @JsonProperty('startDate', DateConverter)
  startDate: Moment = undefined;

  @JsonProperty('endDate', DateConverter)
  endDate: Moment = undefined;

  @JsonProperty('headlineChanges', [GhgHeadlineMetrics])
  headlineChanges: GhgHeadlineMetrics[] = undefined;

  @JsonProperty('lowEmissionsHistory', [HighEmissionHistoryMetrics])
  lowEmissionsHistory: HighEmissionHistoryMetrics[] = undefined;

  @JsonProperty('highEmissionsHistory', [HighEmissionHistoryMetrics])
  highEmissionsHistory: HighEmissionHistoryMetrics[] = undefined;

   @JsonProperty('currency', String, true)
   currency: string = undefined;

   @JsonProperty('currencySymbol', String, true)
   currencySymbol: string = undefined;

}

@JsonObject('ClientTcfdFilterConfiguration')
export class ClientTcfdFilterConfiguration {
  @JsonProperty('filters', GenericFormConfiguration)
  filters: GenericFormConfiguration = undefined;
}

@JsonObject('AllClientTcfdFilterConfiguration')
export class AllClientTcfdFilterConfiguration {
  @JsonProperty('clientTcfdFilter', GenericFormConfiguration)
  clientTcfdFilter: ClientTcfdFilterConfiguration = undefined;
  
  @JsonProperty('clientTcfdHistoryFilter', GenericFormConfiguration)
  clientTcfdHistoryFilter: ClientTcfdFilterConfiguration = undefined;
}


 export interface ClientTcfdFilterParams {
    fromReportDate: string;
    toReportDate: string;  
    dataReportDate: string;
 }