export const CLIENT_HOME = 'home';
export const ACCOUNT = 'account';
export const PMP = 'pmp';
export const AAP = 'aap';
export const PPP = 'ppp';
export const SCENARIO = 'scenario';
export const REPORT = 'report';
export const QUESTIONNAIRE = 'questionnaire';
export const KYC = 'kyc';
export const ESG_QUESTIONNAIRE = 'esg-questionnaire';
export const EARROW = 'earrow';
export const ECO2 = 'eco2';
export const ESG_CONFLICT_REPORT = 'esg-conflict-report';
export const ESG_OVERALL_IMPACT_REPORT = 'esg-overall-impact-report';
export const PROFILE = 'profile';
export const FACT = 'fact';
export const EXP_CHANGE = 'exp_change';
export const ERQ_CONFLICT = 'erq-conflict';
export const TCFD_DASHBOARD = 'tcfd-dashboard';
export const TCFD_DETAILED = 'tcfd-detailed';
export const TCFD_HISTORY = 'tcfd-history';
export const TCFD_ASSET = 'tcfd-asset';
