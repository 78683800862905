
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as AuthActions from '../core/store/auth/authentication.actions';
import * as Path from '../core/routing/routing.const';
import { NavActionService } from '../core/navigation-floating/nav-action.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private ignoredRoutes = [
    'messenger/welcome'
  ];

  constructor(private store: Store<any>,
    private navActionService: NavActionService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({
      headers: request.headers.set('X-Bita-API', 'true').set('X-Requested-With', 'XMLHttpRequest'),
      withCredentials: true
    });

    const isIgnored = this.ignoredRoutes.some(v => request.url.includes(v));

    if (!isIgnored) {
      this.navActionService.isBusy(true);
    }

    return next.handle(request).pipe(tap((httpEvent: HttpEvent<any>) => {
      if (request.method === 'OPTIONS' || httpEvent.type === 0) {
        return;
      }

      if (!isIgnored) {
        this.navActionService.isBusy(false);
      }

      return;
    },
      (err: any) => {

        if (err instanceof HttpErrorResponse) {
          let errorMessage = 'Error caught, please contact the administrator';
          if (err.status === 401) {

            if (err.error.cause) {
              errorMessage = err.error.cause;
            }
            this.store.dispatch(new AuthActions.RequestLoginError(errorMessage));
            this.store.dispatch(new AuthActions.Go({
              path: [Path.LOG_IN_PAGE, {}],
              query: {},
              extras: { replaceUrl: false }
            }));
          }

          if (!isIgnored) {
            this.navActionService.isBusy(false);
          }
        }
      }

    ));
  }
}
