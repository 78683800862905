import { Action } from '@ngrx/store';
import { BuyListKe } from '../../../team-buylist/entity/buy-list-client.model';
import { TeamBuyList } from '../../entity/teamBuyList.model';

export const GET_BUY_LIST_KE_FORM_CONFIG = '[BuyListKeForms] GET_BUY_LIST_KE_FORM_CONFIG';
export const GET_BUY_LIST_KE_FORM_CONFIG_SUCCESS = '[BuyListKeForms] GET_BUY_LIST_KE_FORM_CONFIG_SUCCESS';
export const GET_BUY_LIST_KE_FORM_CONFIG_ERROR = '[BuyListKeForms] GET_BUY_LIST_KE_FORM_CONFIG_ERROR';

export const SUBMIT_BUY_LIST_KE_FORM = '[BuyListKeForms] SUBMIT_BUY_LIST_KE_FORM';
export const SUBMIT_BUY_LIST_KE_FORM_SUCCESS = '[BuyListKeForms] SUBMIT_BUY_LIST_KE_FORM_SUCCESS';
export const SUBMIT_BUY_LIST_KE_FORM_ERROR = '[BuyListKeForms] SUBMIT_BUY_LIST_KE_FORM_ERROR';

export const GET_BUY_LIST_KE_FORM_VALUE_WITH_CONFIG = '[BuyListKeForms] GET_BUY_LIST_KE_FORM_VALUE_WITH_CONFIG';
export const GET_BUY_LIST_KE_FORM_VALUE_WITH_CONFIG_SUCCESS = '[BuyListKeForms] GET_BUY_LIST_KE_FORM_VALUE_WITH_CONFIG_SUCCESS';
export const GET_BUY_LIST_KE_FORM_VALUE_WITH_CONFIG_ERROR = '[BuyListKeForms] GET_BUY_LIST_KE_FORM_VALUE_WITH_CONFIG_ERROR';

export class GetBuyListKeFormConfiguration implements Action {
  readonly type = GET_BUY_LIST_KE_FORM_CONFIG;
  constructor(public assetClass: string, public sedol: string) {}
}

export class GetBuyListKeFormConfigurationSuccess implements Action {
	readonly type = GET_BUY_LIST_KE_FORM_CONFIG_SUCCESS;
	constructor(public teamBuyList: TeamBuyList) {}
}

export class GetBuyListKeFormConfigurationError implements Action {
	readonly type = GET_BUY_LIST_KE_FORM_CONFIG_ERROR;
	constructor(public error: any) {}
}

export class SubmitBuyListKeForm implements Action {
  readonly type = SUBMIT_BUY_LIST_KE_FORM;
  constructor(public clientId: string, public accountId: string, public sedol: string,
    public buyList: BuyListKe) {}
}

export class SubmitBuyListKeFormSuccess implements Action {
  readonly type = SUBMIT_BUY_LIST_KE_FORM_SUCCESS;
  constructor() {}
}

export class SubmitBuyListKeFormError implements Action {
  readonly type = SUBMIT_BUY_LIST_KE_FORM_ERROR;
  constructor(public error: any) {}
}



export class GetBuyListKeFormValueWithConfig implements Action {
  readonly type = GET_BUY_LIST_KE_FORM_VALUE_WITH_CONFIG;
  constructor(public keId: number) {}
}

export class GetBuyListKeFormValueWithConfigSuccess implements Action {
	readonly type = GET_BUY_LIST_KE_FORM_VALUE_WITH_CONFIG_SUCCESS;
	constructor(public teamBuyList: TeamBuyList) {}
}

export class GetBuyListKeFormValueWithConfigError implements Action {
	readonly type = GET_BUY_LIST_KE_FORM_VALUE_WITH_CONFIG_ERROR;
	constructor(public error: any) {}
}

export type All =
GetBuyListKeFormConfiguration |
GetBuyListKeFormConfigurationSuccess |
GetBuyListKeFormConfigurationError |
SubmitBuyListKeForm |
SubmitBuyListKeFormSuccess |
SubmitBuyListKeFormError |
GetBuyListKeFormValueWithConfig |
GetBuyListKeFormValueWithConfigSuccess |
GetBuyListKeFormValueWithConfigError;
