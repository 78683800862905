import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as BuyListKeFormActions from './buy-list-ke-forms.actions';
import { BuyListService } from '../../../team-buylist/buy-list.service';
import { Store } from '@ngrx/store';
import { BuyListState } from '../../../team-buylist/store/buy-list.state';
import * as KnownExceptionActions from '../client-account/knownExceptions/knownExceptions.actions';
import * as CurrentPortfolioActions from '../client-account/account-current-portfolio/account-current-portfolio.actions';
import { NavActionService } from '../../../core/navigation-floating/nav-action.service';

export type BuyListKeFormAction = BuyListKeFormActions.All;

@Injectable()
export class BuyListKeFormsEffects {

  constructor(private actions$: Actions,
             private buyListService: BuyListService,
             private store: Store<BuyListState>,
             private navActionService: NavActionService) {}

	getBuyListKeFormConfiguration: Observable<BuyListKeFormAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(BuyListKeFormActions.GET_BUY_LIST_KE_FORM_CONFIG),
			switchMap(
        (a: BuyListKeFormActions.GetBuyListKeFormConfiguration) =>
        this.buyListService.getBuyListKeFormConfiguration(a.assetClass, a.sedol).pipe(
			    map(post => new BuyListKeFormActions.GetBuyListKeFormConfigurationSuccess(post)),
          catchError(error => {
            this.navActionService.addMessage('Error', 'An error occurred while retrieving form', 'error');
            return of(new BuyListKeFormActions.GetBuyListKeFormConfigurationError(error));
          })
        )
      )
    ));


    submitKe: Observable<BuyListKeFormAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(BuyListKeFormActions.SUBMIT_BUY_LIST_KE_FORM),
			switchMap(
        (a: BuyListKeFormActions.SubmitBuyListKeForm) =>
        this.buyListService.submitKe(a.clientId, a.accountId, a.sedol, a.buyList).pipe(
			    map(post => {
            this.store.dispatch(new KnownExceptionActions.GetKnownExceptions(a.clientId, a.accountId));
            this.store.dispatch(new CurrentPortfolioActions.GetAccountCurrentPortfolio(a.clientId, a.accountId, false));
            return new BuyListKeFormActions.SubmitBuyListKeFormSuccess();
          }),
          catchError(error => of(new BuyListKeFormActions.SubmitBuyListKeFormError(error)))
        )
      )
    ));

    getKeFormValuesWithConfig: Observable<BuyListKeFormAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(BuyListKeFormActions.GET_BUY_LIST_KE_FORM_VALUE_WITH_CONFIG),
			switchMap(
        (a: BuyListKeFormActions.GetBuyListKeFormValueWithConfig) =>
        this.buyListService.getTeamListKeFormValueWithConfig(a.keId).pipe(
			    map(post => new BuyListKeFormActions.GetBuyListKeFormValueWithConfigSuccess(post)),
          catchError(error => of(new BuyListKeFormActions.GetBuyListKeFormValueWithConfigError(error)))
        )
      )
    ));

}
