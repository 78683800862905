import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';
import { Observable, Subject } from 'rxjs';

export interface NavAction {
  title: string;
  status: string;
  disabled: boolean;
  hidden: boolean;
}

export interface NavActionHandler {
  setNavActions(actions: NavAction[]);
  handleNavAction(action: number): void;
}

@Injectable()
export class NavActionService {

  private statusSubject = new Subject<void | string>();
  private showActionsSubject = new Subject<boolean>();
  private busySubject = new Subject<boolean>();
  private messageSubject = new Subject<void | Message>();
  private actionSubject = new Subject<number>();
  private actionsSubject = new Subject<void | NavAction[]>();

  addMessage(title: string, message: string, status: string, isSticky: boolean = false) {
    this.changeStatus(status);
    const messageObject: Message = {severity: status, summary: title, detail: message};
    this.messageSubject.next(messageObject);
  }

  clearMessage() {
    this.clearStatus();
    this.messageSubject.next();
  }

  getMessage(): Observable<any> {
    return this.messageSubject.asObservable();
  }

  changeStatus(status: string) {
    this.statusSubject.next(status);
  }

  clearStatus() {
    this.statusSubject.next();
  }

  getStatus(): Observable<any> {
    return this.statusSubject.asObservable();
  }

  isBusy(busy: boolean) {
    this.busySubject.next(busy);
  }

  getBusy(): Observable<any> {
    return this.busySubject.asObservable();
  }

  showActions() {
    this.showActionsSubject.next(true);
  }
  getShowActions(): Observable<any> {
    return this.showActionsSubject.asObservable();
  }

  getAction(): Observable<any> {
    return this.actionSubject.asObservable();
  }

  clearActions() {
    this.actionsSubject.next();
  }

  setActions(actions: NavAction[]) {
    this.actionsSubject.next(actions);
  }

  getActions(): Observable<any> {
    return this.actionsSubject.asObservable();
  }

  triggerNavAction(action: number) {
    this.actionSubject.next(action);
  }

}
