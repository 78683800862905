import { Action } from '@ngrx/store';

import * as MonitorPortfolioActions from './monitor-portfolio.actions';
import { MonitorPortfolio } from '../../../entity/monitor-portfolio.model';
import { MonitorPortfolioState } from './monitor-portfolio.state';

export type MonitorPortfolioAction = MonitorPortfolioActions.All;
export const initialState: MonitorPortfolioState = { monitorPortfolio: new MonitorPortfolio(), loading: false};

export function monitorPortfolioReducer(state: MonitorPortfolioState = initialState, action: MonitorPortfolioAction):
  MonitorPortfolioState {
    switch (action.type) {

      case MonitorPortfolioActions.GET_MONITOR_PORTFOLIO: {
        return {...initialState, ...{loading: true}};

      }

      case MonitorPortfolioActions.GET_MONITOR_PORTFOLIO_SUCCESS: {
        return {...state, ...{monitorPortfolio: action.monitorPortfolio}, ...{loading: false}};
      }

      case MonitorPortfolioActions.GET_MONITOR_PORTFOLIO_ERROR: {
        console.error('Error caught while loading Client-Account-Monitor-Portfolio', action.error);
        return {...initialState};
      }

      default :
        return state;
    }
}
