import { Action } from '@ngrx/store';

import { TradeRationale } from '../../entity/trade-rationale.model';

export const GET_API_TRADE_RATIONALE = '[ApiTradeRationale] GET_API_TRADE_RATIONALE';
export const GET_API_TRADE_RATIONALE_SUCCESS = '[ApiTradeRationale] GET_API_TRADE_RATIONALE_SUCCESS';
export const GET_API_TRADE_RATIONALE_ERROR = '[ApiTradeRationale] GET_API_TRADE_RATIONALE_ERROR';

export class GetApiTradeRationale implements Action {
	readonly type = GET_API_TRADE_RATIONALE;
	constructor(public clientId: string, public accountId: string) {}
}

export class GetApiTradeRationaleSuccess implements Action {
	readonly type = GET_API_TRADE_RATIONALE_SUCCESS;
	constructor(public tradeRationale: TradeRationale) {}
}

export class GetApiTradeRationaleError implements Action {
	readonly type = GET_API_TRADE_RATIONALE_ERROR;
	constructor(public error: any) {}
}

export type All = GetApiTradeRationale
	| GetApiTradeRationaleSuccess
	| GetApiTradeRationaleError;
