import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import * as InterfaceOptionsActions from './interface-options.actions';
import { map, catchError, switchMap } from 'rxjs/operators';
import { NavActionService } from '../../../core/navigation-floating/nav-action.service';
import { ClientAapService } from '../../../clients/service/client-aap.service';
import { InterfaceOptionsService } from '../../../core/interface-options.service';

export type InterfaceOptionsAction = InterfaceOptionsActions.All;

@Injectable()
export class InterfaceOptionsEffects {

	constructor(private actions$: Actions,
              private interfaceService: InterfaceOptionsService,
              private clientAapService: ClientAapService,
              private navActionService: NavActionService) {
	}

  getAssetColumns: Observable<InterfaceOptionsAction> = createEffect(() =>
  this.actions$
    .pipe(
      ofType(InterfaceOptionsActions.GET_ASSET_COLUMNS),
      switchMap((a: InterfaceOptionsActions.GetAssetColumns) =>  this.interfaceService.getInterfaceOptions(a.category).pipe(
      map(post => new InterfaceOptionsActions.GetAssetColumnsSuccess(post)),
      catchError((error) => of(new InterfaceOptionsActions.GetAssetColumnsError(error)))
    )
	)));

  getInterfaceOptions: Observable<InterfaceOptionsAction> = createEffect(() =>
  this.actions$
    .pipe(
      ofType(InterfaceOptionsActions.GET_INTERFACE_VIEWS),
      switchMap((a: InterfaceOptionsActions.GetInterfaceViews) =>  this.interfaceService.getInterfaceViewsList(a.category).pipe(
      map(post => new InterfaceOptionsActions.GetInterfaceViewsSuccess(post)),
      catchError((error) => of(new InterfaceOptionsActions.GetInterfaceViewsError(error)))
    )
	)));

  deleteInterfaceOption: Observable<InterfaceOptionsAction> = createEffect(() =>
  this.actions$
    .pipe(
      ofType(InterfaceOptionsActions.DELETE_INTERFACE_VIEW),
      switchMap((a: InterfaceOptionsActions.DeleteInterfaceView) => this.interfaceService.deleteInterfaceView(a.view).pipe(
      switchMap(post => [
        new InterfaceOptionsActions.DeleteInterfaceViewSuccess(),
        new InterfaceOptionsActions.GetAssetColumns(a.category),
        new InterfaceOptionsActions.GetInterfaceViews(a.category)
      ]),
      catchError((error) => of(new InterfaceOptionsActions.DeleteInterfaceViewError(error)))
    )
  )));

  saveInterfaceOption: Observable<InterfaceOptionsAction> = createEffect(() =>
  this.actions$
    .pipe(
      ofType(InterfaceOptionsActions.SAVE_INTERFACE_VIEW),
      switchMap((a: InterfaceOptionsActions.SaveInterfaceView) => this.interfaceService.saveInterfaceView(a.view, a.category).pipe(
      switchMap(post => {
        const actions = [
          new InterfaceOptionsActions.SaveInterfaceViewSuccess(),
          new InterfaceOptionsActions.GetInterfaceViews(a.category),
          new InterfaceOptionsActions.GetAssetColumns(a.category)
        ];

        if (!a.loadDefault) {
          actions.splice(-1, 1);
        }

        return actions;
      }),
      catchError((error) => {
        let errorMessage = 'An error occurred';
        if (error.error && error.error.message) {
          errorMessage = error.error.message;
        }
        this.navActionService.addMessage('Error', errorMessage, 'error');
        return of(new InterfaceOptionsActions.SaveInterfaceViewError(error));
      })
    ))
  ));

  deleteInterfaceOptionSuccess = createEffect(() =>
  this.actions$
    .pipe(
      ofType(InterfaceOptionsActions.DELETE_INTERFACE_VIEW_SUCCESS),
      map(() => this.navActionService.addMessage('Success', 'Your profile view was successfully deleted', 'success'))
    ), {dispatch: false});

  saveInterfaceOptionSuccess = createEffect(() =>
  this.actions$
    .pipe(
      ofType(InterfaceOptionsActions.SAVE_INTERFACE_VIEW_SUCCESS),
      map(() => this.navActionService.addMessage('Success', 'Your profile view was successfully saved', 'success'))
    ), {dispatch: false});
}
