import { IprFileNotesState } from './ipr-file-notes/ipr-file-notes.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BmidHoldingListState } from './bmid-holding-list/bmid-holding-list.state';
import { AlertState } from './alerts/alerts.state';
import { IssueState } from './issues/issues.state';
import { ProfileState } from './profile/profile.state';
import { MonitorFlagState } from './monitor-flags/monitor-flag.state';
import { ModelState } from './models/models.state';
import { AABoundaryDeviationState } from './aaBoundaryDeviation/aaBoundaryDeviation.state';
import { AssetRiskContributionState } from './assetRiskContribution/assetRiskContribution.state';
import { SearchResultsState } from './search/search-results/search-results.state';
import { ReportsState } from './reports/reports.state';
import { SearchMetaDataState } from './search/search-criteria/search-criteria.state';
import { MonitorFlagTypeState } from './monitor-flag-type/monitor-flag-type.state';
import { MonitorPortfolioState } from './client-account/monitor-portfolio/monitor-portfolio.state';
import { KnownExceptionState } from './client-account/knownExceptions/knownExceptions.state';
import { AccountCurrentPortfolioState } from './client-account/account-current-portfolio/account-current-portfolio.state';
import { KnownExceptionTypesState } from './client-account/knownExceptionTypes/knownExceptionTypes.state';
import { InterfaceState } from './interface-options/interface-options.state';
import { TradeCashDetailsState } from './trade-cash-details/trade-cash-details.state';
import { TradeRationaleState } from './trade-rationale/trade-rationale.state';
import { AnalyticsState } from './analytics/analytics.state';
import { AssetListState } from './asset-list/asset-list.state';
import { PortfolioConstructionModelState } from './portfolioConstructionModel/portfolioConstructionModel.state';
import { ClientDetailsState } from './client-details/client-details.state';
import { ClientAccountDetailsState } from './client-account-details/client-account-details.state';
import { ApiTradeRationaleState } from './api-trade-rationale/api-trade-rationale.state';
import { SearchFormState } from '../../search/store/search-form/search-form.state';
import { PMPAccountSearchResultsState } from './pmp-search-results/pmp-search-results.state';
import { BuyListKeFormsState } from './buy-list-ke-forms/buy-list-ke-forms.state';
import { SearchResultFormState } from '../../search/store/search-result-form/search-result-form.state';
import { ClientAapState } from '../../clients/store/client-aap/client-aap.state';
import { ReadOnlyPortfolioState } from './read-only-portfolio/readOnlyPortfolio.state';
import { DataModelResultListState } from './data-model-selection/data-model-selection.state';

export interface SharedAppData {
  bmidHoldingList: BmidHoldingListState;
  alerts: AlertState;
  issues: IssueState;
  profile: ProfileState;
  monitorFlags: MonitorFlagState;
  models: ModelState;
  aaBoundaryDeviations: AABoundaryDeviationState;
  assetRiskContributions: AssetRiskContributionState;
  searchResults: SearchResultsState;
  pmpAccountSearchResults: PMPAccountSearchResultsState;
  reports: ReportsState;
  searchMetaData: SearchMetaDataState;
  monitorFlagTypes: MonitorFlagTypeState;
  monitorPortfolio: MonitorPortfolioState;
  knownExceptions: KnownExceptionState;
  accountCurrentPortfolio: AccountCurrentPortfolioState;
  knownExceptionTypes: KnownExceptionTypesState;
  interfaceOptions: InterfaceState;
  tradeCashDetails: TradeCashDetailsState;
  tradeRationale: TradeRationaleState;
  apiTradeRationale: ApiTradeRationaleState;
  analytics: AnalyticsState;
  portfolioConstructionModel: PortfolioConstructionModelState;
  assetList: AssetListState;
  riskModelList: DataModelResultListState;
  alphaModelList: DataModelResultListState;
  clientDetails: ClientDetailsState;
  clientAccountDetails: ClientAccountDetailsState;
  iprFileNotes: IprFileNotesState;
  pmpAccountSearchForm: SearchFormState;
  buyListKeForms: BuyListKeFormsState;
  assetsSearchResultForm: SearchResultFormState;
  assetSeachCriteriaForm: SearchFormState;
  pmpAccountSearchResultForm: SearchResultFormState;
  clientAap: ClientAapState;
  readOnlyPortfolio: ReadOnlyPortfolioState;
}

export const getSharedState = createFeatureSelector<SharedAppData>('shared');

export const getBmidHoldingList = createSelector(getSharedState,
  (state: SharedAppData) => state.bmidHoldingList);

export const getAlerts = createSelector(getSharedState,
  (state: SharedAppData) => state.alerts);

export const getIssues = createSelector(getSharedState,
  (state: SharedAppData) => state.issues);

export const getProfile = createSelector(getSharedState,
  (state: SharedAppData) => state.profile);

export const getMonitorFlags = createSelector(getSharedState,
  (state: SharedAppData) => state.monitorFlags);

export const getMonitorFlagTypes = createSelector(getSharedState,
  (state: SharedAppData) => state.monitorFlagTypes);

export const getModels = createSelector(getSharedState,
  (state: SharedAppData) => state.models);

export const getAABoundaryDeviations = createSelector(getSharedState,
  (state: SharedAppData) => state.aaBoundaryDeviations);

export const getAssetRiskContributions = createSelector(getSharedState,
  (state: SharedAppData) => state.assetRiskContributions);

export const getReports = createSelector(getSharedState,
  (state: SharedAppData) => state.reports);

export const getSearchMetaDataState = createSelector(getSharedState,
  (state: SharedAppData) => state.searchMetaData);

export const getSearchResults = createSelector(getSharedState,
  (state: SharedAppData) => state.searchResults);

export const getPMPAccountSearchResults = createSelector(getSharedState,
  (state: SharedAppData) => state.pmpAccountSearchResults);

export const getMonitorPortfolio = createSelector(getSharedState,
  (state: SharedAppData) => state.monitorPortfolio);

export const getKnownExceptions = createSelector(getSharedState,
  (state: SharedAppData) => state.knownExceptions);

export const getKnownExceptionTypes = createSelector(getSharedState,
  (state: SharedAppData) => state.knownExceptionTypes);

export const getAccountCurrentPortfolio = createSelector(getSharedState,
  (state: SharedAppData) => state.accountCurrentPortfolio);

export const getAccountOverviewConfig = createSelector(getSharedState,
  (state: SharedAppData) => state.accountCurrentPortfolio.accountOverviewConfig);

export const getInterfaceOptions = createSelector(getSharedState,
  (state: SharedAppData) => state.interfaceOptions);

export const getTradeCashDetails = createSelector(getSharedState,
  (state: SharedAppData) => state.tradeCashDetails);

export const getTradeRationale = createSelector(getSharedState,
  (state: SharedAppData) => state.tradeRationale);

export const getApiTradeRationale = createSelector(getSharedState,
  (state: SharedAppData) => state.apiTradeRationale);

export const getAnalytics = createSelector(getSharedState,
  (state: SharedAppData) => state.analytics);

export const getClientDetails = createSelector(getSharedState,
  (state: SharedAppData) => state.clientDetails);

export const getClientAccountDetails = createSelector(getSharedState,
  (state: SharedAppData) => state.clientAccountDetails);

export const getPortfolioConstructionModel = createSelector(getSharedState,
  (state: SharedAppData) => state.portfolioConstructionModel);

export const getAssetList = createSelector(getSharedState,
  (state: SharedAppData) => state.assetList);

export const getRiskModelList = createSelector(getSharedState,
  (state: SharedAppData) => state.riskModelList)

export const getAlphaModelList = createSelector(getSharedState,
  (state: SharedAppData) => state.alphaModelList)

export const getIprFileNotesConfig = createSelector(getSharedState,
  (state: SharedAppData) => state.iprFileNotes.config);

export const getPMPAccountSearchForm = createSelector(getSharedState,
  (state: SharedAppData) => state.pmpAccountSearchForm);

export const getBuyListKeFormConfiguration = createSelector(getSharedState,
  (state: SharedAppData) => state.buyListKeForms);

export const getAssetsSearchResultForm = createSelector(getSharedState,
  (state: SharedAppData) => state.assetsSearchResultForm);

export const getAssetSeachCriteriaForm = createSelector(getSharedState,
  (state: SharedAppData) => state.assetSeachCriteriaForm);

export const getPMPAccountSearchResultForm = createSelector(getSharedState,
  (state: SharedAppData) => state.pmpAccountSearchResultForm);

export const getClientAap = createSelector(getSharedState,
  (state: SharedAppData) => state.clientAap);

export const getReadOnlyPortfolio = createSelector(getSharedState,
  (state: SharedAppData) => state.readOnlyPortfolio);
