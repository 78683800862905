import { Action } from '@ngrx/store';
import * as MonitorFlag from '../../entity/monitor-flag-const.model';
import { MonitorFlagState } from './monitor-flag.state';

export const GET_ALL_WIDGET_MONITOR_FLAGS = '[MonitorFlag] GET_ALL_WIDGET_MONITOR_FLAGS';
export const GET_ALL_WIDGET_MONITOR_FLAGS_SUCCESS = '[MonitorFlag] GET_ALL_WIDGET_MONITOR_FLAGS_SUCCESS';
export const GET_ALL_WIDGET_MONITOR_FLAGS_ERROR = '[MonitorFlag] GET_ALL_WIDGET_MONITOR_FLAGS_ERROR';


export class GetAllWidgetMonitorFlags implements Action {
	readonly type = GET_ALL_WIDGET_MONITOR_FLAGS;
	constructor(public clientId: string, public accountId: string) {}
}

export class GetAllWidgetMonitorFlagsSuccess implements Action {
	readonly type = GET_ALL_WIDGET_MONITOR_FLAGS_SUCCESS;
	constructor(public monitorFlagState: MonitorFlagState) {}
}

export class GetAllWidgetMonitorFlagsError implements Action {
	readonly type = GET_ALL_WIDGET_MONITOR_FLAGS_ERROR;
	constructor(public error: any) {}
}

export type All = GetAllWidgetMonitorFlags | GetAllWidgetMonitorFlagsSuccess | GetAllWidgetMonitorFlagsError;
