import { AccountOverviewConfig } from '../entity/account/accountOverviewConfig.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, empty } from 'rxjs';

import { JsonConvert } from 'json2typescript';

import { JsonConvertService } from '../json-convert/json-convert.service';
import { MonitorPortfolio } from '../entity/monitor-portfolio.model';
import { CurrentPortfolioModel } from '../entity/current-portfolio-model.model';
import { environment } from '../../../environments/environment';
import { ReportCreation } from '../entity/report-creation.model';
import { map, catchError } from 'rxjs/operators';
import { MoveToNewMandateAccountParam } from '../entity/move-to-new-mandate-account.model';
import { MandateDestinationSearch, AccountMoveData } from '../entity/move-to-existing-mandate.model';
import { NavActionService } from '../../core/navigation-floating/nav-action.service';
import { AppRouterService } from '../router/app-router.service';
import { ClientDestinationSearch, MandateMoveData } from '../entity/move-to-existing-client.model';
import { AccountOverview } from '../entity/account/accountOverview.model';

@Injectable()
export class AccountService {

  private jsonConverter: JsonConvert;

  constructor(private httpClient: HttpClient,
    private jsonConvertService: JsonConvertService,
    private navActionService: NavActionService,
    private appRouterService: AppRouterService) {
    this.jsonConverter =  this.jsonConvertService.getJsonConverter();
  }

  getMonitorPortfolio(clientId: string, accountId: string): Observable<MonitorPortfolio> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/client/${clientId}/account/${encodeURIComponent(accountId)}`).pipe(
      map(response => {
        return this.jsonConverter.deserializeObject(response, MonitorPortfolio);
      }));
    }

  getReportInfo(clientId: string, accountId: string, reportName: string): Observable<ReportCreation> {
    return this.httpClient
      .get(`${environment.serverUrl}/restapi/service/report/client/${clientId}/account/${encodeURIComponent(accountId)}/${reportName}/create`).pipe(
      map(response => {
        return this.jsonConverter.deserializeObject(response, ReportCreation);
      }));
  }

  getScenarioReportInfo(clientId: string, accountId: string, scenarioId: number, reportName: string): Observable<ReportCreation> {
    const path = `portfolio/client/${clientId}/account/${encodeURIComponent(accountId)}/scenario/${scenarioId}/report/${reportName}`;
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/${path}`).pipe(
      map(response => {
        return this.jsonConverter.deserializeObject(response, ReportCreation);
      }));
  }
  submitFileNotesAndScenarioReportInfo(clientId: string, accountId: string, scenarioId: number,
     reportName: string, fileNotes: Map<String, String>): Observable<ReportCreation> {
    const path = `portfolio/client/${clientId}/account/${encodeURIComponent(accountId)}/scenario/${scenarioId}/report/${reportName}`;
    return this.httpClient.post(`${environment.serverUrl}/restapi/service/${path}`, fileNotes).pipe(
      map(response => {
        return this.jsonConverter.deserializeObject(response, ReportCreation);
      }));
  }

  getReport(fileName: string): any {
    return this.httpClient
      .get(`${environment.serverUrl}/restapi/service/report/${fileName}/retrieval`,
      { responseType: 'blob' as 'json', observe: 'response'}).pipe(
      map((response: HttpResponse<Blob>) => {
        return response;
      }));
  }

  getCurrentPortfolio(clientId: string, accountId: string, lookThroughEnabled: boolean) {

    let url = `${environment.serverUrl}/restapi/service/account/portfolio/client/${clientId}/account/${encodeURIComponent(accountId)}`;

    if (lookThroughEnabled) {
      url += '/lookthrough';
    }

    return this.httpClient.get(url).pipe(
    map(response => {
      return this.jsonConverter.deserializeObject(response, CurrentPortfolioModel);
    }));
  }

  moveFlatAccountToNewMandate(moveToNewMandateForm: MoveToNewMandateAccountParam) {
    const url = `${environment.serverUrl}/restapi/service/account/move/newmandate`;
    return this.httpClient.post(url, moveToNewMandateForm).pipe(
      map(response => {
        this.navActionService.addMessage('Success', 'Account has been moved.', 'success');
        this.appRouterService.clientHomePage(moveToNewMandateForm.sourceClientDisplayId);
        return response;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.navActionService.addMessage('Error', 'An error occurred while moving to new Mandate', 'error');
          console.error('An error occurred: ', err.error.message);
        } else {
          // TODO: Send error message to polo mint or notification service when it's implemented
          this.navActionService.addMessage('Error', err.error.message, 'error');
          console.error(`Backend returned code ${err.status}, error was: ${err.error.message}`);
        }
        // empty observable
        return empty();
      }));
  }

  moveFlatAccountToExistingMandate(accountMoveData: AccountMoveData) {
    const url = `${environment.serverUrl}/restapi/service/account/move`;
    return this.httpClient.post(url, accountMoveData).pipe(
      map(response => {
        this.navActionService.addMessage('Success', 'Account has been moved to: ' + accountMoveData.destination.aid, 'success');
        this.appRouterService.clientHomePage(accountMoveData.destination.cid);
        return response;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.navActionService.addMessage('Error', 'An error occurred while moving to: ' + accountMoveData.destination.aid, 'error');
          console.error('An error occurred: ', err.error.message);
        } else {
          this.navActionService.addMessage('Error', err.error.message, 'error');
          console.error(`Backend returned code ${err.status}, error was: ${err.error.message}`);
        }
        // empty observable
        return empty();
      }));
  }

  moveMandateAccountToExistingClient(moveData: MandateMoveData) {
    const url = `${environment.serverUrl}/restapi/service/account/move/mandate`;
    return this.httpClient.post(url, moveData).pipe(
      map(response => {
        this.navActionService.addMessage('Success', moveData.sourceMandateId +
          ' has been moved from ' + moveData.sourceClientId + ' to: ' + moveData.destinationClientId, 'success');
        this.appRouterService.clientHomePage(moveData.destinationClientId);
        return response;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.navActionService.addMessage('Error', 'An error occurred while Mandate Account moving to: '
            + moveData.destinationClientId, 'error');
          console.error('An error occurred: ', err.error.message);
        } else {
          this.navActionService.addMessage('Error', err.error.message, 'error');
          console.error(`Backend returned code ${err.status}, error was: ${err.error.message}`);
        }
        // empty observable
        return empty();
      }));
  }

  searchAvailableMandateDestinationByClientId(sourceClientId: string, accountName: string, targetCientId: string) {
    const url = `${environment.serverUrl}` +
      `/restapi/service/account/move/search/availablemandates/${accountName}/${sourceClientId}/${targetCientId}`;
    return this.httpClient.get(url).pipe(
      map(response => {
        return this.jsonConverter.deserializeObject(response, MandateDestinationSearch);
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.error('An error occurred: ', err.error.message);
        } else {
          // TODO: Send error message to polo mint or notification service when it's implemented
          console.error(`Backend returned code ${err.status}, error was: ${err.error.message}`);
        }
        // empty observable
        return empty();
      }));
  }

  searchAvailableClientDestination(clientId: string, clientName: string) {
    const url = `${environment.serverUrl}` +
      `/restapi/service/account/move/search/availableclients?clientId=${clientId}&clientName=${clientName}`;
    return this.httpClient.get(url).pipe(
      map(response => {
        return this.jsonConverter.deserializeObject(response, ClientDestinationSearch);
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.error('An error occurred: ', err.error.message);
        } else {
          // TODO: Send error message to polo mint or notification service when it's implemented
          console.error(`Backend returned code ${err.status}, error was: ${err.error.message}`);
        }
        // empty observable
        return empty();
      }));
  }

  getAccountOverviewConfig(clientId: string, accountId: string): Observable<AccountOverviewConfig> {
    const url = `${environment.serverUrl}/restapi/service/account/portfolio/client/${clientId}/account/${encodeURIComponent(accountId)}/accountOverview/config`;

    return this.httpClient.get(url).pipe(
    map(response => {
      return this.jsonConverter.deserializeObject(response, AccountOverviewConfig);
    }));
  }


  createMandate(clientId: string, accountOverview: AccountOverview): Observable<any> {
    const url = `${environment.serverUrl}/restapi/service/account/portfolio/client/${clientId}`;

    return this.httpClient.post(url, {description: accountOverview.name, currencyCode: accountOverview.currencyCode,
      source: accountOverview.source});
  }

  addFlatAccount(clientId: string, bmid: string, accountOverview: AccountOverview): Observable<any> {
    const url = `${environment.serverUrl}/restapi/service/account/portfolio/client/${clientId}/${bmid}`;

    return this.httpClient.post(url, {externalId: accountOverview.externalId, description: accountOverview.name,
      currencyCode: accountOverview.currencyCode, source: accountOverview.source}).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            this.navActionService.addMessage('Error', 'An error occurred while creating account', 'error');
            console.error('An error occurred: ', err.error.message);
          } else {
            this.navActionService.addMessage('Error', err.error.message, 'error');
            console.error(`Backend returned code ${err.status}, error was: ${err.error.message}`);
          }
          // empty observable
          return empty();
        }));
  }

  updateMandate(clientId: string, accountId: string, current: AccountOverview, update: AccountOverview): Observable<any> {
    const url = `${environment.serverUrl}/restapi/service/account/portfolio/client/${clientId}/account/${encodeURIComponent(accountId)}`;
    const payload = {current: {externalId: current.externalId, description: current.name, source: current.source, riskCategory: current.riskCategory, accountTaxWrapper: current.accountTaxWrapper},
    update: {externalId: update.externalId, description: update.name, source: update.source, riskCategory: update.riskCategory, accountTaxWrapper: update.accountTaxWrapper}};
    return this.httpClient.put(url, payload);
  }

  updateAccountId(clientId: string, accountId: string, newAccountId: string): Observable<any> {
    const url =
    `${environment.serverUrl}/restapi/service/account/portfolio/client/${clientId}/account/${encodeURIComponent(accountId)}/newAccount/${newAccountId}`;
    return this.httpClient.put(url, null).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.navActionService.addMessage('Error', 'An error occurred while updating account ID', 'error');
          console.error('An error occurred: ', err.error.message);
        } else {
          this.navActionService.addMessage('Error', err.error.message, 'error');
          console.error(`Backend returned code ${err.status}, error was: ${err.error.message}`);
        }
        // empty observable
        return empty();
      }));
  }

  updateFlatAccount(clientId: string, accountId: string, update: AccountOverview): Observable<any> {
    const url =
    `${environment.serverUrl}/restapi/service/account/portfolio/client/${clientId}/account/${encodeURIComponent(accountId)}/update`;
    const payload = {accountId: update.externalId, name: update.name};
    return this.httpClient.put(url, payload).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.navActionService.addMessage('Error', 'An error occurred while updating account', 'error');
          console.error('An error occurred: ', err.error.message);
        } else {
          this.navActionService.addMessage('Error', err.error.message, 'error');
          console.error(`Backend returned code ${err.status}, error was: ${err.error.message}`);
        }
        // empty observable
        return empty();
      }));
  }

  saveCurrentPortfolio(currentPortfolio: CurrentPortfolioModel): Observable<CurrentPortfolioModel> {
    const serializedPortfolio = this.jsonConverter.serializeObject(currentPortfolio);
    const url = `${environment.serverUrl}/restapi/service/account/portfolio/save`;
    return this.httpClient.post(url, serializedPortfolio)
    .pipe(
      map(response => {
        return this.jsonConverter.deserializeObject(response, CurrentPortfolioModel);
      }));

  }

  isAccountNameUnique(cid: string, accountName: string): Observable<Object> {
    return this.httpClient.post(`${environment.serverUrl}/restapi/service/account/verify/client/${cid}/account`,
    accountName);
  }
}
