import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ReadOnlyPortfolioService } from '../../service/read-only-portfolio.service';
import * as PcmActions from './readOnlyPortfolio.actions';

export type PcmAction = PcmActions.All;

@Injectable()
export class ReadOnlyPortfolioEffects {

    constructor(private actions$: Actions,
        private readOnlyPortfolioService: ReadOnlyPortfolioService) {}

    getReadOnlyPortfolioModel: Observable<PcmAction> = createEffect(() => 
    this.actions$.pipe(
    ofType(PcmActions.GET_READ_ONLY_PORTFOLIO_MODEL),
		switchMap((a: PcmActions.GetReadOnlyPortfolioModel) =>
			this.readOnlyPortfolioService.getReadOnlyPortfolioModel(a.clientId, a.accountId, a.scenarioName).pipe(
				map(pcm => new PcmActions.GetReadOnlyPortfolioModelSuccess(pcm)),
        catchError((error) => of(new PcmActions.GetReadOnlyPortfolioModelError(error)))
      ))));
}
