import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('RiskBoundary')
export class RiskBoundary {

	@JsonProperty('lower', Number)
	lower: number;

	@JsonProperty('upper', Number)
	upper: number;
}
