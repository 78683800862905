import { Action } from '@ngrx/store';

import { MonitorPortfolio } from '../../../entity/monitor-portfolio.model';

export const GET_MONITOR_PORTFOLIO = '[MonitorPortfolio] GET_MONITOR_PORTFOLIO';
export const GET_MONITOR_PORTFOLIO_SUCCESS = '[MonitorPortfolio]  GET_MONITOR_PORTFOLIO_SUCCESS';
export const GET_MONITOR_PORTFOLIO_ERROR = '[MonitorPortfolio] GET_MONITOR_PORTFOLIO_ERROR';

export class GetMonitorPortfolio implements Action {
	readonly type = GET_MONITOR_PORTFOLIO;
	constructor(public clientId: string, public accountId: string) {}
}

export class GetMonitorPortfolioSuccess implements Action {
	readonly type = GET_MONITOR_PORTFOLIO_SUCCESS;
	constructor(public monitorPortfolio: MonitorPortfolio) {}
}

export class GetMonitorPortfolioError implements Action {
	readonly type = GET_MONITOR_PORTFOLIO_ERROR;
	constructor(public error: any) {}
}

export type All = GetMonitorPortfolio | GetMonitorPortfolioSuccess | GetMonitorPortfolioError;
