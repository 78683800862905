import { Action } from '@ngrx/store';

import * as AssetRiskContributionActions from './assetRiskContribution.actions';
import { AssetRiskContribution } from '../../entity/asset-risk-contribution.model';
import { AssetRiskContributionState } from './assetRiskContribution.state';

export type AssetRiskContributionAction = AssetRiskContributionActions.All;
export const initialState: AssetRiskContributionState = { tacLevelAssetRiskContribution : new Array<AssetRiskContribution>()};

export function assetRiskContributionReducer(state: AssetRiskContributionState = initialState,
   action: AssetRiskContributionAction): AssetRiskContributionState {
  switch (action.type) {

    case AssetRiskContributionActions.GET_TAC_LEVEL_ASSET_RISK_CONTRIBUTION_SUCCESS: {
      return {...state, ...{tacLevelAssetRiskContribution: action.assetRiskContributions}};
    }

    case AssetRiskContributionActions.GET_TAC_LEVEL_ASSET_RISK_CONTRIBUTION_ERROR: {
      console.error('Error caught while loading Asset Allocation Risk Contributions', action.error);
      return {...initialState};
    }

    default :
      return state;

  }

}
