
import { Configuration } from '../entity/configuration.model';
import { Component, OnInit, ChangeDetectionStrategy, HostListener, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { NavigationComponent } from '../navigation/navigation.component';
import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getAuthenticationState } from '../store/appState.model';
import { AuthenticationState } from '../store/auth/authentication.state';
import * as AuthenticationActions from '../store/auth/authentication.actions';
import { AuthenticationService } from '../../auth/authentication.service';
import { AppRouterService } from '../../shared/router/app-router.service';
import { Session } from '../entity/session.model';
import { take, takeUntil, map } from 'rxjs/operators';
import { SettingsService } from '../settingsService.service';
import * as ConfigurationKeys from '../entity/configuration.const';
import { Authority } from '../entity/authority.enum';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DashModalComponent } from '../../dash/dash-modal/dash-modal.component';

@Component({
  selector: 'app-navigation-main',
  templateUrl: './navigation-main.component.html',
  styleUrls: ['./navigation-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NavigationMainComponent extends NavigationComponent implements OnInit, OnDestroy {

  public static readonly DASH = 'dash';
  public static readonly CLIENT = 'client';
  public static readonly NEW_CLIENT = 'newClient';
  public static readonly MONITOR = 'monitor';
  public static readonly ADMIN = 'admin';
  public static readonly APPROVALS = 'approvals';
  public static readonly SEARCH = 'search';
  public static readonly MODELS = 'models';
  public static readonly BULK = 'bulk';
  public static readonly BUY_LIST = 'buyList';

  showQuickSearchBox: boolean;
  sessionDetails: Observable<AuthenticationState>;
  private authorities: String[] = [];
  private configuration: Configuration = new Configuration();
  private adminUrl = `${environment.serverUrl}/admin/dashboard`;

  isNavigationVisible: Boolean;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  protected isNewClientVisible$: Observable<boolean>;

  protected isBulkTradeVisible$: Observable<boolean>;

  protected isAnyConfigurableItemVisible$: Observable<boolean>;

  dashNavigationEnabled: boolean;
  clientNavigationEnabled: boolean;
  searchNavigationEnabled: boolean;

  bsModalRef: BsModalRef;

  navItems = [
    {
      'navId': NavigationMainComponent.DASH,
      'url': 'dash',
      'title': 'Dash'
    },
    {
      'navId': NavigationMainComponent.NEW_CLIENT,
      'url': 'newClient',
      'title': 'New'
    },
    {
      'navId': NavigationMainComponent.CLIENT,
      'url': 'client',
      'title': 'Client'
    },
    {
      'navId': NavigationMainComponent.MONITOR,
      'url': 'monitor',
      'title': 'Mon'
    },
    {
      'navId': NavigationMainComponent.ADMIN,
      'url': 'admin',
      'title': 'Admin'
    },
    {
      'navId': NavigationMainComponent.APPROVALS,
      'url': 'approvals',
      'title': 'Approve'
    },
    {
      'navId': NavigationMainComponent.SEARCH,
      'url': 'search',
      'title': 'Search'
    },
    {
      'navId': NavigationMainComponent.MODELS,
      'url': 'models',
      'title': 'Models'
    },
    {
      'navId': NavigationMainComponent.BULK,
      'url': 'bulk',
      'title': 'Bulk'
    }
    ,
    {
      'navId': NavigationMainComponent.BUY_LIST,
      'url': 'buyList',
      'title': 'Buy List'
    }
  ];

  constructor(private store: Store<any>, private authenticationService: AuthenticationService,
    private appRouterService: AppRouterService, private settingService: SettingsService,
    private modalService: BsModalService) {
    super();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    const obs = this.authenticationService.checkSingleSignOn().pipe(take(1), map((session: Session) => {
      if (session && session.user && session.user.userName
        && 'anonymousUser' !== session.user.userName && '' !== session.user.userName) {
        this.store.dispatch(new AuthenticationActions.CheckSingleSignOnSuccess(session));
      } else {
        this.store.dispatch(new AuthenticationActions.CheckSingleSignOnError(session));
        this.appRouterService.loginPage({});
      }
    }));
    obs.subscribe();
  }

  ngOnInit() {
    this.sessionDetails = this.store.select(getAuthenticationState);

    this.sessionDetails.pipe(takeUntil(this.ngUnsubscribe)).subscribe(session => {
      if (session.session && session.session.user) {
        this.authorities = session.session.user.authorities;
        this.isNavigationVisible = true;

        this.showQuickSearchBox = this.authorities.indexOf(Authority.ROLE_USER) !== -1;
      } else {
        this.authorities = [];
        this.isNavigationVisible = false;
      }

      if (session.session && session.session.configuration) {
        this.configuration = session.session.configuration;
      } else {
        this.configuration = null;
      }
    });

    this.settingService.getConfig().pipe(takeUntil(this.ngUnsubscribe)).subscribe((config: Configuration) => {
      if (config) {
        this.dashNavigationEnabled = config.getConfig(ConfigurationKeys.DASH_NAVIGATION_ENABLED) === 'false' ? false : true;
        this.clientNavigationEnabled = config.getConfig(ConfigurationKeys.CLIENT_NAVIGATION_ENABLED) === 'true' ? true : false;
        this.searchNavigationEnabled = config.getConfig(ConfigurationKeys.SEARCH_NAVIGATION_ENABLED) === 'true' ? true : false;
      }});

    this.isNewClientVisible$ = this.settingService.getConfig().pipe(map(config =>
      config.getConfig(ConfigurationKeys.NEW_CLIENT_ENABLED) === 'true' ? true : false));

    this.isBulkTradeVisible$ = this.settingService.getConfig().pipe(map(config =>
      config.getConfig(ConfigurationKeys.BULK_TRADE_ENABLED) === 'true' ? true : false));

    this.isAnyConfigurableItemVisible$ = this.settingService.getConfig().pipe(map(config =>
      config.getConfig(ConfigurationKeys.NEW_CLIENT_ENABLED) === 'true' ||
      config.getConfig(ConfigurationKeys.BULK_TRADE_ENABLED) === 'true' ));
  }

  isConfigurableItem(navId) {
    switch (navId) {
      case NavigationMainComponent.NEW_CLIENT:
      case NavigationMainComponent.BULK:
        return true;
      default:
        return false;
    }
  }

  isConfigurableItemAvailable(navId, session) {
    if (session == null || !this.isConfigurableItem(navId) || !this.userHasPermission(navId, session)) {
      return false;
    }
    if (this.configuration != null) {
      switch (navId) {
        case NavigationMainComponent.NEW_CLIENT:
          return this.configuration.getConfig(ConfigurationKeys.NEW_CLIENT_ENABLED) === 'true';
        case NavigationMainComponent.BULK:
          return this.configuration.getConfig(ConfigurationKeys.BULK_TRADE_ENABLED) === 'true';
        default:
          return false;
      }
    }
  }

  userHasPermission(navId, session) {

    if (this.authorities.length === 0) {
      return false;
    }

    const hasUserRole = this.authorities.indexOf(Authority.ROLE_USER) !== -1;
    const hasProxyUserRole = this.authorities.indexOf(Authority.ROLE_PROXY_USER) !== -1;

    switch (navId) {
      case NavigationMainComponent.DASH:
        return hasUserRole && this.dashNavigationEnabled;
      case NavigationMainComponent.CLIENT:
        return (hasUserRole && this.clientNavigationEnabled) || hasProxyUserRole;
      case NavigationMainComponent.NEW_CLIENT:
        return hasUserRole &&
          this.authorities.indexOf(Authority.AUTHORITY_CLIENT_CREATE) !== -1;
      case NavigationMainComponent.MONITOR:
        return hasUserRole &&
          this.authorities.indexOf(Authority.AUTHORITY_MONITOR) !== -1;
      case NavigationMainComponent.ADMIN:
        return hasUserRole &&
          this.authorities.indexOf(Authority.AUTHORITY_ADMIN_CLIENT_LIST_R) !== -1 &&
          this.authorities.indexOf(Authority.AUTHORITY_ADMIN_CLIENT_R) !== -1 &&
          this.authorities.indexOf(Authority.AUTHORITY_ADMIN_ENTITIES_R) !== -1 &&
          this.authorities.indexOf(Authority.AUTHORITY_ADMIN_GROUPS_R) !== -1 &&
          this.authorities.indexOf(Authority.AUTHORITY_ADMIN_USERS_R) !== -1;
      case NavigationMainComponent.APPROVALS:
        return hasUserRole &&
          this.authorities.indexOf(Authority.AUTHORITY_APPROVAL) !== -1;
      case NavigationMainComponent.SEARCH:
        return hasUserRole && this.searchNavigationEnabled;
      case NavigationMainComponent.MODELS:
        return hasUserRole &&
          this.authorities.indexOf(Authority.AUTHORITY_MODEL_MANAGEMENT_R) !== -1;
      case NavigationMainComponent.BULK:
        return hasUserRole &&
          this.authorities.indexOf(Authority.AUTHORITY_BULK_TRADE_R) !== -1;
      case NavigationMainComponent.BUY_LIST:
        return hasUserRole &&
          this.authorities.indexOf(Authority.AUTHORITY_TEAM_BUY_LIST_R) !== -1;
    }
  }

  openAdmin() {
    window.open(this.adminUrl, '_blank');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
