export function mockNewClientConfig() {
  return [ {
      'rowComponents': [
        {
          'order': 1,
          'key': 'firstRow',
          'components': [
            {
              'validators': null,
              'order': 1,
              'controlType': 'text',
              'label': 'title',
              'required': true,
              'key': 'clientTitle',
              'disabled': false,
              'options': null,
              'optionsSQL': null,
              'metaData': null
            },
            {
              'validators': null,
              'order': 2,
              'controlType': 'text',
              'label': 'first Name',
              'required': false,
              'key': 'clientFirstName',
              'disabled': false,
              'options': null,
              'optionsSQL': null,
              'metaData': null
            },
            {
              'validators': null,
              'order': 3,
              'controlType': 'text',
              'label': 'last Name',
              'required': true,
              'key': 'clientLastName',
              'disabled': false,
              'options': null,
              'optionsSQL': null,
              'metaData': null
            }
          ]
        },
        {
          'order': 2,
          'key': 'secondRow',
          'components': [
            {
              'validators': null,
              'order': 1,
              'controlType': 'dropdown',
              'label': 'office',
              'required': false,
              'key': 'clientOffice',
              'disabled': true,
              'options': [
                {
                  'value': 'Undefined',
                  'key': 'Undefined'
                },
                {
                  'value': 'Liverpool',
                  'key': 'Liverpool'
                },
                {
                  'value': 'Dublin',
                  'key': 'Dublin'
                },
                {
                  'value': 'Glasgow',
                  'key': 'Glasgow'
                },
                {
                  'value': 'Leicester',
                  'key': 'Leicester'
                },
                {
                  'value': 'Birmingham',
                  'key': 'Birmingham'
                },
                {
                  'value': 'dummy',
                  'key': 'dummy'
                },
                {
                  'value': 'Salisbury',
                  'key': 'Salisbury'
                },
                {
                  'value': 'Belfast',
                  'key': 'Belfast'
                },
                {
                  'value': 'Bristol',
                  'key': 'Bristol'
                },
                {
                  'value': 'Dublin (GBP)',
                  'key': 'Dublin (GBP)'
                },
                {
                  'value': 'North Wales',
                  'key': 'North Wales'
                },
                {
                  'value': 'London',
                  'key': 'London'
                },
                {
                  'value': 'Manchester',
                  'key': 'Manchester'
                },
                {
                  'value': 'Edinburgh',
                  'key': 'Edinburgh'
                },
                {
                  'value': 'Jersey',
                  'key': 'Jersey'
                },
                {
                  'value': 'Firm',
                  'key': 'Firm'
                }
              ],
              'optionsSQL': null,
              'metaData': null
            }, {
              'validators': null,
              'order': 2,
              'controlType': 'dropdown',
              'label': 'status',
              'required': false,
              'key': 'status',
              'disabled': false,
              'options': [
                {
                  'value': 'Questionnaire',
                  'key': 'Questionnaire'
                },
                {
                  'value': 'Test',
                  'key': 'Test'
                },
                {
                  'value': 'Set for Deletion',
                  'key': 'Set for Deletion'
                },
                {
                  'value': 'Live',
                  'key': 'Live'
                },
                {
                  'value': 'Client Engagement',
                  'key': 'Client Engagement'
                },
                {
                  'value': 'Client',
                  'key': 'Client'
                },
                {
                  'value': 'Inquiry',
                  'key': 'Inquiry'
                },
                {
                  'value': 'Proposal',
                  'key': 'Proposal'
                },
                {
                  'value': 'Template',
                  'key': 'Template'
                }
              ],
              'optionsSQL': null,
              'metaData': null
            },
            {
              'validators': null,
              'order': 3,
              'controlType': 'dropdown',
              'label': 'type',
              'required': true,
              'key': 'clientType',
              'disabled': false,
              'options': [
                {
                  'value': 'NDF',
                  'key': 'NDF'
                }
              ],
              'optionsSQL': null,
              'metaData': null
            }
          ],
        },
        {
          'order': 3,
          'components': [
            {
              'validators': null,
              'order': 1,
              'controlType': 'date',
              'label': 'review date',
              'required': false,
              'key': 'reviewDate',
              'disabled': false,
              'options': null,
              'optionsSQL': null,
              'metaData': {
                'cssStyle': 'col-4'
              }
            },
            {
              'validators': null,
              'order': 2,
              'controlType': 'dropdown',
              'label': 'review frequency',
              'required': true,
              'key': 'reviewFrequency',
              'disabled': false,
              'options': [
                {
                  'value': '3 months',
                  'key': '3'
                },
                {
                  'value': '6 months',
                  'key': '6'
                },
                {
                  'value': '1 year',
                  'key': '12'
                },
                {
                  'value': '2 years',
                  'key': '24'
                }
              ],
              'optionsSQL': null,
              'metaData': {
                'cssStyle': 'col-4'
              }
            }
          ],
          'key': 'threeRow'
        },

        {
          'order': 4,
          'key': 'fourthRow',
          'components': [
             {
              'validators': null,
              'order': 1,
              'controlType': 'dropdown',
              'label': 'lead advisor',
              'required': false,
              'key': 'manager1',
              'disabled': false,
              'options': [
                {
                  'value': 'managertest',
                  'key': 'managertest'
                }
              ],
              'optionsSQL': null,
              'metaData': null
            },
            {
              'validators': null,
              'order': 2,
              'controlType': 'dropdown',
              'label': 'advisor',
              'required': false,
              'key': 'manager2',
              'disabled': false,
              'options': [
                {
                  'value': 'managertest',
                  'key': 'managertest'
                }
              ],
              'optionsSQL': null,
              'metaData': null
            },
            {
              'validators': null,
              'order': 3,
              'controlType': 'dropdown',
              'label': 'advisor assistant',
              'required': false,
              'key': 'manager3',
              'disabled': false,
              'options': [
                {
                  'value': 'managertest',
                  'key': 'managertest'
                }
              ],
              'optionsSQL': null,
              'metaData': {
                'cssStyle': ''
              }
            }
          ]
        }
      ],
      'formLabel': 'CLIENT INFO',
      'formName': 'Create Client',
      'formValues': {
        'clientFirstName': '',
        'reviewFrequency': null,
        'clientLastName': '',
        'clientOffice': '',
        'clientTitle': '',
        'manager1': '',
        'manager2': '',
        'manager3': '',
        'status': '',
        'reviewDate': '',
        'clientType': ''
      }
    }
  ];
}
