import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';
import { AccountService } from '../../../../shared/service/account.service';
import * as MonitorPortfolioActions from './monitor-portfolio.actions';
import { map, catchError, switchMap } from 'rxjs/operators';

export type MonitorPortfolioAction = MonitorPortfolioActions.All;

@Injectable()
export class MonitorPortfolioEffects {

	constructor(private actions$: Actions,
							private clientAccountService: AccountService) {
	}

	getMonitorPortfolio: Observable<MonitorPortfolioAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(MonitorPortfolioActions.GET_MONITOR_PORTFOLIO),
      switchMap((action: MonitorPortfolioActions.GetMonitorPortfolio) =>
      this.clientAccountService.getMonitorPortfolio(action.clientId, action.accountId).pipe(
        map(post => new MonitorPortfolioActions.GetMonitorPortfolioSuccess(post)),
        catchError((error) => of(new MonitorPortfolioActions.GetMonitorPortfolioError(error)))
      )
    )));

}
