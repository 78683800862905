import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('EsgFactor')
export class EsgFactor {
  @JsonProperty('factorId', Number)
  factorId: number = undefined;

  /** Environmental, Social, Governance, or null */
  @JsonProperty('pillar', String)
  pillar: string = undefined;

  @JsonProperty('factorName', String)
  factorName: string = undefined;

  @JsonProperty('goodnessScore', Number)
  goodnessScore: number = undefined;

  @JsonProperty('goodnessScoreShortName', String, true)
  goodnessScoreShortName: string = undefined;

  @JsonProperty('goodnessScoreLongName', String, true)
  goodnessScoreLongName: string = undefined;

  @JsonProperty('iconName', String)
  iconName: string = undefined;
}
