import { Action } from '@ngrx/store';


import * as AssetListActions from './asset-list.actions';

export type AssetListAction = AssetListActions.All;

import { AssetListState } from './asset-list.state';

export const initialState: AssetListState  = { assets: new Array(), loading: false};

export function assetListReducer(state: AssetListState = initialState, action: AssetListAction):
AssetListState {
    switch (action.type) {

      case AssetListActions.GET_ALL_ASSETS: {
        return {...initialState, ...{loading: true}};

      }

      case AssetListActions.GET_ALL_ASSETS_SUCCESS: {
        return {...initialState, ...{assets: action.assets}, ...{loading: false}};
      }

      case AssetListActions.GET_ALL_ASSETS_ERROR: {
        console.error('Error caught while loading asset list', action.error);
        return {...initialState};
      }

      default :
        return state;
    }
}
