
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AssetService } from '../../service/asset.service';
import * as AssetListActions from './asset-list.actions';

export type AssetListAction = AssetListActions.All;

@Injectable()
export class AssetListEffects {

	constructor(private actions$: Actions,
							private assetService: AssetService) {
	}

	getAllAssets: Observable<AssetListAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(AssetListActions.GET_ALL_ASSETS),
      switchMap((a: AssetListActions.GetAllAssets ) =>
      this.assetService.getAllAssets(a.clientId, a.accountId).pipe(
			map(post => new AssetListActions.GetAllAssetsSuccess(post)),
      catchError((error) => of(new AssetListActions.GetAllAssetsError(error)))
    ))));

}
