<span class="copy-right-link" (click)="openCopyRightModal()">© BITA Risk Limited {{currentYear}}. All rights reserved</span>

<ng-template #bsModalRef>
    <div class="modal-body" (closeModalEvent)="closeModal()">
        <button type="button" class="close pull-right" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
        corfinancial is a trading name of COR Financial Solutions Limited and its subsidiaries.
        <br/>
        <br/>
        BITA Risk is a trading name of BITA Risk Limited, a subsidiary of COR Financial Solutions Limited.
        <br/>
        <br/>
        COR Financial Solutions Limited and its subsidiaries are, unless stated otherwise, 
        the owners of all copyright (along with all other intellectual property rights which protect this content) 
        in relation to this software and its contents. No part of this software may be published, distributed, 
        reproduced in any form or otherwise communicated to the public, except with the prior written approval of 
        COR Financial Solutions Limited. All such rights are reserved. 
        <br/> 
        <br/>
        <img src="assets/images/ringLogo.jpg" class="ringLogo"> ®, 
        CORFINANCIAL™, COR FINANCIAL®, BITARISK™, BITA RISK®, 
        BITAWEALTH™ and BITA WEALTH® are all trademarks of COR Financial Solutions Limited or 
        its subsidiaries, registered or otherwise in various countries. Full details of the relevant 
        trademark rights can be found at: 
        <br/>
        <a href="https://www.corfinancialgroup.com/terms-and-conditions/">
            https://www.corfinancialgroup.com/terms-and-conditions/</a>.
    </div>
  </ng-template>