import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { BmidHoldingService } from '../../bmid-holding-list/bmid-holding.service';
import * as BmidHoldingListActions from './bmid-holding-list.actions';
import { map, switchMap } from 'rxjs/operators';

export type BmidHoldingListAction = BmidHoldingListActions.All;

@Injectable()
export class BmidHoldingListEffects {

  constructor(private actions$: Actions,
    private bmidHoldingService: BmidHoldingService) {
  }

   getAllBmidHoldingListItem: Observable<BmidHoldingListAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(BmidHoldingListActions.GET_BMID_HOLDING_LIST),
      switchMap((action: BmidHoldingListActions.GetBmidHoldingList) =>
      this.bmidHoldingService.getAllBmidHoldingList(action.monitorFlagName, action.chartType, action.outlierStatusType, action.withKE)),
      map(post => new BmidHoldingListActions.GetBmidHoldingListSuccess(post))
    ));
}
