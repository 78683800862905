import { Injectable } from '@angular/core';
import {JsonConvert, ValueCheckingMode} from 'json2typescript';

@Injectable()
export class JsonConvertService {
  private jsonConvert: JsonConvert;

  constructor() {
    this.jsonConvert = new JsonConvert();

    // please turn this on if you want to see logging for deserialization
    // this.jsonConvert.operationMode = OperationMode.LOGGING;

    this.jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

  }

  public getJsonConverter(): JsonConvert {
    return this.jsonConvert;
  }

}
