import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { empty, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { JsonConvertService } from '../json-convert/json-convert.service';
import { JsonConvert } from 'json2typescript';

import { WidgetAlert } from '../entity/widget-alert.model';
import { AABoundaryDeviation } from '../entity/aa-boundary-deviation.model';
import { AABoundaryDeviationState } from '../store/aaBoundaryDeviation/aaBoundaryDeviation.state';
import { WidgetIssue } from '../entity/widget-issue.model';
import { WidgetProfile, WidgetModelDetail } from '../entity/widget-profile.model';
import { WidgetModel, AnotherAccountInfo } from '../entity/widget-model.model';
import { ModelState } from '../store/models/models.state';
import { MonitorFlagState } from '../store/monitor-flags/monitor-flag.state';
import { WidgetMonitorFlag } from '../entity/widget-monitor-flag.model';
import { WidgetMonitorFlagPosition } from '../entity/widget-monitor-position.model';
import { AssetRiskContribution } from '../entity/asset-risk-contribution.model';
import { PortfolioConstructionModel, TradeOrderErrorResponses, EditProperty } from '../entity/portfolio-construction-model.model';
import { environment } from '../../../environments/environment';
import { SlwiState } from '../store/portfolioConstructionModel/portfolioConstructionModel.state';
import { StartingPortfolioType } from '../entity/starting-portfolio-type.enum';
import { AssetValue } from '../entity/asset-value.model';
import { TradeCashDetails } from '../entity/trade-cash-details.model';
import { TradeRationale } from '../entity/trade-rationale.model';
import { GenericFormConfiguration } from '../entity/generic-form/generic-form.model';
import { NavActionService } from '../../core/navigation-floating/nav-action.service';
import { PortfolioMessage } from '../entity/portfolio-message-model';

@Injectable()
export class ClientPmpService {

	private jsonConverter: JsonConvert;

	constructor(
    private httpClient: HttpClient,
	private jsonConvertService: JsonConvertService,
	private navActionService: NavActionService) {
    this.jsonConverter =  this.jsonConvertService.getJsonConverter();
  }

	getAllWidgetAlerts(clientId: string, accountId: string): Observable<WidgetAlert[]> {

		return this.httpClient.get(`${environment.serverUrl}/restapi/service/portfolio/alerts/client/${clientId}/account/${encodeURIComponent(accountId)}`).pipe(
			map(response => {
				const widgetAlerts: WidgetAlert[] = response as WidgetAlert[];
				return this.jsonConverter.deserializeArray(widgetAlerts, WidgetAlert);
      })
    );
  }

	getAllWidgetIssues(clientId: string, accountId: string): Observable<WidgetIssue[]> {
		return this.httpClient.get(`${environment.serverUrl}/restapi/service/portfolio/issues/client/${clientId}/account/${encodeURIComponent(accountId)}`).pipe(
			map(response => {
				const widgetIssues: WidgetIssue[] = response as WidgetIssue[];
				return this.jsonConverter.deserializeArray(widgetIssues, WidgetIssue);
      })
    );
  }

	getWidgetProfile(clientId: string, accountId: string): Observable<WidgetProfile> {
		return this.httpClient.get(`${environment.serverUrl}/restapi/service/portfolio/info/client/${clientId}/account/${encodeURIComponent(accountId)}`).pipe(
			map(response => {
				return this.jsonConverter.deserializeObject(response, WidgetProfile);
      })
    );
  }

	getAllWidgetModels(clientId: string, accountId: string): Observable<ModelState> {
		return this.httpClient.get(`${environment.serverUrl}/restapi/service/portfolio/models/client/${clientId}/account/${encodeURIComponent(accountId)}`).pipe(
      map(response => this.parseModelState(response))
    );
  }

	private parseModelState(response): ModelState {
		let selectedModel = null;
		if (response['selected']) {
			selectedModel = this.jsonConverter.deserializeObject(response['selected'], WidgetModel);
		} else {
			selectedModel = new WidgetModel();
		}

		const modelState: ModelState = {
      selectedModel: selectedModel,
			availableModels: this.jsonConverter.deserializeArray(response['models'], WidgetModel),
      anotherAccountInfo: response['anotherAccountInfo'] ?
      this.jsonConverter.deserializeObject(response['anotherAccountInfo'], AnotherAccountInfo) : null,
    };

		return modelState;
	}

	getAllMonitorFlag(clientId: string, accountId: string): Observable<MonitorFlagState> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/portfolio/monitorFlags/client/${clientId}/account/${encodeURIComponent(accountId)}`)
    .pipe(
      map(response => this.parseMonitorFlagState(response))
    );
  }

	private parseMonitorFlagState(response): MonitorFlagState  {
		const monitorFlag: MonitorFlagState = response as MonitorFlagState;
    const monitorFlagState: MonitorFlagState = {
      monitorFlags: this.jsonConverter.deserializeArray(monitorFlag.monitorFlags, WidgetMonitorFlag),
      positions: this.jsonConverter.deserializeArray(monitorFlag.positions, WidgetMonitorFlagPosition)
    };

    return monitorFlagState;
	}

	getAABoundaryDeviations(clientId: string, accountId: string): Observable<AABoundaryDeviationState> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/portfolio/slwi/deviations/client/${clientId}/account/${encodeURIComponent(accountId)}`)
    .pipe(
      map(response => this.parseAABoundaryDeviationState(response))
    );
  }

	private parseAABoundaryDeviationState(response): AABoundaryDeviationState {
		const aaBoundariesDeviations: AABoundaryDeviationState = response as AABoundaryDeviationState;
		const aaBoundaryDeviationState: AABoundaryDeviationState = {
			tacLevelCurrentVsTarget: this.jsonConverter.deserializeArray(aaBoundariesDeviations.tacLevelCurrentVsTarget, AABoundaryDeviation),
			tacLevelProposedVsTarget: this.jsonConverter.deserializeArray(aaBoundariesDeviations.tacLevelProposedVsTarget, AABoundaryDeviation)
		};
		return aaBoundariesDeviations;
	}

	getAllAssetRiskContribution(clientId: string, accountId: string): Observable<AssetRiskContribution[]> {
		return this.httpClient.get(
				`${environment.serverUrl}/restapi/service/portfolio/slwi/riskcontributions/client/${clientId}/account/${encodeURIComponent(accountId)}`).pipe(
			map(response => {
				const assetRiskContributions: AssetRiskContribution[] = response as AssetRiskContribution[];
				return this.jsonConverter.deserializeArray(assetRiskContributions, AssetRiskContribution);
      })
    );
  }

	getPortfolioConstructionModel(clientId: string, accountId: string, scenarioId: number): Observable<PortfolioConstructionModel> {
		return this.httpClient
		.get(`${environment.serverUrl}/restapi/service/portfolio/slwi/client/${clientId}/account/${encodeURIComponent(accountId)}/scenario/${scenarioId}`).pipe(
			map(response => {
				const pcm = this.jsonConverter.deserializeObject(response, PortfolioConstructionModel);
				return pcm;
      })
    );
  }

	getPortfolioConstructionPage(clientId: string, accountId: string, scenarioId: number, pdfRequest: boolean): Observable<SlwiState> {
    let params = new HttpParams();
    if (pdfRequest) {
      params = params.set('pdf', pdfRequest ? 'true' : 'false');
    }
		return this.httpClient.get(`${environment.serverUrl}/restapi/service/portfolio/client/${clientId}` +
			`/account/${encodeURIComponent(accountId)}/scenario/${scenarioId}`, {params: params}).pipe(
			map(response => {
				return this.parseSlwiState(response);
			}),
			catchError((err: HttpErrorResponse) => {
				if (err.error instanceof Error) {
					this.navActionService.addMessage('Error', 'Error caught while loading portfolio construction page', 'error');
					console.error('An error occurred: ', err.error.message);
				} else {
					// TODO: Send error message to polo mint or notification service when it's implemented
					this.navActionService.addMessage('Error', err.error.message, 'error');
					console.error(`Backend returned code ${err.status}, error was: ${err.error.message}`);
				}
				// empty observable
				return empty();
			})
    );
  }


	getNewPortfolioConstructionModel(clientId: string, accountId: string): Observable<PortfolioConstructionModel> {
		return this.httpClient.get(`${environment.serverUrl}/restapi/service/portfolio/slwi/client/${clientId}/account/${encodeURIComponent(accountId)}`).pipe(
      map(response => this.jsonConverter.deserializeObject(response, PortfolioConstructionModel))
    );
	}

	getPortfolioConstructionModelAdjustment(clientId: string, accountId: string,
		currentPcm: PortfolioConstructionModel): Observable<PortfolioConstructionModel> {
    const pcm = this.jsonConverter.serializeObject(currentPcm);
		return this.httpClient.post(`${environment.serverUrl}/restapi/service/portfolio/slwi/recalc/risk`, pcm).pipe(
		map(response => this.jsonConverter.deserializeObject(response, PortfolioConstructionModel)));
  }

  getOptimiserConstraintsConfiguration(currentPcm: PortfolioConstructionModel): Observable<GenericFormConfiguration> {
    const pcm = this.jsonConverter.serializeObject(currentPcm);
    return this.httpClient.post(`${environment.serverUrl}/restapi/service/portfolio/optimiser/constraintForm`, pcm).pipe(
      map(response => this.jsonConverter.deserializeObject(response['constraintConfig'], GenericFormConfiguration)));
  }

	optimisePortfolio(constraintConfiguration: any): Observable<SlwiState> {
		return this.httpClient.post(`${environment.serverUrl}/restapi/service/portfolio/optimiser/optimise`, constraintConfiguration).pipe(
		map(response => this.parseSlwiState(response)));
	}

	importAssetForTargetModel(importedAssetList: Array<AssetValue>) {
		const url = `${environment.serverUrl}/restapi/service/portfolio/slwi/assets/importAndBuild`;
		return this.httpClient.post(url, importedAssetList).pipe(
		map(response => this.parseSlwiState(response)),
		catchError((err: HttpErrorResponse) => {
			if (err.error instanceof Error) {
			  this.navActionService.addMessage('Error', 'An error occurred while importing assets', 'error');
			  console.error('An error occurred: ', err.error.message);
			} else {
			  // TODO: Send error message to polo mint or notification service when it's implemented
			  this.navActionService.addMessage('Error', err.error.message, 'error');
			  console.error(`Backend returned code ${err.status}, error was: ${err.error.message}`);
			}
			// empty observable
			return empty();
		  })
		);
	}

	resetPortfolioAdjustments(currentPcm: PortfolioConstructionModel): Observable<SlwiState> {
    const pcm = this.jsonConverter.serializeObject(currentPcm);
		return this.httpClient.post(`${environment.serverUrl}/restapi/service/portfolio/slwi/reset`, pcm).pipe(
		map(response => this.parseSlwiState(response)));
	}

	recalculateAll(currentPcm: PortfolioConstructionModel, editProperty?: EditProperty): Observable<SlwiState> {
    if (editProperty !== undefined) {
      currentPcm.updatedSource = EditProperty[editProperty];
    }
    const pcm = this.jsonConverter.serializeObject(currentPcm);
		return this.httpClient.post(`${environment.serverUrl}/restapi/service/portfolio/slwi/recalc`, pcm).pipe(
		map(response => this.parseSlwiState(response)));
	}

  // TODO: Restore parseSlwiState return when endpoint is updated
	adjustBoundaries(currentPcm: PortfolioConstructionModel): Observable<any> {
    const pcm = this.jsonConverter.serializeObject(currentPcm);
		return this.httpClient.post(`${environment.serverUrl}/restapi/service/portfolio/optimiser/setBoundary`, pcm).pipe(
		map(response => response));
	}

	getNewPortfolioConstructionPageData(clientId: string, accountId: string, pdfRequest: boolean): Observable<SlwiState> {
    let params = new HttpParams();
    if (pdfRequest) {
      params = params.set('pdf', pdfRequest ? 'true' : 'false');
    }
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/portfolio/client/${clientId}/account/${encodeURIComponent(accountId)}`,
    { params: params }).pipe(
		map(response => {
		  return this.parseSlwiState(response);
		}));
	}

	getPortfolioConstructionModelLookThrough(currentPcm: PortfolioConstructionModel): Observable<SlwiState> {
    const pcm = this.jsonConverter.serializeObject(currentPcm);
		return this.httpClient.post(`${environment.serverUrl}/restapi/service/portfolio/slwi/lookthrough`, pcm).pipe(
		map(response => this.parseSlwiState(response)));
	}

	getPortfolioConstructionModelLookThroughRevert(currentPcm: PortfolioConstructionModel): Observable<SlwiState> {
    const pcm = this.jsonConverter.serializeObject(currentPcm);
		return this.httpClient.post(`${environment.serverUrl}/restapi/service/portfolio/slwi/lookthrough/revert`, pcm).pipe(
		map(response => this.parseSlwiState(response)));
	}

	changeStartingPortfolio(startingType: StartingPortfolioType): Observable<SlwiState> {
		return this.httpClient.get(`${environment.serverUrl}/restapi/service/portfolio/slwi/startingPortfolio/
		${startingType === StartingPortfolioType.CURRENT ? 'CURRENT' : 'TARGET'} `).pipe(
      map(response => this.parseSlwiState(response))
    );
  }

	changeTargetModel(modelId: number, currentPcm: PortfolioConstructionModel): Observable<SlwiState> {
		const pcm = this.jsonConverter.serializeObject(currentPcm);
		return this.httpClient.post(`${environment.serverUrl}/restapi/service/portfolio/slwi/reset/target/model/${modelId} `, pcm).pipe(
      map(response => this.parseSlwiState(response))
    );
	}

	changeTargetModelFromAccount(accountId: number): Observable<SlwiState> {
		return this.httpClient.get(
			`${environment.serverUrl}/restapi/service/portfolio/slwi/reset/target/account/${encodeURIComponent(accountId)} `).pipe(
      		map(response => this.parseSlwiState(response))
	);
  }

  changeTargetModelFromModel(id: number): Observable<SlwiState> {
	return this.httpClient.get(
		`${environment.serverUrl}/restapi/service/portfolio/slwi/reset/target/template/${id} `).pipe(
		  map(response => this.parseSlwiState(response))
	);
  }

  getIprFileNotesConfig(clientId: string, accountId: string): Observable<GenericFormConfiguration> {
    return this.httpClient.get(`${environment.serverUrl}/restapi/service/portfolio/`
    + `client/${clientId}/account/${encodeURIComponent(accountId)}/filenotes/ipr`).pipe(
      map(response => {
        const iprFileNotesConfig = this.jsonConverter.deserializeObject(response, GenericFormConfiguration);
        iprFileNotesConfig.sort();
        return iprFileNotesConfig;
      })
    );
  }

	private parseSlwiState(response: Object): SlwiState {
		const clientProfile: WidgetProfile = response['info'] as WidgetProfile;
		const assetRiskContribution: AssetRiskContribution[] = response['assetRiskContribution'] as AssetRiskContribution[];
		const aaBoundaryDeviationState: AABoundaryDeviationState = this.parseAABoundaryDeviationState(response['assetAllocationDeviation']);
		const modelPicker: ModelState = this.parseModelState(response['modelPicker']);
		const widgetIssues: WidgetIssue[] = response['issues'] as WidgetIssue[];
		const widgetAlerts: WidgetAlert[] = response['alerts'] as WidgetAlert[];
		const tradeCashDetails: TradeCashDetails = response['tradeCashDetails'] as TradeCashDetails;
		const tradeRationale: TradeRationale = response['tradeRationale'] as TradeRationale;
		const monitorFlags: MonitorFlagState = this.parseMonitorFlagState(response['monitorFlags']);
		const portfolioConstructionModel: PortfolioConstructionModel = response['portfolio'] as PortfolioConstructionModel;
		const clientModelDetails: WidgetModelDetail[] = response['modelDetails'] as WidgetModelDetail[];
		const isPortfolioReadOnly: boolean = response['readOnly'];
		const portfolioMessages: PortfolioMessage[] = response['portfolioMessages'] as PortfolioMessage[];
		return {
			clientProfile: this.jsonConverter.deserializeObject(clientProfile, WidgetProfile),
			assetRiskContribution: this.jsonConverter.deserializeArray(assetRiskContribution, AssetRiskContribution),
			aaBoundaryDeviations: aaBoundaryDeviationState,
			models: modelPicker,
			issues: this.jsonConverter.deserializeArray(widgetIssues, WidgetIssue),
			alerts: this.jsonConverter.deserializeArray(widgetAlerts, WidgetAlert),
			monitorFlags: monitorFlags,
      portfolioConstructionModel: this.jsonConverter.deserializeObject(portfolioConstructionModel, PortfolioConstructionModel),
      tradeCashDetails: this.jsonConverter.deserializeObject(tradeCashDetails, TradeCashDetails),
	  tradeRationale: this.jsonConverter.deserializeObject(tradeRationale, TradeRationale),
	  clientModelDetails: this.jsonConverter.deserializeArray(clientModelDetails, WidgetModelDetail),
	  isPortfolioReadOnly: isPortfolioReadOnly,
	  portfolioMessages: this.jsonConverter.deserializeArray(portfolioMessages, PortfolioMessage)
		};
	}

	isScenarioNameUnique(cid: string, acid: string, scenarioName: string): Observable<boolean> {
		return this.httpClient.post(`${environment.serverUrl}/restapi/service/portfolio/slwi/scenario/verify/client/${cid}/account/${acid}`,
		 scenarioName, 	{
			responseType: 'text',
			headers: new HttpHeaders().set('Content-Type', 'text/plain')
		}).pipe(map(data => true));
	}

	savePortfolioConstructionModel(currentPcm: PortfolioConstructionModel): Observable<SlwiState> {
    let url = 'portfolio/slwi/scenario/update';

    if (currentPcm.clientStatus === 'Template') {
      url = 'model/scenario/update';
    }
    const pcm = this.jsonConverter.serializeObject(currentPcm);

    return this.httpClient.post(`${environment.serverUrl}/restapi/service/${url}`, pcm).pipe(
      map(response => this.parseSlwiState(response)));
  }

	saveUpdatePortfolioConstructionModel(currentPcm: PortfolioConstructionModel) {
    const pcm = this.jsonConverter.serializeObject(currentPcm);
		return this.httpClient.post(`${environment.serverUrl}/restapi/service/model/scenario/saveAndUpdate`, pcm,
		{
      responseType: 'text'
    });
  }

	createPortfolioConstructionModel(currentPcm: PortfolioConstructionModel): Observable<string> {
    let url = 'portfolio/slwi/scenario/create';

    if (currentPcm.clientStatus === 'Template') {
      url = 'model/scenario/create';
    }

    const pcm = this.jsonConverter.serializeObject(currentPcm);

		return this.httpClient.post(`${environment.serverUrl}/restapi/service/${url}`, pcm,
		{
      responseType: 'text'
    }).pipe(map(scenarioId => scenarioId));
	}

	updateCurrentHoldings(currentPcm: PortfolioConstructionModel): Observable<string> {
    const pcm = this.jsonConverter.serializeObject(currentPcm);
		return this.httpClient.post(`${environment.serverUrl}/restapi/service/portfolio/slwi/update/currentHoldings`, pcm,
		{
      responseType: 'text'
    });
  }

  updateCurrentHoldingsOpenNew(currentPcm: PortfolioConstructionModel): Observable<any> {
    const pcm = this.jsonConverter.serializeObject(currentPcm);
    return this.httpClient.post(`${environment.serverUrl}/restapi/service/portfolio/slwi/update/currentHoldings/openNew`, pcm);
  }

  updateAccountHoldings(currentPcm: PortfolioConstructionModel): Observable<SlwiState> {
    const pcm = this.jsonConverter.serializeObject(currentPcm);
		return this.httpClient.post(`${environment.serverUrl}/restapi/service/portfolio/slwi/updateAccountHoldings`, pcm).pipe(
		map(response => this.parseSlwiState(response)));
	}

  getApiTradeRationales(cid: string, acid: string): Observable<TradeRationale> {
    const base = environment.serverUrl;
    return this.httpClient.get(`${base}/restapi/service/tradeOrder/client/${cid}/account/${acid}/tradeRationaleOptions`).pipe(
			map(response => this.jsonConverter.deserializeObject(response, TradeRationale)));
  }

  submitTrades(currentPcm: PortfolioConstructionModel): Observable<string> {
    const pcm = this.jsonConverter.serializeObject(currentPcm);

	return this.httpClient.post(`${environment.serverUrl}/restapi/service/tradeOrder/createOrders`, pcm,
	{
      responseType: 'text'
    }).pipe(
      map(scenarioId => scenarioId),
      catchError((error) => throwError(this.jsonConverter.deserializeObject(JSON.parse(error.error), TradeOrderErrorResponses)))
    );
  }

  saveTradesOnServer(tradeOrders: string, mode: string) {
	const url = `${environment.serverUrl}/restapi/service/tradeOrder/saveTradesOnServer/${mode}`;
	console.log('pmp exportToServer url:', url);
	// return this.httpClient.post(url, tradeOrder).pipe(map(response => response));
	return this.httpClient.post(url, tradeOrders, 
	{
	  responseType: 'text'
	}).pipe(
      map(message => message),
      catchError((err: HttpErrorResponse) => {
		if (err.error instanceof Error) {
            this.navActionService.addMessage('Error', '${err.error.message}', 'error');
            console.error('An error occurred: ', err.error.message);
		} else {
			this.navActionService.addMessage('Error', 'Problem saving trade orders', 'error');
            console.error(`Backend returned code ${err.status}, error was: ${err.error}`);
		}
		// empty observable
		return empty();
		})
    );
  }

  exportData(currentPcm: PortfolioConstructionModel, dataExportFormat: string) : Observable<any> {
	const pcm = this.jsonConverter.serializeObject(currentPcm);
    return this.httpClient.post(`${environment.serverUrl}/restapi/service/portfolio/export.`+dataExportFormat, pcm,
     { responseType: 'blob' as 'json', observe: 'response'}).pipe(
      map((response: HttpResponse<Blob>) => {
        return response;
     }));
  }

  createModel(clientId: string, accountId: string, currentPcm: PortfolioConstructionModel): Observable<string> {
    const pcm = this.jsonConverter.serializeObject(currentPcm);
    return this.httpClient.post(
      `${environment.serverUrl}/restapi/service/portfolio/model/client/${clientId}/account/${encodeURIComponent(accountId)}/create/`, pcm,
      {
        responseType: 'text'
      }).pipe(map(scenarioId => scenarioId));
  }

  applyChosenRiskModel(currentPcm: PortfolioConstructionModel, chosenRiskModelId: number): Observable<SlwiState> {
	const pcm = this.jsonConverter.serializeObject(currentPcm);
    return this.httpClient.post(`${environment.serverUrl}/restapi/service/portfolio/riskModels/${chosenRiskModelId}`, pcm).pipe(
		map(response => this.parseSlwiState(response))
	  );
  }

  applyChosenAlphaModel(currentPcm: PortfolioConstructionModel, chosenAlphaModelId: number): Observable<SlwiState> {
	const pcm = this.jsonConverter.serializeObject(currentPcm);
    return this.httpClient.post(`${environment.serverUrl}/restapi/service/portfolio/alphaModels/${chosenAlphaModelId}`, pcm).pipe(
		map(response => this.parseSlwiState(response))
	  );
  }
}
