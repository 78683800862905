import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('EsgProductRestriction')
export class EsgProductRestriction {
  @JsonProperty('factorId', Number)
  factorId: number = undefined;

  @JsonProperty('factorName', String, true)
  factorName: string = undefined;

  @JsonProperty('percentageOfRevenue', Number, true)
  percentageOfRevenue: number = undefined;

  @JsonProperty('maximumPercentageOfRevenue', Number, true)
  maximumPercentageOfRevenue: number = undefined;

  @JsonProperty('conflicted', Boolean, true)
  conflicted: boolean = undefined;
}
