import { Action } from '@ngrx/store';
import { GenericFormConfiguration } from '../../../shared/entity/generic-form/generic-form.model';

export const GET_SEARCH_FORM_CONFIG = '[DashMetric] GET_SEARCH_FORM_CONFIG';
export const GET_SEARCH_FORM_CONFIG_SUCCESS = '[DashMetric]  GET_SEARCH_FORM_CONFIG_SUCCESS';
export const GET_SEARCH_FORM_CONFIG_ERROR = '[DashMetric] GET_SEARCH_FORM_CONFIG_ERROR';

export const GET_PMP_ACCOUNT_SEARCH_FORM_CONFIG = '[DashMetric] GET_PMP_ACCOUNT_SEARCH_FORM_CONFIG';
export const GET_PMP_ACCOUNT_SEARCH_FORM_CONFIG_SUCCESS = '[DashMetric]  GET_PMP_ACCOUNT_SEARCH_FORM_CONFIG_SUCCESS';
export const GET_PMP_ACCOUNT_SEARCH_FORM_CONFIG_ERROR = '[DashMetric] GET_PMP_ACCOUNT_SEARCH_FORM_CONFIG_ERROR';

export const GET_ASSET_SEARCH_FORM_CONFIG = '[DashMetric] GET_ASSET__SEARCH_FORM_CONFIG';
export const GET_ASSET_SEARCH_FORM_CONFIG_SUCCESS = '[DashMetric]  GET_ASSET_SEARCH_FORM_CONFIG_SUCCESS';
export const GET_ASSET_SEARCH_FORM_CONFIG_ERROR = '[DashMetric] GET_ASSET_SEARCH_FORM_CONFIG_ERROR';

export class GetSearchFormConfig implements Action {
  readonly type = GET_SEARCH_FORM_CONFIG;
  constructor() {}
}

export class GetSearchFormConfigSuccess implements Action {
  readonly type = GET_SEARCH_FORM_CONFIG_SUCCESS;
  constructor(public searchFormConfig: GenericFormConfiguration) {}
}

export class GetSearchFormConfigError implements Action {
  readonly type = GET_SEARCH_FORM_CONFIG_ERROR;
}

export class GetPMPAccountSearchFormConfig implements Action {
  readonly type = GET_PMP_ACCOUNT_SEARCH_FORM_CONFIG;
  constructor() {}
}

export class GetPMPAccountSearchFormConfigSuccess implements Action {
  readonly type = GET_PMP_ACCOUNT_SEARCH_FORM_CONFIG_SUCCESS;
  constructor(public pmpAccountsearchFormConfig: GenericFormConfiguration) {}
}

export class GetPMPAccountSearchFormConfigError implements Action {
  readonly type = GET_PMP_ACCOUNT_SEARCH_FORM_CONFIG_ERROR;
}

export class GetAssetSearchFormConfig implements Action {
  readonly type = GET_ASSET_SEARCH_FORM_CONFIG;
  constructor() {}
}

export class GetAssetSearchFormConfigSuccess implements Action {
  readonly type = GET_ASSET_SEARCH_FORM_CONFIG_SUCCESS;
  constructor(public assetSearchFormConfig: GenericFormConfiguration) {}
}

export class GetAssetSearchFormConfigError implements Action {
  readonly type = GET_ASSET_SEARCH_FORM_CONFIG_ERROR;
}
export type All = GetSearchFormConfig | GetSearchFormConfigSuccess | GetSearchFormConfigError
| GetPMPAccountSearchFormConfig | GetPMPAccountSearchFormConfigSuccess | GetPMPAccountSearchFormConfigError
| GetAssetSearchFormConfig | GetAssetSearchFormConfigSuccess | GetAssetSearchFormConfigError;
