import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getAuthenticationState } from '../store/appState.model';
import { AuthenticationState } from '../store/auth/authentication.state';
import { User } from '../../shared/entity/user.model';
import * as AuthenticationActions from '../store/auth/authentication.actions';
import { AppRouterService } from '../../shared/router/app-router.service';
import { takeUntil } from 'rxjs/operators';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Authority } from '../entity/authority.enum';

@Component({
  selector: 'app-user-options',
  templateUrl: './user-options.component.html',
  styleUrls: ['./user-options.component.scss']
})

export class UserOptionsComponent implements OnInit, OnDestroy {
  private sessionDetails: Observable<AuthenticationState>;
  user: User;
  clientHistory: String[];
  versionDetails: String[];

  @Input()
  isNavigationVisible: boolean;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  faSignOutAlt = faSignOutAlt;

  authorities: String[] = [];

  hasUserRole: boolean;

  constructor(private store: Store<any>, private appRouterService: AppRouterService) { }

  ngOnInit() {
    this.sessionDetails = this.store.select(getAuthenticationState);
    this.sessionDetails.pipe(takeUntil(this.ngUnsubscribe)).subscribe(session => {
      if (session.session) {
        this.user = session.session.user;
        this.clientHistory =  Object.assign([], session.session.lastAccessedClientList);
        if (session.session.configuration) {
          this.versionDetails =  Object.assign([], session.session.configuration.versionDetails);
        }

        if (session.session.user) {
          this.authorities = session.session.user.authorities;
          this.hasUserRole = this.authorities.indexOf(Authority.ROLE_USER) !== -1;
        } else {
          this.authorities = [];
          this.hasUserRole = false;
        }
      } else {
        this.user = undefined;
        this.clientHistory = [];
        this.versionDetails = [];
        this.authorities = [];
        this.hasUserRole = false;
      }
    });
  }

  redirectToClientHomePage(clientId: string) {
    this.appRouterService.clientHomePage(clientId);
  }

  logout() {
    this.store.dispatch(new AuthenticationActions.RequestLogout());
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
