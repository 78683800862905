import { Action } from '@ngrx/store';

import * as SearchResultsActions from './search-results.actions';
import { SearchResult } from '../../../entity/search-result.model';
import { SearchResultsState } from './search-results.state';

export type SearchResultsAction = SearchResultsActions.All;
export const initialState: SearchResultsState = { searchResults: new Array<SearchResult>(), loading: false};

export function searchResultsReducer(state: SearchResultsState = initialState, action: SearchResultsAction):
SearchResultsState {
    switch (action.type) {

      case SearchResultsActions.GET_SEARCH_RESULTS: {
        return {...state, ...{loading: true}};
      }

      case SearchResultsActions.GET_SEARCH_RESULTS_SUCCESS: {
        return {...state, ...{searchResults: action.searchResults}, ...{loading: false}};
      }

      case SearchResultsActions.GET_SEARCH_RESULTS_ERROR: {
        console.error('Error caught while loading Search-Results', action.error);
        return {...initialState};
      }

      case SearchResultsActions.GET_SEARCH_TERM_RESULTS: {
        return {...initialState, ...{loading: true}};
      }

      case SearchResultsActions.GET_SEARCH_TERM_RESULTS_SUCCESS: {
        return {...state, ...{searchResults: action.searchResults}, ...{loading: false}};
      }

      case SearchResultsActions.GET_SEARCH_TERM_RESULTS_ERROR: {
        console.error('Error caught while loading Search-Results', action.error);
        return {...initialState};
      }


      default :
        return state;
    }
}
