export function mockClientScenarios() {
  return {
    clientScenarios: [
      {
        id: 'SCN1234',
        clientId: 'CID1234',
        accountId: 'BMID001HH',
        scenarioType: 'SAA',
        scenarioName: 'testing123',
        scenarioStatus: 'Current',
        lastModifiedDate: '2017-07-19 10:00:00',
        lastModifiedBy: 'user_a'
      },
      {
        id: 'SCN1235',
        clientId: 'CID1234',
        accountId: 'BMID001GG',
        scenarioType: 'TAA',
        scenarioName: 'testing456',
        scenarioStatus: 'Archived',
        lastModifiedDate: '2017-05-19 10:00:00',
        lastModifiedBy: 'user_a'
      },
      {
        id: 'SCN1236',
        clientId: 'CID1234',
        accountId: 'BMID001TT',
        scenarioType: 'SAA',
        scenarioName: 'testing678',
        scenarioStatus: 'Expired',
        lastModifiedDate: '2017-05-02 10:00:00',
        lastModifiedBy: 'user_a'
      }
    ],
    scenarioStatuses: ['Current', 'Expired', 'Archived']
  };
}
