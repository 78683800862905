import { Action } from '@ngrx/store';

import * as IssueActions from './issues.actions';
import { IssueState } from './issues.state';

export type IssueAction = IssueActions.All;
export const initialState: IssueState = { issues : new Array(), loading: false};

export function issueReducer(state: IssueState = initialState, action: IssueAction): IssueState {
  switch (action.type) {

    case IssueActions.GET_WIDGET_ISSUES: {
      return {...state, ...{loading: true}};

    }

    case IssueActions.GET_WIDGET_ISSUES_SUCCESS: {
      return {...state, ...{issues: action.issues}, ...{loading: false}};
    }

    case IssueActions.GET_WIDGET_ISSUES_ERROR: {
      console.error('Error caught while loading Widgets-issues', action.error);
      return {...initialState};
    }

    default :
      return state;
  }

}
