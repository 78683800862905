import { JsonObject, JsonProperty } from 'json2typescript';
import { WidgetMonitorFlag } from '../../shared/entity/widget-monitor-flag.model';
import { DateConverter } from '../../shared/json-convert/DateConvertor';
import { Moment } from 'moment';

@JsonObject('ClientAccountDetails')
export class ClientAccountDetails {

  @JsonProperty('accountId', String)
  accountId: string = undefined;

  @JsonProperty('accountType', String)
  accountType: string = undefined;

  @JsonProperty('clientId', String)
  clientId: string = undefined;

  @JsonProperty('name', String)
  name: string = undefined;

  @JsonProperty('source', String, true)
  source: string = undefined;

  @JsonProperty('value', String, true)
  value: number = undefined;

  @JsonProperty('currency', String, true)
  currency: string = undefined;

  @JsonProperty('questionnaire', String, true)
  questionnaire: string = undefined;

  @JsonProperty('questionnaireLabel', String, true)
  questionnaireLabel: string = undefined;

  @JsonProperty('questionnaireCompleted', Boolean)
  questionnaireCompleted: boolean = undefined;

  @JsonProperty('active', Boolean)
  active: boolean = undefined;

  @JsonProperty('questionnaireDate', DateConverter)
  questionnaireDate: Moment = undefined;

  @JsonProperty('scenario', String, true)
  scenario: string = undefined;

  @JsonProperty('accountLocked', Boolean, true)
  accountLocked: boolean = undefined;

  @JsonProperty('scenarioLabel', String, true)
  scenarioLabel: string = undefined;

  @JsonProperty('monitorFlags', [WidgetMonitorFlag], true)
  monitorFlags: WidgetMonitorFlag[] = undefined;

  @JsonProperty('subAccounts', [ClientAccountDetails], true)
  subAccounts: ClientAccountDetails[] = undefined;

  public getMonitorFlagByTitle(targetFlagTitle: string): WidgetMonitorFlag {
    if (this.monitorFlags && this.monitorFlags.length > 0) {
      for (const flag of this.monitorFlags) {
        if (flag.title === targetFlagTitle) {
          return flag;
        }
      }
    }
    return null;
  }

}
