import { Component, Input } from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { BaseFormComponent } from '../../shared/base-form/base-form.component';
import { AppRouterService } from '../../shared/router/app-router.service';

@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss']
})

export class QuickSearchComponent extends BaseFormComponent {
  searchTerm: string;

  @Input()
  isNavigationVisible: boolean;

  faSearch = faSearch;

  constructor(private appRouterService: AppRouterService) {
    super();
  }

  search() {
    if (this.searchTerm && '' !== this.searchTerm) {
      this.appRouterService.searchPage({ searchTerm: this.searchTerm });
    }
  }

}
