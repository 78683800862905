import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';

import { ClientPmpService } from '../../pmp/pmp.service';
import * as AlertActions from './alerts.actions';
import { map, catchError, switchMap } from 'rxjs/operators';

export type AlertAction = AlertActions.All;

@Injectable()
export class AlertEffects {

	constructor(private actions$: Actions,
							private clientSlwiService: ClientPmpService) {
	}

	getAllAlerts: Observable<AlertAction> = createEffect(() =>
	 this.actions$.pipe(
    ofType(AlertActions.GET_WIDGET_ALERTS),
		switchMap((a: AlertActions.GetWidgetAlerts) => this.clientSlwiService.getAllWidgetAlerts(a.clientId, a.accountId).pipe(
      map(alerts => new AlertActions.GetWidgetAlertsSuccess(alerts)),
      catchError((error) => of(new AlertActions.GetWidgetAlertsError(error)))
    ))));
}
