import * as SearchFormActions from './search-form.actions';
import { SearchFormState } from './search-form.state';

export type SearchFormAction = SearchFormActions.All;
export const initialState: SearchFormState = {
  searchForm: undefined,
  loading: false
};

export function searchFormReducer(state: SearchFormState = initialState, action: SearchFormAction): SearchFormState {
  switch (action.type) {
    case SearchFormActions.GET_SEARCH_FORM_CONFIG: {
      return { ...state, ...{ loading: true } };
    }

    case SearchFormActions.GET_SEARCH_FORM_CONFIG_SUCCESS: {
      return {...state, ...{ searchForm: action.searchFormConfig }, ...{ loading: false }};
    }

    case SearchFormActions.GET_SEARCH_FORM_CONFIG_ERROR: {
      return { ...initialState };
    }

    case SearchFormActions.GET_PMP_ACCOUNT_SEARCH_FORM_CONFIG: {
      return { ...state, ...{ loading: true } };
    }

    case SearchFormActions.GET_PMP_ACCOUNT_SEARCH_FORM_CONFIG_SUCCESS: {
      return {...state, ...{ searchForm: action.pmpAccountsearchFormConfig }, ...{ loading: false }};
    }

    case SearchFormActions.GET_PMP_ACCOUNT_SEARCH_FORM_CONFIG_ERROR: {
      return { ...initialState };
    }

    case SearchFormActions.GET_ASSET_SEARCH_FORM_CONFIG: {
      return { ...state, ...{ loading: true } };
    }

    case SearchFormActions.GET_ASSET_SEARCH_FORM_CONFIG_SUCCESS: {
      return {...state, ...{ searchForm: action.assetSearchFormConfig }, ...{ loading: false }};
    }

    case SearchFormActions.GET_ASSET_SEARCH_FORM_CONFIG_ERROR: {
      return { ...initialState };
    }

    default:
      return state;
  }
}
