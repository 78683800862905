export function mockSuccessfulLogin() {
  return {
      id: 123213213,
      username: 'user_A',
      authorities: [
        'BITAStarAdminAuditTrail_r',
        'BITAStarAdminClientList_r',
        'BITAStarAdminClientList_w',
        'BITAStarAdminClient_r',
        'BITAStarAdminClient_w',
        'BITAStarAdminEntities_r',
        'BITAStarAdminEntities_w',
        'BITAStarAdminGroups_r',
        'BITAStarAdminGroups_w',
        'BITAStarAdminUsers_r',
        'BITAStarAdminUsers_w',
        'BITAStarClientAssets_r',
        'BITAStarClientAssets_w',
        'BITAStarClientCreate_r',
        'BITAStarClientCreate_w',
        'BITAStarClientDetails_r',
        'BITAStarClientDetails_w',
        'BITAStarClientHome_r',
        'BITAStarClientHome_w',
        'BITAStarClientList_r',
        'BITAStarClientList_w',
        'BITAStarKEApproval_r',
        'BITAStarKEApproval_w',
        'BITAStarKnownException_r',
        'BITAStarMandate_r',
        'BITAStarMandate_w',
        'BITAStarMonitor_r',
        'BITAStarMonitor_w',
        'BITAStarPortfolio_r',
        'BITAStarPortfolio_w',
        'BITAStarProfile_r',
        'BITAStarQuestionnaire_r',
        'BITAStarQuestionnaire_w',
        'BITAStarReport_r',
        'ROLE_USER',
        'SLW_ADMIN'
    ],
    // put null if the user has never accessed any clients
    lastAccessedClientId: 'SLW1873933'
  };
}
