export function mockAssetRiskContributions() {
  return [
    {
      id: 1,
      assetName: 'Cash',
      currentRiskContribution: 50,
      proposedRiskContribution: 50
    },
    {
      id: 2,
      assetName: 'Alts',
      currentRiskContribution: 8,
      proposedRiskContribution: 8
    },
    {
      id: 3,
      assetName: 'Property',
      currentRiskContribution: 3,
      proposedRiskContribution: 8
    },
    {
      id: 4,
      assetName: 'Intl EQ',
      currentRiskContribution: 7,
      proposedRiskContribution: 5
    },
    {
      id: 5,
      assetName: 'UK EQ',
      currentRiskContribution: 0,
      proposedRiskContribution: 10
    },
    {
      id: 6,
      assetName: 'Fixed Inc',
      currentRiskContribution: 0,
      proposedRiskContribution: 7
    }

  ];

}
