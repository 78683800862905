import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';

import { map, switchMap, catchError} from 'rxjs/operators';


import { SearchService } from '../../../../search/search.service';
import * as SearchResultsActions from './search-results.actions';

export type SearchResultsAction = SearchResultsActions.All;

@Injectable()
export class SearchResultsEffects {

	constructor(private actions$: Actions,
							private searchResultsService: SearchService) {
	}

	getSearchResults: Observable<SearchResultsAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(SearchResultsActions.GET_SEARCH_RESULTS),
      switchMap((action: SearchResultsActions.GetSearchResults) =>
        this.searchResultsService.getSearchResults(action.searchCriteria).pipe(
        map(post => new SearchResultsActions.GetSearchResultsSuccess(post)) ,
        catchError((error) => of(new SearchResultsActions.GetSearchResultsError(error)))
      )
    )
    ));

	getSearchTermResults: Observable<SearchResultsAction> = createEffect(() =>
		this.actions$.pipe(
      ofType(SearchResultsActions.GET_SEARCH_TERM_RESULTS),
      switchMap((action: SearchResultsActions.GetSearchTermResults) =>
        this.searchResultsService.getSearchTermResults(action.searchTerm).pipe(
          map(post => new SearchResultsActions.GetSearchTermResultsSuccess(post)),
          catchError((error) => of(new SearchResultsActions.GetSearchTermResultsError(error)))
        )
      )));


}
