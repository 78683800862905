import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable ,  of } from 'rxjs';

import { ClientPmpService } from '../../../shared/pmp/pmp.service';
import * as ModelActions from './models.actions';
import { map, catchError, switchMap } from 'rxjs/operators';

export type ModelAction = ModelActions.All;

@Injectable()
export class ModelEffects {

	constructor(private actions$: Actions,
							private clientSlwiService: ClientPmpService) {
	}

	getAllModels: Observable<ModelAction> = createEffect(() => 
  this.actions$.pipe(
    ofType(ModelActions.GET_WIDGET_MODELS),
    switchMap((a: ModelActions.GetWidgetModels) => this.clientSlwiService.getAllWidgetModels(a.clientId, a.accountId).pipe(
      map(models => new ModelActions.GetWidgetModelsSuccess(models)),
      catchError((error) => of(new ModelActions.GetWidgetModelsError(error)))
    )
  )));

}
