import {JsonObject, JsonProperty} from 'json2typescript';
import { SearchCriteria } from '../../shared/entity/search-criteria.model';

@JsonObject('ApprovalsMetaData')
export class ApprovalsMetaData {

	@JsonProperty('owners', [SearchCriteria], true)
	owners: SearchCriteria[] = undefined;

	@JsonProperty('monitorFlags', [SearchCriteria], true)
	monitorFlags: SearchCriteria[] = undefined;

	@JsonProperty('teams', [SearchCriteria], true)
	teams: SearchCriteria[] = undefined;

	@JsonProperty('reasons', [SearchCriteria], true)
	reasons: SearchCriteria[] = undefined;

	@JsonProperty('requesters', [SearchCriteria], true)
	requesters: SearchCriteria[] = undefined;

	@JsonProperty('keStatuses', [SearchCriteria], true)
	keStatuses: SearchCriteria[] = undefined;

	@JsonProperty('approvers', [SearchCriteria], true)
	approvers: SearchCriteria[] = undefined;

	@JsonProperty('managers', [SearchCriteria], true)
	managers: SearchCriteria[] = undefined;
}
