import * as AuthenticationActions from './authentication.actions';
import { AuthenticationState } from './authentication.state';
import { Session } from '../../entity/session.model';

export type AuthenticationAction = AuthenticationActions.All;
export const initialState: AuthenticationState = {
  session: new Session(),
  loading: false
};

export function authenticationReducer(state: AuthenticationState = initialState,
  action: AuthenticationAction): AuthenticationState {

  switch (action.type) {

    case AuthenticationActions.REQUEST_LOGIN: {
      return { ...state, ...{ loading: true } };
    }

    case AuthenticationActions.REQUEST_LOGIN_SUCCESS: {
      return { ...state, ...{ session: action.session, loading: false } };
    }

    case AuthenticationActions.REQUEST_LOGIN_ERROR: {
      console.error('Error caught while trying to login', action.error);
      return { ...initialState, error: action.error };
    }

    case AuthenticationActions.CHECK_SINGLE_SIGN_ON_SUCCESS: {
      if (state.session && state.session.lastAccessedClientList) {
        action.session.lastAccessedClientList = Object.assign([], state.session.lastAccessedClientList);
      }
      return { ...state, ...{ session: action.session, loading: false } };
    }

    case AuthenticationActions.CHECK_SINGLE_SIGN_ON_ERROR: {
      console.error('Error caught while trying to check single sign on');
      return { ...state, ...{ session: action.session, loading: false } };
    }

    case AuthenticationActions.REQUEST_LOGOUT_SUCCESS: {
      if (state.session) {
        // keep authenticationConfig
        var session: Session = new Session();
        session.authenticationConfig = state.session.authenticationConfig;
        return { ...state, ... { session: session, loading: false } };
      }
      return { ...state, ... { session: undefined, loading: false } };
    }

    case AuthenticationActions.REQUEST_LOGOUT_ERROR: {
      console.error('Error caught while trying to logout', action.error);
      return { ...state, error: action.error };
    }
    case AuthenticationActions.CLEAR_ERROR: {
      return { ...state, error: undefined };
    }

    case AuthenticationActions.UPDATE_LAST_ACCESSED_CLIENT: {
      const newState = {
        ...state,
        session: {
          ...state.session,
          lastAccessedClientId: action.newClientId,
          lastAccessedClientList: Object.assign([], state.session.lastAccessedClientList)
        }
      };

      if (action.newClientId != null) {
        if (newState.session.lastAccessedClientList.indexOf(action.newClientId) > -1) {
          const index = newState.session.lastAccessedClientList.findIndex(clientId => clientId === action.newClientId);
          newState.session.lastAccessedClientList.splice(index, 1);
          newState.session.lastAccessedClientList.splice(0, 0, action.newClientId);
        } else {
          newState.session.lastAccessedClientList.unshift(action.newClientId);
        }
        if (newState.session.lastAccessedClientList.length > 10) {
          newState.session.lastAccessedClientList.length = 10;
        }
      }
      return newState;
    }

    default:
      return state;
  }
}
