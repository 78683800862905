import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {

  currentYear = new Date().getFullYear();
  bsModalRef: BsModalRef;

  @ViewChild('bsModalRef')
  copyRightTemplate: TemplateRef<any>;

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  openCopyRightModal() {
    this.bsModalRef = this.modalService.show(this.copyRightTemplate, {class: 'copyRightModal'});
  }

}
