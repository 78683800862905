import { Action } from '@ngrx/store';
import { ReadOnlyPortfolioModel } from '../../entity/read-only-portfolio.model';

export const GET_READ_ONLY_PORTFOLIO_MODEL = '[ReadOnlyPortfolio] GET_READ_ONLY_PORTFOLIO_MODEL';
export const GET_READ_ONLY_PORTFOLIO_MODEL_SUCCESS = '[ReadOnlyPortfolio] GET_READ_ONLY_PORTFOLIO_MODEL_SUCCESS';
export const GET_READ_ONLY_PORTFOLIO_MODEL_ERROR = '[ReadOnlyPortfolio] GET_READ_ONLY_PORTFOLIO_MODEL_ERROR';

export class GetReadOnlyPortfolioModel implements Action {
	readonly type = GET_READ_ONLY_PORTFOLIO_MODEL;

	constructor(public clientId: string, public accountId: string, public scenarioName: string) {}
}

export class GetReadOnlyPortfolioModelSuccess implements Action {
	readonly type = GET_READ_ONLY_PORTFOLIO_MODEL_SUCCESS;
	constructor(public pcm: ReadOnlyPortfolioModel) {}
}

export class GetReadOnlyPortfolioModelError implements Action {
	readonly type = GET_READ_ONLY_PORTFOLIO_MODEL_ERROR;
	constructor(public error: any) {
	}
}

export type All = GetReadOnlyPortfolioModel | GetReadOnlyPortfolioModelSuccess | GetReadOnlyPortfolioModelError;
