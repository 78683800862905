import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('PresetMonitorParameters')
export class PresetMonitorParameters {

  @JsonProperty('type', String)
  type: string  = undefined;

  @JsonProperty('selected', [String], true)
  selected: string[] = [];

  constructor(type: string, selected: string[]) {
    this.type = type;
    this.selected = selected;
}
}

@JsonObject('PresetStatusGroupParameters')
export class PresetStatusGroupParameters {

  @JsonProperty('type', String)
  type: string = undefined;

  @JsonProperty('selected', String, true)
  selected: string = undefined;
}

@JsonObject('Preset')
export class Preset {

  @JsonProperty('id', Number, true)
  id: number = undefined;

  @JsonProperty('name', String, true)
  name: string = undefined;

  @JsonProperty('editable', Boolean, true)
  editable: boolean = undefined;

  @JsonProperty('description', String, true)
  description: string = undefined;

  @JsonProperty('monitorParameters', [PresetMonitorParameters], true)
  monitorParameters: PresetMonitorParameters[] = undefined;

  @JsonProperty('statusGroup', [PresetStatusGroupParameters], true)
  statusGroup: PresetStatusGroupParameters[] = undefined;
}

export class ReportRequestParams {
  reportName: string;
  reportDescription: string;
  monitorParameters: PresetMonitorParameters[];
  statusGroup: PresetStatusGroupParameters[];
  reportFormat: string = null;
}

export const AllPlaceholderToken = '*ALL*';
