import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('AccountOverviewConfig')
export class AccountOverviewConfig {

  @JsonProperty('clientId', String)
  clientId: string = undefined;

  @JsonProperty('currencies', [String])
  currencies: string[] = undefined;

  @JsonProperty('defaultCurrency', String)
  defaultCurrency: string = undefined;

  @JsonProperty('sources', [String])
  sources: string[] = undefined;

  @JsonProperty('defaultSource', String)
  defaultSource: string = undefined;

  @JsonProperty('isAccountSourceVisibleGlobally', Boolean)
  isAccountSourceVisibleGlobally: boolean = undefined;

  @JsonProperty('accountNamePrefix', String, true)
  accountNamePrefix: string = undefined;

  @JsonProperty('accountNameSuggestion', [String])
  accountNameSuggestion: string[] = undefined;

  @JsonProperty('useAccountNamePrefix', Boolean)
  useAccountNamePrefix: boolean = undefined;

  @JsonProperty('defaultAccountName', String)
  defaultAccountName: string = undefined;

  @JsonProperty('useRiskCategory', Boolean)
  useRiskCategory: boolean = undefined;

  @JsonProperty('riskCategories', [String])
  riskCategories: string[] = undefined;

  @JsonProperty('useAccountTaxWrapper', Boolean)
  useAccountTaxWrapper: boolean = undefined;

  @JsonProperty('accountTaxWrappers', [String])
  accountTaxWrappers: string[] = undefined;

  @JsonProperty('disabledFields', [String])
  disabledFields: string[] = undefined;
}
