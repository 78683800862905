export function mockAssetAnalyticsColumns() {
  return [
    { field: 'classification', header: 'classification' },
    { field: 'icon', header: 'icon' },
    { field: 'assetName', header: 'name' },
    { field: 'assetIdentifier', header: 'identifier' },
    { field: 'assetCurrency', header: 'currency' },
    { field: 'assetValue', header: 'value' },
    { field: 'assetWeight', header: 'weight' },
    { field: 'mctr', header: 'mct' },
    { field: 'ctr', header: 'ctr' },
    { field: 'riskVolatility', header: 'vol current model' },
    { field: 'riskPreviousVolatility', header: 'vol previous model' },
    { field: 'riskVolatilityChange', header: 'vol change' },
    { field: 'riskMaxDrawDown', header: 'max draw down' },
    { field: 'riskCVaR', header: 'CVaR' },
    { field: 'riskNegativeReturns', header: '% -ve returns' },
    { field: 'riskBeta', header: 'BETA' },
    { field: 'returnThreeMonth', header: 'over last 3 mths' },
    { field: 'returnOneYear', header: 'over last 1 year' },
    { field: 'returnThreeYear', header: 'over last 3 years' },
    { field: 'returnSacFromMetadata', header: 'SUB ASSET CLASS' },
    { field: 'returnThreeMonthRelToAssetClass', header: 'relative to asset class 3 mths' },
    { field: 'returnOneYearRelToAssetClass', header: 'relative to asset class 12` mths' },
    { field: 'returnThreeYearRelToAssetClass', header: 'relative to asset class 36 mths' },
  ];
}
