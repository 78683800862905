import { Injectable } from '@angular/core';
import { GenericFormConfiguration } from '../entity/generic-form/generic-form.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormComponentBase } from '../entity/generic-form/form-component-element';
import { CustomValidators } from '../validators/custom-validators';

@Injectable()
export class GenericFormService {

  constructor() { }

  toFormGroup(formConfiguration: GenericFormConfiguration, formValue?: Map<string, string>): UntypedFormGroup {
    const group: any = {};
    formConfiguration.rowComponents.forEach(element => {
      const componentGroup: any = {};
      element.components.forEach(component => {
        let value: any = formValue ? formValue.get(component.key) : '';

        if (component.controlType === 'date' && value && value !== '') {
          // value = moment(value);
          value = new Date(value);
        }
        componentGroup[component.key] = new UntypedFormControl(value);
        componentGroup[component.key].setValidators(this.extractValidator(component));
        if(component.disabled ) {
          componentGroup[component.key].disable() 
        }
      });
      group[element.key] = new UntypedFormGroup(componentGroup);
    });

    return new UntypedFormGroup(group);
  }

  extractValidator(component: FormComponentBase<any>) {
    const validators: Validators[] = [];
    if (component.controlType === 'switchText') {
      if (component.required && !component.disabled) {
        validators.push(Validators.required);
      }
    }
    else if (component.required) {
      validators.push(Validators.required);
    }

    if (component.validators) {
      component.validators.forEach((value, key) => {
        switch (key.toLowerCase()) {
          case 'maxlength': {
            let maxLength = value.get('maxLength');
            maxLength = maxLength ? maxLength : '0';
            validators.push(Validators.maxLength(+maxLength));
            break;
          }
          case 'numberwithoptionaldecimal': {
            validators.push(CustomValidators.numberWithOptionalDecimal);
            break;
          }
          case 'futuredate': {
            validators.push(CustomValidators.futureDate);
            break;
          }
        }
      });
    }
    return validators;
  }

  extractAllSelectedValuesFromForm(formConfig: GenericFormConfiguration, form: UntypedFormGroup ): any {
    const collectedValue = {};
    formConfig.rowComponents.forEach(rowcomponent => {
      rowcomponent.components.forEach( inputComponent => {
        if (inputComponent.controlType === 'dropdown') {
          const dropDownValue = form.get(rowcomponent.key).get(inputComponent.key).value;

          if (dropDownValue && dropDownValue.key) {
            collectedValue[inputComponent.key] = dropDownValue.key;
          } else {
            collectedValue[inputComponent.key] = dropDownValue;
          }
        } else {
          collectedValue[inputComponent.key] = form.get(rowcomponent.key).get(inputComponent.key).value;
        }
      });
    });

    return collectedValue;
  }

}
